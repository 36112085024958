<template>
  <a @click="setShowFeedbackModal" :class="outerCssClass" v-tooltip="`Send Feedback`">
    <svg :class="innerCssClass" data-slot="icon" fill="none" stroke-width="1.5" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"></path>
    </svg>
  </a>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "FeedbackButton",
  props: {
    outerCssClass: {
      type: String,
      default: "cursor-pointer ",
    },
    innerCssClass: {
      type: String,
      default: "w-7 h-7 home-screen-icon",
    },
  },
  setup() {
    const store = useStore();
    const setShowFeedbackModal = () => store.commit("setShowFeedbackModal", true);
    return { setShowFeedbackModal };
  },
};
</script>
