import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, copiedLinksUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    copiedLinks: [],
  },
  getters: {},
  mutations: {
    setCopiedLinkData(state, copiedLinksData) {
      state.copiedLinks = copiedLinksData?.sort((cl1, cl2) => (cl1.Name > cl2.Name ? 1 : -1)) ?? [];
    },
    clearCopiedLinks(state) {
      state.copiedLinks = [];
    },
    _addCopiedLink(state, copiedLink) {
      const copiedLinksData = _.cloneDeep(state.copiedLinks) ?? [];
      copiedLinksData.push(copiedLink);
      state.copiedLinks = copiedLinksData.sort((cl1, cl2) => (cl1.Name > cl2.Name ? 1 : -1)) ?? [];
    },
    _updateCopiedLink(state, updateCopiedLink) {
      const rootId = Number(updateCopiedLink?.RootId ?? 0);
      if (rootId > 0) {
        let copiedLinksData = _.cloneDeep(state.copiedLinks) ?? [];
        state.copiedLinks = [...copiedLinksData.filter((copiedLink) => Number(copiedLink?.RootId ?? 0) !== rootId), _.cloneDeep(updateCopiedLink)].sort((cl1, cl2) => (cl1.Name > cl2.Name ? 1 : -1)) ?? [];
      }
    },
    _removeCopiedLink(state, deleteCopiedLink) {
      const rootId = Number(deleteCopiedLink?.RootId ?? 0);
      if (rootId > 0) {
        let copiedLinksData = _.cloneDeep(state.copiedLinks) ?? [];
        state.copiedLinks = [...copiedLinksData.filter((copiedLink) => Number(copiedLink?.RootId ?? 0) !== rootId)].sort((cl1, cl2) => (cl1.Name > cl2.Name ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getCopiedLinksData(context, { UserRootId, LinksAttachmentRootId, IncludeMetadata, MaxResults }) {
      await timeOperation(async () => {
        const formattedUrl = `${copiedLinksUrl
          }?userrootid=${Number(UserRootId ?? 0)
          }&linksattachmentrootid=${Number(LinksAttachmentRootId ?? 0)
          }&maxresults=${Number(MaxResults ?? 10)
          }&includemetadata=${IncludeMetadata ?? true}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setCopiedLinkData", data);
      }, "copiedLinks - getCopiedLinksData");
    },
    async addCopiedLink(context, copiedLink) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: copiedLinksUrl, Payload: copiedLink });
        context.commit("_addCopiedLink", data);
        return data;
      }, "copiedLinks - addCopiedLink");
    },
    async updateCopiedLink(context, copiedLink) {
      await timeOperation(async () => {
        const formattedUrl = `${copiedLinksUrl}/${Number(copiedLink?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: copiedLink });
        context.commit("_updateCopiedLink", data);
      }, "copiedLinks - updateCopiedLink");
    },
    async removeCopiedLink(context, copiedLink) {
      await timeOperation(async () => {
        const formattedUrl = `${copiedLinksUrl}/${Number(copiedLink?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "copiedLinks - removeCopiedLink");
    },
  },
};
