import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productSearchLocationsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productSearchLocations: [],
    lastProductSearchLocationProductRootId: 0,
  },
  getters: {},
  mutations: {
    setProductSearchLocationData(state, productSearchLocations) {
      state.productSearchLocations = productSearchLocations ?? [];
    },
    clearProductSearchLocations(state) {
      state.productSearchLocations = [];
      state.lastProductSearchLocationProductRootId = 0;
    },
    setLastProductSearchLocationProductRootId(state, rootId) {
      state.lastProductSearchLocationProductRootId = rootId;
    },
    _addProductSearchLocation(state, productSearchLocation) {
      const productSearchLocationsData = _.cloneDeep(state.productSearchLocations) ?? [];
      productSearchLocationsData.push(productSearchLocation);
      state.productSearchLocations = productSearchLocationsData;
    },
    _updateProductSearchLocation(state, updateProductSearchLocation) {
      const rootId = Number(updateProductSearchLocation?.RootId ?? 0);
      if (rootId > 0) {
        let productSearchLocationsData = _.cloneDeep(state.productSearchLocations) ?? [];
        productSearchLocationsData =
          [...productSearchLocationsData.filter((productSearchLocation) => Number(productSearchLocation?.RootId ?? 0) !== rootId), _.cloneDeep(updateProductSearchLocation)] ?? [];
        state.productSearchLocations = productSearchLocationsData;
      }
    },
    _removeProductSearchLocation(state, deleteProductSearchLocation) {
      const rootId = Number(deleteProductSearchLocation?.RootId ?? 0);
      if (rootId > 0) {
        let productSearchLocationsData = _.cloneDeep(state.productSearchLocations) ?? [];
        productSearchLocationsData = [...productSearchLocationsData.filter((productSearchLocation) => Number(productSearchLocation?.RootId ?? 0) !== rootId)] ?? [];
        state.productSearchLocations = productSearchLocationsData;
      }
    },
  },
  actions: {
    async getProductSearchLocations(context, { ProductRootId }) {
      await timeOperation(async () => {
        if (context.state.lastProductSearchLocationProductRootId === ProductRootId) {
          return;
        } // Already loaded
        context.commit("setLastProductSearchLocationProductRootId", ProductRootId);
        const formattedUrl = `${productSearchLocationsUrl}?productrootid=${ProductRootId}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductSearchLocationData", data);
      }, "productSearchLocations - getProductSearchLocations");
    },
    async addProductSearchLocation(context, productSearchLocation) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productSearchLocationsUrl, Payload: productSearchLocation });
        if (data?.RootId > 0) {
          productSearchLocation.RootId = data.RootId;
          context.commit("_addProductSearchLocation", data);
        }
        return data;
      }, "productSearchLocations - addProductSearchLocation");
    },
    async updateProductSearchLocation(context, productSearchLocation) {
      await timeOperation(async () => {
        const formattedUrl = `${productSearchLocationsUrl}/${Number(productSearchLocation?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productSearchLocation });
        context.commit("_updateProductSearchLocation", data);
      }, "productSearchLocations - updateProductSearchLocation");
    },
    async removeProductSearchLocation(context, productSearchLocation) {
      await timeOperation(async () => {
        const formattedUrl = `${productSearchLocationsUrl}/${Number(productSearchLocation?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductSearchLocation", productSearchLocation);
      }, "productSearchLocations - removeProductSearchLocation");
    },
  },
};
