import { FORM_LAYOUT_CONTROLS_IS_IMMUTABLE, FORM_LAYOUT_CONTROLS_IS_RICH_TEXT } from "@/types/formLayoutControlsTypes.js";
import { TEXT, LARGE_TEXT_EDITOR } from "@/utils/types/inputTypes.js";

export const TEMPLATE_BUILDER_HEIGHT_KEY = "height";
export const TEMPLATE_BUILDER_WIDTH_KEY = "width";
export const TEMPLATE_BUILDER_X_POS = "left";
export const TEMPLATE_BUILDER_Y_POS = "top";
export const TEMPLATE_BUILDER_TYPE_KEY = "Type";
export const TEMPLATE_BUILDER_LOCK_ASPECT_RATIO = "lockAspectRatio";

export function getFormItemType(formItem, key = "Type") {
  if (formItem?.[FORM_LAYOUT_CONTROLS_IS_RICH_TEXT]) {
    return LARGE_TEXT_EDITOR;
  }
  if (formItem?.[FORM_LAYOUT_CONTROLS_IS_IMMUTABLE]) {
    return TEXT;
  }
  return formItem[key] ?? "";
}
