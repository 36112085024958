<template>
  <BackgroundOverlay
    :grow-with-content="growWithContent"
    :show="show"
    :isModal="true"
    :fadeBackground="fadeBackground"
    :on-top-of-modal="onTopOfModal"
    :fillScreen="fillScreen"
    :expandToMaxSize="expandToMaxSize"
  >
    <div class="container-fluid" :class="containerClasses">
      <button v-if="clickToClose" @click="hideModal" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div v-if="displayLogo" class="overflow-x-hidden">
        <template v-if="displayAdminLogo">
          <div class="pt-2 ml-2 flex">
            <img class="" src="../../assets/images/truly-logo-gray.png" alt="" style="width: 100px !important" />
            <h4 style="margin-bottom: 0 !important; line-height: 1 !important" class="mx-2">| Admin Portal</h4>
          </div>
        </template>
        <template v-else-if="displayLinksLogo">
          <div class="row justify-content-center pt-3">
            <img class="logo" src="../../assets/images/links-color-136w.png" alt="" />
          </div>
        </template>
        <template v-else-if="displayTrulyEsqLogo">
          <div class="row justify-content-center pt-3">
            <img class="logo" src="../../assets/images/esq-logo-gray.png" alt="" style="width: 100px" />
          </div>
        </template>
        <template v-else-if="displayDesqLogo">
          <div class="row justify-content-center pt-3">
            <img class="logo" src="../../assets/images/d-esq-logo-gray.png" alt="" style="width: 100px" />
          </div>
        </template>
        <hr />
      </div>
      <template v-if="hasMessage">
        <div class="row justify-content-center pt-2 psides-3rem">
          <p style="font-size: 1.2rem">
            {{ message }}
          </p>
        </div>
      </template>
      <div class="overflow-x-hidden">
        <div
          :class="`${classSettings} ${innerContainerClasses}`"
          class="text-black overflow-x-hidden mx-2"
          :style="innerContainerStyles"
          style="background-color: white !important"
        >
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="h-20">
      <div v-if="showSaveButtonSpinner" class="pt-4 pb-3" style="padding-bottom: 20px">
        <div class="w-full flex justify-content-center">
          <div class="mx-2 flex">
            <button :class="button.cssClass" v-for="(button, index) in buttons" :disabled="button.isDisabled" :key="index" @click="onButtonClick(button)">
              {{ button.text }}
              <i v-if="button.isSaveLoading" class="fa fa-spinner fa-spin"></i>
            </button>
          </div>
        </div>
        <div class="ml-auto w-full text-right relative bottom-8" style="pointer-events: none">
          <slot name="end" />
        </div>
      </div>
      <div v-else class="pt-4 pb-3">
        <div class="w-full flex justify-content-center">
          <div class="mx-2 flex">
            <button :class="button.cssClass" v-for="(button, index) in buttons" :disabled="button.isDisabled" :key="index" @click="onButtonClick(button)">
              {{ button.text }}
            </button>
          </div>
        </div>
        <div class="ml-auto w-full text-right relative bottom-8" style="pointer-events: none">
          <slot name="end" />
        </div>
      </div>
    </div>
  </BackgroundOverlay>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import BackgroundOverlay from "@/components/uIElements/BackgroundOverlay";

import { TrulyEsqProductRootId, DesqProductRootId, LinksProductRootId } from "@/lib/settings";
export default {
  name: "LogoModal",
  components: {
    BackgroundOverlay,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    growWithContent: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    innerContainerStyles: {
      type: Object,
      default: () => ({}),
    },
    containerClasses: {
      type: String,
      default: "",
    },
    clickToClose: {
      type: Boolean,
      default: true,
    },
    showSaveButtonSpinner: {
      type: Boolean,
      default: false,
    },
    disableSizeSettings: {
      type: Boolean,
      default: false,
    },
    fadeBackground: {
      type: Boolean,
      default: true,
    },
    hideLogo: {
      type: Boolean,
      default: false,
    },
    onTopOfModal: {
      type: Boolean,
      default: false,
    },
    innerContainerClasses: {
      type: String,
      default: "",
    },
    showDesqLogo: {
      type: Boolean,
      default: false,
    },
    showTrulyEsqLogo: {
      type: Boolean,
      default: false,
    },
    fillScreen: {
      type: Boolean,
      default: false,
    },
    expandToMaxSize: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:show", "buttonClicked"],
  setup(props, { emit }) {
    const route = useRoute();

    const store = useStore();
    // Getters
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);

    // Computed
    const hasMessage = computed(() => props.message?.length > 0);
    const displayLogo = computed(() => !props.hideLogo && (displayAdminLogo.value || displayLinksLogo.value || displayTrulyEsqLogo.value || displayDesqLogo.value));

    const isOnLinks = computed(() => selectedProduct.value === "links");
    const isOnTrulyEsq = computed(() => selectedProduct.value === "trulyesq");
    const isOnDesq = computed(() => selectedProduct.value === "desq");
    const selectedProduct = computed(() => {
      const hostName = window.location.host ?? "";
      const fullPath = route.fullPath.toLowerCase();
      if (hostName.includes("links") || fullPath.includes("links")) {
        return "links";
      }
      if (hostName.includes("truly-esq") || hostName.includes("trulyesq") || fullPath.includes("truly-esq") || fullPath.includes("trulyesq")) {
        return "trulyesq";
      }
      return "desq";
    });
    const displayAdminLogo = computed(() => route.path.toLowerCase().includes("admin"));
    const displayLinksLogo = computed(() => isOnLinks.value || ((isOnViewsEditor.value || isOnUserSettings.value) && getGlobalSelectedProductRootId.value === LinksProductRootId));
    const displayTrulyEsqLogo = computed(
      () => props.showTrulyEsqLogo || isOnTrulyEsq.value || ((isOnViewsEditor.value || isOnUserSettings.value) && getGlobalSelectedProductRootId.value === TrulyEsqProductRootId)
    );
    const displayDesqLogo = computed(
      () =>
        props.showDesqLogo ||
        isOnDesq.value ||
        ((isOnViewsEditor.value || isOnUserSettings.value || isOnDesqCreateOrder.value) && getGlobalSelectedProductRootId.value === DesqProductRootId)
    );
    const isOnViewsEditor = computed(() => route.path.toLowerCase().includes("views-editor"));
    const isOnDesqCreateOrder = computed(() => route.path.toLowerCase().includes("place-desq-order"));
    const isOnUserSettings = computed(() => route.path.toLowerCase().includes("user-settings"));
    const classSettings = computed(() =>
      props.disableSizeSettings || props.fillScreen
        ? "row justify-content-center pt-2 overflow-y-auto max-height-s"
        : "row justify-content-center pt-2 overflow-y-auto max-w-100 max-h-100"
    );

    // Functions
    function hideModal() {
      emit("update:show", false);
    }
    function onButtonClick(button) {
      emit("buttonClicked", button);
    }

    return {
      hasMessage,
      displayLogo,
      displayAdminLogo,
      displayLinksLogo,
      displayTrulyEsqLogo,
      displayDesqLogo,
      classSettings,
      hideModal,
      onButtonClick,
    };
  },
};
</script>

<style scoped>
.max-h-100 {
  max-height: 30rem;
}
.max-w-100 {
  max-width: 75rem;
}
.max-height-s {
  max-height: calc(85vh - 7rem); /* this adds the button height below this content*/
}
</style>
