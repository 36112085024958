// Make sure to register before importing any components
import "tailwindcss/tailwind.css";
import { createApp } from "vue";
import App from "./App.vue";
import _ from "lodash";
// import Vue from 'vue';

import mitt from "mitt";
const emitter = mitt();
import VueTelInput from "vue-tel-input";
// import VTooltipPlugin from "v-tooltip";
import VueFab from "vue-float-action-button";
//import { DashboardLayoutPlugin } from '@syncfusion/ej2-vue-layouts';
// import Vuetify from 'vuetify';
import { VTooltip } from "v-tooltip";

import Cognito from "./store/cognito-init";

import "font-awesome/css/font-awesome.min.css";

import { store } from "./store";
import router from "./router";
import { registerLicense } from "@syncfusion/ej2-base";

registerLicense(process.env.VUE_APP_SYNCFUSION_API_KEY);

// import VueTour from 'vue-tour';  // FIXME: doesn't work with vue3

// require('vue-tour/dist/vue-tour.css');

const app = createApp(App).use(store).use(router);
app.config.devtools = process.env.NODE_ENV === "debug";
app.config.performance = process.env.NODE_ENV === "debug";

app.directive("tooltip", VTooltip);
app.directive("height", (el, binding) => {
  if (_.endsWith(binding.value, "%")) {
    el.style.height = binding.value;
    return;
  }
  el.style.height = binding.value + "px";
});
app.directive("min-height", (el, binding) => {
  if (_.endsWith(binding.value, "%")) {
    el.style["min-height"] = binding.value;
    return;
  }
  el.style["min-height"] = binding.value + "px";
});
app.directive("width", (el, binding) => {
  if (_.endsWith(binding.value, "%")) {
    el.style.width = binding.value;
    return;
  }
  el.style.width = binding.value + "px";
});
app.directive("min-width", (el, binding) => {
  if (_.endsWith(binding.value, "%")) {
    el.style["min-width"] = binding.value;
    return;
  }
  el.style["min-width"] = binding.value + "px";
});

app.config.globalProperties.emitter = emitter;
app.provide("emitter", emitter); // provide for injecting into setup

app.use(VueFab);

// FIXME: Vue 3 migration. Make sure anywhere using these components imports them. See: https://ej2.syncfusion.com/vue/documentation/getting-started/vue3-tutorial/
//app.use(DashboardLayoutPlugin);
// FIXME: Vue 3 migration. https://floating-vue.starpad.dev/migration/migration-from-v3.html#breaking-changes
//app.use(VTooltipPlugin);
app.use(VueTelInput);
// app.use(Vuetify);
app.use(Cognito);
// app.use(VueTour);

app.mount("#app");
