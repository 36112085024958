import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, viewedOrdersUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    viewedOrders: [],
  },
  getters: {},
  mutations: {
    setViewedOrderData(state, viewedOrdersData) {
      state.viewedOrders = viewedOrdersData?.sort((vo1, vo2) => (vo1.Name > vo2.Name ? 1 : -1)) ?? [];
    },
    clearViewedOrders(state) {
      state.viewedOrders = [];
    },
    _addViewedOrder(state, viewedOrder) {
      const viewedOrdersData = _.cloneDeep(state.viewedOrders) ?? [];
      viewedOrdersData.push(viewedOrder);
      state.viewedOrders = viewedOrdersData.sort((vo1, vo2) => (vo1.Name > vo2.Name ? 1 : -1)) ?? [];
    },
    _updateViewedOrder(state, updateViewedOrder) {
      const rootId = Number(updateViewedOrder?.RootId ?? 0);
      if (rootId > 0) {
        let viewedOrdersData = _.cloneDeep(state.viewedOrders) ?? [];
        state.viewedOrders = [...viewedOrdersData.filter((viewedOrder) => Number(viewedOrder?.RootId ?? 0) !== rootId), _.cloneDeep(updateViewedOrder)].sort((vo1, vo2) => (vo1.Name > vo2.Name ? 1 : -1)) ?? [];
      }
    },
    _removeViewedOrder(state, deleteViewedOrder) {
      const rootId = Number(deleteViewedOrder?.RootId ?? 0);
      if (rootId > 0) {
        let viewedOrdersData = _.cloneDeep(state.viewedOrders) ?? [];
        state.viewedOrders = [...viewedOrdersData.filter((viewedOrder) => Number(viewedOrder?.RootId ?? 0) !== rootId)].sort((vo1, vo2) => (vo1.Name > vo2.Name ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getViewedOrdersData(context, { UserRootId, OrderRootId, IncludeMetadata, MaxResults }) {
      await timeOperation(async () => {
        const formattedUrl = `${viewedOrdersUrl
          }?userrootid=${Number(UserRootId ?? 0)
          }&orderrootid=${Number(OrderRootId ?? 0)
          }&maxresults=${Number(MaxResults ?? 10)
          }&includemetadata=${IncludeMetadata ?? true}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setViewedOrderData", data);
      }, "viewedOrders - getViewedOrdersData");
    },
    async addViewedOrder(context, viewedOrder) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: viewedOrdersUrl, Payload: viewedOrder });
        context.commit("_addViewedOrder", data);
        return data;
      }, "viewedOrders - addViewedOrder");
    },
    async updateViewedOrder(context, viewedOrder) {
      await timeOperation(async () => {
        const formattedUrl = `${viewedOrdersUrl}/${Number(viewedOrder?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: viewedOrder });
        context.commit("_updateViewedOrder", data);
      }, "viewedOrders - updateViewedOrder");
    },
    async removeViewedOrder(context, viewedOrder) {
      await timeOperation(async () => {
        const formattedUrl = `${viewedOrdersUrl}/${Number(viewedOrder?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "viewedOrders - removeViewedOrder");
    },
  },
};
