import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productCustomFieldEntriesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productDataSourceCustomFieldEntries: {},
  },
  getters: {
    getProductCustomFieldEntryByRootId: (state) => (rootId) => {
      const productCustomFieldEntries = _.reduce(
        state.productDataSourceCustomFieldEntries,
        (result, listOfProductCustomFieldEntries) => {
          if (listOfProductCustomFieldEntries?.length > 0) {
            listOfProductCustomFieldEntries.forEach((productCustomFieldEntry) => {
              result.push(productCustomFieldEntry);
            });
          }
          return result;
        },
        []
      );
      const productCustomFieldEntry =
        productCustomFieldEntries.find((productCustomFieldEntry) => Number(productCustomFieldEntry?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
      return productCustomFieldEntry?.RootId > 0 ? _.cloneDeep(productCustomFieldEntry) : null;
    },
  },
  mutations: {
    setProductDataSourceCustomFieldEntries(state, { ProductDataSourceRootId, Data }) {
      if (ProductDataSourceRootId > 0) {
        state.productDataSourceCustomFieldEntries[ProductDataSourceRootId] = Data?.sort((pcfe1, pcfe2) => (pcfe1.Position > pcfe2.Position ? 1 : -1)) ?? [];
      } else {
        let productDataSourceCustomFieldEntries = {};
        Data?.forEach((productCustomFieldEntry) => {
          const productDataSourceRootId = Number(productCustomFieldEntry?.ProductDataSourceRootId ?? 0);
          if (productDataSourceRootId > 0) {
            if (!productDataSourceCustomFieldEntries.hasOwnProperty(productDataSourceRootId)) {
              productDataSourceCustomFieldEntries[productDataSourceRootId] = [];
            }
            productDataSourceCustomFieldEntries[productDataSourceRootId].push(_.cloneDeep(productCustomFieldEntry));
          }
        });
        state.productDataSourceCustomFieldEntries = productDataSourceCustomFieldEntries;
      }
    },
    clearProductCustomFieldEntries(state) {
      state.productDataSourceCustomFieldEntries = {};
    },
    _addProductCustomFieldEntry(state, productCustomFieldEntry) {
      if (productCustomFieldEntry?.RootId > 0) {
        const productDataSourceRootId = Number(productCustomFieldEntry?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomFieldEntries.hasOwnProperty(productDataSourceRootId)) {
          state.productDataSourceCustomFieldEntries[productDataSourceRootId].push(productCustomFieldEntry);
          state.productDataSourceCustomFieldEntries[productDataSourceRootId] = state.productDataSourceCustomFieldEntries[productDataSourceRootId].sort((pcfe1, pcfe2) => (pcfe1.Position > pcfe2.Position ? 1 : -1)) ?? [];
        } else {
          state.productDataSourceCustomFieldEntries[productDataSourceRootId] = [productCustomFieldEntry];
        }
      }
    },
    _updateProductCustomFieldEntry(state, updateProductCustomFieldEntry) {
      const rootId = Number(updateProductCustomFieldEntry?.RootId ?? 0);
      if (rootId > 0) {
        const productDataSourceRootId = Number(updateProductCustomFieldEntry?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomFieldEntries.hasOwnProperty(productDataSourceRootId)) {
          let productCustomFieldEntriesData = _.cloneDeep(state.productDataSourceCustomFieldEntries[productDataSourceRootId]) ?? [];
          productCustomFieldEntriesData = [...productCustomFieldEntriesData.filter((productCustomFieldEntry) => Number(productCustomFieldEntry?.RootId ?? 0) !== rootId), _.cloneDeep(updateProductCustomFieldEntry)] ?? [];
          state.productDataSourceCustomFieldEntries[productDataSourceRootId] = (_.map(productCustomFieldEntriesData, (productCustomFieldEntry) => {return { ...productCustomFieldEntry, CanEdit: Number(productCustomFieldEntry.ProductCustomFieldRootId ?? 0) < 1 }}) ?? []).sort((pcfe1, pcfe2) => (pcfe1.Position > pcfe2.Position ? 1 : -1)) ?? [];
        } else {
          state.productDataSourceCustomFieldEntries[productDataSourceRootId] = [updateProductCustomFieldEntry];
        }
      }
    },
    _updateProductCustomFieldEntryEnabled(state, updateProductCustomFieldEntry) {
      const rootId = Number(updateProductCustomFieldEntry?.RootId ?? 0);
      if (rootId > 0) {
        const productDataSourceRootId = Number(updateProductCustomFieldEntry?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomFieldEntries.hasOwnProperty(productDataSourceRootId)) {
          let productCustomFieldEntry = state.productDataSourceCustomFieldEntries[productDataSourceRootId]?.find((productCustomFieldEntry) => Number(productCustomFieldEntry?.RootId ?? 0) === rootId) ?? null;
          if (productCustomFieldEntry?.RootId > 0) {
            productCustomFieldEntry.Enabled = updateProductCustomFieldEntry.Enabled;
          }
        }
      }
    },
    _removeProductCustomFieldEntry(state, deleteProductCustomFieldEntry) {
      const rootId = Number(deleteProductCustomFieldEntry?.RootId ?? 0);
      if (rootId > 0) {
        const productDataSourceRootId = Number(deleteProductCustomFieldEntry?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomFieldEntries.hasOwnProperty(productDataSourceRootId)) {
          let productCustomFieldEntriesData = _.cloneDeep(state.productDataSourceCustomFieldEntries[productDataSourceRootId]) ?? [];
          productCustomFieldEntriesData =
            [...productCustomFieldEntriesData.filter((productCustomFieldEntry) => Number(productCustomFieldEntry?.RootId ?? 0) !== rootId)] ?? [];
          state.productDataSourceCustomFieldEntries[productDataSourceRootId] =
            (
              _.map(productCustomFieldEntriesData, (productCustomFieldEntry) => {
                return { ...productCustomFieldEntry, CanEdit: Number(productCustomFieldEntry.ProductCustomFieldRootId ?? 0) < 1 };
              }) ?? []
            ).sort((pcfe1, pcfe2) => (pcfe1.Position > pcfe2.Position ? 1 : -1)) ?? [];
        }
      }
    },
  },
  actions: {
    async getProductCustomFieldEntries(context, { ProductRootId, ProductDataSourceRootId, UseCache }) {
      const isCached = UseCache && context.state.productDataSourceCustomFieldEntries?.hasOwnProperty(ProductDataSourceRootId);
      const hasProductsCustomFieldEntries =
        isCached &&
        context.state.productDataSourceCustomFieldEntries[ProductDataSourceRootId]?.find(
          (productCustomFieldEntry) => Number(productCustomFieldEntry?.ProductRootId ?? 0) === Number(ProductRootId ?? 0)
        );
      if (!hasProductsCustomFieldEntries) {
        await timeOperation(async () => {
          context.commit("setProductDataSourceCustomFieldEntries", { ProductDataSourceRootId, Data: [] });
          const formattedUrl = `${productCustomFieldEntriesUrl}?productrootid=${ProductRootId}&productcustomfieldrootid=0&productdatasourcerootid=${ProductDataSourceRootId}&includemetadata=true`;
          const Data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setProductDataSourceCustomFieldEntries", { ProductDataSourceRootId, Data });
        }, "productCustomFieldEntries - getProductCustomFieldEntries");
      }
    },
    async addProductCustomFieldEntry(context, productCustomFieldEntry) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productCustomFieldEntriesUrl, Payload: productCustomFieldEntry });
        if (data?.RootId > 0) {
          productCustomFieldEntry.RootId = data.RootId;
          context.commit("_addProductCustomFieldEntry", data);
        }
        return data;
      }, "productCustomFieldEntries - addProductCustomFieldEntry");
    },
    async updateProductCustomFieldEntry(context, productCustomFieldEntry) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomFieldEntriesUrl}/${Number(productCustomFieldEntry?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productCustomFieldEntry });
        context.commit("_updateProductCustomFieldEntry", data);
      }, "productCustomFieldEntries - updateProductCustomFieldEntry");
    },
    async enableProductCustomFieldEntry(context, productCustomFieldEntry) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomFieldEntriesUrl}/${Number(productCustomFieldEntry?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...productCustomFieldEntry, Enabled: true } });
        context.commit("_updateProductCustomFieldEntryEnabled", data);
      }, "productCustomFieldEntries - enableProductCustomFieldEntry");
    },
    async disableProductCustomFieldEntry(context, productCustomFieldEntry) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomFieldEntriesUrl}/${Number(productCustomFieldEntry?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...productCustomFieldEntry, Enabled: false } });
        context.commit("_updateProductCustomFieldEntryEnabled", data);
      }, "productCustomFieldEntries - disableProductCustomFieldEntry");
    },
    async updateProductCustomFieldEntryPositions(context, productCustomFieldEntries) {
      await timeOperation(async () => {
        if (productCustomFieldEntries?.length > 0) {
          context.commit("setProductCustomFieldEntryData", []);
          const formattedUrl = `${productCustomFieldEntriesUrl}/updatepositions`;
          const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: productCustomFieldEntries });
          context.commit("setProductCustomFieldEntryData", data);
        }
      }, "productCustomFieldEntries - updateProductCustomFieldEntryPositions");
    },
    async removeProductCustomFieldEntry(context, productCustomFieldEntry) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomFieldEntriesUrl}/${Number(productCustomFieldEntry?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductCustomFieldEntry", productCustomFieldEntry);
      }, "productCustomFieldEntries - removeProductCustomFieldEntry");
    },
  },
};
