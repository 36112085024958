import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, partiesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    parties: [],
  },
  getters: {},
  mutations: {
    setPartyData(state, partiesData) {
      state.parties = partiesData?.sort((party1, party2) => (party1.Name > party2.Name ? 1 : -1)) ?? [];
    },
    clearParties(state) {
      state.parties = [];
    },
    _addParty(state, party) {
      const partiesData = _.cloneDeep(state.parties) ?? [];
      partiesData.push(party);
      state.parties = partiesData.sort((party1, party2) => (party1.Name > party2.Name ? 1 : -1)) ?? [];
    },
    _updateParty(state, updateParty) {
      const rootId = Number(updateParty?.RootId ?? 0);
      if (rootId > 0) {
        let partiesData = _.cloneDeep(state.parties) ?? [];
        partiesData =
          [...partiesData.filter((party) => Number(party?.RootId ?? 0) !== rootId), _.cloneDeep(updateParty)].sort((party1, party2) => (party1.Name > party2.Name ? 1 : -1)) ?? [];
        state.parties = partiesData;
      }
    },
    _removeParty(state, deleteParty) {
      const rootId = Number(deleteParty?.RootId ?? 0);
      if (rootId > 0) {
        let partiesData = _.cloneDeep(state.parties) ?? [];
        partiesData = [...partiesData.filter((party) => Number(party?.RootId ?? 0) !== rootId)].sort((party1, party2) => (party1.Name > party2.Name ? 1 : -1)) ?? [];
        state.parties = partiesData;
      }
    },
  },
  actions: {
    async getPartiesData(context, includeMetadata) {
      await timeOperation(async () => {
        const formattedUrl = `${partiesUrl}?includemetadata=${includeMetadata ?? false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setPartyData", data);
      }, "parties - getPartiesData");
    },
    async addParty(context, party) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: partiesUrl, Payload: party });
        context.commit("_addParty", data);
        return data;
      }, "parties - addParty");
    },
    async updateParty(context, party) {
      await timeOperation(async () => {
        const formattedUrl = `${partiesUrl}/${Number(party?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: party });
        context.commit("_updateParty", data);
      }, "parties - updateParty");
    },
    async removeParty(context, party) {
      await timeOperation(async () => {
        const formattedUrl = `${partiesUrl}/${Number(party?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeParty", party);
      }, "parties - removeParty");
    },
  },
};
