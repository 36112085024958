import {
  ApiRequest,
  GetVerb,
  PostVerb,
  PutVerb,
  DeleteVerb,
  documentTypesUrl,
  documentTypePartiesUrl,
  documentTypePartyRolesUrl,
  documentTypeTitlesUrl,
  documentTypeCategoriesUrl,
} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    documentTypes: [],
    selectedAdminDocumentType: {},
    documentTypeTitles: [],
    cachedDocumentTypesProductRootId: -1,
    cachedDocumentTypesOrganizationRootId: -1,
  },
  getters: {
    getDocumentTypeByRootId: (state) => (rootId) => {
      return state.documentTypes.find((documentType) => Number(documentType?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getDocumentTypeByName: (state) => (name) => {
      return state.documentTypes.find((documentType) => String(documentType?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getNextDocumentTypeRootId: (state) => {
      let result = 1;
      state.documentTypes.forEach((documentType) => {
        if (Number(documentType.RootId) >= result) {
          result = Number(documentType.RootId) + 1;
        }
      });
      return result;
    },
    isDocumentTypeNameTaken: (state) => (currentDocumentType) => {
      const { documentTypes } = state;
      if (documentTypes?.length < 1) {
        console.error("no document types");
        return false;
      }
      if (!currentDocumentType) {
        console.error("no document type given");
        return true;
      }
      const { Name: currentTypeName, RootId } = currentDocumentType;
      if (!currentTypeName) {
        console.error("no name");
        return true;
      }
      if (!RootId) {
        console.error("invalid document type");
        return true;
      }
      return !!documentTypes.find((docType) => docType?.Name === currentTypeName && RootId !== docType?.RootId);
    },
  },
  mutations: {
    setDocumentTypeTitles(state, newDocumentTypeTitlesData) {
      state.documentTypeTitles = _.cloneDeep(newDocumentTypeTitlesData);
    },
    setSelectedAdminDocumentType(state, newSelectedAdminDocumentType) {
      state.selectedAdminDocumentType = _.cloneDeep(newSelectedAdminDocumentType);
    },
    setCachedDocumentTypesProductRootId(state, cachedDocumentTypesProductRootId) {
      state.cachedDocumentTypesProductRootId = Number(cachedDocumentTypesProductRootId ?? -1);
    },
    setCachedDocumentTypesOrganizationRootId(state, cachedDocumentTypesOrganizationRootId) {
      state.cachedDocumentTypesOrganizationRootId = Number(cachedDocumentTypesOrganizationRootId ?? -1);
    },
    setDocumentTypes(state, documentTypesData) {
      state.documentTypes = documentTypesData?.sort((dt1, dt2) => (dt1.Name > dt2.Name ? 1 : -1)) ?? [];
    },
    _addDocumentType(state, documentType) {
      const documentTypesData = _.cloneDeep(state.documentTypes) ?? [];
      documentTypesData.push(documentType);
      state.documentTypes = documentTypesData.sort((dt1, dt2) => (dt1.Name > dt2.Name ? 1 : -1)) ?? [];
    },
    _updateDocumentType(state, updateDocumentType) {
      const rootId = Number(updateDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let documentTypesData = _.cloneDeep(state.documentTypes) ?? [];
        state.documentTypes =
          [...documentTypesData.filter((documentType) => Number(documentType?.RootId ?? 0) !== rootId), _.cloneDeep(updateDocumentType)].sort((dt1, dt2) =>
            dt1.Name > dt2.Name ? 1 : -1
          ) ?? [];
      }
    },
    _removeDocumentType(state, deleteDocumentType) {
      const rootId = Number(deleteDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let documentTypesData = _.cloneDeep(state.documentTypes) ?? [];
        state.documentTypes =
          [...documentTypesData.filter((documentType) => Number(documentType?.RootId ?? 0) !== rootId)].sort((dt1, dt2) => (dt1.Name > dt2.Name ? 1 : -1)) ?? [];
      }
    },
    _addDocumentTypeTitle(state, documentTypeTitle) {
      const documentTypeTitlesData = _.cloneDeep(state.documentTypeTitles);
      documentTypeTitlesData.push(documentTypeTitle);
      state.documentTypeTitles = documentTypeTitlesData;
    },
    _updateDocumentTypeTitle(state, updateDocumentTypeTitle) {
      const rootId = Number(updateDocumentTypeTitle?.RootId ?? 0);
      if (rootId > 0) {
        let documentTypeTitlesData = _.cloneDeep(state.documentTypeTitles) ?? [];
        state.documentTypeTitles =
          [...documentTypeTitlesData.filter((documentTypeTitle) => Number(documentTypeTitle?.RootId ?? 0) !== rootId), _.cloneDeep(updateDocumentTypeTitle)].sort((dtt1, dtt2) =>
            dtt1.Name > dtt2.Name ? 1 : -1
          ) ?? [];
      }
    },
    _removeDocumentTypeTitle(state, deleteDocumentTypeTitle) {
      const rootId = Number(deleteDocumentTypeTitle?.RootId ?? 0);
      if (rootId > 0) {
        let documentTypeTitlesData = _.cloneDeep(state.documentTypeTitles) ?? [];
        state.documentTypeTitles =
          [...documentTypeTitlesData.filter((documentTypeTitle) => Number(documentTypeTitle?.RootId ?? 0) !== rootId)].sort((dtt1, dtt2) => (dtt1.Name > dtt2.Name ? 1 : -1)) ?? [];
      }
    },
    _updatedDocumentTypeCategories(state, params) {
      const documentTypeRootId = Number(params?.DocumentTypeRootId ?? 0);
      const documentTypeCategories = params?.DocumentTypeCategories ?? [];
      if (documentTypeRootId > 0 && state.selectedAdminDocumentType?.RootId > 0 && documentTypeRootId === Number(state.selectedAdminDocumentType.RootId)) {
        state.selectedAdminDocumentType.DocumentTypeCategories = _.cloneDeep(documentTypeCategories ?? []).sort((dtc1, dtc2) => (dtc1.Position > dtc2.Position ? 1 : -1)) ?? [];
        let updateDocumentType = state.documentTypes.find((documentType) => Number(documentType?.RootId ?? 0) === documentTypeRootId) ?? null;
        if (updateDocumentType?.RootId > 0) {
          updateDocumentType.DocumentTypeCategories = _.cloneDeep(documentTypeCategories ?? []).sort((dtc1, dtc2) => (dtc1.Position > dtc2.Position ? 1 : -1)) ?? [];
          let documentTypesData = _.cloneDeep(state.documentTypes) ?? [];
          state.documentTypes =
            [...documentTypesData.filter((documentType) => Number(documentType?.RootId ?? 0) !== documentTypeRootId), _.cloneDeep(updateDocumentType)].sort((dtt1, dtt2) =>
              dtt1.Name > dtt2.Name ? 1 : -1
            ) ?? [];
        }
      }
    },
  },
  actions: {
    async getDocumentTypes(context, params) {
      await timeOperation(async () => {
        const useCache = params?.UseCache ?? false;
        const productRootId = Number(params?.ProductRootId ?? 0);
        const organizationRootId = Number(params?.OrganizationRootId ?? 0);
        const cachedDocumentTypesProductRootId = Number(context.state.cachedDocumentTypesProductRootId ?? 0);
        const cachedDocumentTypesOrganizationRootId = Number(context.state.cachedDocumentTypesOrganizationRootId ?? 0);
        const dirtyCache = cachedDocumentTypesProductRootId !== productRootId || cachedDocumentTypesOrganizationRootId !== organizationRootId;
        if (!useCache || dirtyCache) {
          context.commit("setCachedDocumentTypesProductRootId", productRootId);
          context.commit("setCachedDocumentTypesOrganizationRootId", organizationRootId);
          const formattedUrl = `${documentTypesUrl}?productrootid=${Number(params?.ProductRootId ?? 0)}&organizationrootid=${Number(
            params?.OrganizationRootId ?? 0
          )}&includemetadata=${params?.IncludeMetadata ?? false}&includedocumenttypefields=${params?.IncludeDocumentTypeFields ?? false}&includedocumenttypecategories=${
            params?.IncludeDocumentTypeCategories ?? false
          }&includedocumenttypetitles=${params?.IncludeDocumentTypeTitles ?? false}&includepartiesandroles=${params?.IncludePartiesAndRoles ?? false}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setDocumentTypes", data);
        }
      }, "documentTypes - getDocumentTypes");
    },
    async addDocumentType(context, documentType) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentTypesUrl, Payload: documentType });
        context.commit("_addDocumentType", data);
      }, "documentTypes - addDocumentType");
    },
    async updateDocumentType(context, documentType) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypesUrl}/${Number(documentType?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentType });
        context.commit("_updateDocumentType", data);
      }, "documentTypes - updateDocumentType");
    },
    async removeDocumentType(context, documentType) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypesUrl}/${Number(documentType?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeDocumentType", documentType);
      }, "documentTypes - removeDocumentType");
    },
    async updateDocumentTypePositions(context, documentTypes) {
      await timeOperation(async () => {
        if (documentTypes?.length > 0) {
          const formattedUrl = `${documentTypesUrl}/updatepositions`;
          const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: documentTypes });
          context.commit("setDocumentTypes", data);
        }
      }, "documentTypes - updateDocumentTypePositions");
    },
    async getSelectedDocumentTypePartiesAndPartyRoles(context) {
      await timeOperation(async () => {
        const { RootId } = context.state.selectedAdminDocumentType;
        if (!RootId) {
          console.error("cannot find selected admin document category");
          return;
        }
        await context.dispatch("getSelectedAdminDocumentTypePartyRoles", RootId);
        await context.dispatch("getSelectedAdminDocumentTypeParties", RootId);
      }, "DocumentTypes - getSelectedDocumentTypePartiesAndPartyRoles");
    },
    async getSelectedAdminDocumentTypeParties(context, RootId) {
      await timeOperation(async () => {
        if (!RootId) {
          console.error("no document category");
          return;
        }
        const formattedUrl = `${documentTypePartiesUrl}?DocumentTypeRootId=${encodeURIComponent(Number(RootId))}`;
        const queryResult = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (!queryResult?.data) {
          console.error("invalid query " + queryResult?.ErrorMessage);
          return;
        }
        const parties = queryResult.data;
        context.commit("setSelectedAdminDocumentType", { ...context.state.selectedAdminDocumentType, UserParties: _.cloneDeep(parties) });
      }, "DocumentTypes - getSelectedAdminDocumentTypeParties");
    },
    async addDocumentTypeParty(context, documentTypeParty) {
      await timeOperation(async () => {
        if (!documentTypeParty?.DocumentTypeRootId) {
          console.error("no document category");
          return;
        }
        if (!documentTypeParty?.PartyRootId) {
          console.error("no party given");
          return;
        }
        if (documentTypeParty?.Id) {
          console.error("cannot add existing record");
          return;
        }
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentTypePartiesUrl, Payload: documentTypeParty });
      }, "DocumentTypes - addDocumentTypeParty");
    },
    async removeDocumentTypeParty(context, documentTypeParty) {
      await timeOperation(async () => {
        if (!documentTypeParty?.RootId) {
          console.error("no record to remove");
        }
        const formattedUrl = `${documentTypePartiesUrl}/${Number(documentTypeParty?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "DocumentTypes - removeDocumentTypeParty");
    },
    async getSelectedAdminDocumentTypePartyRoles(context, RootId) {
      await timeOperation(async () => {
        if (!RootId) {
          console.error("no document category");
          return;
        }
        const formattedUrl = `${documentTypePartyRolesUrl}?DocumentTypeRootId=${encodeURIComponent(Number(RootId))}`;
        const queryResult = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (!queryResult?.data) {
          console.error("invalid query " + queryResult?.ErrorMessage);
          return;
        }
        const partyRoles = queryResult.data;
        context.commit("setSelectedAdminDocumentType", { ...context.state.selectedAdminDocumentType, DocumentTypePartyRoles: _.cloneDeep(partyRoles) });
      }, "DocumentTypes - getSelectedAdminDocumentTypePartyRoles");
    },
    async addDocumentTypePartyRole(context, documentTypePartyRole) {
      await timeOperation(async () => {
        if (!documentTypePartyRole?.DocumentTypeRootId) {
          console.error("no document category");
          return;
        }
        if (!documentTypePartyRole?.PartyRoleRootId) {
          console.error("no party role");
          return;
        }
        if (documentTypePartyRole?.Id) {
          console.error("cannot add existing record");
          return;
        }
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentTypePartyRolesUrl, Payload: documentTypePartyRole });
      }, "DocumentTypes - addDocumentTypePartyRole");
    },
    async removeDocumentTypePartyRole(context, documentTypePartyRole) {
      await timeOperation(async () => {
        if (!documentTypePartyRole?.RootId) {
          console.error("no record to remove");
          return;
        }
        const formattedUrl = `${documentTypePartyRolesUrl}/${Number(documentTypePartyRole?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "DocumentTypes - removeDocumentTypePartyRole");
    },
    async getDocumentTypeTitles(context, params) {
      await timeOperation(async () => {
        if (!params.DocumentTypeRootId) {
          console.error("no id");
          return;
        }
        const formattedUrl = `${documentTypeTitlesUrl}?documenttyperootid=${Number(params?.DocumentTypeRootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setDocumentTypeTitles", data);
      }, "documentTypes - getDocumentTypeTitles");
    },
    async addDocumentTypeTitle(context, documentTypeTitle) {
      await timeOperation(async () => {
        if (!documentTypeTitle) {
          console.error("no title");
          return;
        }
        if (documentTypeTitle.Id) {
          console.error("already added");
          return;
        }
        if (!documentTypeTitle.Name) {
          console.error("invalid title");
          return;
        }
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentTypeTitlesUrl, Payload: documentTypeTitle });
        context.commit("_addDocumentTypeTitle", data);
      }, "documentTypes - addDocumentTypeTitles");
    },
    async updateDocumentTypeTitle(context, documentTypeTitle) {
      await timeOperation(async () => {
        if (!documentTypeTitle) {
          console.error("no title given");
          return;
        }
        if (!documentTypeTitle.RootId) {
          console.error("invalid title");
          return;
        }
        if (!documentTypeTitle.Name) {
          console.error("invalid title name");
          return;
        }
        const formattedUrl = `${documentTypeTitlesUrl}/${Number(documentTypeTitle?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentTypeTitle });
        context.commit("_updateDocumentTypeTitle", data);
      }, "documentTypes - updateDocumentTypeTitles");
    },
    async removeDocumentTypeTitle(context, documentTypeTitle) {
      await timeOperation(async () => {
        if (!documentTypeTitle) {
          console.error("no title given");
          return;
        }
        if (!documentTypeTitle.RootId) {
          console.error("invalid title");
          return;
        }
        const formattedUrl = `${documentTypeTitlesUrl}/${Number(documentTypeTitle?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeDocumentTypeTitle", documentTypeTitle);
      }, "documentTypes - removeDocumentTypeTitles");
    },
    async saveDocumentTypeCategories(context, params) {
      await timeOperation(async () => {
        const documentTypeRootId = Number(params?.DocumentTypeRootId ?? 0);
        const documentTypeCategories = params?.DocumentTypeCategories ?? [];
        const formattedUrl = `${documentTypeCategoriesUrl}/${documentTypeRootId}/updatecategories`;
        const updatedDocumentTypeCategories = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentTypeCategories });
        context.commit("_updatedDocumentTypeCategories", { DocumentTypeRootId: documentTypeRootId, DocumentTypeCategories: updatedDocumentTypeCategories ?? [] });
      }, "documentTypes - updateDocumentTypeTitles");
    },
  },
};
