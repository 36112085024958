import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, bookmarkedOrdersUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    bookmarkedOrders: [],
    cachedBookmarkedOrdersUserRootId: 0,
    cachedBookmarkedOrdersOrderRootId: 0,
    cachedBookmarkedOrdersIncludeMetadata: 0,
    cachedBookmarkedOrdersMaxResults: 0,
  },
  getters: {},
  mutations: {
    setCachedBookmarkedOrdersUserRootId(state, cachedBookmarkedOrdersUserRootId) {
      state.cachedBookmarkedOrdersUserRootId = cachedBookmarkedOrdersUserRootId;
    },
    setCachedBookmarkedOrdersOrderRootId(state, cachedBookmarkedOrdersOrderRootId) {
      state.cachedBookmarkedOrdersOrderRootId = cachedBookmarkedOrdersOrderRootId;
    },
    setCachedBookmarkedOrdersIncludeMetadata(state, cachedBookmarkedOrdersIncludeMetadata) {
      state.cachedBookmarkedOrdersIncludeMetadata = cachedBookmarkedOrdersIncludeMetadata;
    },
    setCachedBookmarkedOrdersMaxResults(state, cachedBookmarkedOrdersMaxResults) {
      state.cachedBookmarkedOrdersMaxResults = cachedBookmarkedOrdersMaxResults;
    },
    setBookmarkedOrderData(state, bookmarkedOrdersData) {
      state.bookmarkedOrders = bookmarkedOrdersData?.sort((bo1, bo2) => (bo1.Name > bo2.Name ? 1 : -1)) ?? [];
    },
    _addBookmarkedOrder(state, bookmarkedOrder) {
      const bookmarkedOrdersData = _.cloneDeep(state.bookmarkedOrders) ?? [];
      bookmarkedOrdersData.push(bookmarkedOrder);
      state.bookmarkedOrders = bookmarkedOrdersData.sort((bo1, bo2) => (bo1.Name > bo2.Name ? 1 : -1)) ?? [];
    },
    _updateBookmarkedOrder(state, updateBookmarkedOrder) {
      const rootId = Number(updateBookmarkedOrder?.RootId ?? 0);
      if (rootId > 0) {
        let bookmarkedOrdersData = _.cloneDeep(state.bookmarkedOrders) ?? [];
        state.bookmarkedOrders =
          [...bookmarkedOrdersData.filter((bookmarkedOrder) => Number(bookmarkedOrder?.RootId ?? 0) !== rootId), _.cloneDeep(updateBookmarkedOrder)].sort((bo1, bo2) =>
            bo1.Name > bo2.Name ? 1 : -1
          ) ?? [];
      }
    },
    _removeBookmarkedOrder(state, deleteBookmarkedOrder) {
      const rootId = Number(deleteBookmarkedOrder?.RootId ?? 0);
      if (rootId > 0) {
        let bookmarkedOrdersData = _.cloneDeep(state.bookmarkedOrders) ?? [];
        state.bookmarkedOrders =
          [...bookmarkedOrdersData.filter((bookmarkedOrder) => Number(bookmarkedOrder?.RootId ?? 0) !== rootId)].sort((bo1, bo2) => (bo1.Name > bo2.Name ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getBookmarkedOrdersData(context, { UserRootId = 0, OrderRootId = 0, IncludeMetadata = true, MaxResults = 10 }) {
      if (
        UserRootId === context.state.cachedBookmarkedOrdersUserRootId &&
        OrderRootId === context.state.cachedBookmarkedOrdersOrderRootId &&
        IncludeMetadata === context.state.cachedBookmarkedOrdersIncludeMetadata &&
        MaxResults === context.state.cachedBookmarkedOrdersMaxResults
      ) {
        return;
      }
      await timeOperation(async () => {
        context.commit("setCachedBookmarkedOrdersUserRootId", UserRootId);
        context.commit("setCachedBookmarkedOrdersOrderRootId", OrderRootId);
        context.commit("setCachedBookmarkedOrdersIncludeMetadata", IncludeMetadata);
        context.commit("setCachedBookmarkedOrdersMaxResults", MaxResults);
        const formattedUrl = `${bookmarkedOrdersUrl}?userrootid=${Number(UserRootId ?? 0)}&orderrootid=${Number(OrderRootId ?? 0)}&maxresults=${Number(
          MaxResults ?? 10
        )}&includemetadata=${IncludeMetadata ?? true}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setBookmarkedOrderData", data);
      }, "bookmarkedOrders - getBookmarkedOrdersData");
    },
    async addBookmarkedOrder(context, bookmarkedOrder) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: bookmarkedOrdersUrl, Payload: bookmarkedOrder });
        context.commit("_addBookmarkedOrder", data);
        return data;
      }, "bookmarkedOrders - addBookmarkedOrder");
    },
    async updateBookmarkedOrder(context, bookmarkedOrder) {
      await timeOperation(async () => {
        const formattedUrl = `${bookmarkedOrdersUrl}/${Number(bookmarkedOrder?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: bookmarkedOrder });
        context.commit("_updateBookmarkedOrder", data);
      }, "bookmarkedOrders - updateBookmarkedOrder");
    },
    async removeBookmarkedOrder(context, bookmarkedOrder) {
      await timeOperation(async () => {
        const formattedUrl = `${bookmarkedOrdersUrl}/${Number(bookmarkedOrder?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "bookmarkedOrders - removeBookmarkedOrder");
    },
  },
};
