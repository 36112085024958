export function getDataFieldKeyFromRootId(organizationDataSourceRootId) {
  return organizationDataSourceRootId > 0 ? `odf${organizationDataSourceRootId}` : "";
}

export function getDataFieldKeyRootId(fieldKey) {
  const res = fieldKey?.length > 3 ? fieldKey.slice(3) : "";
  if (res === "") {
    return 0;
  }
  const resAsNumber = Number(res);
  if (isNaN(resAsNumber)) {
    // console.warn("failed to get root id from field key", fieldKey);
    return 0;
  }
  return resAsNumber;
}
