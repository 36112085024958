import {ApiRequest, GetVerb, PutVerb, potentialDocumentTypesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";
export default {
  state: {
    potentialDocumentTypes: [],
  },
  getters: {
    getPotentialDocumentTypeById: (state) => (potentialDocumentTypeId) => {
      return state.potentialDocumentTypes?.find((potentialDocumentType) => Number(potentialDocumentType?.Id ?? 0) === Number(potentialDocumentTypeId ?? 0)) ?? null;
    },
  },
  mutations: {
    setPotentialDocumentTypeData(state, potentialDocumentTypesData) {
      let potentialDocumentTypes = [];
      if (potentialDocumentTypesData?.length > 0) {
        potentialDocumentTypesData.forEach((potentialDocumentType) => {
          if (potentialDocumentType.CreatedDateTime) {
            if (String(potentialDocumentType.CreatedDateTime).includes("T") && !String(potentialDocumentType.CreatedDateTime).includes("Z")) {
              potentialDocumentType.CreatedDateTime = `${potentialDocumentType.CreatedDateTime}.000Z`;
            }
            potentialDocumentType.CreatedDateTime = new Date(potentialDocumentType.CreatedDateTime);
            if (potentialDocumentType.CreatedDateTime.getFullYear() < 1901) {
              potentialDocumentType.CreatedDateTime = null;
            }
          } else {
            potentialDocumentType.CreatedDateTime = null;
          }
          potentialDocumentTypes.push(potentialDocumentType);
        });
      }
      state.potentialDocumentTypes = potentialDocumentTypes?.sort((pdt1, pdt2) => (pdt1.CreatedDateTime > pdt2.CreatedDateTime ? 1 : -1));
    },
    _removePotentialDocumentType(state, deletePotentialDocumentType) {
      const rootId = Number(deletePotentialDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let potentialDocumentTypesData = _.cloneDeep(state.potentialDocumentTypes) ?? [];
        potentialDocumentTypesData = [...potentialDocumentTypesData.filter(potentialDocumentType => Number(potentialDocumentType?.RootId ?? 0) !== rootId)].sort((pdt1, pdt2) => (pdt1.CreatedDateTime > pdt2.CreatedDateTime ? 1 : -1)) ?? [];
        state.potentialDocumentTypes = potentialDocumentTypesData;
      }
    },
  },
  actions: {
    async getUnprocessedPotentialDocumentTypes(context, includeMetadata) {
      await timeOperation(async () => {
        const formattedUrl = `${potentialDocumentTypesUrl}?&includemetadata=${includeMetadata ?? false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setPotentialDocumentTypeData", data);
      }, "potentialDocumentTypes - getUnprocessedPotentialDocumentTypes");
    },
    async updateProcessedPotentialDocumentType(context, potentialDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${potentialDocumentTypesUrl}/${Number(potentialDocumentType?.Id ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: potentialDocumentType });
        context.commit("_removePotentialDocumentType", potentialDocumentType);
      }, "potentialDocumentTypes - updateProcessedPotentialDocumentType");
    },
  },
};
