import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productDataSourcesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";
import {
  OrdersRecordType,
  DocumentsRecordType,
  VendorOrdersRecordType,
  MessagesRecordType,
  ChatsRecordType,
  NotesRecordType,
  WorkflowTasksRecordType,
  WorkflowRulesRecordType,
  WorkflowNotificationsRecordType,
  OrderDocumentRecordType,
  AuditLogsRecordType,
  DocumentLogRecordType,
  SharedDataLogRecordType,
  WorkflowLogRecordType,
  UserSearchSettingsLogRecordType,
  UserAccessSettingsLogRecordType,
  UserConfigSettingsLogRecordType,
  OrganizationContactCompanyRecordType,
  OrganizationContactPersonRecordType,
  OrderContactCompanyRecordType,
  OrderContactPersonRecordType,
  WorkflowTaskTemplatesRecordType,
  VendorOrderEntriesRecordType,
  VendorOrderOfferingsRecordType,
} from "@/lib/settings";

export default {
  state: {
    productDataSources: [],
    cachedProductDataSourcesProductRootId: -1,
  },
  getters: {
    getProductDataSourceByRootId: (state) => (rootId) => {
      return state.productDataSources?.find((productDataSource) => Number(productDataSource?.RootId || 0) === Number(rootId || 0)) || null;
    },
    getFilteredProductDataSources: (state) => (params) => {
      let result = [];
      const productRootId = Number(params?.ProductRootId || 0);
      const documentTypesOnly = params?.DocumentTypesOnly || false;
      state.productDataSources?.forEach((productDataSource) => {
        if (
          (productDataSource.ProductRootId === productRootId && !documentTypesOnly) ||
          (productDataSource.Type === DocumentsRecordType && !productDataSource.Name?.toLowerCase().includes("dropbox"))
        ) {
          switch (productDataSource.Type || "") {
            case OrdersRecordType:
            case VendorOrdersRecordType:
            case DocumentsRecordType:
            case WorkflowTasksRecordType:
            case WorkflowRulesRecordType:
            case WorkflowNotificationsRecordType:
            case OrganizationContactCompanyRecordType:
            case OrganizationContactPersonRecordType:
            case OrderContactCompanyRecordType:
            case OrderContactPersonRecordType:
            case WorkflowTaskTemplatesRecordType:
            case VendorOrderEntriesRecordType:
            case VendorOrderOfferingsRecordType:
              result.push(_.cloneDeep(productDataSource));
              break;
            case MessagesRecordType:
            case ChatsRecordType:
            case NotesRecordType:
            case OrderDocumentRecordType:
            case AuditLogsRecordType:
            case DocumentLogRecordType:
            case SharedDataLogRecordType:
            case WorkflowLogRecordType:
            case UserSearchSettingsLogRecordType:
            case UserAccessSettingsLogRecordType:
            case UserConfigSettingsLogRecordType:
              break;
          }
        }
      });
      return result;
    },
  },
  mutations: {
    setProductDataSources(state, Data) {
      state.productDataSources = Data?.sort((pds1, pds2) => (pds1.Name > pds2.Name ? 1 : -1)) || [];
    },
    clearProductDataSourceDataSources(state) {
      state.productDataSources = [];
    },
    setCachedProductDataSourcesProductRootId(state, cachedProductDataSourcesProductRootId) {
      state.cachedProductDataSourcesProductRootId = cachedProductDataSourcesProductRootId;
    },
    _addProductDataSource(state, productDataSource) {
      if (productDataSource?.RootId > 0) {
        state.productDataSources.push(productDataSource);
        state.productDataSources = state.productDataSources.sort((ods1, ods2) => (ods1.Name > ods2.Name ? 1 : -1)) || [];
      }
    },
    _updateProductDataSource(state, updateProductDataSource) {
      const rootId = Number(updateProductDataSource?.RootId || 0);
      if (rootId > 0) {
        let productDataSourcesData = _.cloneDeep(state.productDataSources) || [];
        productDataSourcesData =
          [...productDataSourcesData.filter((productDataSource) => Number(productDataSource?.RootId || 0) !== rootId), _.cloneDeep(updateProductDataSource)] || [];
        state.productDataSources = productDataSourcesData.sort((ods1, ods2) => (ods1.Name > ods2.Name ? 1 : -1)) || [];
      }
    },
    _updateProductDataSourceIsStandardDataSource(state, updateProductDataSource) {
      const rootId = Number(updateProductDataSource?.RootId || 0);
      if (rootId > 0) {
        let productDataSource = state.productDataSources?.find((productDataSource) => Number(productDataSource?.RootId || 0) === rootId);
        if (productDataSource?.RootId > 0) {
          productDataSource.IsStandardDataSource = updateProductDataSource.IsStandardDataSource || false;
        }
      }
    },
    _updateProductDataSourceDataSourceField(state, updateProductDataSourceField) {
      if (state.productDataSources?.length > 0 && updateProductDataSourceField?.RootId > 0) {
        state.productDataSources.forEach((productDataSource) => {
          if (Number(productDataSource.RootId) === Number(updateProductDataSourceField.ProductDataSourceRootId)) {
            if (productDataSource?.ProductDataSourceFields?.length > 0) {
              productDataSource.ProductDataSourceFields.forEach((productDataSourceField) => {
                if (Number(productDataSourceField.RootId) === Number(updateProductDataSourceField.RootId)) {
                  productDataSourceField.IsPrimaryKey = updateProductDataSourceField.IsPrimaryKey;
                  productDataSourceField.IsForeignKey = updateProductDataSourceField.IsForeignKey;
                  productDataSourceField.IsViewable = updateProductDataSourceField.IsViewable;
                  productDataSourceField.IsEditable = updateProductDataSourceField.IsEditable;
                  productDataSourceField.IsNullable = updateProductDataSourceField.IsNullable;
                  productDataSourceField.IsImmutable = updateProductDataSourceField.IsImmutable;
                  productDataSourceField.IsReadonly = updateProductDataSourceField.IsReadonly;
                  productDataSourceField.IsRichText = updateProductDataSourceField.IsRichText;
                  productDataSourceField.IsSharable = updateProductDataSourceField.IsSharable;
                  productDataSourceField.ShowColumn = updateProductDataSourceField.ShowColumn;
                  productDataSourceField.Description = updateProductDataSourceField.Description;
                  productDataSourceField.UseInQuickSearch = updateProductDataSourceField.UseInQuickSearch;
                  productDataSourceField.CanUseInAdvancedSearch = updateProductDataSourceField.CanUseInAdvancedSearch;
                }
              });
            }
          }
        });
      }
    },
    _removeProductDataSource(state, deleteProductDataSource) {
      const rootId = Number(deleteProductDataSource?.RootId || 0);
      if (rootId > 0) {
        let productDataSourcesData = _.cloneDeep(state.productDataSources) || [];
        productDataSourcesData = [...productDataSourcesData.filter((productDataSource) => Number(productDataSource?.RootId || 0) !== rootId)] || [];
        state.productDataSources = productDataSourcesData.sort((ods1, ods2) => (ods1.Name > ods2.Name ? 1 : -1)) || [];
      }
    },
  },
  actions: {
    async getProductDataSources(context, { UseCache, ProductRootId, CanUseServerCache }) {
      await timeOperation(async () => {
        UseCache = UseCache || false;
        ProductRootId = Number(ProductRootId || 0);
        CanUseServerCache = CanUseServerCache || true;
        const cachedProductDataSourcesProductRootId = Number(context.state.cachedProductDataSourcesProductRootId || 0);
        const dirtyCache = cachedProductDataSourcesProductRootId !== ProductRootId;
        if (!UseCache || dirtyCache) {
          context.commit("setCachedProductDataSourcesProductRootId", ProductRootId);
          const formattedUrl = `${productDataSourcesUrl}?productrootid=${ProductRootId}&canusecache=${CanUseServerCache}&includecustomfields=true&includemetadata=true`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setProductDataSources", data);
        }
      }, "productDataSources - getProductDataSources");
    },
    async addProductDataSource(context, productDataSource) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productDataSourcesUrl, Payload: productDataSource });
        if (data?.RootId > 0) {
          productDataSource.RootId = data.RootId;
          context.commit("_addProductDataSource", data);
        }
        return data;
      }, "productDataSources - addProductDataSource");
    },
    async updateProductDataSource(context, productDataSource) {
      await timeOperation(async () => {
        const formattedUrl = `${productDataSourcesUrl}/${Number(productDataSource?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDataSource });
        context.commit("_updateProductDataSource", data);
      }, "productDataSources - updateProductDataSource");
    },
    async updateProductDataSourceUpdateStandardDataSourceFlag(context, productDataSource) {
      return timeOperation(async () => {
        const formattedUrl = `${productDataSourcesUrl}/${Number(productDataSource?.RootId || 0)}/updatestandarddatasourceflag`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDataSource });
        context.commit("_updateProductDataSourceIsStandardDataSource", data);
        return data;
      }, "productDataSources - updateProductDataSourceUpdateStandardDataSourceFlag");
    },
    async removeProductDataSource(context, productDataSource) {
      await timeOperation(async () => {
        const formattedUrl = `${productDataSourcesUrl}/${Number(productDataSource?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductDataSource", productDataSource);
      }, "productDataSources - removeProductDataSource");
    },
  },
};
