import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, widgetContextFieldTypesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    widgetContextFieldTypes: [],
  },
  getters: {
    getWidgetContextFieldTypeByRootId: (state) => (rootId) => {
      return state.widgetContextFieldTypes.find((widgetContextFieldType) => Number(widgetContextFieldType?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getWidgetContextFieldTypeByName: (state) => (name) => {
      return state.widgetContextFieldTypes.find((widgetContextFieldType) => (String(widgetContextFieldType?.Name ?? "") === String(name ?? "")) || (String(widgetContextFieldType?.DisplayName ?? "") === String(name ?? ""))) ?? null;
    },
  },
  mutations: {
    setWidgetContextFieldTypeData(state, widgetContextFieldTypes) {
      state.widgetContextFieldTypes = widgetContextFieldTypes?.sort((wcft1, wcft2) => (wcft1.DisplayName > wcft2.DisplayName ? 1 : -1)) ?? [];
    },
    clearWidgetContextFieldTypes(state) {
      state.widgetContextFieldTypes = [];
    },
    _addWidgetContextFieldType(state, widgetContextFieldType) {
      const widgetContextFieldTypesData = _.cloneDeep(state.widgetContextFieldTypes) ?? [];
      widgetContextFieldTypesData.push(widgetContextFieldType);
      state.widgetContextFieldTypes = widgetContextFieldTypesData.sort((wcft1, wcft2) => (wcft1.DisplayName > wcft2.DisplayName ? 1 : -1));
    },
    _updateWidgetContextFieldType(state, updateWidgetContextFieldType) {
      const rootId = Number(updateWidgetContextFieldType?.RootId ?? 0);
      if (rootId > 0) {
        let widgetContextFieldTypesData = _.cloneDeep(state.widgetContextFieldTypes) ?? [];
        widgetContextFieldTypesData = [...widgetContextFieldTypesData.filter(widgetContextFieldType => Number(widgetContextFieldType?.RootId ?? 0) !== rootId), _.cloneDeep(updateWidgetContextFieldType)].sort((wcft1, wcft2) => (wcft1.DisplayName > wcft2.DisplayName ? 1 : -1)) ?? [];
        state.widgetContextFieldTypes = widgetContextFieldTypesData;
      }
    },
    _removeWidgetContextFieldType(state, deleteWidgetContextFieldType) {
      const rootId = Number(deleteWidgetContextFieldType?.RootId ?? 0);
      if (rootId > 0) {
        let widgetContextFieldTypesData = _.cloneDeep(state.widgetContextFieldTypes) ?? [];
        widgetContextFieldTypesData = [...widgetContextFieldTypesData.filter(widgetContextFieldType => Number(widgetContextFieldType?.RootId ?? 0) !== rootId)].sort((wcft1, wcft2) => (wcft1.DisplayName > wcft2.DisplayName ? 1 : -1)) ?? [];
        state.widgetContextFieldTypes = widgetContextFieldTypesData;
      }
    },
  },
  actions: {
    async getWidgetContextFieldTypes(context) {
      if (!(context.state.widgetContextFieldTypes?.length > 0)) {
        await timeOperation(async () => {
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: widgetContextFieldTypesUrl, Payload: null });
          context.commit("setWidgetContextFieldTypeData", data);
        }, "widgetContextFieldTypes - getWidgetContextFieldTypes");
      }
    },
    async addWidgetContextFieldType(context, widgetContextFieldType) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: widgetContextFieldTypesUrl, Payload: widgetContextFieldType });
        if (data?.RootId > 0) {
          widgetContextFieldType.RootId = data.RootId;
          context.commit("_addWidgetContextFieldType", data);
        }
        return data;
      }, "widgetContextFieldTypes - addWidgetContextFieldType");
    },
    async updateWidgetContextFieldType(context, widgetContextFieldType) {
      await timeOperation(async () => {
        const formattedUrl = `${widgetContextFieldTypesUrl}/${Number(widgetContextFieldType?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: widgetContextFieldType });
        context.commit("_updateWidgetContextFieldType", data);
      }, "widgetContextFieldTypes - updateWidgetContextFieldType");
    },
    async removeWidgetContextFieldType(context, widgetContextFieldType) {
      await timeOperation(async () => {
        const formattedUrl = `${widgetContextFieldTypesUrl}/${Number(widgetContextFieldType?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeWidgetContextFieldType", widgetContextFieldType);
      }, "widgetContextFieldTypes - removeWidgetContextFieldType");
    },
  },
};
