<template>
  <img ref="imageRef" :class="imageClasses" class="cursor-pointer" :src="imageSrc" alt="" @click="handleOpenMenu" />
</template>

<script>
import { ref, computed, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import _ from "lodash";

import { openContextMenuAtTargetElement } from "@/utils/contextMenuHelper";

import {
  OrdersTypeNumberOfOrdersToDisplayInOrderHistoryMenuConfigKey,
  OrdersTypeRecentOrdersFormatConfigKey,
  OrdersTypeCanViewDebugInfoAccessKey,
  DocumentsTypeCanViewDebugInfoAccessKey,
  TrulyEsqProductRootId,
  DesqProductRootId,
  LinksProductRootId,
  OrdersRecordType,
  HomeViewLocationDefaultRootId,
} from "@/lib/settings";

const DEFAULT_NUMBER_OF_ITEMS_SHOWN = 15;
const MAX_LENGTH = 150;
export default {
  name: "NavLogo",
  props: {
    imageClasses: {
      type: String,
      default: "",
    },
    imageSrc: {
      type: String,
      default: "",
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    showRecentHistory: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["menuItemSelected", "tabSelected"],
  setup(props, { emit }) {
    const route = useRoute();
    const isOnFolderView = computed(() => route.fullPath.includes("truly-esq"));

    const store = useStore();

    // State
    const loggedInUser = computed(() => store.state.auth.loggedInUser);
    const viewedOrders = computed(() => store.state.vwdord.viewedOrders);
    const currentFolderLabelViewType = computed(() => store.state.ords.currentFolderLabelViewType);
    // Getters
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    const getUserConfigSetting = computed(() => store.getters.getUserConfigSetting);
    const getOrderByRootId = computed(() => store.getters.getOrderByRootId);
    const getUserAccessSetting = computed(() => store.getters.getUserAccessSetting);
    function getBooleanAccessSetting(productRootId, settingName) {
      return Boolean(String(getUserAccessSetting.value({ ProductRootId: productRootId, SettingName: settingName })) === "true");
    }
    const canViewDebugInfo = computed(() => {
      const productRootId = getGlobalSelectedProductRootId.value;
      switch (productRootId) {
        case TrulyEsqProductRootId:
        case DesqProductRootId:
          return getBooleanAccessSetting(productRootId, OrdersTypeCanViewDebugInfoAccessKey);
        case LinksProductRootId:
          return getBooleanAccessSetting(productRootId, DocumentsTypeCanViewDebugInfoAccessKey);
      }
      return false;
    });
    // Actions
    const loadOperation = async (Operation, Message) => await store.dispatch("loadOperation", { Operation, Message, Caller: "NavLogo" });
    const getViewedOrdersData = async () =>
      await store.dispatch("getViewedOrdersData", { UserRootId: loggedInUser.value?.UserRootId ?? 0, IncludeMetadata: true, MaxResults: numberOfItemsShown.value });
    function getStringConfigSetting(settingName) {
      return String(getUserConfigSetting.value({ ProductRootId: getGlobalSelectedProductRootId.value, SettingName: settingName }) ?? "");
    }
    function getNumberOfItemsShownConfigSetting() {
      const num = getNumberConfigSetting(OrdersTypeNumberOfOrdersToDisplayInOrderHistoryMenuConfigKey);
      return num > 0 ? num : DEFAULT_NUMBER_OF_ITEMS_SHOWN;
    }
    function getNumberConfigSetting(settingName) {
      return Number(getUserConfigSetting.value({ ProductRootId: getGlobalSelectedProductRootId.value, SettingName: settingName }) ?? "");
    }
    const getSingleOrderData = async (RootId, OrderNumber) =>
      await store.dispatch("getSingleOrderData", {
        RootId,
        OrderNumber,
        ProductRootId: getGlobalSelectedProductRootId.value,
        IncludeDebugData: canViewDebugInfo.value,
        UseCache: true,
      });

    const numberOfItemsShown = ref(10);
    const imageRef = ref(null);
    const locationRootId = ref(HomeViewLocationDefaultRootId);

    const recentHistoryContextMenuItems = computed(() => {
      const menuItemFormat = getStringConfigSetting(OrdersTypeRecentOrdersFormatConfigKey);
      const formattedHistory = _.cloneDeep(viewedOrders.value)?.map((order) => ({ ...order, PropertyAddress1Address2: getAddressFromHistory(order) })) ?? [];
      const fields = menuItemFormat.split("|");
      if (formattedHistory?.length > 0 && fields.length > 0 && fields[0].length > 0) {
        const menuItems = [];
        formattedHistory.forEach((item) => {
          let menuItem = _.cloneDeep(item);
          let itemParts = [];
          fields.forEach((fieldName) => {
            if ((fieldName ?? "").toLowerCase().includes("ordernumber")) {
              if (item.OrderNumber?.length > 0) {
                itemParts.push(item.OrderNumber);
              }
            } else if ((fieldName ?? "").toLowerCase().includes("address")) {
              if (item.PropertyAddress1Address2?.length > 0) {
                itemParts.push(item.PropertyAddress1Address2);
              }
            } else if ((fieldName ?? "").toLowerCase().includes("buyer")) {
              if (item.Buyer?.length > 0) {
                itemParts.push(item.Buyer);
              }
            } else if ((fieldName ?? "").toLowerCase().includes("seller")) {
              if (item.Seller?.length > 0) {
                itemParts.push(item.Seller);
              }
            }
          });
          if (itemParts.length > 0) {
            menuItem.text = itemParts.join(" | ").substring(0, MAX_LENGTH);
          } else {
            menuItem.text = item[textField.value].substring(0, MAX_LENGTH);
          }
          menuItems.push(menuItem);
        });
        return [{ iconCss: "e-icons e-clock relative top-1", text: "Recent History", items: menuItems }];
      }
      return formattedHistory.map((item) => ({ ...item, text: item[textField.value] }));
    });
    const textField = computed(() => {
      if (!isOnFolderView.value) {
        return "OrderNumber";
      } else {
        switch (currentFolderLabelViewType.value) {
          case "External Order Number":
          case "Order Number":
            return "OrderNumber";
          case "Buyer/Borrower":
            return "Buyer";
          case "Seller/Owner":
            return "Seller";
          case "Last Modified Date/Time":
          case "Property Address":
          default: {
            return "PropertyAddress1Address2";
          }
        }
      }
    });

    function getAddressFromHistory(bookmark) {
      const { PropertyAddress1, PropertyAddress2, PropertyCity, PropertyState, PropertyZip } = bookmark;
      return `${PropertyAddress1}${PropertyAddress2 ? " " + PropertyAddress2 : ""}${PropertyCity ? ", " + PropertyCity : ""}${PropertyState ? ", " + PropertyState : ""}${
        PropertyZip ? ", " + PropertyZip : ""
      }`;
    }
    async function handleOpenMenu() {
      if (props.showRecentHistory && viewedOrders.value?.length < 1) {
        await loadOperation(async () => {
          await getViewedOrdersData();
        }, "Loading Recent History");
      }
      const { x, y, height } = imageRef.value.getBoundingClientRect();
      openContextMenuAtTargetElement(x + 4, y + height - 5, [...props.menuItems, ...(props.showRecentHistory ? recentHistoryContextMenuItems.value : [])], async (item) => {
        if (item?.OrderRootId) {
          const { OrderRootId } = item;
          let order = getOrderByRootId.value(OrderRootId ?? 0);
          if (!order) {
            await loadOperation(async () => {
              await getSingleOrderData(OrderRootId, "");
              await getViewedOrdersData();
            }, "Loading Order Data");
          }
          order = getOrderByRootId.value(OrderRootId ?? 0);
          if (!order) {
            console.error("order not found");
            return;
          }
          if (!order?.RootId > 0) {
            console.error("invalid identification from found order");
            return;
          }
          if (order.Processing) {
            console.warn("order still processing");
            return;
          }
          if (order?.RootId > 0) {
            const tab = {
              Name: order.OrderNumber,
              RootId: order.RootId,
              ProductRootId: order.ProductRootId,
              CanClose: true,
              Selected: true,
              ProductDataSourceType: OrdersRecordType,
              SearchLocationRootId: locationRootId.value,
              AutoActivate: true,
              IsOrderTab: true,
              SearchGroupRootId: 0,
            };
            emit("tabSelected", tab);
            return;
          }
        }
        emit("menuItemSelected", item);
      });
    }

    onMounted(() => {
      nextTick(async () => {
        numberOfItemsShown.value = getNumberOfItemsShownConfigSetting();
      });
    });

    return {
      imageRef,

      handleOpenMenu,
    };
  },
};
</script>
