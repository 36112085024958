import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, formLocationsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    formLocations: [],
  },
  getters: {
    getFormLocationByRootId: (state) => (rootId) => {
      return state.formLocations.find((formLocation) => Number(formLocation?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getFormLocationByName: (state) => (name) => {
      return state.formLocations.find((formLocation) => String(formLocation?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getFormLocationRootIdByName: (state) => (name) => {
      return (state.formLocations.find((formLocation) => String(formLocation?.Name ?? "") === String(name ?? "")) ?? null)?.RootId ?? 0;
    },
  },
  mutations: {
    setFormLocationData(state, formLocations) {
      state.formLocations = formLocations?.sort((fl1, fl2) => (fl1.Name > fl2.Name ? 1 : -1)) ?? [];
    },
    clearFormLocations(state) {
      state.formLocations = [];
    },
    _addFormLocation(state, formLocation) {
      const formLocationsData = _.cloneDeep(state.formLocations) ?? [];
      formLocationsData.push(formLocation);
      state.formLocations = formLocationsData.sort((fl1, fl2) => (fl1.Name > fl2.Name ? 1 : -1));
    },
    _updateFormLocation(state, updateFormLocation) {
      const rootId = Number(updateFormLocation?.RootId ?? 0);
      if (rootId > 0) {
        let formLocationsData = _.cloneDeep(state.formLocations) ?? [];
        formLocationsData = [...formLocationsData.filter(formLocation => Number(formLocation?.RootId ?? 0) !== rootId), _.cloneDeep(updateFormLocation)].sort((fl1, fl2) => (fl1.Name > fl2.Name ? 1 : -1)) ?? [];
        state.formLocations = formLocationsData;
      }
    },
    _removeFormLocation(state, deleteFormLocation) {
      const rootId = Number(deleteFormLocation?.RootId ?? 0);
      if (rootId > 0) {
        let formLocationsData = _.cloneDeep(state.formLocations) ?? [];
        formLocationsData = [...formLocationsData.filter(formLocation => Number(formLocation?.RootId ?? 0) !== rootId)].sort((fl1, fl2) => (fl1.Name > fl2.Name ? 1 : -1)) ?? [];
        state.formLocations = formLocationsData;
      }
    },
  },
  actions: {
    async getFormLocations(context) {
      if (!(context.state.formLocations?.length > 0)) {
        await timeOperation(async () => {
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formLocationsUrl, Payload: null });
          context.commit("setFormLocationData", data);
        }, "formLocations - getFormLocations");
      }
    },
    async addFormLocation(context, formLocation) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formLocationsUrl, Payload: formLocation });
        if (data?.RootId > 0) {
          formLocation.RootId = data.RootId;
          context.commit("_addFormLocation", data);
        }
        return data;
      }, "formLocations - addFormLocation");
    },
    async updateFormLocation(context, formLocation) {
      await timeOperation(async () => {
        const formattedUrl = `${formLocationsUrl}/${Number(formLocation?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: formLocation });
        context.commit("_updateFormLocation", data);
      }, "formLocations - updateFormLocation");
    },
    async removeFormLocation(context, formLocation) {
      await timeOperation(async () => {
        const formattedUrl = `${formLocationsUrl}/${Number(formLocation?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeFormLocation", formLocation);
      }, "formLocations - removeFormLocation");
    },
  },
};
