import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productDataSourceFieldsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productDataSourceFieldDataSourceFields: {},
  },
  getters: {
    getProductDataSourceFieldByRootId: (state) => (rootId) => {
      const productDataSourceFields = _.reduce(
          state.productDataSourceFieldDataSourceFields,
          (result, listOfProductDataSourceFields) => {
            if (listOfProductDataSourceFields?.length > 0) {
              listOfProductDataSourceFields.forEach((productDataSourceField) => {
                result.push(productDataSourceField);
              });
            }
            return result;
          },
          []
      );
      const productDataSourceField = productDataSourceFields.find((productDataSourceField) => Number(productDataSourceField?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
      return productDataSourceField?.RootId > 0 ? _.cloneDeep(productDataSourceField) : null;
    },
  },
  mutations: {
    setProductDataSourceFields(state, { ProductDataSourceRootId, Data }) {
      state.productDataSourceFieldDataSourceFields[ProductDataSourceRootId] = Data?.sort((odsf1, odsf2) => (odsf1.Name > odsf2.Name ? 1 : -1)) ?? [];
    },
    clearProductDataSourceFieldDataSourceFields(state) {
      state.productDataSourceFieldDataSourceFields = {};
    },
    _addProductDataSourceField(state, productDataSourceField) {
      if (productDataSourceField?.RootId > 0) {
        const productDataSourceRootId = Number(productDataSourceField?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceFieldDataSourceFields.hasOwnProperty(productDataSourceRootId)) {
          state.productDataSourceFieldDataSourceFields[productDataSourceRootId].push(productDataSourceField);
          state.productDataSourceFieldDataSourceFields[productDataSourceRootId] = state.productDataSourceFieldDataSourceFields[productDataSourceRootId].sort((odsf1, odsf2) => (odsf1.Name > odsf2.Name ? 1 : -1)) ?? [];
        } else {
          state.productDataSourceFieldDataSourceFields[productDataSourceRootId] = [productDataSourceField];
        }
      }
    },
    _updateProductDataSourceField(state, updateProductDataSourceField) {
      if ((state.productDataSourceFieldDataSourceFields?.length > 0) && (updateProductDataSourceField?.RootId > 0)) {
        state.productDataSourceFieldDataSourceFields.forEach((productDataSource) => {
          if (Number(productDataSource.RootId) === Number(updateProductDataSourceField.ProductDataSourceRootId)) {
            if (productDataSource?.ProductDataSourceFields?.length > 0) {
              productDataSource.ProductDataSourceFields.forEach((productDataSourceField) => {
                if (Number(productDataSourceField.RootId) === Number(updateProductDataSourceField.RootId)) {
                  productDataSourceField.IsPrimaryKey = updateProductDataSourceField.IsPrimaryKey;
                  productDataSourceField.IsForeignKey = updateProductDataSourceField.IsForeignKey;
                  productDataSourceField.IsViewable = updateProductDataSourceField.IsViewable;
                  productDataSourceField.IsEditable = updateProductDataSourceField.IsEditable;
                  productDataSourceField.IsNullable = updateProductDataSourceField.IsNullable;
                  productDataSourceField.IsImmutable = updateProductDataSourceField.IsImmutable;
                  productDataSourceField.IsReadonly = updateProductDataSourceField.IsReadonly;
                  productDataSourceField.IsRichText = updateProductDataSourceField.IsRichText;
                  productDataSourceField.IsSharable = updateProductDataSourceField.IsSharable;
                  productDataSourceField.ShowColumn = updateProductDataSourceField.ShowColumn;
                  productDataSourceField.Description = updateProductDataSourceField.Description;
                  productDataSourceField.UseInQuickSearch = updateProductDataSourceField.UseInQuickSearch;
                  productDataSourceField.CanUseInAdvancedSearch = updateProductDataSourceField.CanUseInAdvancedSearch;
                }
              });
            }
          }
        });
      }
    },
    _removeProductDataSourceField(state, deleteProductDataSourceField) {
      const rootId = Number(deleteProductDataSourceField?.RootId ?? 0);
      if (rootId > 0) {
        const productDataSourceRootId = Number(deleteProductDataSourceField?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceFieldDataSourceFields.hasOwnProperty(productDataSourceRootId)) {
          let productDataSourceFieldsData = _.cloneDeep(state.productDataSourceFieldDataSourceFields[productDataSourceRootId]) ?? [];
          productDataSourceFieldsData = [...productDataSourceFieldsData.filter((productDataSourceField) => Number(productDataSourceField?.RootId ?? 0) !== rootId)] ?? [];
          state.productDataSourceFieldDataSourceFields[productDataSourceRootId] = (_.map(productDataSourceFieldsData, (productDataSourceField) => { return { ...productDataSourceField, CanEdit: Number(productDataSourceField.ProductDataSourceRootId ?? 0) < 1 }; }) ?? []).sort((odsf1, odsf2) => (odsf1.Name > odsf2.Name ? 1 : -1)) ?? [];
        }
      }
    },
  },
  actions: {
    async getProductDataSourceFields(context, { ProductRootId, ProductDataSourceRootId, UseCache, CanUseServerCache }) {
      UseCache = UseCache ?? false;
      ProductRootId = Number(ProductRootId ?? 0);
      ProductDataSourceRootId = Number(ProductDataSourceRootId ?? 0);
      CanUseServerCache = CanUseServerCache || true;
      const isCached = UseCache && context.state.productDataSourceFieldDataSourceFields?.hasOwnProperty(ProductDataSourceRootId);
      if (!isCached) {
        await timeOperation(async () => {
          context.commit("setProductDataSourceFields", { ProductDataSourceRootId, Data: [] });
          const formattedUrl = `${productDataSourceFieldsUrl}?productrootid=${ProductRootId}&productdatasourcerootid=${ProductDataSourceRootId}&canusecache=${CanUseServerCache}&includemetadata=true`;
          const Data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setProductDataSourceFields", { ProductDataSourceRootId, Data });
        }, "productDataSourceFields - getProductDataSourceFields");
      }
    },
    async addProductDataSourceField(context, productDataSourceField) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productDataSourceFieldsUrl, Payload: productDataSourceField });
        if (data?.RootId > 0) {
          productDataSourceField.RootId = data.RootId;
          context.commit("_addProductDataSourceField", data);
        }
        return data;
      }, "productDataSourceFields - addProductDataSourceField");
    },
    async updateProductDataSourceField(context, productDataSourceField) {
      await timeOperation(async () => {
        const formattedUrl = `${productDataSourceFieldsUrl}/${Number(productDataSourceField?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDataSourceField });
        context.commit("_updateProductDataSourceField", data);
        context.commit("_updateProductDataSourceDataSourceField", data);
      }, "productDataSourceFields - updateProductDataSourceField");
    },
    async removeProductDataSourceField(context, productDataSourceField) {
      await timeOperation(async () => {
        const formattedUrl = `${productDataSourceFieldsUrl}/${Number(productDataSourceField?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductDataSourceField", productDataSourceField);
      }, "productDataSourceFields - removeProductDataSourceField");
    },
  },
};
