import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, workflowTaskTemplatesUrl, organizationWorkflowTaskTemplatesUrl, userWorkflowTaskTemplatesUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  actions: {
    async getProductLevelWorkflowTaskTemplates(context, { ProductRootId, InjectReferences, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        InjectReferences = InjectReferences ?? true;
        IncludeMetadata = IncludeMetadata ?? true;
        if (ProductRootId > 0) {
          const formattedUrl = `${workflowTaskTemplatesUrl}?productrootid=${ProductRootId}&organizationrootid=0&userrootid=0&isproductlevel=true&injectreferences=${InjectReferences}&includemetadata=${IncludeMetadata}&includepossible=false`;
          return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        }
        return [];
      }, "workflowTaskTemplates - getProductLevelWorkflowTaskTemplates");
    },
    async getOrganizationLevelWorkflowTaskTemplates(context, { ProductRootId, OrganizationRootId, InjectReferences, IncludePossible, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        InjectReferences = InjectReferences ?? true;
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? true;
        if (OrganizationRootId > 0) {
          const formattedUrl = `${workflowTaskTemplatesUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=0&isorganizationlevel=true&injectreferences=${InjectReferences}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
          return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        }
        return [];
      }, "workflowTaskTemplates - getOrganizationLevelWorkflowTaskTemplates");
    },
    async getUserLevelWorkflowTaskTemplates(context, { ProductRootId, OrganizationRootId, UserRootId, WorkflowTaskTemplateRootId, IncludePossible, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        WorkflowTaskTemplateRootId = Number(WorkflowTaskTemplateRootId ?? 0);
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? false;
        if (UserRootId > 0) {
          const formattedUrl = `${userWorkflowTaskTemplatesUrl}?userrootid=${UserRootId}&organizationrootid=${OrganizationRootId}&productrootid=${ProductRootId}&workflowtasktemplaterootid=${WorkflowTaskTemplateRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
          return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        }
        return [];
      }, "organizations - getUserLevelWorkflowTaskTemplates");
    },
    async getWorkflowTaskTemplateTemplate(context, { ProductRootId, ProductDataSourceRootId, OrganizationDataSourceRootId, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        ProductDataSourceRootId = Number(ProductDataSourceRootId ?? 0);
        OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0);
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${workflowTaskTemplatesUrl}?productrootid=${ProductRootId}&productdatasourcerootid=${ProductDataSourceRootId}&organizationdatasourcerootid=${OrganizationDataSourceRootId}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}&template=true&injectreferences=true`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "workflowTaskTemplates - getWorkflowTaskTemplateTemplate");
    },
    async addWorkflowTaskTemplate(context, { WorkflowTaskTemplate, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${workflowTaskTemplatesUrl}?isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: WorkflowTaskTemplate });
      }, "workflowTaskTemplates - addWorkflowTaskTemplate");
    },
    async updateWorkflowTaskTemplate(context, { WorkflowTaskTemplate, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${workflowTaskTemplatesUrl}/${Number(
          WorkflowTaskTemplate?.RootId ?? 0
        )}?isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: WorkflowTaskTemplate });
      }, "workflowTaskTemplates - updateWorkflowTaskTemplate");
    },
    async cloneWorkflowTaskTemplate(context, { WorkflowTaskTemplate, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${workflowTaskTemplatesUrl}/${Number(
          WorkflowTaskTemplate?.RootId ?? 0
        )}/clone?isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: WorkflowTaskTemplate });
      }, "workflowTaskTemplates - cloneWorkflowTaskTemplate");
    },
    async renameWorkflowTaskTemplate(context, workflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowTaskTemplatesUrl}/${Number(workflowTaskTemplate?.RootId ?? 0)}/rename`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowTaskTemplate });
      }, "workflowTaskTemplates - renameWorkflowTaskTemplate");
    },
    async enableProductWorkflowTaskTemplate(context, workflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowTaskTemplatesUrl}/${Number(workflowTaskTemplate?.RootId ?? 0)}/updateproductworkflowtasktemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowTaskTemplate, Enabled: true } });
      }, "workflowTaskTemplates - enableProductWorkflowTaskTemplate");
    },
    async disableProductWorkflowTaskTemplate(context, workflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowTaskTemplatesUrl}/${Number(workflowTaskTemplate?.RootId ?? 0)}/updateproductworkflowtasktemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowTaskTemplate, Enabled: false } });
      }, "workflowTaskTemplates - disableProductWorkflowTaskTemplate");
    },
    async enableOrganizationWorkflowTaskTemplate(context, workflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowTaskTemplatesUrl}/${Number(workflowTaskTemplate?.RootId ?? 0)}/updateorganizationworkflowtasktemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowTaskTemplate, Enabled: true } });
      }, "workflowTaskTemplates - enableOrganizationWorkflowTaskTemplate");
    },
    async disableOrganizationWorkflowTaskTemplate(context, workflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowTaskTemplatesUrl}/${Number(workflowTaskTemplate?.RootId ?? 0)}/updateorganizationworkflowtasktemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowTaskTemplate, Enabled: false } });
      }, "workflowTaskTemplates - disableOrganizationWorkflowTaskTemplate");
    },
    async updateOrganizationWorkflowTaskTemplateAlias(context, workflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowTaskTemplatesUrl}/${Number(workflowTaskTemplate?.RootId ?? 0)}/updateorganizationworkflowtasktemplatealias`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowTaskTemplate });
      }, "workflowTaskTemplates - updateOrganizationWorkflowTaskTemplateAlias");
    },
    async updateOrganizationWorkflowTaskTemplateAccessSettings(context, organizationWorkflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationWorkflowTaskTemplatesUrl}/${Number(organizationWorkflowTaskTemplate?.RootId ?? 0)}/updateaccesssettings`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationWorkflowTaskTemplate });
      }, "organizations - updateOrganizationWorkflowTaskTemplateAccessSettings");
    },
    async updateWorkflowTaskTemplateAccessSettings(context, workflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowTaskTemplatesUrl}/${Number(workflowTaskTemplate?.RootId ?? 0)}/updateaccesssettings`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowTaskTemplate });
      }, "organizations - updateWorkflowTaskTemplateAccessSettings");
    },
    async removeWorkflowTaskTemplate(context, workflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowTaskTemplatesUrl}/${Number(workflowTaskTemplate?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "workflowTaskTemplates - removeWorkflowTaskTemplate");
    },
    async updateUserWorkflowTaskTemplateEnabledStatus(context, userWorkflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${userWorkflowTaskTemplatesUrl}/${Number(userWorkflowTaskTemplate?.RootId ?? 0)}/updateenabledstatus`;
        const updatedUserWorkflowTaskTemplate = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userWorkflowTaskTemplate });
        context.commit("_updateUserWorkflowTaskTemplate", {
          UserRootId: updatedUserWorkflowTaskTemplate.UserRootId,
          ProductRootId: updatedUserWorkflowTaskTemplate.ProductRootId,
          WorkflowTaskTemplateRootId: updatedUserWorkflowTaskTemplate.WorkflowTaskTemplateRootId,
          Enabled: updatedUserWorkflowTaskTemplate.Enabled ?? false,
        });
        return updatedUserWorkflowTaskTemplate;
      }, "organizations - updateUserWorkflowTaskTemplateEnabledStatus");
    },
    async updateUserWorkflowTaskTemplateSelectedStatus(context, userWorkflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${userWorkflowTaskTemplatesUrl}/${Number(userWorkflowTaskTemplate?.RootId ?? 0)}/updateselectedstatus`;
        const updatedUserWorkflowTaskTemplate = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userWorkflowTaskTemplate });
        context.commit("_updateUserWorkflowTaskTemplate", {
          UserRootId: updatedUserWorkflowTaskTemplate.UserRootId,
          ProductRootId: updatedUserWorkflowTaskTemplate.ProductRootId,
          WorkflowTaskTemplateRootId: updatedUserWorkflowTaskTemplate.WorkflowTaskTemplateRootId,
          Selected: updatedUserWorkflowTaskTemplate.Selected ?? false,
        });
        return updatedUserWorkflowTaskTemplate;
      }, "organizations - updateUserWorkflowTaskTemplateSelectedStatus");
    },
    async updateUserWorkflowTaskTemplateAccessSettings(context, userWorkflowTaskTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${userWorkflowTaskTemplatesUrl}/${Number(userWorkflowTaskTemplate?.RootId ?? 0)}/updateaccesssettings`;
        const updatedUserWorkflowTaskTemplate = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userWorkflowTaskTemplate });
        context.commit("_updateUserWorkflowTaskTemplate", {
          UserRootId: updatedUserWorkflowTaskTemplate.UserRootId,
          ProductRootId: updatedUserWorkflowTaskTemplate.ProductRootId,
          WorkflowTaskTemplateRootId: updatedUserWorkflowTaskTemplate.WorkflowTaskTemplateRootId,
          CanCopy: updatedUserWorkflowTaskTemplate.CanCopy ?? false,
          CanClose: updatedUserWorkflowTaskTemplate.CanClose ?? false,
          CanShare: updatedUserWorkflowTaskTemplate.CanShare ?? false,
        });
        return updatedUserWorkflowTaskTemplate;
      }, "organizations - updateUserWorkflowTaskTemplateAccessSettings");
    },
  },
};
