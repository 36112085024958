import _ from "lodash";

export const REQUIRE_FORM_NONEMPTY_KEY = "FormMustBeNonEmptyForValidation";

export const ValidationTypes = {
  RequireFormNonEmpty: 0,
  NonEmptyString: 1,
  PositiveNumber: 2,
  PastCustomDate: 3,
  ValidateInnerForm: 4,
  RepeatedEntriesValidation: 5,
};

export const VALIDATION_KEY = "validationType";
export const CUSTOM_DATE_KEY = "customDate";
export const FAILURE_MESSAGE = "failureMessage";
export const INNER_FORM_VALIDATION = "innerValidationDefinition";
// in the case of REQUIRE_FORM_NONEMPTY_KEY, the VALIDATION_KEY is just true
// interface validationDefinitionItem {
//   publicName: string,
//   validationType: number, (from the above validation types)
//   customDate?: Date,
//   innerValidationDefinition?: validationDefinitionItem
// }

function passesValidation(value, validation, validationObject) {
  switch (validation) {
    case ValidationTypes.NonEmptyString:
      if (!(typeof value === "string") && !(value instanceof String)) {
        return false;
      }
      return !!value;
    case ValidationTypes.PastCustomDate:
      if (!validationObject[CUSTOM_DATE_KEY]) {
        console.error("no custom date given for validation");
        return true;
      }
      {
        const customDate = validationObject[CUSTOM_DATE_KEY];
        try {
          return new Date(value) - customDate > 0;
        } catch (e) {
          return false;
        }
      }
    default:
      return true;
  }
}

function validateEntries(formItemEntries, formEntryValidationDefinition) {
  if (!formEntryValidationDefinition) {
    console.error("no validation supplied");
    return;
  }
  if (!formItemEntries) {
    console.error("no form item entries");
    if (formEntryValidationDefinition[REQUIRE_FORM_NONEMPTY_KEY]) {
      if (!formEntryValidationDefinition[REQUIRE_FORM_NONEMPTY_KEY][VALIDATION_KEY]) {
        return;
      }
      return [{ [REQUIRE_FORM_NONEMPTY_KEY]: formEntryValidationDefinition[REQUIRE_FORM_NONEMPTY_KEY][FAILURE_MESSAGE] }];
    }
    return;
  }
  if (formEntryValidationDefinition[REQUIRE_FORM_NONEMPTY_KEY]?.[VALIDATION_KEY] && formItemEntries?.length < 1) {
    return [{ [REQUIRE_FORM_NONEMPTY_KEY]: formEntryValidationDefinition[REQUIRE_FORM_NONEMPTY_KEY][FAILURE_MESSAGE] }];
  }

  // this runs if formItemEntries is nonempty and validation exists
  let validationErrors = [];
  _.forEach(formItemEntries, (entry, index) => {
    validationErrors[index] = validateFormObject(entry, formEntryValidationDefinition);
  });
  return validationErrors;
}

export function validateFormObject(formEntry, formEntryValidationDefinition) {
  if (!formEntryValidationDefinition) {
    console.error("no validation given");
    return {};
  }
  if (!formEntry) {
    console.error("no entry");
    if (formEntryValidationDefinition[REQUIRE_FORM_NONEMPTY_KEY]?.[VALIDATION_KEY]) {
      return {
        [REQUIRE_FORM_NONEMPTY_KEY]: formEntryValidationDefinition[REQUIRE_FORM_NONEMPTY_KEY][FAILURE_MESSAGE],
      };
    }
    return {};
  }
  if (formEntryValidationDefinition[REQUIRE_FORM_NONEMPTY_KEY]?.[VALIDATION_KEY] && _.isEmpty(formEntry)) {
    return {
      [REQUIRE_FORM_NONEMPTY_KEY]: formEntryValidationDefinition[REQUIRE_FORM_NONEMPTY_KEY][FAILURE_MESSAGE],
    };
  }

  let entryValidationErrors = {};
  _.forEach(formEntry, (value, key) => {
    if (formEntryValidationDefinition[key]) {
      if (!formEntryValidationDefinition[key][VALIDATION_KEY]) {
        console.error("validation condition missing validation type");
      } else {
        const validationCondition = formEntryValidationDefinition[key][VALIDATION_KEY];
        switch (validationCondition) {
          case ValidationTypes.ValidateInnerForm:
            if (!formEntryValidationDefinition[key][INNER_FORM_VALIDATION]) {
              console.error("no form validation for inner form");
              entryValidationErrors[key] = {};
              break;
            }
            entryValidationErrors[key] = validateFormObject(value, formEntryValidationDefinition[key][INNER_FORM_VALIDATION]);
            break;
          case ValidationTypes.RepeatedEntriesValidation:
            if (!formEntryValidationDefinition[key][INNER_FORM_VALIDATION]) {
              console.error("no form validation for inner repeated form item");
              entryValidationErrors[key] = [];
              break;
            }
            entryValidationErrors[key] = validateEntries(value, formEntryValidationDefinition[key][INNER_FORM_VALIDATION]);
            break;
          default:
            if (!passesValidation(value, validationCondition, formEntryValidationDefinition[key])) {
              entryValidationErrors[key] = formEntryValidationDefinition[key][FAILURE_MESSAGE];
            }
        }
      }
    }
  });
  // validateForm returns {
  //    [key]: string
  //    [key]: validateForm
  //    [key]: validateForm[]
  // }
  return entryValidationErrors; //returns an object with key to string, object like this, or array of objects like this
}

export function validateFormResultHasErrors(validationErrors) {
  // validateForm returns {
  //    [key]: string
  //    [key]: validateForm
  //    [key]: validateForm[]
  // }
  let res = false;
  _.forEach(validationErrors, (errorResult) => {
    // error is a non-empty string
    if ((typeof errorResult === "string" || errorResult instanceof String) && errorResult) {
      res = true;
    } else {
      // error is an object or array
      _.forEach(errorResult, (innerErrorResult) => {
        res = res ? res : validateFormResultHasErrors(innerErrorResult);
      });
    }
  });

  return res;
}

export function getFlattenedValidationErrors(validationErrors) {
  const res = [];

  _.forEach(validationErrors, (errorResult) => {
    if (typeof errorResult === "string") {
      // error is a non-empty string
      res.push(errorResult);
    } else {
      // error is an object or array
      _.forEach(errorResult, (innerErrorResult) => {
        const errors = getFlattenedValidationErrors(innerErrorResult);
        errors.forEach((error) => {
          res.push(error);
        });
      });
    }
  });

  return res;
}

export function validateForm(form, formValidation) {
  const errors = validateFormObject(form, formValidation);
  if (validateFormResultHasErrors(errors)) {
    return getFlattenedValidationErrors(errors);
  } else {
    return [];
  }
}
