export default {
  state: {
    focusedId: [],
  },
  mutations: {
    setFocusedId(state, newFocusedId) {
      state.focusedId = newFocusedId;
    },
  },
};
