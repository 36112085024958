import {ApiRequest, GetVerb, PostVerb, instantMessagesUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import {ChatsRecordType} from "@/lib/settings";
import {getGridData} from "@/store/searchResults";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getInstantMessagesAdvancedSearchData(context, {
      ProductRootId,
      OrganizationRootId,
      UserRootId,
      SearchRootId,
      PreviewParameters,
      IncludeMetadata,
      IncludeDebugData,
      CanUseServerCache,
    }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        UserRootId = Number(UserRootId || 0);
        SearchRootId = Number(SearchRootId || 0);
        IncludeMetadata = IncludeMetadata || true;
        IncludeDebugData = IncludeDebugData || false;
        CanUseServerCache = CanUseServerCache || true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${instantMessagesUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&searchrootid=${SearchRootId
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata
          }&includedebugdata=${IncludeDebugData}&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        context.commit("setSearchResultsData", { ViewType: ChatsRecordType, Data });
      }, "instantMessages - getInstantMessagesAdvancedSearchData");
    },
    async addInstantMessage(context, instantMessage) {
      await timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: instantMessagesUrl, Payload: instantMessage });
      }, "instantMessages - addInstantMessage");
    },
  },
};
