export default {
  state: {
    uploadInProgress: false,
  },
  mutations: {
    setUploadInProgress(state, payload) {
      state.uploadInProgress = payload;
    },
  },
};
