import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, notificationTypesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    notificationTypes: [],
  },
  getters: {
    getNotificationTypeByRootId: (state) => (rootId) => {
      return state.notificationTypes.find((notificationType) => Number(notificationType?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getNotificationTypeByDisplayName: (state) => (name) => {
      return state.notificationTypes.find((notificationType) => String(notificationType?.DisplayName ?? "") === String(name ?? "")) ?? null;
    },
    getNotificationTypeRootIdByDisplayName: (state) => (name) => {
      return (state.notificationTypes.find((notificationType) => String(notificationType?.DisplayName ?? "") === String(name ?? "")) ?? null)?.RootId ?? 0;
    },
  },
  mutations: {
    setNotificationTypeData(state, notificationTypes) {
      state.notificationTypes = notificationTypes?.sort((ntype1, ntype2) => (ntype1.DisplayName > ntype2.DisplayName ? 1 : -1)) ?? [];
    },
    clearNotificationTypes(state) {
      state.notificationTypes = [];
    },
    _addNotificationType(state, notificationType) {
      const notificationTypesData = _.cloneDeep(state.notificationTypes) ?? [];
      notificationTypesData.push(notificationType);
      state.notificationTypes = notificationTypesData.sort((ntype1, ntype2) => (ntype1.DisplayName > ntype2.DisplayName ? 1 : -1));
    },
    _updateNotificationType(state, updateNotificationType) {
      const rootId = Number(updateNotificationType?.RootId ?? 0);
      if (rootId > 0) {
        let notificationTypesData = _.cloneDeep(state.notificationTypes) ?? [];
        notificationTypesData = [...notificationTypesData.filter(notificationType => Number(notificationType?.RootId ?? 0) !== rootId), _.cloneDeep(updateNotificationType)].sort((ntype1, ntype2) => (ntype1.DisplayName > ntype2.DisplayName ? 1 : -1)) ?? [];
        state.notificationTypes = notificationTypesData;
      }
    },
    _removeNotificationType(state, deleteNotificationType) {
      const rootId = Number(deleteNotificationType?.RootId ?? 0);
      if (rootId > 0) {
        let notificationTypesData = _.cloneDeep(state.notificationTypes) ?? [];
        notificationTypesData = [...notificationTypesData.filter(notificationType => Number(notificationType?.RootId ?? 0) !== rootId)].sort((ntype1, ntype2) => (ntype1.DisplayName > ntype2.DisplayName ? 1 : -1)) ?? [];
        state.notificationTypes = notificationTypesData;
      }
    },
  },
  actions: {
    async getNotificationTypes(context) {
      if (!(context.state.notificationTypes?.length > 0)) {
        await timeOperation(async () => {
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: notificationTypesUrl, Payload: null });
          context.commit("setNotificationTypeData", data);
        }, "notificationTypes - getNotificationTypes");
      }
    },
    async addNotificationType(context, notificationType) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: notificationTypesUrl, Payload: notificationType });
        if (data?.RootId > 0) {
          notificationType.RootId = data.RootId;
          context.commit("_addNotificationType", data);
        }
        return data;
      }, "notificationTypes - addNotificationType");
    },
    async updateNotificationType(context, notificationType) {
      await timeOperation(async () => {
        const formattedUrl = `${notificationTypesUrl}/${Number(notificationType?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: notificationType });
        context.commit("_updateNotificationType", data);
      }, "notificationTypes - updateNotificationType");
    },
    async removeNotificationType(context, notificationType) {
      await timeOperation(async () => {
        const formattedUrl = `${notificationTypesUrl}/${Number(notificationType?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeNotificationType", notificationType);
      }, "notificationTypes - removeNotificationType");
    },
  },
};
