import {ApiRequest, PostVerb, feedbackEntriesUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  actions: {
    async addFeedbackEntry(context, feedbackEntry) {
      await timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: feedbackEntriesUrl, Payload: feedbackEntry });
      }, "feedbackEntries - addFeedbackEntry");
    },
  },
};
