import Axios from "axios";

const FLUEID_COGNITO_CLIENT_ID = process.env.VUE_APP_FLUEID_COGNITO_CLIENT_ID;
const FLUEID_COGNITO_ENDPOINT = process.env.VUE_APP_FLUEID_COGNITO_AUTHENTICATION_ENDPOINT;
const FLUEID_COGNITO_USERNAME = process.env.VUE_APP_FLUEID_COGNITO_USERNAME;
const FLUEID_COGNITO_PASSWORD = process.env.VUE_APP_FLUEID_COGNITO_PASSWORD;

const FLUEID_BASE_URL = process.env.VUE_APP_FLUEID_API_BASE_URL;
const FLUEID_API_FIND_PROPERTY_BY_SEARCH_TEXT = process.env.VUE_APP_FLUEID_API_FIND_PROPERTY_BY_SEARCH_TEXT;
const FLUEID_API_PROPERTY_DETAILS = process.env.VUE_APP_FLUEID_API_PROPERTY_DETAILS;
const FLUEID_API_PROPERTY_SEARCH_USED_BY_FLUEID_PRO = process.env.VUE_APP_FLUEID_API_PROPERTY_SEARCH_USED_BY_FLUEID_PRO;

const MinSearchResults = 5;
const MaxSearchResults = 100;
const DefaultMaxResults = 10;

export default {
  state: {
    flueidAccessToken: null,
    flueidRefreshToken: null,
  },
  getters: {},
  mutations: {
    setFlueidAccessToken(state, flueidAccessToken) {
      state.flueidAccessToken = flueidAccessToken;
    },
    setFlueidRefreshToken(state, flueidRefreshToken) {
      state.flueidRefreshToken = flueidRefreshToken;
    },
  },
  actions: {
    async authenticateWithFlueidPro({ commit }) {
      const body = {
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: FLUEID_COGNITO_CLIENT_ID,
        AuthParameters: {
          USERNAME: FLUEID_COGNITO_USERNAME,
          PASSWORD: FLUEID_COGNITO_PASSWORD,
        },
      };

      const headers = {
        "Content-Type": "application/x-amz-json-1.1",
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      };

      await Axios.post(FLUEID_COGNITO_ENDPOINT, body, {
        headers,
      })
        .then((response) => {
          const AuthenticationResult = response?.data?.AuthenticationResult;
          if (!AuthenticationResult) {
            console.error("failed to authenticate with Flueid Pro");
            return;
          }
          const { AccessToken, RefreshToken } = AuthenticationResult;
          if (!AccessToken) {
            console.error("failed to get access token from Flueid Pro");
            return;
          }
          commit("setFlueidAccessToken", AccessToken);
          if (!RefreshToken) {
            console.warn("failed to get refresh token from Flueid Pro");
            // not catastrophic, just keep the access token and move on
            return;
          }
          commit("setFlueidRefreshToken", RefreshToken);
        })
        .catch((e) => {
          console.error("failed to authenticate with Flueid Pro ", e);
        });
    },
    async getSearchTextResultsFromFlueidPro({ state }, { searchText, maxResults }) {
      const url = FLUEID_BASE_URL + FLUEID_API_FIND_PROPERTY_BY_SEARCH_TEXT;

      const headers = {
        Authorization: `Bearer ${state.flueidAccessToken}`,
        "Content-Type": "application/json",
      };

      const body = {
        searchText,
        maxResults: maxResults < MinSearchResults || maxResults > MaxSearchResults ? DefaultMaxResults : maxResults,
      };

      const result = await Axios.post(url, body, {
        headers,
      })
        .then((result) => result)
        .catch(() => null);

      if (!result) {
        return result;
      }

      return result?.data ?? [];
    },
    async getPropertyDetailsFromFlueid({ state }, { propertyId }) {
      const url = `${FLUEID_BASE_URL}${FLUEID_API_PROPERTY_DETAILS}?propertyId=${encodeURIComponent(propertyId)}`;

      const headers = {
        Authorization: `Bearer ${state.flueidAccessToken}`,
        "Content-Type": "application/json",
      };

      const result = await Axios.get(url, {
        headers,
      })
        .then((result) => result)
        .catch(() => null);

      if (!result) {
        return result;
      }

      return result?.data ?? {};
    },
    async getPropertyDetailsPrimedWithLocation({ state }, { lat, lng, maxResults, searchString }) {
      const headers = {
        Authorization: `Bearer ${state.flueidAccessToken}`,
        "Content-Type": "application/json",
      };

      const url = `${FLUEID_BASE_URL}${FLUEID_API_PROPERTY_SEARCH_USED_BY_FLUEID_PRO}`;

      const body = {
        latitude: lat,
        longitude: lng,
        maxResultsPerType: maxResults,
        searchString: searchString,
      };
      const result = await Axios.post(url, body, {
        headers,
      })
        .then((result) => result)
        .catch(() => null);

      if (!result) {
        return result;
      }

      return result?.data ?? {};
    },
  },
};
