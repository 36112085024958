import { ApiRequest, GetVerb, PostVerb, workflowTasksUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { WorkflowTasksRecordType } from "@/lib/settings";
import { getGridData } from "@/store/searchResults.js";

export default {
  actions: {
    async getWorkflowTasksAdvancedSearchData(
      context,
      { ProductRootId, OrganizationRootId, UserRootId, SearchRootId, OrderRootId, DocumentPlaceOrderRootId, PreviewParameters, IncludeMetadata, IncludeDebugData, NoCache, CanUseServerCache }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        OrderRootId = Number(OrderRootId ?? 0);
        DocumentPlaceOrderRootId = Number(DocumentPlaceOrderRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        NoCache = NoCache ?? false;
        CanUseServerCache = CanUseServerCache || true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${workflowTasksUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&searchrootid=${SearchRootId}&previewparameters=${previewParametersJson}&timezone=${encodeURIComponent(
          getLocalTimezone()
        )}&includemetadata=${IncludeMetadata}&includedebugdata=${IncludeDebugData}&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        if (NoCache) {
          context.commit("setSearchResultsBasicData", { ViewType: WorkflowTasksRecordType, Data: Data.relatedData, SearchResultsRecords: Data.data });
          return Data;
        }
        context.commit("setSearchResultsData", { ViewType: WorkflowTasksRecordType, Data });
      }, "workflowTasks - getWorkflowTasksAdvancedSearchData");
    },
    async addWorkflowTasks(context, workflowTasks) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: workflowTasksUrl, Payload: workflowTasks });
      }, "workflowTasks - addWorkflowTasks");
    },
  },
};
