import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationVendorCategoriesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationVendorCategories: [],
    selectedAdminOrganizationVendorCategory: null,
  },
  getters: {
    getOrganizationVendorCategoryByName: (state) => (name) => {
      return state.organizationVendorCategories.find((organizationVendorCategory) => String(organizationVendorCategory?.DisplayName ?? "") === String(name ?? "")) ?? null;
    },
    getNextOrganizationVendorCategoryRootId: (state) => {
      let result = 1;
      state.organizationVendorCategories.forEach((organizationVendorCategory) => {
        if (Number(organizationVendorCategory.RootId) >= result) {
          result = Number(organizationVendorCategory.RootId) + 1;
        }
      });
      return result;
    },
    isOrganizationVendorCategoryNameTaken: (state) => (currentOrganizationVendorCategory) => {
      const { organizationVendorCategories } = state;
      if (organizationVendorCategories?.length < 1) {
        console.error("no document categories");
        return false;
      }
      if (!currentOrganizationVendorCategory) {
        console.error("no document category given");
        return true;
      }
      const { Name: currentCategoryName, RootId } = currentOrganizationVendorCategory;
      if (!currentCategoryName) {
        console.error("no name");
        return true;
      }
      if (!RootId) {
        console.error("invalid document category");
        return true;
      }
      return !!organizationVendorCategories.find((organizationVendorCategory) => organizationVendorCategory?.DisplayName === currentCategoryName && RootId !== organizationVendorCategory?.RootId);
    },
  },
  mutations: {
    setSelectedAdminOrganizationVendorCategory(state, newOrganizationVendorCategory) {
      state.selectedAdminOrganizationVendorCategory = _.cloneDeep(newOrganizationVendorCategory);
    },
    setOrganizationVendorCategories(state, organizationVendorCategoriesData) {
      state.organizationVendorCategories = organizationVendorCategoriesData?.sort((ovc1, ovc2) => (ovc1.DisplayName > ovc2.DisplayName ? 1 : -1)) ?? [];
    },
    _addOrganizationVendorCategory(state, organizationVendorCategory) {
      const organizationVendorCategoriesData = _.cloneDeep(state.organizationVendorCategories) ?? [];
      organizationVendorCategoriesData.push(organizationVendorCategory);
      state.organizationVendorCategories = organizationVendorCategoriesData.sort((ovc1, ovc2) => (ovc1.DisplayName > ovc2.DisplayName ? 1 : -1)) ?? [];
    },
    _updateOrganizationVendorCategory(state, updateOrganizationVendorCategory) {
      const rootId = Number(updateOrganizationVendorCategory?.RootId ?? 0);
      if (rootId > 0) {
        let organizationVendorCategoriesData = _.cloneDeep(state.organizationVendorCategories) ?? [];
        state.organizationVendorCategories = [...organizationVendorCategoriesData.filter((organizationVendorCategory) => Number(organizationVendorCategory?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationVendorCategory)].sort((ovc1, ovc2) => ovc1.DisplayName > ovc2.DisplayName ? 1 : -1) ?? [];
      }
    },
    _updateOrganizationVendorCategoryEnabled(state, updateOrganizationVendorCategory) {
      const rootId = Number(updateOrganizationVendorCategory?.RootId ?? 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationVendorCategory?.OrganizationDataSourceRootId ?? 0);
        if (state.organizationDataSourceVendorCategories.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationVendorCategory =
            state.organizationDataSourceVendorCategories[organizationDataSourceRootId]?.find(
              (organizationVendorCategory) => Number(organizationVendorCategory?.RootId ?? 0) === rootId
            ) ?? null;
          if (organizationVendorCategory?.RootId > 0) {
            organizationVendorCategory.Enabled = updateOrganizationVendorCategory.Enabled;
          }
        }
      }
    },
    _updateOrganizationVendorCategoryAlias(state, updateOrganizationVendorCategory) {
      const rootId = Number(updateOrganizationVendorCategory?.RootId ?? 0);
      if (rootId > 0) {
        const organizationDataSourceRootId = Number(updateOrganizationVendorCategory?.OrganizationDataSourceRootId ?? 0);
        if (state.organizationDataSourceVendorCategories.hasOwnProperty(organizationDataSourceRootId)) {
          let organizationVendorCategory =
            state.organizationDataSourceVendorCategories[organizationDataSourceRootId]?.find(
              (organizationVendorCategory) => Number(organizationVendorCategory?.RootId ?? 0) === rootId
            ) ?? null;
          if (organizationVendorCategory?.RootId > 0) {
            organizationVendorCategory.Alias = updateOrganizationVendorCategory.Alias;
          }
        }
      }
    },
    _removeOrganizationVendorCategory(state, deleteOrganizationVendorCategory) {
      const rootId = Number(deleteOrganizationVendorCategory?.RootId ?? 0);
      if (rootId > 0) {
        let organizationVendorCategoriesData = _.cloneDeep(state.organizationVendorCategories) ?? [];
        state.organizationVendorCategories = [...organizationVendorCategoriesData.filter((organizationVendorCategory) => Number(organizationVendorCategory?.RootId ?? 0) !== rootId)].sort((ovc1, ovc2) => (ovc1.DisplayName > ovc2.DisplayName ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getOrganizationVendorCategories(context, { ProductRootId, OrganizationRootId, IncludeMetadata, UseCache}) {
      await timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? false;
        UseCache = UseCache ?? false;
        if (!UseCache || !(context.state.organizationVendorCategories?.length > 0)) {
          const formattedUrl = `${organizationVendorCategoriesUrl}?organizationrootid=${OrganizationRootId}&productrootid=${ProductRootId}&includemetadata=${IncludeMetadata}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setOrganizationVendorCategories", data);
        }
      }, "organizationVendorCategories - getOrganizationVendorCategories");
    },
    async addOrganizationVendorCategory(context, organizationVendorCategory) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationVendorCategoriesUrl, Payload: organizationVendorCategory });
        context.commit("_addOrganizationVendorCategory", data);
      }, "organizationVendorCategories - addOrganizationVendorCategory");
    },
    async updateOrganizationVendorCategory(context, organizationVendorCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationVendorCategoriesUrl}/${Number(organizationVendorCategory?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationVendorCategory });
        context.commit("_updateOrganizationVendorCategory", data);
      }, "organizationVendorCategories - updateOrganizationVendorCategory");
    },
    async removeOrganizationVendorCategory(context, organizationVendorCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationVendorCategoriesUrl}/${Number(organizationVendorCategory?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationVendorCategory", organizationVendorCategory);
      }, "organizationVendorCategories - removeOrganizationVendorCategory");
    },
    async enableOrganizationVendorCategory(context, organizationVendorCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationVendorCategoriesUrl}/${Number(organizationVendorCategory?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationVendorCategory, Enabled: true } });
        context.commit("_updateOrganizationVendorCategoryEnabled", data);
      }, "organizationVendorCategories - enableOrganizationVendorCategory");
    },
    async disableOrganizationVendorCategory(context, organizationVendorCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationVendorCategoriesUrl}/${Number(organizationVendorCategory?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...organizationVendorCategory, Enabled: false } });
        context.commit("_updateOrganizationVendorCategoryEnabled", data);
      }, "organizationVendorCategories - disableOrganizationVendorCategory");
    },
    async updateOrganizationVendorCategoryAlias(context, organizationVendorCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationVendorCategoriesUrl}/${Number(organizationVendorCategory?.RootId ?? 0)}/updatealias`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationVendorCategory });
        context.commit("_updateOrganizationVendorCategoryAlias", data);
      }, "organizationVendorCategories - updateOrganizationVendorCategoryAlias");
    },
  },
};
