export default {
  state: {
    allowFileDrop: true,
  },
  mutations: {
    setAllowFileDrop(state, allowFileDrop) {
      state.allowFileDrop = allowFileDrop;
    },
  },
};
