import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export default function (time, timezone, format) {
  if (!time || !timezone) {
    return "";
  }
  return dayjs
    .utc(time)
    .tz(timezone)
    .format(format?.length > 0 ? format : "YYYY-MM-DDTHH:mm");
}
