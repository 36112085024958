import {ApiRequest, PostVerb, salesLeadsUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  actions: {
    async addSalesLead(context, salesLead) {
      await timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: salesLeadsUrl, Payload: salesLead });
      }, "salesLeads - addSalesLead");
    },
  },
};
