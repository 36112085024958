import {ApiRequest, GetVerb, PostVerb, orderContactPersonsUrl, PutVerb, DeleteVerb} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import {OrderContactPersonRecordType} from "@/lib/settings";
import {getGridData} from "@/store/searchResults";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getOrderContactPersonsAdvancedSearchData(context, {
      ProductRootId,
      OrganizationRootId,
      UserRootId,
      OrderRootId,
      SearchRootId,
      PreviewParameters,
      IncludeMetadata,
      IncludeDebugData,
    }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        OrderRootId = Number(OrderRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${orderContactPersonsUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&orderrootid=${OrderRootId
          }&searchrootid=${SearchRootId
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata
          }&includedebugdata=${IncludeDebugData}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        context.commit("setSearchResultsData", { ViewType: OrderContactPersonRecordType, Data });
      }, "orderContactPersons - getOrderContactPersonsAdvancedSearchData");
    },
    async getOrderContactPersons(context, {
      OrderRootId,
      UseCache
    }) {
      return timeOperation(async () => {
        OrderRootId = Number(OrderRootId ?? 0);
        UseCache = UseCache ?? false;
        if (OrderRootId > 0) {
          const formattedUrl = `${orderContactPersonsUrl}?orderrootid=${OrderRootId}&includemetadata=true`;
          return await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        } else {
          console.error("OrderRootId is not valid");
        }
        return [];
      }, "orders - getOrderContactPersons");
    },
    async addOrderContactPerson(context, orderContactPerson) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: orderContactPersonsUrl, Payload: orderContactPerson});
      }, "orderContactPersons - addOrderContactPerson");
    },
    async updateOrderContactPerson(context, orderContactPerson) {
      return timeOperation(async () => {
        const formattedUrl = `${orderContactPersonsUrl}/${Number(orderContactPerson?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: orderContactPerson});
      }, "orderContactPersons - updateOrderContactPerson");
    },
    async removeOrderContactPerson(context, orderContactPerson) {
      await timeOperation(async () => {
        const formattedUrl = `${orderContactPersonsUrl}/${orderContactPerson.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "orderContactPersons - removeOrderContactPerson");
    },
  },
};
