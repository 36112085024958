import { ApiRequest, GetVerb, PostVerb, PutVerb, sharedFormLayoutsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    sharedFormLayouts: [],
  },
  getters: {
    getCopyOfSharedFormLayoutsByUserRootIdAndFormLayoutRootId: (state) => (params) => {
      let result = [];
      const ownerUserRootId = Number(params?.OwnerUserRootId ?? 0);
      const formLayoutRootId = Number(params?.FormLayoutRootId ?? 0);
      state.sharedFormLayouts.forEach((sharedFormLayout) => {
        if ((Number(sharedFormLayout.OwnerUserRootId) === ownerUserRootId) && (Number(sharedFormLayout.FormLayoutRootId) === formLayoutRootId)) {
          result.push(_.cloneDeep(sharedFormLayout));
        }
      });
      return result.sort((sharedFormLayout1, sharedFormLayout2) => (sharedFormLayout1.FormLayoutName > sharedFormLayout2.FormLayoutName ? 1 : -1)) ?? [];
    },
  },
  mutations: {
    setSharedFormLayoutData(state, sharedFormLayoutsData) {
      state.sharedFormLayouts = sharedFormLayoutsData?.sort((sharedFormLayout1, sharedFormLayout2) => (sharedFormLayout1.FormLayoutName > sharedFormLayout2.FormLayoutName ? 1 : -1)) ?? [];
    },
    clearSharedFormLayouts(state) {
      state.sharedFormLayouts = [];
    },
    _updateSharedFormLayout(state, updateSharedFormLayout) {
      const rootId = Number(updateSharedFormLayout?.RootId ?? 0);
      if (rootId > 0) {
        let sharedFormLayoutsData = _.cloneDeep(state.sharedFormLayouts) ?? [];
        sharedFormLayoutsData = [...sharedFormLayoutsData.filter(sharedFormLayout => Number(sharedFormLayout?.RootId ?? 0) !== rootId), _.cloneDeep(updateSharedFormLayout)].sort((sharedFormLayout1, sharedFormLayout2) => (sharedFormLayout1.FormLayoutName > sharedFormLayout2.FormLayoutName ? 1 : -1)) ?? [];
        state.sharedFormLayouts = sharedFormLayoutsData;
      }
    },
  },
  actions: {
    async getUserSharedFormLayouts(context, params) {
      await timeOperation(async () => {
        const formLayoutRootId = Number(params?.FormLayoutRootId ?? 0);
        const ownerUserRootId = Number(params?.OwnerUserRootId ?? 0);
        const formattedUrl = `${sharedFormLayoutsUrl
          }?formlayoutrootid=${formLayoutRootId
          }&owneruserrootid=${ownerUserRootId
          }&includemetadata=${params?.IncludeMetadata ?? true}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setSharedFormLayoutData", data);
      }, "sharedFormLayouts - getUserSharedFormLayouts");
    },
    async updateSharedFormLayout(context, sharedFormLayout) {
      await timeOperation(async () => {
        const formattedUrl = `${sharedFormLayoutsUrl}/${Number(sharedFormLayout?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: sharedFormLayout });
        context.commit("_updateSharedFormLayout", data);
      }, "sharedFormLayouts - updateSharedFormLayout");
    },
    async saveSharedFormLayouts(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${sharedFormLayoutsUrl}/${Number(params?.FormLayoutRootId ?? 0)}/savesharedformlayouts`;
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: params?.SharedFormLayouts ?? [] });
      }, "sharedFormLayouts - saveSharedFormLayouts");
    },
  },
};
