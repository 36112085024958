import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, searchLocationsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    searchLocations: [],
  },
  getters: {
    getSearchLocationByRootId: (state) => (rootId) => {
      return state.searchLocations.find((searchLocation) => Number(searchLocation?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getSearchLocationByName: (state) => (name) => {
      return state.searchLocations.find((searchLocation) => String(searchLocation?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getSearchLocationRootIdByName: (state) => (name) => {
      return (state.searchLocations.find((searchLocation) => String(searchLocation?.Name ?? "") === String(name ?? "")) ?? null)?.RootId ?? 0;
    },
  },
  mutations: {
    setSearchLocationData(state, searchLocations) {
      state.searchLocations = searchLocations?.sort((sl1, sl2) => (sl1.Name > sl2.Name ? 1 : -1)) ?? [];
    },
    clearSearchLocations(state) {
      state.searchLocations = [];
    },
    _addSearchLocation(state, searchLocation) {
      const searchLocationsData = _.cloneDeep(state.searchLocations) ?? [];
      searchLocationsData.push(searchLocation);
      state.searchLocations = searchLocationsData.sort((sl1, sl2) => (sl1.Name > sl2.Name ? 1 : -1));
    },
    _updateSearchLocation(state, updateSearchLocation) {
      const rootId = Number(updateSearchLocation?.RootId ?? 0);
      if (rootId > 0) {
        let searchLocationsData = _.cloneDeep(state.searchLocations) ?? [];
        searchLocationsData = [...searchLocationsData.filter(searchLocation => Number(searchLocation?.RootId ?? 0) !== rootId), _.cloneDeep(updateSearchLocation)].sort((sl1, sl2) => (sl1.Name > sl2.Name ? 1 : -1)) ?? [];
        state.searchLocations = searchLocationsData;
      }
    },
    _removeSearchLocation(state, deleteSearchLocation) {
      const rootId = Number(deleteSearchLocation?.RootId ?? 0);
      if (rootId > 0) {
        let searchLocationsData = _.cloneDeep(state.searchLocations) ?? [];
        searchLocationsData = [...searchLocationsData.filter(searchLocation => Number(searchLocation?.RootId ?? 0) !== rootId)].sort((sl1, sl2) => (sl1.Name > sl2.Name ? 1 : -1)) ?? [];
        state.searchLocations = searchLocationsData;
      }
    },
  },
  actions: {
    async getSearchLocations(context) {
      if (!(context.state.searchLocations?.length > 0)) {
        await timeOperation(async () => {
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: searchLocationsUrl, Payload: null });
          context.commit("setSearchLocationData", data);
        }, "searchLocations - getSearchLocations");
      }
    },
    async addSearchLocation(context, searchLocation) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: searchLocationsUrl, Payload: searchLocation });
        if (data?.RootId > 0) {
          searchLocation.RootId = data.RootId;
          context.commit("_addSearchLocation", data);
        }
        return data;
      }, "searchLocations - addSearchLocation");
    },
    async updateSearchLocation(context, searchLocation) {
      await timeOperation(async () => {
        const formattedUrl = `${searchLocationsUrl}/${Number(searchLocation?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: searchLocation });
        context.commit("_updateSearchLocation", data);
      }, "searchLocations - updateSearchLocation");
    },
    async removeSearchLocation(context, searchLocation) {
      await timeOperation(async () => {
        const formattedUrl = `${searchLocationsUrl}/${Number(searchLocation?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeSearchLocation", searchLocation);
      }, "searchLocations - removeSearchLocation");
    },
  },
};
