export default {
  state: {
    backgroundOverlayIsActive: false,
  },
  mutations: {
    setBackgroundOverlayIsActive(state, value) {
      state.backgroundOverlayIsActive = value;
    },
  },
};
