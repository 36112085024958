export const MaxFileUploadSize = 100000000;
export const EXTERNAL_DATA_SOURCE_BASE_ROOT_ID = 10000000000;
export const TEMP_SEARCH_ROOT_ID = 1000000000;

export const RIBBON_HEIGHT = 39;
export const SCROLL_BAR_HEIGHT = 16;
export const SAVE_RESIZE_DELAY_MILLISECONDS = 500;

export const LEFT_PANE = "left";
export const RIGHT_PANE = "right";

export const HomeViewLocationName = "home";
export const OrderEditorDocumentsViewLocationName = "order-editor-documents";
export const OrderEditorTasksViewLocationName = "order-editor-tasks";
export const OrderEditorInfoViewLocationName = "order-editor-info";
export const PlaceNewOrderViewLocationName = "place-new-order";
export const DeliverProductViewLocationName = "deliver-product";
export const OrganizationContactsViewLocationName = "organization-contacts";
export const OrderEditorDocumentsModalViewLocationName = "order-editor-documents-modal";
export const OrderEditorTemplateTasksModalViewLocationName = "order-editor-template-tasks-modal";
export const OrderEditorCompanyContactsModalViewLocationName = "order-editor-company-contacts-modal";
export const OrderEditorPersonContactsModalViewLocationName = "order-editor-person-contacts-modal";
export const OrderEditorFreeFormTasksModalViewLocationName = "order-editor-free-form-tasks-modal";
export const CreateNewOrderModalViewLocationName = "create-new-order-modal";

export const HomeViewLocationDisplayName = "Home";
export const OrderEditorDocumentsViewLocationDisplayName = "Order Editor - Documents";
export const OrderEditorTasksViewLocationDisplayName = "Order Editor - Tasks";
export const OrderEditorInfoViewLocationDisplayName = "Order Editor - Info";
export const PlaceNewOrderViewLocationDisplayName = "Place New Order";
export const DeliverProductViewLocationDisplayName = "Deliver Product";
export const OrganizationContactsViewLocationDisplayName = "Organization Contacts";
export const OrderEditorDocumentsModalViewLocationDisplayName = "Order Editor - Documents Modal";
export const OrderEditorTemplateTasksModalViewLocationDisplayName = "Order Editor - Template Tasks Modal";
export const OrderEditorCompanyContactsModalViewLocationDisplayName = "Order Editor - Company Contacts Modal";
export const OrderEditorPersonContactsModalViewLocationDisplayName = "Order Editor - Person Contacts Modal";
export const OrderEditorFreeFormTasksModalViewLocationDisplayName = "Order Editor - Free Form Tasks Modal";
export const CreateNewOrderModalViewLocationDisplayName = "Create New Order Modal";

export const HomeViewLocationDefaultRootId = 2;
export const OrderEditorDocumentsViewLocationDefaultRootId = 3;
export const OrderEditorTasksViewLocationDefaultRootId = 4;
export const OrderEditorInfoViewLocationDefaultRootId = 5;
export const PlaceNewOrderViewLocationDefaultRootId = 6;
export const DeliverProductViewLocationDefaultRootId = 7;
export const OrganizationContactsViewLocationDefaultRootId = 8;
export const OrderEditorDocumentsModalViewLocationDefaultRootId = 9;
export const OrderEditorTemplateTasksModalViewLocationDefaultRootId = 10;
export const OrderEditorCompanyContactsModalViewLocationDefaultRootId = 11;
export const OrderEditorPersonContactsModalViewLocationDefaultRootId = 12;
export const OrderEditorFreeFormTasksModalViewLocationDefaultRootId = 13;
export const CreateNewOrderModalViewLocationDefaultRootId = 14;

export const OrderEditorInfoFormLocationName = "order-editor-info";
export const PlaceNewOrderFormLocationName = "place-new-order";
export const HomeFormLocationName = "home";
export const OrderEditorTemplateTasksModalFormLocationName = "order-editor-template-tasks-modal";
export const OrderEditorFreeFormTasksModalFormLocationName = "order-editor-free-form-tasks-modal";
export const ServiceOfferingDetailsFormLocationName = "service-offering-details";
export const OrderEditorTasksFormLocationName = "order-editor-tasks";
export const OrderEditorDocumentsFormLocationName = "order-editor-documents";
export const DeliverProductFormLocationName = "deliver-product";
export const OrganizationContactsFormLocationName = "organization-contacts";
export const OrderEditorDocumentsModalFormLocationName = "order-editor-documents-modal";
export const OrderEditorCompanyContactsModalFormLocationName = "order-editor-company-contacts-modal";
export const OrderEditorPersonContactsModalFormLocationName = "order-editor-person-contacts-modal";
export const CreateNewOrderModalFormLocationName = "create-new-order-modal";

export const OrderEditorInfoFormLocationDisplayName = "Order Editor - Info";
export const PlaceNewOrderFormLocationDisplayName = "Place New Order";
export const HomeFormLocationDisplayName = "Home";
export const ServiceOfferingDetailsFormLocationDisplayName = "Service Offering Details";
export const OrderEditorTasksFormLocationDisplayName = "Order Editor - Tasks";
export const OrderEditorDocumentsFormLocationDisplayName = "Order Editor - Documents";
export const DeliverProductFormLocationDisplayName = "Deliver Product";
export const OrganizationContactsFormLocationDisplayName = "Organization Contacts";
export const OrderEditorDocumentsModalFormLocationDisplayName = "Order Editor - Documents Modal";
export const OrderEditorTemplateTasksModalFormLocationDisplayName = "Order Editor - Template Tasks Modal";
export const OrderEditorCompanyContactsModalFormLocationDisplayName = "Order Editor - Company Contacts Modal";
export const OrderEditorPersonContactsModalFormLocationDisplayName = "Order Editor - Person Contacts Modal";
export const OrderEditorFreeFormTasksModalFormLocationDisplayName = "Order Editor - Free Form Tasks Modal";
export const CreateNewOrderModalFormLocationDisplayName = "Create New Order Modal";

export const OrderEditorInfoFormLocationDefaultRootId = 1;
export const PlaceNewOrderFormLocationDefaultRootId = 2;
export const HomeFormLocationDefaultRootId = 3;
export const ServiceOfferingDetailsFormLocationDefaultRootId = 4;
export const OrderEditorTasksFormLocationDefaultRootId = 5;
export const OrderEditorDocumentsFormLocationDefaultRootId = 6;
export const DeliverProductFormLocationDefaultRootId = 7;
export const OrganizationContactsFormLocationDefaultRootId = 8;
export const OrderEditorDocumentsModalFormLocationDefaultRootId = 9;
export const OrderEditorTemplateTasksModalFormLocationDefaultRootId = 10;
export const OrderEditorCompanyContactsModalFormLocationDefaultRootId = 11;
export const OrderEditorPersonContactsModalFormLocationDefaultRootId = 12;
export const OrderEditorFreeFormTasksModalFormLocationDefaultRootId = 13;
export const CreateNewOrderModalFormLocationDefaultRootId = 14;

export const GroupMemberTypeGroup = 0;
export const GroupMemberTypeUser = 1;

export const GroupTypeAny = -1;
export const GroupTypePermissions = 0;
export const GroupTypeProcess = 1;

export const TrulyEsqProductRootId = 1;
export const LinksProductRootId = 2;
export const DesqProductRootId = 3;
export const AdminProductRootId = 4;

export const MemberTypeGroup = 0;
export const MemberTypeUser = 1;

export const DefaultGridPageSize = 20;
export const SentinelGridPageSize = 50;
export const MaxGridPageSize = 1000;
export const MaxReportDisplayNameSize = 40;
export const MaxUploadedDocumentNameSize = 22;
export const MaxFieldDocumentNameSize = 25;
export const InactiveModalShowTimer = 10; // In minutes
export const InactiveUserLogOutTimer = 10; // In seconds
export const MaxSearchesDisplayedOnContextMenu = 10;

export const Links = "links";
export const LinksDropbox = "links-dropbox";
export const LinksSearch = "links-search";

export const DocumentsReport = "documents";
export const BranchDocumentsReport = "branchdocuments";
export const OrdersReport = "orders";
export const BranchOrdersReport = "branchorders";

export const SubmittedWriteUpDocumentTypeName = "Attorney Agent Exam Worksheet";
export const CommitmentDocumentTypeName = "Commitment";
export const PreliminaryCommitmentDocumentTypeName = "Preliminary Commitment";
export const SignedCommitmentDocumentTypeName = "Signed Commitment";
export const RealEstateContractDocumentTypeName = "Real Estate Contract";
export const InternalWriteUpDocumentTypeName = "Internal Write Up";

export const ScheduleTypeGeneral = "general";
export const ScheduleTypeA = "schedulea";
export const ScheduleTypeB1 = "scheduleb1";
export const ScheduleTypeB2 = "scheduleb2";

export const InternalWriteUpFieldTypeIssuingAgent = "IssuingAgent";
export const InternalWriteUpFieldTypeIssuingOffice = "IssuingOffice";
export const InternalWriteUpFieldTypeIssuingOfficeOrderNumber = "IssuingOfficeOrderNumber";
export const InternalWriteUpFieldTypeTrulyOrderNumber = "OrderNumber";
export const InternalWriteUpFieldTypeEffectiveDate = "EffectiveDate";
export const InternalWriteUpFieldTypePolicies = "Policies";
export const InternalWriteUpFieldTypeVestingAtEffectiveDate = "VestingEffectiveDate";
export const InternalWriteUpFieldTypeInsuredInterestIfOtherThanFeeSimple = "InsuredInterestIfOtherThanFeeSimple";
export const InternalWriteUpFieldTypeProperty = "Property";

export const OrdersRecordType = "orders";
export const DocumentsRecordType = "documents";
export const VendorOrdersRecordType = "vendor-orders";
export const MessagesRecordType = "messages";
export const ChatsRecordType = "chats";
export const NotesRecordType = "notes";
export const WorkflowTasksRecordType = "workflow-tasks";
export const WorkflowRulesRecordType = "workflow-rules";
export const WorkflowNotificationsRecordType = "workflow-notifications";
export const OrderDocumentRecordType = "orderdocuments";
export const AuditLogsRecordType = "auditlogs";
export const DocumentLogRecordType = "documentlog";
export const SharedDataLogRecordType = "shareddatalog";
export const WorkflowLogRecordType = "workflowlog";
export const UserSearchSettingsLogRecordType = "usersearchsettingslog";
export const UserAccessSettingsLogRecordType = "useraccesssettingslog";
export const UserConfigSettingsLogRecordType = "userconfigsettingslog";
export const OrganizationContactCompanyRecordType = "organizationcontactcompany";
export const OrganizationContactPersonRecordType = "organizationcontactperson";
export const OrderContactCompanyRecordType = "ordercontactcompany";
export const OrderContactPersonRecordType = "ordercontactperson";
export const WorkflowTaskTemplatesRecordType = "workflow-task-templates";
export const VendorOrderEntriesRecordType = "vendor-order-items";
export const VendorOrderOfferingsRecordType = "vendor-order-offerings";

export const OrdersGridViewType = "orders-grid";
export const PreviewOrdersGridViewType = "preview-orders-grid";
export const SearchPackageGridViewType = "search-package-grid";
export const SubmittedExamWorksheetGridViewType = "submitted-exam-worksheet-grid";
export const PreliminaryCommitmentGridViewType = "preliminary-commitment-grid";
export const SignedAttachmentsGridViewType = "signed-attachment-grid";
export const BulkMetadataEditSearchPackageGridViewType = "bulk-metadata-edit-search-package-grid";
export const LinksSearchViewType = "links-search-grid";
export const LinksWorkQueueViewType = "links-work-queue-grid";

export const GridRecordsPerPageUserSettingKey = "GridRecordsPerPage";

export const EnableOrganizationLevelTagsConfigKey = "EnableOrganizationLevelTags";
export const EnableUserLevelTagsConfigKey = "EnableUserLevelTags";

// Orders Product Type Access Setting Keys
export const OrdersTypeCanSearchAllOrdersAccessKey = "CanSearchAllOrders";
export const OrdersTypeCanAddMetadataToDocumentsAccessKey = "CanAddMetadataToDocuments";
export const OrdersTypeCanViewDebugInfoAccessKey = "CanViewDebugInfo";
export const OrdersTypeCanAddDocumentsAccessKey = "CanAddDocuments";
export const OrdersTypeCanEditDocumentsAccessKey = "CanEditDocuments";
export const OrdersTypeCanDeleteDocumentsAccessKey = "CanDeleteDocuments";
export const OrdersTypeCanCompleteDocumentsAccessKey = "CanCompleteDocuments";
export const OrdersTypeCanShareViewsAccessKey = "CanShareViews";
export const OrdersTypeCanShareViewsGloballyAccessKey = "CanShareViewsGlobally";
export const OrdersTypeCanCopyPrivateHyperlinksAccessKey = "CanCopyPrivateHyperlinks";
export const OrdersTypeCanPlaceDocumentOrdersAccessKey = "CanPlaceOrders";
export const OrdersTypeCanPlaceTitleOrdersAccessKey = "CanPlaceTitleOrders";

// Documents Product Type Access Setting Keys
export const DocumentsTypeCanSearchAccessKey = "CanSearch";
export const DocumentsTypeCanCreateLinksAccessKey = "CanCreateLinks";
export const DocumentsTypeCanAddMetadataToDocumentsAccessKey = "CanAddMetadataToDocuments";
export const DocumentsTypeCanAddDocumentsAccessKey = "CanAddDocuments";
export const DocumentsTypeCanEditDocumentsAccessKey = "CanEditDocuments";
export const DocumentsTypeCanDeleteDocumentsAccessKey = "CanDeleteDocuments";
export const DocumentsTypeCanViewDebugInfoAccessKey = "CanViewDebugInfo";
export const DocumentsTypeCanChangePublicPrivateLinkCreationSettingsAccessKey = "CanChangePublicPrivateLinkCreationSettings";
export const DocumentsTypeCanShareViewsAccessKey = "CanShareViews";
export const DocumentsTypeCanShareViewsGloballyAccessKey = "CanShareViewsGlobally";
export const DocumentsTypeCanCopyPrivateHyperlinksAccessKey = "CanCopyPrivateHyperlinks";

export const CanCreateViewsAccessKey = "CanCreateViews";
export const CanCreateFormLayoutsAccessKey = "CanCreateFormLayouts";
export const CanCreateWorkflowTasksAccessKey = "CanCreateWorkflowTasks";
export const CanCreateWorkflowRulesAccessKey = "CanCreateWorkflowRules";
export const CanCreateWorkflowNotificationsAccessKey = "CanCreateWorkflowNotifications";

export const NumberDataTypeNameLowerCase = "number";
export const MoneyDataTypeNameLowerCase = "money";
export const DecimalDataTypeNameLowerCase = "decimal";
export const TextDataTypeNameLowerCase = "text";
export const BooleanDataTypeNameLowerCase = "boolean";
export const GuidDataTypeNameLowerCase = "guid";
export const DateDataTypeNameLowerCase = "date";
export const DateTimeDataTypeNameLowerCase = "datetime";

export const NumberDataTypeName = "Number";
export const MoneyDataTypeName = "Money";
export const DecimalDataTypeName = "Decimal";
export const TextDataTypeName = "Text";
export const BooleanDataTypeName = "Boolean";
export const GuidDataTypeName = "Guid";
export const DateDataTypeName = "Date";
export const DateTimeDataTypeName = "Date/Time";

// Admin Config Setting Keys
export const AdminPotentialDocumentTypeShowWarningMessageConfigKey = "PotentialDocumentTypeShowWarningMessage";

// Orders Product Type Config Setting Keys
export const OrdersTypeDocumentsOrderEditorViewTypeConfigKey = "DocumentsOrderEditorViewType";
export const OrdersTypeTemplatesOrderEditorViewTypeConfigKey = "TemplatesOrderEditorViewType";
export const OrdersTypeAppletsOrderEditorViewTypeConfigKey = "AppletsOrderEditorViewType";
export const OrdersTypeCommunicationOrderEditorViewTypeConfigKey = "CommunicationOrderEditorViewType";
export const OrdersTypeDocumentsOrderEditorVerticalSplitPercentageConfigKey = "DocumentsOrderEditorVerticalSplitPercentage";
export const OrdersTypeTemplatesOrderEditorVerticalSplitPercentageConfigKey = "TemplatesOrderEditorVerticalSplitPercentage";
export const OrdersTypeAppletsOrderEditorVerticalSplitPercentageConfigKey = "AppletsOrderEditorVerticalSplitPercentage";
export const OrdersTypeCommunicationOrderEditorVerticalSplitPercentageConfigKey = "CommunicationOrderEditorVerticalSplitPercentage";
export const OrdersTypeReceiveNotificationsConfigKey = "ReceiveNotifications";
export const OrdersTypeInternalWriteUpGeneralHeaderConfigKey = "InternalWriteUpGeneralHeader";
export const OrdersTypeInternalWriteUpScheduleAHeaderConfigKey = "InternalWriteUpScheduleAHeader";
export const OrdersTypeInternalWriteUpScheduleB1HeaderConfigKey = "InternalWriteUpScheduleB1Header";
export const OrdersTypeInternalWriteUpScheduleB2HeaderConfigKey = "InternalWriteUpScheduleB2Header";
export const OrdersTypeInternalWriteUpDefaultCommitmentTimeConfigKey = "InternalWriteUpDefaultCommitmentTime";
export const OrdersTypeInternalWriteUpAutoAssignNumbersConfigKey = "InternalWriteUpAutoAssignNumbers";
export const OrdersTypeInternalWriteUpScheduleB1StartAtNumberConfigKey = "InternalWriteUpScheduleB1StartAtNumber";
export const OrdersTypeInternalWriteUpScheduleB2StartAtNumberConfigKey = "InternalWriteUpScheduleB2StartAtNumber";
export const OrdersTypeInternalWriteUpPropertyIdentifierNameConfigKey = "InternalWriteUpPropertyIdentifierName";
export const OrdersTypeInternalWriteUpGeneralPositionConfigKey = "InternalWriteUpGeneralPosition";
export const OrdersTypeInternalWriteUpScheduleAPositionConfigKey = "InternalWriteUpScheduleAPosition";
export const OrdersTypeInternalWriteUpScheduleB1PositionConfigKey = "InternalWriteUpScheduleB1Position";
export const OrdersTypeInternalWriteUpScheduleB2PositionConfigKey = "InternalWriteUpScheduleB2Position";
export const OrdersTypeInternalWriteUpGeneralMemberPositionConfigKey = "InternalWriteUpGeneralMemberPosition";
export const OrdersTypeInternalWriteUpGeneralMainContactPositionConfigKey = "InternalWriteUpGeneralMainContactPosition";
export const OrdersTypeInternalWriteUpGeneralMemberOrderNumberPositionConfigKey = "InternalWriteUpGeneralMemberOrderNumberPosition";
export const OrdersTypeInternalWriteUpGeneralTrulyOrderNumberPositionConfigKey = "InternalWriteUpGeneralTrulyOrderNumberPosition";
export const OrdersTypeInternalWriteUpScheduleAEffectiveDatePositionConfigKey = "InternalWriteUpScheduleAEffectiveDatePosition";
export const OrdersTypeInternalWriteUpScheduleAPoliciesPositionConfigKey = "InternalWriteUpScheduleAPoliciesPosition";
export const OrdersTypeInternalWriteUpScheduleAInsuredInterestIfOtherThanFeeSimplePositionConfigKey = "InternalWriteUpScheduleAInsuredInterestIfOtherThanFeeSimplePosition";
export const OrdersTypeInternalWriteUpScheduleAVestingAtEffectiveDatePositionConfigKey = "InternalWriteUpScheduleAVestingEffectiveDatePosition";
export const OrdersTypeInternalWriteUpScheduleAPropertyPositionConfigKey = "InternalWriteUpScheduleAPropertyPosition";
export const OrdersTypeInternalWriteUpAutoAssignLegalDescriptionConfigKey = "InternalWriteUpAutoAssignLegalDescription";
export const OrdersTypeInternalWriteUpAutoAssignPIDConfigKey = "InternalWriteUpAutoAssignPID";
export const OrdersTypeInternalWriteUpInLegalDescriptionShowOnlyDocumentsWithLegalDescriptionFieldsConfigKey =
  "InternalWriteUpInLegalDescriptionShowOnlyDocumentsWithLegalDescriptionFields";
export const OrdersTypeInternalWriteUpInPIDShowOnlyDocumentsWithPIDFieldsConfigKey = "InternalWriteUpInPIDShowOnlyDocumentsWithPIDFields";
export const OrdersTypeInternalWriteUpInScheduleB1ShowOnlyDocumentsWithMatchingCategoryToCodeConfigKey = "InternalWriteUpInScheduleB1ShowOnlyDocumentsWithMatchingCategoryToCode";
export const OrdersTypeInternalWriteUpInScheduleB2ShowOnlyDocumentsWithMatchingCategoryToCodeConfigKey = "InternalWriteUpInScheduleB2ShowOnlyDocumentsWithMatchingCategoryToCode";
export const OrdersTypeShowInternalWriteUpConfigKey = "ShowInternalWriteUp";
export const OrdersTypeWriteUpAutoAssignNumbersConfigKey = "WriteUpAutoAssignNumbers";
export const OrdersTypeWriteUpMortgagesAndLiensStartAtNumberConfigKey = "WriteUpMortgagesAndLiensStartAtNumber";
export const OrdersTypeWriteUpNameIndexSearchesStartAtNumberConfigKey = "WriteUpNameIndexSearchesStartAtNumber";
export const OrdersTypeWriteUpProceedingsStartAtNumberConfigKey = "WriteUpProceedingsStartAtNumber";
export const OrdersTypeWriteUpCCRsStartAtNumberConfigKey = "WriteUpCCRsStartAtNumber";
export const MaxNumberOfRecordsToShowConfigKey = "MaxNumberOfRecordsToShow";
export const OrdersTypeDeleteDocumentShowWarningMessageConfigKey = "DeleteDocumentShowWarningMessage";
export const OrdersTypeEnableOrdersExportToExcelConfigKey = "EnableOrdersExportToExcel";
export const OrdersTypeShowAllDocumentVersionsConfigKey = "ShowAllDocumentVersions";
export const OrdersTypeShowChangeTrackingVersionsOfDocumentsConfigKey = "ShowChangeTrackingVersionsOfDocuments";
export const OrdersTypeWorkScreenOrderSummaryFieldsConfigKey = "WorkScreenOrderSummaryFields";
export const OrdersTypeShowCommitmentDocumentTypesAlwaysConfigKey = "ShowCommitmentDocumentTypesAlways";
export const OrdersTypeShowInternalWriteUpTenancyControlsConfigKey = "ShowInternalWriteUpTenancyControls";
export const OrdersTypeShowInternalWriteUpPropertyInformationControlsConfigKey = "ShowInternalWriteUpPropertyInformationControls";
export const OrdersTypeShowInternalWriteUpPropertyTypeControlsConfigKey = "ShowInternalWriteUpPropertyTypeControls";
export const OrdersTypeShowInternalWriteUpTaxStatusControlsConfigKey = "ShowInternalWriteUpTaxStatusControls";
export const OrdersTypeInternalWriteUpShowWaringWhenChangingPositionsConfigKey = "InternalWriteUpShowWaringWhenChangingPositions";
export const OrdersTypeDefaultInternalNotificationEmailConfigKey = "DefaultInternalNotificationEmail";
export const OrdersTypeDefaultExternalNotificationEmailConfigKey = "DefaultExternalNotificationEmail";
export const OrdersTypeDefaultStateConfigKey = "DefaultState";
export const OrdersTypeNumberOfItemsToDisplayInPropertySearchConfigKey = "NumberOfItemsToDisplayInPropertySearch";
export const OrdersTypeNumberOfOrdersToDisplayInOrderHistoryMenuConfigKey = "NumberOfOrdersToDisplayInOrderHistoryMenu";
export const OrdersTypeNumberOfOrdersToDisplayInOrderBookmarksMenuConfigKey = "NumberOfOrdersToDisplayInOrderBookmarksMenu";
export const OrdersTypeEnableBookmarksMenuForOrdersConfigKey = "EnableBookmarksMenuForOrders";
export const OrdersTypeEnableHistoryMenuForOrdersConfigKey = "EnableHistoryMenuForOrders";
export const OrdersTypeEnableOrganizationLevelTagsConfigKey = "EnableOrganizationLevelTags";
export const OrdersTypeEnableUserLevelTagsConfigKey = "EnableUserLevelTags";
export const OrdersTypeEnableCommunicationConfigKey = "EnableCommunication";
export const OrdersTypeUseVendorNeededByDateTimeSettingsWhenIndexingConfigKey = "UseVendorNeededByDateTimeSettingsWhenIndexing";
export const OrdersTypeShowHintsWhenIndexingDocumentsConfigKey = "ShowHintsWhenIndexingDocuments";
export const OrdersTypeTransactionNumberNotFoundIndexingHintConfigKey = "TransactionNumberNotFoundIndexingHint";
export const OrdersTypeNoTransactionNumberIndexingHintConfigKey = "NoTransactionNumberIndexingHint";
export const OrdersTypeNoDocumentCategoryIndexingHintConfigKey = "NoDocumentCategoryIndexingHint";
export const OrdersTypeNoDocumentTypeIndexingHintConfigKey = "NoDocumentTypeIndexingHint";
export const OrdersTypeNoDocumentNameIndexingHintConfigKey = "NoDocumentNameIndexingHint";
export const OrdersTypeShowDataSectionInOrderEditorConfigKey = "ShowDataSectionInOrderEditor";
export const OrdersTypeShowOrganizationNameInPlaceNewOrderConfigKey = "ShowOrganizationNameInPlaceNewOrder";
export const OpenNewlyCreatedOrdersConfigKey = "OpenNewlyCreatedOrders";
export const OrdersTypeOrderNeededByDateTimeTypeConfigKey = "OrderNeededByDateTimeType";
export const OrdersTypeOrderNeededByDateTimeHoursBeforeConfigKey = "OrderNeededByDateTimeHoursBefore";
export const OrdersTypeOrderNeededByDateTimeRushOrderHoursConfigKey = "OrderNeededByDateTimeRushOrderHours";
export const OrdersTypeNewDocumentsOrderNeededByTimeChoicesConfigKey = "NewDocumentsOrderNeededByTimeChoices";
export const OrdersTypeShowSubjectInPlaceNewOrderConfigKey = "ShowSubjectInPlaceNewOrder";
export const OrdersTypeEnableDocumentIndexingConfigKey = "EnableDocumentIndexing";
export const OrdersTypeShowPlaceOrderConfirmationMessageConfigKey = "ShowPlaceOrderConfirmationMessage";
export const OrdersTypeShowDeliverProductConfirmationMessageConfigKey = "ShowDeliverProductConfirmationMessage";
export const OrdersTypeAutoRefreshOrdersConfigKey = "AutoRefreshOrders";
export const OrdersTypeAutoRefreshOrdersDeltaSecondsConfigKey = "AutoRefreshOrdersDeltaSeconds";
export const OrdersTypeAutoRefreshDocumentsConfigKey = "AutoRefreshDocuments";
export const OrdersTypeAutoRefreshDocumentsDeltaSecondsConfigKey = "AutoRefreshDocumentsDeltaSeconds";
export const OrdersTypeRecentOrdersFormatConfigKey = "RecentOrdersFormat";
export const OrdersTypeDefaultQuickSearchDataSetConfigKey = "DefaultQuickSearchDataSet";
export const OrdersTypeViewsEditorViewTypeConfigKey = "ViewsEditorViewType";
export const OrdersTypeViewsEditorVerticalSplitPercentageConfigKey = "ViewsEditorVerticalSplitPercentage";
export const OrdersTypeHomePreviewDataFieldsConfigKey = "HomePreviewDataFields";
export const OrdersTypeHomePreviewDocumentsConfigKey = "HomePreviewDocuments";
export const OrdersTypeOrderEditorPreviewDataFieldsConfigKey = "OrderEditorPreviewDataFields";
export const OrdersTypeOrderEditorPreviewDocumentsConfigKey = "OrderEditorPreviewDocuments";
export const OrdersTypeShowDocumentOrdersInSeparateViewsConfigKey = "ShowDocumentOrdersInSeparateViews";
export const OrdersTypeOrderEditorPreviewRawTextConfigKey = "OrderEditorPreviewRawText";
export const OrdersTypeDefaultOrderTypeConfigKey = "DefaultOrderType";
export const WorkflowTasksEditorViewTypeConfigKey = "WorkflowTasksEditorViewType";
export const WorkflowTasksEditorVerticalSplitPercentageConfigKey = "WorkflowTasksEditorVerticalSplitPercentage";
export const WorkflowRulesEditorViewTypeConfigKey = "WorkflowRulesEditorViewType";
export const WorkflowRulesEditorVerticalSplitPercentageConfigKey = "WorkflowRulesEditorVerticalSplitPercentage";
export const WorkflowNotificationsEditorViewTypeConfigKey = "WorkflowNotificationsEditorViewType";
export const WorkflowNotificationsEditorVerticalSplitPercentageConfigKey = "WorkflowNotificationsEditorVerticalSplitPercentage";
export const CloseOrderTabWhenPlacingNewServiceOrderKey = "CloseOrderTabWhenPlacingNewServiceOrder";
export const CloseOrderTabWhenDeliveringServiceOrderKey = "CloseOrderTabWhenDeliveringServiceOrder";
export const MostRecentlySelectedViewRootIdKey = "MostRecentlySelectedViewRootId";
export const CanExportViewsKey = "CanExportViews";
export const CanImportViewsKey = "CanImportViews";
export const CanExportFormsKey = "CanExportForms";
export const CanImportFormsKey = "CanImportForms";

// Documents Product Type Config Setting Keys
export const DocumentsTypeDefaultHyperlinkTypeConfigKey = "DefaultHyperlinkType";
export const DocumentsTypeDefaultLinksViewTypeConfigKey = "DefaultLinksViewType";
export const DocumentsTypeRequireTransactionNumberBeforeCreatingHyperlinkConfigKey = "RequireTransactionNumberBeforeCreatingHyperlink";
export const DocumentsTypeRequireTransactionNumberWhenAddedConfigKey = "RequireTransactionNumberWhenAdded";
export const DocumentsTypeCheckForValidOrderNumberConfigKey = "CheckForValidOrderNumber";
export const DocumentsTypeOrderNumberMustExistInSystemConfigKey = "OrderNumberMustExistInSystem";
export const DocumentsTypeDropboxExpirationDaysConfigKey = "DropboxExpirationDays";
export const DocumentsTypeDeleteDocumentShowWarningMessageConfigKey = "DeleteDocumentShowWarningMessage";
export const DocumentsTypeAddToFavoritesShowWarningMessageConfigKey = "FavoritesShowWarningMessage";
export const DocumentsTypeRemoveFromFavoritesShowWarningMessageConfigKey = "RemoveFromFavoritesShowWarningMessage";
export const DocumentsTypeMinimumOrderNumberLengthConfigKey = "MinimumOrderNumberLength";
export const DocumentsTypeDefaultLinksTabNameConfigKey = "DefaultLinksTabName";
export const DocumentsTypeEnableOrganizationLevelTagsConfigKey = "EnableOrganizationLevelTags";
export const DocumentsTypeEnableUserLevelTagsConfigKey = "EnableUserLevelTags";
export const DefaultPlaceOrderToOrganizationConfigKey = "DefaultPlaceOrderToOrganization";
export const DefaultUserPlaceOrderToOrganizationConfigKey = "DefaultUserPlaceOrderToOrganization";
export const ShowViewDataSourceInHeaderConfigKey = "ShowViewDataSourceInHeader";
export const ShowAppletsInOrderEditorKey = "ShowAppletsInOrderEditor";
export const ShowTemplatesInOrderEditorKey = "ShowTemplatesInOrderEditor";
export const EnableInfoEditingKey = "EnableInfoEditing";

export const DocumentsTypeSendNewOrdersToEmailConfigKey = "SendNewOrdersToEmail";
export const DocumentsTypeIncludeAttachmentsInNewOrderNotificationEmailsConfigKey = "IncludeAttachmentsInNewOrderNotificationEmails";
export const DocumentsTypeUseVendorNeededByDateTimeSettingsWhenIndexingConfigKey = "UseVendorNeededByDateTimeSettingsWhenIndexing";
export const DocumentsTypeShowHintsWhenIndexingDocumentsConfigKey = "ShowHintsWhenIndexingDocuments";
export const DocumentsTypeTransactionNumberNotFoundIndexingHintConfigKey = "TransactionNumberNotFoundIndexingHint";
export const DocumentsTypeNoTransactionNumberIndexingHintConfigKey = "NoTransactionNumberIndexingHint";
export const DocumentsTypeNoDocumentCategoryIndexingHintConfigKey = "NoDocumentCategoryIndexingHint";
export const DocumentsTypeNoDocumentTypeIndexingHintConfigKey = "NoDocumentTypeIndexingHint";
export const DocumentsTypeNoDocumentNameIndexingHintConfigKey = "NoDocumentNameIndexingHint";
export const DocumentsTypeEnableDocumentIndexingConfigKey = "EnableDocumentIndexing";
export const DocumentsTypeLinksVerticalSplitPercentageConfigKey = "LinksVerticalSplitPercentage";
export const DocumentsTypeLinksViewTypeConfigKey = "LinksViewType";
export const DocumentsTypeAutoRefreshDocumentsConfigKey = "AutoRefreshDocuments";
export const DocumentsTypeAutoRefreshDocumentsDeltaSecondsConfigKey = "AutoRefreshDocumentsDeltaSeconds";
export const DocumentsTypeDefaultQuickSearchDataSetConfigKey = "DefaultQuickSearchDataSet";
export const DocumentsTypeViewsEditorViewTypeConfigKey = "ViewsEditorViewType";
export const DocumentsTypeViewsEditorVerticalSplitPercentageSetConfigKey = "ViewsEditorVerticalSplitPercentage";
export const DocumentsTypeHomePreviewDataFieldsConfigKey = "HomePreviewDataFields";
export const DocumentsTypeHomePreviewDocumentsConfigKey = "HomePreviewDocuments";
export const IncludeExternalOrderNumbersInQuickSearchConfigKey = "IncludeExternalOrderNumbersInQuickSearch";
export const UseCustomOrderFormsConfigKey = "UseCustomOrderForms";
export const OnlyShowCompletedItemsInDeliverProductsConfigKey = "OnlyShowCompletedItemsInDeliverProducts";
export const ResetGridShowWarningMessageConfigKey = "ResetGridShowWarningMessage";
export const EnableTextExtractionConfigKey = "EnableTextExtraction";
export const FormLayoutsEditorViewTypeConfigKey = "FormLayoutsEditorViewType";
export const FormLayoutsEditorVerticalSplitPercentageConfigKey = "FormLayoutsEditorVerticalSplitPercentage";
export const IncludeSenderInProductDeliveryNotificationsConfigKey = "IncludeSenderInProductDeliveryNotifications";
export const UserIncludeSenderInProductDeliveryNotificationsConfigKey = "UserIncludeSenderInProductDeliveryNotifications";
export const IncludeEmailsInProductDeliveryNotificationsConfigKey = "IncludeEmailsInProductDeliveryNotifications";
export const CanCreateAdvancedViewsAccessKey = "CanCreateAdvancedViews";
export const CanViewAdvancedSearchAccessKey = "CanViewAdvancedSearch";

// System Config Setting Keys
export const InternalWriteUpEffectiveDateDocumentCategoriesSettingKey = "InternalWriteUpEffectiveDateDocumentCategories";
export const InternalWriteUpVestingDocumentCategoriesSettingKey = "InternalWriteUpVestingDocumentCategories";
export const InternalWriteUpLegalDescriptionDocumentCategoriesSettingKey = "InternalWriteUpLegalDescriptionDocumentCategories";
export const InternalWriteUpPINDocumentCategoriesSettingKey = "InternalWriteUpPINDocumentCategories";
export const AttorneyWorksheetMortgagesAndLiensDocumentCategoriesSettingKey = "AttorneyWorksheetMortgagesAndLiensDocumentCategories";
export const AttorneyWorksheetProceedingsDocumentCategoriesSettingKey = "AttorneyWorksheetProceedingsDocumentCategories";
export const AttorneyWorksheetNameSearchDocumentCategoriesSettingKey = "AttorneyWorksheetNameSearchDocumentCategories";
export const AttorneyWorksheetCCRsDocumentCategoriesSettingKey = "AttorneyWorksheetCCRsDocumentCategories";
