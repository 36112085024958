import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, groupAccessSettingsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    groupAccessSettings: [],
  },
  getters: {
    getGroupAccessSettingByRootId: (state) => (rootId) => {
      return state.groupAccessSettings.find((groupAccessSetting) => Number(groupAccessSetting?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
  },
  mutations: {
    setGroupAccessSettingData(state, groupAccessSettings) {
      state.groupAccessSettings = groupAccessSettings ?? [];
    },
    _addGroupAccessSetting(state, groupAccessSetting) {
      const groupAccessSettingsData = _.cloneDeep(state.groupAccessSettings) ?? [];
      groupAccessSettingsData.push(groupAccessSetting);
      state.groupAccessSettings = groupAccessSettingsData ?? [];
    },
    _updateGroupAccessSetting(state, updateGroupAccessSetting) {
      const rootId = Number(updateGroupAccessSetting?.RootId ?? 0);
      if (rootId > 0) {
        let groupAccessSettingsData = _.cloneDeep(state.groupAccessSettings) ?? [];
        groupAccessSettingsData = [...groupAccessSettingsData.filter(groupAccessSetting => Number(groupAccessSetting?.RootId ?? 0) !== rootId), _.cloneDeep(updateGroupAccessSetting)] ?? [];
        state.groupAccessSettings = groupAccessSettingsData;
      }
    },
    _removeGroupAccessSetting(state, deleteGroupAccessSetting) {
      const rootId = Number(deleteGroupAccessSetting?.RootId ?? 0);
      if (rootId > 0) {
        let groupAccessSettingsData = _.cloneDeep(state.groupAccessSettings) ?? [];
        groupAccessSettingsData = [...groupAccessSettingsData.filter(groupAccessSetting => Number(groupAccessSetting?.RootId ?? 0) !== rootId)] ?? [];
        state.groupAccessSettings = groupAccessSettingsData;
      }
    },
  },
  actions: {
    async getGroupAccessSettings(context, { GroupRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        GroupRootId = Number(GroupRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${groupAccessSettingsUrl}?grouprootid=${GroupRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setGroupAccessSettingData", data);
        return data;
      }, "groupAccessSettings - getGroupAccessSettings");
    },
    async addGroupAccessSetting(context, groupAccessSetting) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: groupAccessSettingsUrl, Payload: groupAccessSetting });
        if (data?.RootId > 0) {
          groupAccessSetting.RootId = data.RootId;
          context.commit("_addGroupAccessSetting", data);
        }
        return data;
      }, "groupAccessSettings - addGroupAccessSetting");
    },
    async updateGroupAccessSetting(context, groupAccessSetting) {
      return timeOperation(async () => {
        const formattedUrl = `${groupAccessSettingsUrl}/${Number(groupAccessSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: groupAccessSetting});
        context.commit("_updateGroupAccessSetting", data);
        return data;
      }, "groupAccessSettings - updateGroupAccessSetting");
    },
    async removeGroupAccessSetting(context, groupAccessSetting) {
      return timeOperation(async () => {
        const formattedUrl = `${groupAccessSettingsUrl}/${Number(groupAccessSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("_removeGroupAccessSetting", groupAccessSetting);
        return data;
      }, "groupAccessSettings - removeGroupAccessSetting");
    },
  },
};
