import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationSettingsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationConfigSettings: [],
  },
  getters: {
    getOrganizationConfigSettingByRootId: (state) => (rootId) => {
      return state.organizationConfigSettings.find((organizationConfigSetting) => Number(organizationConfigSetting?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
  },
  mutations: {
    setOrganizationConfigSettingData(state, organizationConfigSettings) {
      state.organizationConfigSettings = organizationConfigSettings ?? [];
    },
    _addOrganizationConfigSetting(state, organizationConfigSetting) {
      const organizationConfigSettingsData = _.cloneDeep(state.organizationConfigSettings) ?? [];
      organizationConfigSettingsData.push(organizationConfigSetting);
      state.organizationConfigSettings = organizationConfigSettingsData ?? [];
    },
    _updateOrganizationConfigSetting(state, updateOrganizationConfigSetting) {
      const rootId = Number(updateOrganizationConfigSetting?.RootId ?? 0);
      if (rootId > 0) {
        let organizationConfigSettingsData = _.cloneDeep(state.organizationConfigSettings) ?? [];
        organizationConfigSettingsData = [...organizationConfigSettingsData.filter(organizationConfigSetting => Number(organizationConfigSetting?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationConfigSetting)] ?? [];
        state.organizationConfigSettings = organizationConfigSettingsData;
      }
    },
    _removeOrganizationConfigSetting(state, deleteOrganizationConfigSetting) {
      const rootId = Number(deleteOrganizationConfigSetting?.RootId ?? 0);
      if (rootId > 0) {
        let organizationConfigSettingsData = _.cloneDeep(state.organizationConfigSettings) ?? [];
        organizationConfigSettingsData = [...organizationConfigSettingsData.filter(organizationConfigSetting => Number(organizationConfigSetting?.RootId ?? 0) !== rootId)] ?? [];
        state.organizationConfigSettings = organizationConfigSettingsData;
      }
    },
  },
  actions: {
    async getOrganizationConfigSettings(context, { OrganizationRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${organizationSettingsUrl}?organizationrootid=${OrganizationRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setOrganizationConfigSettingData", data);
        return data;
      }, "organizationConfigSettings - getOrganizationConfigSettings");
    },
    async addOrganizationConfigSetting(context, organizationConfigSetting) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationSettingsUrl, Payload: organizationConfigSetting });
        if (data?.RootId > 0) {
          organizationConfigSetting.RootId = data.RootId;
          context.commit("_addOrganizationConfigSetting", data);
        }
        return data;
      }, "organizationConfigSettings - addOrganizationConfigSetting");
    },
    async updateOrganizationConfigSetting(context, organizationConfigSetting) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationSettingsUrl}/${Number(organizationConfigSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationConfigSetting});
        context.commit("_updateOrganizationConfigSetting", data);
        return data;
      }, "organizationConfigSettings - updateOrganizationConfigSetting");
    },
    async removeOrganizationConfigSetting(context, organizationConfigSetting) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationSettingsUrl}/${Number(organizationConfigSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("_removeOrganizationConfigSetting", organizationConfigSetting);
        return data;
      }, "organizationConfigSettings - removeOrganizationConfigSetting");
    },
  },
};
