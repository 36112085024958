import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productVendorCategoriesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productVendorCategories: [],
    selectedAdminProductVendorCategory: null,
  },
  getters: {
    getProductVendorCategoryByName: (state) => (name) => {
      return state.productVendorCategories.find((productVendorCategory) => String(productVendorCategory?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getNextProductVendorCategoryRootId: (state) => {
      let result = 1;
      state.productVendorCategories.forEach((productVendorCategory) => {
        if (Number(productVendorCategory.RootId) >= result) {
          result = Number(productVendorCategory.RootId) + 1;
        }
      });
      return result;
    },
    isProductVendorCategoryNameTaken: (state) => (currentProductVendorCategory) => {
      const { productVendorCategories } = state;
      if (productVendorCategories?.length < 1) {
        console.error("no document categories");
        return false;
      }
      if (!currentProductVendorCategory) {
        console.error("no document category given");
        return true;
      }
      const { Name: currentCategoryName, RootId } = currentProductVendorCategory;
      if (!currentCategoryName) {
        console.error("no name");
        return true;
      }
      if (!RootId) {
        console.error("invalid document category");
        return true;
      }
      return !!productVendorCategories.find((productVendorCategory) => productVendorCategory?.Name === currentCategoryName && RootId !== productVendorCategory?.RootId);
    },
  },
  mutations: {
    setSelectedAdminProductVendorCategory(state, newProductVendorCategory) {
      state.selectedAdminProductVendorCategory = _.cloneDeep(newProductVendorCategory);
    },
    setProductVendorCategories(state, productVendorCategoriesData) {
      state.productVendorCategories = productVendorCategoriesData?.sort((pvc1, pvc2) => (pvc1.Name > pvc2.Name ? 1 : -1)) ?? [];
    },
    _addProductVendorCategory(state, productVendorCategory) {
      const productVendorCategoriesData = _.cloneDeep(state.productVendorCategories) ?? [];
      productVendorCategoriesData.push(productVendorCategory);
      state.productVendorCategories = productVendorCategoriesData.sort((pvc1, pvc2) => (pvc1.Name > pvc2.Name ? 1 : -1)) ?? [];
    },
    _updateProductVendorCategory(state, updateProductVendorCategory) {
      const rootId = Number(updateProductVendorCategory?.RootId ?? 0);
      if (rootId > 0) {
        let productVendorCategoriesData = _.cloneDeep(state.productVendorCategories) ?? [];
        state.productVendorCategories = [...productVendorCategoriesData.filter((productVendorCategory) => Number(productVendorCategory?.RootId ?? 0) !== rootId), _.cloneDeep(updateProductVendorCategory)].sort((pvc1, pvc2) => pvc1.Name > pvc2.Name ? 1 : -1) ?? [];
      }
    },
    _removeProductVendorCategory(state, deleteProductVendorCategory) {
      const rootId = Number(deleteProductVendorCategory?.RootId ?? 0);
      if (rootId > 0) {
        let productVendorCategoriesData = _.cloneDeep(state.productVendorCategories) ?? [];
        state.productVendorCategories = [...productVendorCategoriesData.filter((productVendorCategory) => Number(productVendorCategory?.RootId ?? 0) !== rootId)].sort((pvc1, pvc2) => (pvc1.Name > pvc2.Name ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getProductVendorCategories(context, { ProductRootId, IncludeMetadata, UseCache}) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? false;
        UseCache = UseCache ?? false;
        if (!UseCache || !(context.state.productVendorCategories?.length > 0)) {
          const formattedUrl = `${productVendorCategoriesUrl}?productrootid=${ProductRootId}&includemetadata=${IncludeMetadata}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setProductVendorCategories", data);
        }
      }, "productVendorCategories - getProductVendorCategories");
    },
    async addProductVendorCategory(context, productVendorCategory) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productVendorCategoriesUrl, Payload: productVendorCategory });
        context.commit("_addProductVendorCategory", data);
      }, "productVendorCategories - addProductVendorCategory");
    },
    async updateProductVendorCategory(context, productVendorCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${productVendorCategoriesUrl}/${Number(productVendorCategory?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productVendorCategory });
        context.commit("_updateProductVendorCategory", data);
      }, "productVendorCategories - updateProductVendorCategory");
    },
    async removeProductVendorCategory(context, productVendorCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${productVendorCategoriesUrl}/${Number(productVendorCategory?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductVendorCategory", productVendorCategory);
      }, "productVendorCategories - removeProductVendorCategory");
    },
  },
};
