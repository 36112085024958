<!--suppress JSUndeclaredVariable -->
<template>
  <div class="row">
    <div v-if="showMainProductHeader" class="main-product-header-wrapper w-100">
      <div class="main-product-header-top">
        <template v-if="loggedInUserHasProducts">
          <template v-if="isOnTrulyEsq">
            <template v-if="canLoggedInUserAccessDesq">
              <div class="main-product-header-item">
                <a @click="navigateToDesq" class="cursor-pointer">
                  <img
                    class="nav-logo"
                    style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.75rem; margin-top: -10px"
                    src="../assets/images/d-esq-logo-white.png"
                    alt=""
                  />
                </a>
              </div>
            </template>
            <template v-if="canLoggedInUserAccessLinks">
              <div class="main-product-header-item">
                <a @click="navigateToLinks" class="cursor-pointer">
                  <img class="nav-logo" style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.5rem; margin-top: -6px" src="../assets/images/links-white-136w.png" alt="" />
                </a>
              </div>
            </template>
            <template v-if="canLoggedInUserAccessTrulyEsq">
              <div class="main-product-header-item" :class="{ activeTab: isOnTrulyEsq }">
                <a @click="navigateToTrulyEsq" class="cursor-pointer">
                  <img class="nav-logo" style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.25rem" src="../assets/images/esq-logo-gray.png" alt="" />
                </a>
              </div>
            </template>
          </template>
          <template v-else-if="isOnLinks">
            <template v-if="canLoggedInUserAccessDesq">
              <div class="main-product-header-item">
                <a @click="navigateToDesq" class="cursor-pointer">
                  <img
                    class="nav-logo"
                    style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.75rem; margin-top: -10px"
                    src="../assets/images/d-esq-logo-white.png"
                    alt=""
                  />
                </a>
              </div>
            </template>
            <template v-if="canLoggedInUserAccessLinks">
              <div class="main-product-header-item" :class="{ activeTab: isOnLinks || !(canLoggedInUserAccessTrulyEsq && canLoggedInUserAccessDesq) }">
                <a @click="navigateToLinks" class="cursor-pointer">
                  <img class="nav-logo" style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.5rem; margin-top: -6px" src="../assets/images/links-color-136w.png" alt="" />
                </a>
              </div>
            </template>
            <template v-if="canLoggedInUserAccessTrulyEsq">
              <div class="main-product-header-item">
                <a @click="navigateToTrulyEsq" class="cursor-pointer">
                  <img class="nav-logo" style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.25rem; margin-top: -2px" src="../assets/images/esq-logo-white.png" alt="" />
                </a>
              </div>
            </template>
          </template>
          <template v-else-if="isOnDesq">
            <template v-if="canLoggedInUserAccessDesq">
              <div class="main-product-header-item" :class="{ activeTab: isOnDesq }">
                <a @click="navigateToDesq" class="cursor-pointer">
                  <img
                    class="nav-logo"
                    style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.75rem; margin-top: -10px"
                    src="../assets/images/d-esq-logo-gray.png"
                    alt=""
                  />
                </a>
              </div>
            </template>
            <template v-if="canLoggedInUserAccessLinks">
              <div class="main-product-header-item">
                <a @click="navigateToLinks" class="cursor-pointer">
                  <img class="nav-logo" style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.5rem; margin-top: -6px" src="../assets/images/links-white-136w.png" alt="" />
                </a>
              </div>
            </template>
            <template v-if="canLoggedInUserAccessTrulyEsq">
              <div class="main-product-header-item">
                <a @click="navigateToTrulyEsq" class="cursor-pointer">
                  <img class="nav-logo" style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.25rem; margin-top: -2px" src="../assets/images/esq-logo-white.png" alt="" />
                </a>
              </div>
            </template>
          </template>
          <template v-else>
            <template v-if="canLoggedInUserAccessDesq">
              <div class="main-product-header-item">
                <a @click="navigateToDesq" class="cursor-pointer">
                  <img
                    class="nav-logo"
                    style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.75rem; margin-top: -10px"
                    src="../assets/images/d-esq-logo-white.png"
                    alt=""
                  />
                </a>
              </div>
            </template>
            <template v-if="canLoggedInUserAccessLinks">
              <div class="main-product-header-item">
                <a @click="navigateToLinks" class="cursor-pointer">
                  <img class="nav-logo" style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.5rem; margin-top: -6px" src="../assets/images/links-white-136w.png" alt="" />
                </a>
              </div>
            </template>
            <template v-if="canLoggedInUserAccessTrulyEsq">
              <div class="main-product-header-item">
                <a @click="navigateToTrulyEsq" class="cursor-pointer">
                  <img class="nav-logo" style="padding-right: 2rem; padding-left: 2rem; padding-top: 0.25rem; margin-top: -2px" src="../assets/images/esq-logo-white.png" alt="" />
                </a>
              </div>
            </template>
          </template>
        </template>
        <template v-else>
          <div class="header-top-inner-left-left bg-white">
            <img class="nav-logo" src="../assets/images/esq-logo-gray.png" alt="" />
          </div>
        </template>
      </div>
    </div>
    <div class="w-full">
      <div class="">
        <template v-if="showOrdersNavbar && !isOnDeliverDocuments && !isOnPlaceNewOrder">
          <OrdersNavbar
            @update:quickSearchOrderNumber="(value) => emit('update:quickSearchOrderNumber', value)"
            @update:quickSearchOrderRootId="(value) => emit('update:quickSearchOrderRootId', value)"
            @update:customSearchText="(value) => emit('update:customSearchText', value)"
            @update:loadQuickSearch="(value) => emit('update:loadQuickSearch', value)"
            @advancedSearchClicked="emit('advancedSearchClicked')"
            @tabSelected="(tab) => emit('tabSelected', tab)"
          />
        </template>
        <template v-else-if="showLinksNavbar && !isOnDeliverDocuments && !isOnPlaceNewOrder">
          <LinksNavbar
            @update:quickSearchOrderNumber="(value) => emit('update:quickSearchOrderNumber', value)"
            @update:quickSearchOrderRootId="(value) => emit('update:quickSearchOrderRootId', value)"
            @update:customSearchText="(value) => emit('update:customSearchText', value)"
            @update:loadQuickSearch="(value) => emit('update:loadQuickSearch', value)"
            @advancedSearchClicked="emit('advancedSearchClicked')"
          />
        </template>
        <template v-else>
          <SimpleNavbar />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import OrdersNavbar from "@/components/navbars/OrdersNavbar";
import SimpleNavbar from "@/components/navbars/SimpleNavbar";
import LinksNavbar from "@/components/navbars/LinksNavbar";
import { TrulyEsqProductRootId, DesqProductRootId, LinksProductRootId } from "@/lib/settings";
import getOrdersGridLink from "@/utils/navigation/getOrdersGridLink";

const CHANGE_TO_PRODUCT_DISABLE_MILLISECONDS = 3000; // Three Seconds

export default {
  name: "MainProductsNavHeader",
  components: {
    OrdersNavbar,
    SimpleNavbar,
    LinksNavbar,
  },
  props: {
    showMainProductHeader: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "createNewSearch",
    "update:quickSearchOrderNumber",
    "update:quickSearchOrderRootId",
    "advancedSearchClicked",
    "update:customSearchText",
    "update:loadQuickSearch",
    "tabSelected",
  ],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const localModelValue = ref(0);

    // Vuex
    const store = useStore();
    // State
    const openOrderTabs = computed(() => store.state.ords.openOrderTabs);
    // Getters
    const loggedInUserHasProducts = computed(() => store.getters.loggedInUserHasProducts);
    const canLoggedInUserAccessTrulyEsq = computed(() => store.getters.canLoggedInUserAccessTrulyEsq);
    const canLoggedInUserAccessDesq = computed(() => store.getters.canLoggedInUserAccessDesq);
    const canLoggedInUserAccessLinks = computed(() => store.getters.canLoggedInUserAccessLinks);
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    // Mutations
    const setCurrentHomeRoute = (homeRoute) => store.commit("setCurrentHomeRoute", homeRoute);
    const setGlobalSelectedProductRootId = (productRootId) => store.commit("setGlobalSelectedProductRootId", productRootId);

    // Computed
    const isOnAdmin = computed(() => route.path.toLowerCase().includes("admin"));
    const isOnViewsEditor = computed(() => route.path.toLowerCase().includes("views-editor"));
    const isOnUserSettings = computed(() => route.path.toLowerCase().includes("user-settings"));
    const isOnDeliverDocuments = computed(() => route.path.toLowerCase().includes("deliver-documents"));
    const isOnPlaceNewOrder = computed(() => route.path.toLowerCase().includes("place-order"));

    const isOnLinks = computed(() => selectedProduct.value === "links");
    const isOnTrulyEsq = computed(() => selectedProduct.value === "trulyesq");
    const isOnDesq = computed(() => selectedProduct.value === "desq");
    const selectedProduct = computed(() => {
      const hostName = window.location.host ?? "";
      const fullPath = route.fullPath.toLowerCase();
      if (hostName.includes("links") || fullPath.includes("links")) {
        return "links";
      }
      if (hostName.includes("truly-esq") || hostName.includes("trulyesq") || fullPath.includes("truly-esq") || fullPath.includes("trulyesq")) {
        return "trulyesq";
      }
      return "desq";
    });
    const showOrdersNavbar = computed(() => (canLoggedInUserAccessTrulyEsq.value && isOnTrulyEsq.value) || (canLoggedInUserAccessDesq.value && isOnDesq.value));
    const showLinksNavbar = computed(() => canLoggedInUserAccessLinks.value && isOnLinks.value);
    const changeToProductRootId = ref(0);
    const productChangeTimer = ref(null);
    const productChangeTimeout = ref(false);

    // Functions
    function handleCreateNewSearch() {
      emit("createNewSearch");
    }
    function onChangeToProductDelayEnd() {
      if (new Date() - productChangeTimer.value < CHANGE_TO_PRODUCT_DISABLE_MILLISECONDS) {
        setTimeout(onChangeToProductDelayEnd, CHANGE_TO_PRODUCT_DISABLE_MILLISECONDS);
      } else {
        productChangeTimeout.value = false;
        changeToProductRootId.value = 0;
        console.log(`Delay requirement reset`);
      }
    }
    async function navigateToTrulyEsq() {
      if (canLoggedInUserAccessTrulyEsq.value) {
        await navigateToNewProduct(TrulyEsqProductRootId);
      }
    }
    async function navigateToDesq() {
      if (canLoggedInUserAccessDesq.value) {
        await navigateToNewProduct(DesqProductRootId);
      }
    }
    async function navigateToLinks() {
      if (canLoggedInUserAccessLinks.value) {
        await navigateToNewProduct(LinksProductRootId);
      }
    }
    async function navigateToNewProduct(newProductRootId) {
      if (newProductRootId > 0 && changeToProductRootId.value === 0) {
        changeToProductRootId.value = newProductRootId;
        setGlobalSelectedProductRootId(changeToProductRootId.value);
        await loadSelectedProduct();
        productChangeTimer.value = new Date();
        if (productChangeTimeout.value === false) {
          productChangeTimeout.value = true;
          setTimeout(onChangeToProductDelayEnd, CHANGE_TO_PRODUCT_DISABLE_MILLISECONDS);
        }
      } else {
        console.log(`Unable to load product '${newProductRootId}' because of delay requirement`);
      }
    }
    async function loadSelectedProduct() {
      const productRootId = getGlobalSelectedProductRootId.value;
      switch (productRootId) {
        case TrulyEsqProductRootId:
        case DesqProductRootId:
          router.push(getOrdersGridLink(productRootId)).catch(() => {
            console.error("Unable to open orders grid");
          });
          break;
        default:
          await router.push({ path: "/links" }).catch(() => {
            console.error("Unable to open links");
          });
          break;
      }
    }
    onMounted(async () => {
      if (!(isOnAdmin.value || isOnViewsEditor.value || isOnUserSettings.value)) {
        setCurrentHomeRoute(route.path);
      }
    });

    return {
      router,
      route,

      emit,

      loggedInUserHasProducts,
      canLoggedInUserAccessTrulyEsq,
      canLoggedInUserAccessDesq,
      canLoggedInUserAccessLinks,
      isOnDeliverDocuments,
      isOnPlaceNewOrder,

      localModelValue,
      isOnAdmin,
      isOnViewsEditor,
      isOnUserSettings,
      isOnTrulyEsq,
      isOnDesq,
      isOnLinks,
      showOrdersNavbar,
      showLinksNavbar,
      openOrderTabs,

      handleCreateNewSearch,
      navigateToTrulyEsq,
      navigateToDesq,
      navigateToLinks,
    };
  },
};
</script>

<style>
.main-product-header-wrapper {
  display: grid;
  grid-template-columns: 5fr 3fr;
  background-color: #34406b;
  height: 37px;
}
.main-product-header-top {
  display: grid;
  height: 37px;
  grid-template-columns: 180px 180px 180px;
  /*grid-gap: 1rem;*/
  align-items: center;
  /*justify-items: center;*/
}
.main-product-header-item {
  display: grid;
  align-items: center;
  justify-items: center;
  height: 37px;
}
</style>
