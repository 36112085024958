import _ from "lodash";
import {OrdersRecordType, DocumentsRecordType, MessagesRecordType, VendorOrdersRecordType} from "@/lib/settings";
import {getDataFieldKeyRootId, getDataFieldKeyFromRootId} from "@/utils/getDataFieldKey";

export function getGridColumns(dataSourceFields, fieldSettings, useProductDataSource, dataSources, hasMultipleDataSources, isStandardSearch, showViewDataSourceInHeader) {
  let gridColumns = [];
  if (dataSourceFields?.length > 0 && fieldSettings?.length > 0) {
    useProductDataSource = useProductDataSource ?? false;
    hasMultipleDataSources = hasMultipleDataSources ?? false;
    fieldSettings.forEach((fieldSetting) => {
      if (!fieldSetting.OrganizationDataSourceRootId) {
        fieldSetting.OrganizationDataSourceRootId = 0;
      }
      if (!fieldSetting.OrganizationDataSourceFieldRootId) {
        fieldSetting.OrganizationDataSourceFieldRootId = 0;
      }
      let dataSourceField = useProductDataSource
        ? dataSourceFields.find((dataSourceField) => Number(dataSourceField?.RootId ?? 0) === Number(fieldSetting?.ProductDataSourceFieldRootId ?? 0)) ?? null
        : dataSourceFields.find((dataSourceField) => Number(dataSourceField?.RootId ?? 0) === Number(fieldSetting?.OrganizationDataSourceFieldRootId ?? 0)) ?? null;
      if (dataSourceField) {
        if (!dataSourceField.OrganizationDataSourceRootId) {
          dataSourceField.OrganizationDataSourceRootId = 0;
        }
        const fieldName = getDataFieldKeyFromRootId(dataSourceField.RootId);
        let dataTypePrefix = "";
        if (hasMultipleDataSources) {
          let dataSource = useProductDataSource
            ? dataSources?.find((productDataSource) => Number(productDataSource.RootId ?? 0) === Number(fieldSetting.ProductDataSourceRootId ?? 0))
            : dataSources?.find((organizationDataSource) => Number(organizationDataSource.RootId ?? 0) === Number(fieldSetting.OrganizationDataSourceRootId ?? 0));
          dataTypePrefix = dataSource?.DataType?.length > 0 ? `${dataSource.DataType} - ` : "";
        }
        const externalPrefix = showViewDataSourceInHeader && (fieldSetting.IsExternal ?? false) ? "External - " : "";
        const extendedExternalPrefix = fieldSetting.IsExternal ?? false ? "External - " : "";
        const columnHeaderText = showViewDataSourceInHeader && hasMultipleDataSources
          ? `${externalPrefix}${dataTypePrefix}${fieldSetting.DisplayName}`
          : `${fieldSetting.DisplayName ?? dataSourceField.DisplayName ?? ""}`;
        const extendedColumnHeaderText = hasMultipleDataSources
          ? `${extendedExternalPrefix}${dataTypePrefix}${fieldSetting.DisplayName}`
          : `${fieldSetting.DisplayName ?? dataSourceField.DisplayName ?? ""}`;
        switch (String(dataSourceField.DataType).toLowerCase()) {
          case "object":
            break;
          case "date":
            if (dataSourceField.IsViewable) {
              gridColumns.push({
                field: fieldName,
                headerText: columnHeaderText,
                extendedHeaderText: extendedColumnHeaderText,
                isPrimaryKey: !dataSourceField.IsEditable,
                visible: fieldSetting.ShowColumn || fieldSetting.GroupColumn,
                Position: fieldSetting.Position,
                showInColumnChooser: dataSourceField.IsViewable,
                textAlign: "Right",
                format: { type: "date", format: "MM/dd/yyyy" },
              });
            }
            break;
          case "datetime":
            if (dataSourceField.IsViewable) {
              gridColumns.push({
                field: fieldName,
                headerText: columnHeaderText,
                extendedHeaderText: extendedColumnHeaderText,
                isPrimaryKey: !dataSourceField.IsEditable,
                visible: fieldSetting.ShowColumn || fieldSetting.GroupColumn,
                Position: fieldSetting.Position,
                showInColumnChooser: dataSourceField.IsViewable,
                textAlign: "Right",
                format: { type: "dateTime", format: "MM/dd/yyyy hh:mm:ss a" },
              });
            }
            break;
          case "decimal":
          case "numeric":
          case "currency":
            if (dataSourceField.IsViewable) {
              gridColumns.push({
                field: fieldName,
                headerText: columnHeaderText,
                extendedHeaderText: extendedColumnHeaderText,
                isPrimaryKey: !dataSourceField.IsEditable,
                visible: fieldSetting.ShowColumn || fieldSetting.GroupColumn,
                Position: fieldSetting.Position,
                showInColumnChooser: dataSourceField.IsViewable,
                textAlign: "Right",
                format: { type: "number", format: "C2" },
              });
            }
            break;
          case "short":
          case "int":
          case "long":
            if (dataSourceField.IsViewable) {
              gridColumns.push({
                field: fieldName,
                headerText: columnHeaderText,
                extendedHeaderText: extendedColumnHeaderText,
                isPrimaryKey: !dataSourceField.IsEditable,
                visible: fieldSetting.ShowColumn || fieldSetting.GroupColumn,
                Position: fieldSetting.Position,
                showInColumnChooser: dataSourceField.IsViewable,
                textAlign: "Right",
                format: { type: "number" },
              });
            }
            break;
          case "bool":
          case "boolean":
            if (dataSourceField.IsViewable) {
              gridColumns.push({
                field: fieldName,
                headerText: columnHeaderText,
                extendedHeaderText: extendedColumnHeaderText,
                isPrimaryKey: !dataSourceField.IsEditable,
                visible: fieldSetting.ShowColumn || fieldSetting.GroupColumn,
                Position: fieldSetting.Position,
                showInColumnChooser: dataSourceField.IsViewable,
                textAlign: "Right",
                format: { type: "boolean" },
              });
            }
            break;
          default:
            if (dataSourceField.IsViewable) {
              gridColumns.push({
                field: fieldName,
                headerText: columnHeaderText,
                extendedHeaderText: extendedColumnHeaderText,
                isPrimaryKey: !dataSourceField.IsEditable,
                visible: fieldSetting.ShowColumn || fieldSetting.GroupColumn,
                Position: fieldSetting.Position,
                showInColumnChooser: dataSourceField.IsViewable,
                textAlign: "Left",
                format: { type: "string" },
              });
            }
            break;
        }
      }
    });
  }
  return gridColumns.sort((gc1, gc2) => (gc1.Position > gc2.Position ? 1 : -1)) ?? [];
}

export function displayGridColumns(fieldSettings) {
  let columnNumber = 1;
  if (fieldSettings?.length > 0) {
    console.log("->->-> Show Column");
    fieldSettings.forEach((fieldSetting) => {
      if (fieldSetting?.ShowColumn ?? false) {
        console.log(`${columnNumber++}. ${fieldSetting.FullDisplayName?.length > 0 ? fieldSetting.FullDisplayName : fieldSetting.DisplayName}`);
      }
    });
    console.log("->->-> Is Viewable");
    fieldSettings.forEach((fieldSetting) => {
      if ((fieldSetting?.IsViewable ?? false) && !(fieldSetting?.ShowColumn ?? false)) {
        console.log(`${columnNumber++}. ${fieldSetting.FullDisplayName?.length > 0 ? fieldSetting.FullDisplayName : fieldSetting.DisplayName}`);
      }
    });
  }
}
export function displayDataSourceFields(dataSourceFields, isProductLevel) {
  let columnNumber = 1;
  console.log(`$dataSourceFields: ${dataSourceFields?.length || 0}`);
  if (dataSourceFields?.length > 0) {
    if (isProductLevel) {
      console.log(">>>>>> Can Be Filter");
      dataSourceFields.forEach((productDataSourceField) => {
        if ((productDataSourceField?.IsViewable ?? false) || (productDataSourceField?.ShowColumn ?? false)) {
          console.log(`${columnNumber++}. ${productDataSourceField.DisplayName ?? productDataSourceField.Name ?? ""} (${productDataSourceField.ProductDataSourceRootId}-${productDataSourceField.RootId})`);
        }
      });
    } else {
      console.log(">>>>>> Can Be Filter");
      dataSourceFields.forEach((organizationDataSourceField) => {
        if ((organizationDataSourceField?.IsViewable ?? false) || (organizationDataSourceField?.ShowColumn ?? false)) {
          console.log(`${columnNumber++}. ${organizationDataSourceField.DisplayName ?? organizationDataSourceField.Name ?? ""} (${organizationDataSourceField.ProductDataSourceRootId}-${organizationDataSourceField.ProductDataSourceFieldRootId}-${organizationDataSourceField.OrganizationDataSourceRootId}-${organizationDataSourceField.RootId})`);
        }
      });
    }
  }
}
export function displaySearchFilters(searchFilters, dataSources, isProductLevel) {
  console.log(`*** Search Filter Count: ${searchFilters?.length ?? 0}`);
  if ((searchFilters?.length > 0) && (dataSources?.length > 0)) {
    if (isProductLevel) {
      searchFilters.forEach((searchFilter) => {
        const productDataSource = dataSources?.find((productDataSource) => Number(productDataSource?.RootId ?? 0) === Number(searchFilter.ProductDataSourceRootId ?? 0));
        const productDataSourceField = productDataSource?.ProductDataSourceFields?.find((productDataSourceField) => (Number(productDataSourceField?.ProductDataSourceRootId ?? 0) === Number(searchFilter.ProductDataSourceRootId ?? 0)) && (Number(productDataSourceField?.RootId ?? 0) === Number(searchFilter.ProductDataSourceFieldRootId ?? 0)));
        const fieldName = (productDataSource?.Name?.length > 0) && (productDataSourceField?.Name?.length > 0) ? `${productDataSource.Name} - ${productDataSourceField.Name}` : `${searchFilter.ProductDataSourceRootId}-${searchFilter.ProductDataSourceFieldRootId}`;
        const compareValue = (searchFilter.CompareValue2?.length > 0 ? `${searchFilter.CompareValue}, ${searchFilter.CompareValue2}` : searchFilter.CompareValue) ?? "";
        console.log(`${fieldName} ${searchFilter.SearchOperator ?? ""} ${compareValue}`);
      });
    } else {
      searchFilters.forEach((searchFilter) => {
        const organizationDataSource = dataSources?.find((organizationDataSource) => Number(organizationDataSource?.RootId ?? 0) === Number(searchFilter.OrganizationDataSourceRootId ?? 0));
        const organizationDataSourceField = organizationDataSource?.OrganizationDataSourceFields?.find((organizationDataSourceField) => (Number(organizationDataSourceField?.OrganizationDataSourceRootId ?? 0) === Number(searchFilter.OrganizationDataSourceRootId ?? 0)) && (Number(organizationDataSourceField?.RootId ?? 0) === Number(searchFilter.OrganizationDataSourceFieldRootId ?? 0)));
        const fieldName = (organizationDataSource?.Name?.length > 0) && (organizationDataSourceField?.Name?.length > 0) ? `${organizationDataSource.Name} - ${organizationDataSourceField.Name}` : `${searchFilter.OrganizationDataSourceRootId}-${searchFilter.OrganizationDataSourceFieldRootId}`;
        const compareValue = (searchFilter.CompareValue2?.length > 0 ? `${searchFilter.CompareValue}, ${searchFilter.CompareValue2}` : searchFilter.CompareValue) ?? "";
        console.log(`${fieldName} ${searchFilter.SearchOperator ?? ""} ${compareValue}`);
      });
    }
  }
}

export function updateSearchResultsColumnGroupStatus({ CurrentSettings, RootId, GroupColumn, OrganizationDataSourceFields }) {
  if (!OrganizationDataSourceFields || OrganizationDataSourceFields.length < 1) {
    console.error("updateSearchResultsColumnGroupStatus: OrganizationDataSourceFields is required.");
    return CurrentSettings;
  }
  if (!RootId) {
    console.error("updateSearchResultsColumnGroupStatus: ColumnName is required.");
    return CurrentSettings;
  }
  let newSettings = _.cloneDeep(CurrentSettings ?? []);
  const organizationDataSourceFieldRootId =
    (OrganizationDataSourceFields?.find((organizationDataSourceField) => organizationDataSourceField?.RootId === RootId) ?? null)?.RootId ?? 0;
  if (organizationDataSourceFieldRootId > 0) {
    let fieldSetting = newSettings?.find((fieldSetting) => Number(fieldSetting?.OrganizationDataSourceFieldRootId ?? 0) === organizationDataSourceFieldRootId) ?? null;
    if (fieldSetting && fieldSetting.GroupColumn !== GroupColumn) {
      fieldSetting.ShowColumn = fieldSetting.ShowColumn || GroupColumn;
      fieldSetting.GroupColumn = GroupColumn;
    }
  }
  return newSettings;
}
export function updateSearchResultsColumnOrder({ CurrentSettings, GridColumns, FromIndex, ToIndex }) {
  if (FromIndex < 0 || ToIndex < 0) {
    console.error("updateSearchResultsColumnOrder: FromIndex and ToIndex are required.");
    return CurrentSettings;
  }
  if (FromIndex === ToIndex) {
    console.error("updateSearchResultsColumnOrder: FromIndex and ToIndex must be different.");
    return CurrentSettings;
  }
  let newSettings = _.cloneDeep(CurrentSettings ?? []);
  if (GridColumns?.length > 0) {
    let prunedGridColumns = [];
    GridColumns.forEach((gridColumn) => {
      if (gridColumn?.visible ?? false) {
        prunedGridColumns.push(gridColumn);
      }
    });
    GridColumns.forEach((gridColumn) => {
      if ((gridColumn?.showInColumnChooser ?? false) && !(gridColumn?.visible ?? false)) {
        prunedGridColumns.push(gridColumn);
      }
    });
    let position = 1;
    let reorderedFieldSettings = [];
    prunedGridColumns.forEach((gridColumn) => {
      let fieldSetting = null;
      let fieldText = gridColumn?.field ?? "";
      if (fieldText.startsWith("odf")) {
        const organizationDataSourceFieldRootId = getDataFieldKeyRootId(fieldText);
        if (organizationDataSourceFieldRootId > 0) {
          fieldSetting = newSettings.find((fieldSetting) => Number(fieldSetting?.OrganizationDataSourceFieldRootId ?? 0) === organizationDataSourceFieldRootId);
        }
      } else {
        fieldSetting = newSettings.find((fieldSetting) => (String(gridColumn?.headerText ?? "") === String(fieldSetting?.DisplayName ?? "")) || (String(gridColumn?.extendedHeaderText ?? "") === String(fieldSetting?.FullDisplayName ?? "")));
      }
      if (fieldSetting) {
        let fieldSettingCopy = _.cloneDeep(fieldSetting);
        fieldSettingCopy.Position = position++;
        reorderedFieldSettings.push(fieldSettingCopy);
      }
    });
    newSettings = reorderedFieldSettings;
  }
  return recalculateColumnPositions(newSettings?.sort((fs1, fs2) => (fs1.Position > fs2.Position ? 1 : -1)) ?? []);
}
export function updateSearchResultsColumnShowStatus({ CurrentSettings, GridColumns }) {
  const newSettings = _.cloneDeep(CurrentSettings) ?? [];
  newSettings.forEach((fieldSetting) => {
    if (fieldSetting?.OrganizationDataSourceRootId > 0) {
      let fieldText = getDataFieldKeyFromRootId(fieldSetting.OrganizationDataSourceFieldRootId);
      let gridColumn = GridColumns?.find((gridColumn) => (String(gridColumn?.field ?? "") === fieldText) || (String(gridColumn?.foreignKeyField ?? "") === fieldText));
      if (!gridColumn) {
        gridColumn = GridColumns?.find((gridColumn) => (String(gridColumn?.headerText ?? "") === String(fieldSetting?.DisplayName ?? "")) || (String(gridColumn?.extendedHeaderText ?? "") === String(fieldSetting?.FullDisplayName ?? "")));
      }
      if (gridColumn) {
        fieldSetting.IsViewable = gridColumn?.showInColumnChooser ?? false;
        fieldSetting.ShowColumn = gridColumn?.visible ?? false;
      }
    }
  });
  return recalculateColumnPositions(newSettings);
}
export function recalculateColumnPositions(fieldSettings) {
  let result = [];
  let position = 1;
  fieldSettings?.forEach((fieldSetting) => {
    if (fieldSetting?.ShowColumn ?? false) {
      let fieldSettingCopy = _.cloneDeep(fieldSetting);
      fieldSettingCopy.Position = position++;
      result.push(fieldSettingCopy);
    }
  });
  fieldSettings?.forEach((fieldSetting) => {
    if ((fieldSetting?.IsViewable ?? false) && !(fieldSetting?.ShowColumn ?? false)) {
      let fieldSettingCopy = _.cloneDeep(fieldSetting);
      fieldSettingCopy.Position = position++;
      result.push(fieldSettingCopy);
    }
  });
  result = result.sort((fs1, fs2) => (fs1.Position > fs2.Position ? 1 : -1)) ?? [];
  // result.forEach((fieldSetting) => {
  //   console.log(`${fieldSetting.Position}. ${fieldSetting.FullDisplayName?.length > 0 ? fieldSetting.FullDisplayName : fieldSetting.DisplayName}`);
  // });
  return result;
}
export function getGridGroupColumns(dataSourceFields, fieldSettings, useProductDataSource) {
  return fieldSettings
    .reduce((result, fieldSetting) => {
      const field =
        useProductDataSource ?? false
          ? dataSourceFields.find(
              (dataSourceField) => Number(dataSourceField.ProductDataSourceFieldRootId ?? 0) === Number(fieldSetting.ProductDataSourceFieldRootId ?? 0) && fieldSetting.GroupColumn
            )
          : dataSourceFields.find(
              (dataSourceField) => Number(dataSourceField.RootId ?? 0) === Number(fieldSetting.OrganizationDataSourceFieldRootId ?? 0) && fieldSetting.GroupColumn
            );
      if (field) {
        result.push({
          Position: Number(fieldSetting.GroupColumnPosition ?? 0),
          Name: String(field.Name ?? ""),
        });
      }
      return result;
    }, [])
    .sort((gfs1, gfs2) => (gfs1.Position > gfs2.Position ? 1 : -1))
    .map((value) => value.Name);
}

export function getSortByColumn(sortByColumnName, fieldSettings) {
  return fieldSettings?.find((column) => String(column?.DisplayName ?? "") === String(sortByColumnName ?? ""))?.DisplayName ?? "";
}

const recordKeyPrefix = "odf";
export function getGridData(Data) {
  Data = Data ?? {};
  let records = Data?.data ?? [];
  let dataSourceFields = Data?.organizationDataSourceFields ?? [];
  let fieldSettings = Data.fieldSettings?.sort((fs1, fs2) => (fs1.Position > fs2.Position ? 1 : -1) || [])
  if (dataSourceFields?.length > 0 && fieldSettings?.length > 0) {
    fieldSettings.forEach((fieldSetting) => {
      let dataSourceField =
        dataSourceFields.find((dataSourceField) => Number(dataSourceField?.RootId ?? 0) === Number(fieldSetting?.OrganizationDataSourceFieldRootId ?? 0)) ?? null;
      if (dataSourceField) {
        const alternateKey = recordKeyPrefix + dataSourceField.RootId;
        switch (String(dataSourceField.DataType).toLowerCase()) {
          case "date":
          case "datetime":
            if (records?.length > 0) {
              records.forEach((attachmentRecord) => {
                if (attachmentRecord[dataSourceField.Name]) {
                  attachmentRecord[dataSourceField.Name] = new Date(attachmentRecord[dataSourceField.Name]);
                  if (attachmentRecord[dataSourceField.Name].getFullYear() <= 1900) {
                    attachmentRecord[dataSourceField.Name] = null;
                  }
                }
                if (attachmentRecord[alternateKey]) {
                  attachmentRecord[alternateKey] = new Date(attachmentRecord[alternateKey]);
                  if (attachmentRecord[alternateKey].getFullYear() <= 1900) {
                    attachmentRecord[alternateKey] = null;
                  }
                }
              });
            }
            break;
          case "decimal":
          case "numeric":
          case "currency":
          case "short":
          case "int":
          case "long":
            if (records?.length > 0) {
              records.forEach((attachmentRecord) => {
                if (attachmentRecord[dataSourceField.Name]) {
                  attachmentRecord[dataSourceField.Name] = Number(attachmentRecord[dataSourceField.Name]);
                }
                if (attachmentRecord[alternateKey]) {
                  attachmentRecord[alternateKey] = Number(attachmentRecord[alternateKey]);
                }
              });
            }
            break;
          case "bool":
          case "boolean":
            if (records?.length > 0) {
              records.forEach((attachmentRecord) => {
                if (attachmentRecord[dataSourceField.Name]) {
                  attachmentRecord[dataSourceField.Name] = Boolean(attachmentRecord[dataSourceField.Name]);
                }
                if (attachmentRecord[alternateKey]) {
                  attachmentRecord[alternateKey] = Boolean(attachmentRecord[alternateKey]);
                }
              });
            }
            break;
        }
      }
    });
  }
  Data.data = records;
  Data.organizationDataSourceFields = dataSourceFields;
  Data.fieldSettings = fieldSettings;
  return Data;
}
export function getSimpleSearchResultsSortOptions(sortColumn, sortDirection) {
  let sortColumns = [];
  if (sortColumn) {
    sortColumns.push({
      field: sortColumn ?? "",
      direction: (sortDirection ?? "").toLowerCase().startsWith("asc") ? "Ascending" : "Descending",
    });
  }
  return { columns: sortColumns };
}

export default {
  state: {
    rawSearchResultsData: {},
    searchResultsRecords: {},
    searchResultsRelatedData: {},
    searchResultsSortByColumn: {},
    searchResultsSortDirection: {},
    searchResultsPageSize: {},
    searchResultsOrganizationDataSourceFields: {},
    searchResultsFieldSettings: {},
    searchResultsFieldNameRegistry: {},
    searchResultsNameFieldRegistry: {},
    searchResultsDataGridColumns: {},
    searchResultsDataGridGroupColumns: {},
  },
  getters: {
    getOrderByRootId: (state) => (rootId) => {
      return state.searchResultsRecords.hasOwnProperty(OrdersRecordType) ?? false
        ? state.searchResultsRecords[OrdersRecordType]?.find((searchResultsRecord) => Number(searchResultsRecord?.RootId ?? 0) === Number(rootId ?? 0)) ?? null
        : null;
    },
    getDocumentPlaceOrderByRootId: (state) => (rootId) => {
      return state.searchResultsRecords.hasOwnProperty(VendorOrdersRecordType) ?? false
        ? state.searchResultsRecords[VendorOrdersRecordType]?.find((searchResultsRecord) => Number(searchResultsRecord?.RootId ?? 0) === Number(rootId ?? 0)) ?? null
        : null;
    },
    getAttachmentByRootId: (state) => (rootId) => {
      return state.searchResultsRecords.hasOwnProperty(DocumentsRecordType) ?? false
        ? state.searchResultsRecords[DocumentsRecordType]?.find((searchResultsRecord) => Number(searchResultsRecord?.RootId ?? 0) === Number(rootId ?? 0)) ?? null
        : null;
    },
    getAttachmentByLinksQueuedAttachmentRootId: (state) => (linksQueuedAttachmentRootId) => {
      return state.searchResultsRecords.hasOwnProperty(DocumentsRecordType) ?? false
        ? state.searchResultsRecords[DocumentsRecordType]?.find(
            (searchResultsRecord) => Number(searchResultsRecord?.LinksQueuedAttachmentRootId ?? 0) === Number(linksQueuedAttachmentRootId ?? 0)
          ) ?? null
        : null;
    },
    getCopyOfAttachments: (state) => {
      return (state.searchResultsRecords.hasOwnProperty(DocumentsRecordType) ?? false) && state.searchResultsRecords[DocumentsRecordType]?.length > 0
        ? _.cloneDeep(state.searchResultsRecords[DocumentsRecordType] ?? [])
        : [];
    },
    getCopyOfVisibleAttachments: (state) => {
      let attachments = [];
      if ((state.searchResultsRecords.hasOwnProperty(DocumentsRecordType) ?? false) && state.searchResultsRecords[DocumentsRecordType]?.length > 0) {
        state.searchResultsRecords[DocumentsRecordType].forEach((attachment) => {
          if (attachment?.Visible ?? false) {
            attachments.push(_.cloneDeep(attachment));
          }
        });
      }
      return attachments;
    },
    getSearchResultsSortOptions: (state) => (viewType) => {
      viewType = String(viewType ?? "");
      return getSimpleSearchResultsSortOptions(state.searchResultsSortByColumn[viewType], state.searchResultsSortDirection[viewType]);
    },
  },
  mutations: {
    setSearchResultsBasicData(state, { ViewType, Data, SearchResultsRecords }) {
      ViewType = String(ViewType ?? "");
      Data = Data ?? "";
      if (ViewType.length > 0) {
        state.searchResultsRelatedData[ViewType] = Data;
        if (ViewType === DocumentsRecordType) {
          state.searchResultsRecords[ViewType] = SearchResultsRecords;
        }
      }
    },
    setSearchResultsData(state, { ViewType, Data }) {
      ViewType = String(ViewType ?? "");
      Data = Data ?? {};
      if (ViewType.length > 0) {
        state.rawSearchResultsData[ViewType] = [];
        state.searchResultsOrganizationDataSourceFields[ViewType] = Data.organizationDataSourceFields ?? [];
        state.searchResultsFieldSettings[ViewType] = Data.fieldSettings?.sort((fs1, fs2) => (fs1.Position > fs2.Position ? 1 : -1)) ?? [];
        state.searchResultsFieldNameRegistry[ViewType] = Data.fieldNameRegistry ?? [];
        state.searchResultsNameFieldRegistry[ViewType] = Data.nameFieldRegistry ?? [];
        state.searchResultsRelatedData[ViewType] = Data.relatedData ?? "";
        state.searchResultsSortByColumn[ViewType] = Data.sortByColumn ?? "";
        state.searchResultsSortDirection[ViewType] = Data.sortDirection ?? "";
        state.searchResultsPageSize[ViewType] = Data.pageSize ?? 10;
        state.searchResultsDataGridColumns[ViewType] = Data.gridColumns ?? [];
        state.searchResultsDataGridGroupColumns[ViewType] = Data.groupColumns ?? [];
        Data.organizationDataSourceFields = state.searchResultsOrganizationDataSourceFields[ViewType];
        Data.fieldSettings = state.searchResultsFieldSettings[ViewType];
        state.searchResultsRecords[ViewType] = getGridData(Data)?.data ?? [];
        //console.log(`Log: ${Data.log ?? ""}`);
      }
    },
    clearSearchResultsData(state, ViewType) {
      ViewType = String(ViewType ?? "");
      if (ViewType.length > 0) {
        state.rawSearchResultsData[ViewType] = [];
        state.searchResultsOrganizationDataSourceFields[ViewType] = [];
        state.searchResultsFieldSettings[ViewType] = [];
        state.searchResultsFieldNameRegistry[ViewType] = [];
        state.searchResultsNameFieldRegistry[ViewType] = [];
        state.searchResultsRelatedData[ViewType] = "";
        state.searchResultsSortByColumn[ViewType] = "";
        state.searchResultsSortDirection[ViewType] = "";
        state.searchResultsPageSize[ViewType] = 10;
        state.searchResultsDataGridColumns[ViewType] = [];
        state.searchResultsDataGridGroupColumns[ViewType] = [];
        state.searchResultsRecords[ViewType] = [];
      } else {
        state.rawSearchResultsData = {};
        state.searchResultsOrganizationDataSourceFields = {};
        state.searchResultsFieldSettings = {};
        state.searchResultsFieldNameRegistry = {};
        state.searchResultsNameFieldRegistry = {};
        state.searchResultsRelatedData = {};
        state.searchResultsSortByColumn = {};
        state.searchResultsSortDirection = {};
        state.searchResultsPageSize = {};
        state.searchResultsDataGridColumns = {};
        state.searchResultsDataGridGroupColumns = {};
        state.searchResultsRecords = {};
      }
    },
    setSearchResultsColumnOrder(state, { ViewType, SearchResultsFieldSettings }) {
      ViewType = String(ViewType ?? "");
      if (ViewType.length > 0) {
        state.searchResultsFieldSettings[ViewType] = SearchResultsFieldSettings;
      }
    },
    updateSearchResultsColumnOrder(state, { ViewType, FromIndex, ToIndex }) {
      ViewType = String(ViewType ?? "");
      FromIndex = Number(FromIndex ?? -1);
      ToIndex = Number(ToIndex ?? -1);
      if (
        ViewType.length > 0 &&
        (state.searchResultsFieldSettings.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsOrganizationDataSourceFields.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsDataGridColumns.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsDataGridGroupColumns.hasOwnProperty(ViewType) ?? false) &&
        FromIndex > -1 &&
        ToIndex > -1 &&
        FromIndex !== ToIndex
      ) {
        let searchResultsFieldSettings = _.cloneDeep(state.searchResultsFieldSettings);
        if (FromIndex < ToIndex) {
          for (let index = 0; index < searchResultsFieldSettings[ViewType].length; index++) {
            if (index === ToIndex) {
              searchResultsFieldSettings[ViewType][index].Position = FromIndex + 1;
            } else if (index >= FromIndex && index < ToIndex) {
              searchResultsFieldSettings[ViewType][index].Position = searchResultsFieldSettings[ViewType][index].Position + 1;
            }
          }
        } else {
          for (let index = 0; index < searchResultsFieldSettings[ViewType].length; index++) {
            if (index === ToIndex) {
              searchResultsFieldSettings[ViewType][index].Position = FromIndex + 1;
            } else if (index > ToIndex && index <= FromIndex) {
              searchResultsFieldSettings[ViewType][index].Position = searchResultsFieldSettings[ViewType][index].Position - 1;
            }
          }
        }
        searchResultsFieldSettings[ViewType] = searchResultsFieldSettings[ViewType]?.sort((fs1, fs2) => (fs1.Position > fs2.Position ? 1 : -1)) ?? [];
        let searchResultsOrganizationDataSourceFields = _.cloneDeep(state.searchResultsOrganizationDataSourceFields);
        const newDataGridColumns = getGridColumns(searchResultsOrganizationDataSourceFields[ViewType], searchResultsFieldSettings[ViewType], false, [], false, false, false);
        if (!_.isEqual(state.searchResultsDataGridColumns[ViewType], newDataGridColumns)) {
          let searchResultsDataGridColumns = _.cloneDeep(state.searchResultsDataGridColumns);
          searchResultsDataGridColumns[ViewType] = newDataGridColumns;
          state.searchResultsDataGridColumns = searchResultsDataGridColumns;
        }
        const newDataGridGroupColumns = getGridGroupColumns(searchResultsOrganizationDataSourceFields[ViewType], searchResultsFieldSettings[ViewType]);
        if (!_.isEqual(state.searchResultsDataGridGroupColumns[ViewType], newDataGridGroupColumns)) {
          let searchResultsDataGridGroupColumns = _.cloneDeep(state.searchResultsDataGridGroupColumns);
          searchResultsDataGridGroupColumns[ViewType] = newDataGridGroupColumns;
          state.searchResultsDataGridGroupColumns[ViewType] = searchResultsDataGridGroupColumns;
        }
        state.searchResultsFieldSettings = searchResultsFieldSettings;
      }
    },
    updateSearchResultsColumnShowStatus(state, { ViewType, Columns, UpdateGridColumns }) {
      ViewType = String(ViewType ?? "");
      Columns = Columns ?? [];
      UpdateGridColumns = UpdateGridColumns ?? true;
      if (
        ViewType.length > 0 &&
        (state.searchResultsFieldSettings.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsOrganizationDataSourceFields.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsDataGridColumns.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsDataGridGroupColumns.hasOwnProperty(ViewType) ?? false) &&
        Columns.length > 0
      ) {
        let searchResultsFieldSettings = _.cloneDeep(state.searchResultsFieldSettings);
        let searchResultsDataGridColumns = UpdateGridColumns ? _.cloneDeep(state.searchResultsDataGridColumns) : null;
        let searchResultsDataGridGroupColumns = UpdateGridColumns ? _.cloneDeep(state.searchResultsDataGridGroupColumns) : null;
        let searchResultsOrganizationDataSourceFields = UpdateGridColumns ? _.cloneDeep(state.searchResultsOrganizationDataSourceFields) : null;
        Columns.forEach((column) => {
          const ColumnName = String(column?.ColumnName ?? "");
          const ShowColumn = column?.ShowColumn ?? false;
          if (ColumnName.length > 0) {
            const organizationDataSourceFieldRootId =
              (
                state.searchResultsOrganizationDataSourceFields[ViewType]?.find((organizationDataSourceField) => String(organizationDataSourceField?.Name ?? "") === ColumnName) ??
                null
              )?.RootId ?? 0;
            if (organizationDataSourceFieldRootId > 0) {
              let fieldSetting =
                searchResultsFieldSettings[ViewType]?.find((fieldSetting) => Number(fieldSetting?.OrganizationDataSourceFieldRootId ?? 0) === organizationDataSourceFieldRootId) ??
                null;
              if (fieldSetting && (fieldSetting.ShowColumn !== ShowColumn || ColumnName === "CreatedDateTime")) {
                fieldSetting.GroupColumn = fieldSetting.GroupColumn && ShowColumn;
                fieldSetting.ShowColumn = ShowColumn;
                if (UpdateGridColumns) {
                  searchResultsDataGridColumns[ViewType] = getGridColumns(
                    searchResultsOrganizationDataSourceFields[ViewType],
                    searchResultsFieldSettings[ViewType],
                    false,
                    [],
                    false,
                    false,
                    false,
                  );
                  searchResultsDataGridGroupColumns[ViewType] = getGridGroupColumns(searchResultsOrganizationDataSourceFields[ViewType], searchResultsFieldSettings[ViewType]);
                }
              }
            }
          }
        });
        if (UpdateGridColumns) {
          state.searchResultsDataGridColumns = searchResultsDataGridColumns;
          state.searchResultsDataGridGroupColumns = searchResultsDataGridGroupColumns;
        }
        state.searchResultsFieldSettings = searchResultsFieldSettings;
      }
    },
    updateSearchResultsColumnGroupStatus(state, { ViewType, ColumnName, GroupColumn, UpdateGridColumns }) {
      ViewType = String(ViewType ?? "");
      ColumnName = String(ColumnName ?? "");
      GroupColumn = GroupColumn ?? false;
      UpdateGridColumns = UpdateGridColumns ?? true;
      if (
        ViewType.length > 0 &&
        (state.searchResultsFieldSettings.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsOrganizationDataSourceFields.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsDataGridColumns.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsDataGridGroupColumns.hasOwnProperty(ViewType) ?? false) &&
        ColumnName.length > 0
      ) {
        let searchResultsFieldSettings = _.cloneDeep(state.searchResultsFieldSettings);
        let searchResultsDataGridColumns = UpdateGridColumns ? _.cloneDeep(state.searchResultsDataGridColumns) : null;
        let searchResultsDataGridGroupColumns = UpdateGridColumns ? _.cloneDeep(state.searchResultsDataGridGroupColumns) : null;
        let searchResultsOrganizationDataSourceFields = UpdateGridColumns ? _.cloneDeep(state.searchResultsOrganizationDataSourceFields) : null;
        const organizationDataSourceFieldRootId =
          (
            state.searchResultsOrganizationDataSourceFields[ViewType]?.find(
              (organizationDataSourceField) => String(organizationDataSourceField?.Name ?? "") === String(ColumnName ?? "")
            ) ?? null
          )?.RootId ?? 0;
        if (organizationDataSourceFieldRootId > 0) {
          let fieldSetting =
            searchResultsFieldSettings[ViewType]?.find((fieldSetting) => Number(fieldSetting?.OrganizationDataSourceFieldRootId ?? 0) === organizationDataSourceFieldRootId) ??
            null;
          if (fieldSetting && fieldSetting.GroupColumn !== GroupColumn) {
            fieldSetting.ShowColumn = fieldSetting.ShowColumn || GroupColumn;
            fieldSetting.GroupColumn = GroupColumn;
            if (UpdateGridColumns) {
              searchResultsDataGridColumns[ViewType] = getGridColumns(searchResultsOrganizationDataSourceFields[ViewType], searchResultsFieldSettings[ViewType], false, [], false, false, false);
              searchResultsDataGridGroupColumns[ViewType] = getGridGroupColumns(searchResultsOrganizationDataSourceFields[ViewType], searchResultsFieldSettings[ViewType]);
            }
          }
        }
        if (UpdateGridColumns) {
          state.searchResultsDataGridColumns = searchResultsDataGridColumns;
          state.searchResultsDataGridGroupColumns = searchResultsDataGridGroupColumns;
        }
        state.searchResultsFieldSettings = searchResultsFieldSettings;
      }
    },
    updateSearchResultsColumnWidth(state, { ViewType, ColumnName, ColumnWidth, UpdateGridColumns }) {
      ViewType = String(ViewType ?? "");
      ColumnName = String(ColumnName ?? "");
      ColumnWidth = Number(ColumnWidth ?? 0);
      UpdateGridColumns = UpdateGridColumns ?? true;
      if (
        ViewType.length > 0 &&
        (state.searchResultsFieldSettings.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsOrganizationDataSourceFields.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsDataGridColumns.hasOwnProperty(ViewType) ?? false) &&
        (state.searchResultsDataGridGroupColumns.hasOwnProperty(ViewType) ?? false) &&
        ColumnName.length > 0
      ) {
        let searchResultsFieldSettings = _.cloneDeep(state.searchResultsFieldSettings);
        let searchResultsDataGridColumns = UpdateGridColumns ? _.cloneDeep(state.searchResultsDataGridColumns) : null;
        let searchResultsDataGridGroupColumns = UpdateGridColumns ? _.cloneDeep(state.searchResultsDataGridGroupColumns) : null;
        let searchResultsOrganizationDataSourceFields = UpdateGridColumns ? _.cloneDeep(state.searchResultsOrganizationDataSourceFields) : null;
        const organizationDataSourceFieldRootId =
          (
            state.searchResultsOrganizationDataSourceFields[ViewType]?.find(
              (organizationDataSourceField) => String(organizationDataSourceField?.Name ?? "") === String(ColumnName ?? "")
            ) ?? null
          )?.RootId ?? 0;
        if (organizationDataSourceFieldRootId > 0) {
          let fieldSetting =
            searchResultsFieldSettings[ViewType]?.find((fieldSetting) => Number(fieldSetting?.OrganizationDataSourceFieldRootId ?? 0) === organizationDataSourceFieldRootId) ??
            null;
          if (fieldSetting && Number(fieldSetting.ColumnWidth ?? 0) !== ColumnWidth) {
            fieldSetting.ColumnWidth = ColumnWidth;
            if (UpdateGridColumns) {
              searchResultsDataGridColumns[ViewType] = getGridColumns(searchResultsOrganizationDataSourceFields[ViewType], searchResultsFieldSettings[ViewType], false, [], false, false, false);
              searchResultsDataGridGroupColumns[ViewType] = getGridGroupColumns(searchResultsOrganizationDataSourceFields[ViewType], searchResultsFieldSettings[ViewType]);
            }
          }
        }
        if (UpdateGridColumns) {
          state.searchResultsDataGridColumns = searchResultsDataGridColumns;
          state.searchResultsDataGridGroupColumns = searchResultsDataGridGroupColumns;
        }
        state.searchResultsFieldSettings = searchResultsFieldSettings;
      }
    },
    _updateOrder(state, updateOrder) {
      const rootId = Number(updateOrder?.RootId ?? 0);
      if (rootId > 0 && (state.searchResultsRecords.hasOwnProperty(OrdersRecordType) ?? false)) {
        let ordersData = _.cloneDeep(state.searchResultsRecords[OrdersRecordType]) ?? [];
        state.searchResultsRecords[OrdersRecordType] = [...ordersData.filter((order) => Number(order?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrder)];
      }
    },
    _removeOrder(state, deleteOrder) {
      const rootId = Number(deleteOrder?.RootId ?? 0);
      if (rootId > 0 && (state.searchResultsRecords.hasOwnProperty(OrdersRecordType) ?? false)) {
        let ordersData = _.cloneDeep(state.searchResultsRecords[OrdersRecordType]) ?? [];
        state.searchResultsRecords[OrdersRecordType] = [...ordersData.filter((order) => Number(order?.RootId ?? 0) !== rootId)];
      }
    },
    _addAttachment(state, attachment) {
      if (state.searchResultsRecords.hasOwnProperty(DocumentsRecordType) ?? false) {
        let attachmentsData = _.cloneDeep(state.searchResultsRecords[DocumentsRecordType]) ?? [];
        attachmentsData.push(attachment);
        state.searchResultsRecords[DocumentsRecordType] = attachmentsData.sort((attachment1, attachment2) => (attachment1.Position > attachment2.Position ? 1 : -1)) ?? [];
      }
    },
    _updateAttachment(state, updateAttachment) {
      const rootId = Number(updateAttachment?.RootId ?? 0);
      if (rootId > 0 && (state.searchResultsRecords.hasOwnProperty(DocumentsRecordType) ?? false)) {
        let updateAttachmentCopy = _.cloneDeep(updateAttachment);
        let attachmentsData = [];
        state.searchResultsRecords[DocumentsRecordType].forEach((attachment) => {
          if (Number(attachment?.RootId ?? 0) === rootId) {
            updateAttachmentCopy.Tags = attachment.Tags;
            updateAttachmentCopy.Visible = attachment.Visible;
            updateAttachmentCopy.Position = attachment.Position;
            attachmentsData.push(_.cloneDeep(updateAttachmentCopy));
          } else {
            attachmentsData.push(_.cloneDeep(attachment));
          }
        });
        state.searchResultsRecords[DocumentsRecordType] = attachmentsData.sort((attachment1, attachment2) => (attachment1.Position > attachment2.Position ? 1 : -1)) ?? [];
      }
    },
    _removeAttachment(state, deleteAttachment) {
      const rootId = Number(deleteAttachment?.RootId ?? 0);
      if (rootId > 0 && (state.searchResultsRecords.hasOwnProperty(DocumentsRecordType) ?? false)) {
        let attachmentsData = _.cloneDeep(state.searchResultsRecords[DocumentsRecordType]) ?? [];
        state.searchResultsRecords[DocumentsRecordType] =
          [...attachmentsData.filter((attachment) => Number(attachment?.RootId ?? 0) !== rootId)].sort((attachment1, attachment2) =>
            attachment1.Position > attachment2.Position ? 1 : -1
          ) ?? [];
      }
    },
    _removeAttachmentByLinksQueuedAttachmentRootId(state, deleteAttachment) {
      const linksQueuedAttachmentRootId = Number(deleteAttachment?.LinksQueuedAttachmentRootId ?? 0);
      if (linksQueuedAttachmentRootId > 0 && (state.searchResultsRecords.hasOwnProperty(DocumentsRecordType) ?? false)) {
        let attachmentsData = _.cloneDeep(state.searchResultsRecords[DocumentsRecordType]) ?? [];
        state.searchResultsRecords[DocumentsRecordType] =
          [...attachmentsData.filter((attachment) => Number(attachment?.LinksQueuedAttachmentRootId ?? 0) !== linksQueuedAttachmentRootId)].sort((attachment1, attachment2) =>
            attachment1.Position > attachment2.Position ? 1 : -1
          ) ?? [];
      }
    },
    _removeAttachmentByLinksQueuedAttachment(state, deleteLinksQueuedAttachment) {
      const linksQueuedAttachmentRootId = Number(deleteLinksQueuedAttachment?.RootId ?? 0);
      if (linksQueuedAttachmentRootId > 0 && (state.searchResultsRecords.hasOwnProperty(DocumentsRecordType) ?? false)) {
        let attachmentsData = _.cloneDeep(state.searchResultsRecords[DocumentsRecordType]) ?? [];
        state.searchResultsRecords[DocumentsRecordType] =
          [...attachmentsData.filter((attachment) => Number(attachment?.LinksQueuedAttachmentRootId ?? 0) !== linksQueuedAttachmentRootId)].sort((attachment1, attachment2) =>
            attachment1.Position > attachment2.Position ? 1 : -1
          ) ?? [];
      }
    },
    _updateAttachments(state, attachmentsData) {
      state.searchResultsRecords[DocumentsRecordType] = attachmentsData?.sort((attachment1, attachment2) => (attachment1.Position > attachment2.Position ? 1 : -1)) ?? [];
    },
    _addNotification(state, notification) {
      if (state.searchResultsRecords.hasOwnProperty(MessagesRecordType) ?? false) {
        let notificationsData = _.cloneDeep(state.searchResultsRecords[MessagesRecordType]) ?? [];
        notificationsData.push(notification);
        state.searchResultsRecords[MessagesRecordType] = notificationsData.sort((no1, no2) => (no1.CreatedDateTime > no2.CreatedDateTime ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {},
};
