import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productFormLocationsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productFormLocations: [],
    lastProductRootId: 0,
  },
  getters: {},
  mutations: {
    setProductFormLocationData(state, productFormLocations) {
      state.productFormLocations = productFormLocations ?? [];
    },
    clearProductFormLocations(state) {
      state.productFormLocations = [];
      state.lastProductRootId = 0;
    },
    setLastProductRootId(state, rootId) {
      state.lastProductRootId = rootId;
    },
    _addProductFormLocation(state, productFormLocation) {
      const productFormLocationsData = _.cloneDeep(state.productFormLocations) ?? [];
      productFormLocationsData.push(productFormLocation);
      state.productFormLocations = productFormLocationsData;
    },
    _updateProductFormLocation(state, updateProductFormLocation) {
      const rootId = Number(updateProductFormLocation?.RootId ?? 0);
      if (rootId > 0) {
        let productFormLocationsData = _.cloneDeep(state.productFormLocations) ?? [];
        productFormLocationsData =
          [...productFormLocationsData.filter((productFormLocation) => Number(productFormLocation?.RootId ?? 0) !== rootId), _.cloneDeep(updateProductFormLocation)] ?? [];
        state.productFormLocations = productFormLocationsData;
      }
    },
    _removeProductFormLocation(state, deleteProductFormLocation) {
      const rootId = Number(deleteProductFormLocation?.RootId ?? 0);
      if (rootId > 0) {
        let productFormLocationsData = _.cloneDeep(state.productFormLocations) ?? [];
        productFormLocationsData = [...productFormLocationsData.filter((productFormLocation) => Number(productFormLocation?.RootId ?? 0) !== rootId)] ?? [];
        state.productFormLocations = productFormLocationsData;
      }
    },
  },
  actions: {
    async getProductFormLocations(context, { ProductRootId }) {
      if (context.state.lastProductRootId === ProductRootId) {
        return;
      } // Already loaded
      await timeOperation(async () => {
        context.commit("setLastProductRootId", ProductRootId);
        const formattedUrl = `${productFormLocationsUrl}?productrootid=${ProductRootId}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductFormLocationData", data);
      }, "productFormLocations - getProductFormLocations");
    },
    async addProductFormLocation(context, productFormLocation) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productFormLocationsUrl, Payload: productFormLocation });
        if (data?.RootId > 0) {
          productFormLocation.RootId = data.RootId;
          context.commit("_addProductFormLocation", data);
        }
        return data;
      }, "productFormLocations - addProductFormLocation");
    },
    async updateProductFormLocation(context, productFormLocation) {
      await timeOperation(async () => {
        const formattedUrl = `${productFormLocationsUrl}/${Number(productFormLocation?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productFormLocation });
        context.commit("_updateProductFormLocation", data);
      }, "productFormLocations - updateProductFormLocation");
    },
    async removeProductFormLocation(context, productFormLocation) {
      await timeOperation(async () => {
        const formattedUrl = `${productFormLocationsUrl}/${Number(productFormLocation?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductFormLocation", productFormLocation);
      }, "productFormLocations - removeProductFormLocation");
    },
  },
};
