<template>
  <LogoModal
    :show="localShow"
    @update:show="clearModal"
    :buttons="feedbackDialogButtons"
    @buttonClicked="onSubmitFeedbackClicked"
    @drop="onDrop"
    @paste="onPaste"
    name="feedbackModal"
    :message="getFeedbackDialogMessage"
  >
    <div class="col">
      <p class="feedback-modal-text">Type of Feedback:</p>
      <select id="feedbackType" v-model="feedbackType" @change="onFeedbackTypeSelected" class="feedback-dropdown">
        <option value="bug">Bug</option>
        <option value="enhancement-request">Enhancement Request</option>
        <option value="slow-performance">Slow Performance</option>
        <option value="positive-experience">Positive Experience</option>
        <option value="other">Other</option>
      </select>
      <p class="feedback-modal-text">Please tell us about your experience:</p>
      <input v-model="feedBackSubject" placeholder="Subject" type="text" class="subject-line-feedback" />
      <RichTextEditorWrapper v-model="feedbackMessage" />
    </div>
  </LogoModal>
</template>

<script>
import { ref, watch, onMounted, nextTick, computed } from "vue";
import { TrulyEsqProductRootId } from "@/lib/settings";
import { useStore } from "vuex";
import _ from "lodash";

import LogoModal from "@/components/uIElements/LogoModal.vue";
import RichTextEditorWrapper from "@/components/syncfusionWrappers/RichTextEditorWrapper";

export default {
  components: {
    LogoModal,
    RichTextEditorWrapper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:show"],
  setup(props, { emit }) {
    // Vuex
    const store = useStore();
    // Actions
    const addFeedbackEntry = async (feedbackEntry) => await store.dispatch("addFeedbackEntry", feedbackEntry);
    // Mutations
    const setShowFeedbackModal = () => store.commit("setShowFeedbackModal", false);

    // State
    const loggedInUser = computed(() => store.state.auth.loggedInUser);

    const feedbackType = ref("bug");
    const isSubmitted = ref(false);
    const localShow = ref(false);
    const feedbackMessage = ref("");
    const feedBackSubject = ref("");
    const feedbackEntry = ref({
      Id: 0,
      FromUserRootId: 0,
      FromOrganizationRootId: 0,
      FeedbackType: "",
      Subject: "",
      Message: "",
    });
    const feedbackDialogButtons = ref([
      {
        Id: 1,
        text: "Submit",
        isDisabled: true,
        cssClass: "btn btn-primary m-1",
        type: "submit",
      },
      {
        Id: 2,
        text: "Cancel",
        isDisabled: false,
        cssClass: "btn btn-secondary m-1",
        type: "cancel",
      },
    ]);

    const getFeedbackDialogMessage = computed(() => (isSubmitted.value ? "Thanks for your feedback!" : "Have something you would like to share with us? We'd love to hear it!"));

    watch(feedbackMessage, () => {
      onFeedBackChange();
    });

    watch(
      () => props.show,
      (newVal) => {
        localShow.value = newVal;
      }
    );

    function onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
    }

    function onPaste(e) {
      e.stopPropagation();
    }

    function onFeedBackChange() {
      feedbackDialogButtons.value[0].isDisabled = !(feedbackMessage.value?.length > 0);
    }

    function onFeedbackTypeSelected($event) {
      return (feedbackType.value = $event.target.value);
    }

    function clearModal() {
      feedBackSubject.value = "";
      feedbackType.value = "bug";
      feedbackMessage.value = "";
      feedbackEntry.value = {
        Id: 0,
        FromUserRootId: 0,
        FromOrganizationRootId: 0,
        FeedbackType: "",
        Subject: "",
        Message: "",
      };
      setShowFeedbackModal();
    }

    async function onSubmitFeedbackClicked(button) {
      switch ((button?.type ?? "").toLowerCase()) {
        case "yes":
        case "submit":
          await submitFeedback();
          isSubmitted.value = true;
          clearModal();
          localShow.value = false;
          emit("update:show", false);
          break;
        case "no":
        case "cancel":
          isSubmitted.value = false;
          localShow.value = false;
          emit("update:show", false);
          clearModal();
          break;
      }
    }

    async function submitFeedback() {
      feedbackEntry.value.FeedbackType = feedbackType.value ?? "bug";
      feedbackEntry.value.Message = feedbackMessage.value ?? "";
      feedbackEntry.value.Subject = feedBackSubject.value ?? "";
      feedbackEntry.value.ProductRootId = TrulyEsqProductRootId; // Use TrulyESQ Product for now.
      feedbackEntry.value.FromUserRootId = loggedInUser.value?.UserRootId ?? 1;
      feedbackEntry.value.FromOrganizationRootId = loggedInUser.value?.OrganizationRootId ?? 1;
      await addFeedbackEntry(_.cloneDeep(feedbackEntry.value));
    }

    onMounted(async () => {
      await nextTick();
      localShow.value = _.cloneDeep(props.show);
    });

    return {
      feedbackDialogButtons,
      feedbackType,
      localShow,
      feedbackEntry,
      getFeedbackDialogMessage,
      feedBackSubject,
      feedbackMessage,

      onDrop,
      onPaste,
      clearModal,
      onFeedbackTypeSelected,
      onSubmitFeedbackClicked,
    };
  },
};
</script>
