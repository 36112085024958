import { ApiRequest, GetVerb, workflowLogUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import { WorkflowLogRecordType } from "@/lib/settings";
import { getGridData } from "@/store/searchResults";

export default {
  actions: {
    async getWorkflowLogAdvancedSearchData(context, { ProductRootId, OrganizationRootId, UserRootId, SearchRootId, IncludeMetadata, IncludeDebugData, CanUseServerCache, PreviewParameters }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        UserRootId = Number(UserRootId || 0);
        SearchRootId = Number(SearchRootId || 0);
        IncludeMetadata = IncludeMetadata || true;
        IncludeDebugData = IncludeDebugData || false;
        CanUseServerCache = CanUseServerCache || true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${workflowLogUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&searchrootid=${SearchRootId}&previewparameters=${previewParametersJson}&timezone=${encodeURIComponent(
          getLocalTimezone()
        )}&includemetadata=${IncludeMetadata}&includedebugdata=${IncludeDebugData}&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        context.commit("setSearchResultsData", { ViewType: WorkflowLogRecordType, Data });
      }, "workflowLog - getWorkflowLogAdvancedSearchData");
    },
  },
};
