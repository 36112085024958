import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, notificationEventsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    notificationEvents: [],
  },
  getters: {
    getNotificationEventByRootId: (state) => (rootId) => {
      return state.notificationEvents.find((notificationEvent) => Number(notificationEvent?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getNotificationEventByDisplayName: (state) => (name) => {
      return state.notificationEvents.find((notificationEvent) => String(notificationEvent?.DisplayName ?? "") === String(name ?? "")) ?? null;
    },
    getNotificationEventRootIdByDisplayName: (state) => (name) => {
      return (state.notificationEvents.find((notificationEvent) => String(notificationEvent?.DisplayName ?? "") === String(name ?? "")) ?? null)?.RootId ?? 0;
    },
  },
  mutations: {
    setNotificationEventData(state, notificationEvents) {
      state.notificationEvents = notificationEvents?.sort((nevnt1, nevnt2) => (nevnt1.DisplayName > nevnt2.DisplayName ? 1 : -1)) ?? [];
    },
    clearNotificationEvents(state) {
      state.notificationEvents = [];
    },
    _addNotificationEvent(state, notificationEvent) {
      const notificationEventsData = _.cloneDeep(state.notificationEvents) ?? [];
      notificationEventsData.push(notificationEvent);
      state.notificationEvents = notificationEventsData.sort((nevnt1, nevnt2) => (nevnt1.DisplayName > nevnt2.DisplayName ? 1 : -1));
    },
    _updateNotificationEvent(state, updateNotificationEvent) {
      const rootId = Number(updateNotificationEvent?.RootId ?? 0);
      if (rootId > 0) {
        let notificationEventsData = _.cloneDeep(state.notificationEvents) ?? [];
        notificationEventsData = [...notificationEventsData.filter(notificationEvent => Number(notificationEvent?.RootId ?? 0) !== rootId), _.cloneDeep(updateNotificationEvent)].sort((nevnt1, nevnt2) => (nevnt1.DisplayName > nevnt2.DisplayName ? 1 : -1)) ?? [];
        state.notificationEvents = notificationEventsData;
      }
    },
    _removeNotificationEvent(state, deleteNotificationEvent) {
      const rootId = Number(deleteNotificationEvent?.RootId ?? 0);
      if (rootId > 0) {
        let notificationEventsData = _.cloneDeep(state.notificationEvents) ?? [];
        notificationEventsData = [...notificationEventsData.filter(notificationEvent => Number(notificationEvent?.RootId ?? 0) !== rootId)].sort((nevnt1, nevnt2) => (nevnt1.DisplayName > nevnt2.DisplayName ? 1 : -1)) ?? [];
        state.notificationEvents = notificationEventsData;
      }
    },
  },
  actions: {
    async getNotificationEvents(context) {
      if (!(context.state.notificationEvents?.length > 0)) {
        await timeOperation(async () => {
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: notificationEventsUrl, Payload: null });
          context.commit("setNotificationEventData", data);
        }, "notificationEvents - getNotificationEvents");
      }
    },
    async addNotificationEvent(context, notificationEvent) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: notificationEventsUrl, Payload: notificationEvent });
        if (data?.RootId > 0) {
          notificationEvent.RootId = data.RootId;
          context.commit("_addNotificationEvent", data);
        }
        return data;
      }, "notificationEvents - addNotificationEvent");
    },
    async updateNotificationEvent(context, notificationEvent) {
      await timeOperation(async () => {
        const formattedUrl = `${notificationEventsUrl}/${Number(notificationEvent?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: notificationEvent });
        context.commit("_updateNotificationEvent", data);
      }, "notificationEvents - updateNotificationEvent");
    },
    async removeNotificationEvent(context, notificationEvent) {
      await timeOperation(async () => {
        const formattedUrl = `${notificationEventsUrl}/${Number(notificationEvent?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeNotificationEvent", notificationEvent);
      }, "notificationEvents - removeNotificationEvent");
    },
  },
};
