import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, workflowRuleTemplatesUrl, organizationWorkflowRuleTemplatesUrl, userWorkflowRuleTemplatesUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  actions: {
    async getProductLevelWorkflowRuleTemplates(context, { ProductRootId, InjectReferences, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        InjectReferences = InjectReferences ?? true;
        IncludeMetadata = IncludeMetadata ?? true;
        if (ProductRootId > 0) {
          const formattedUrl = `${workflowRuleTemplatesUrl}?productrootid=${ProductRootId}&organizationrootid=0&userrootid=0&isproductlevel=true&injectreferences=${InjectReferences}&includemetadata=${IncludeMetadata}&includepossible=false`;
          return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        }
        return [];
      }, "workflowRuleTemplates - getProductLevelWorkflowRuleTemplates");
    },
    async getOrganizationLevelWorkflowRuleTemplates(context, { ProductRootId, OrganizationRootId, InjectReferences, IncludePossible, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        InjectReferences = InjectReferences ?? true;
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? true;
        if (OrganizationRootId > 0) {
          const formattedUrl = `${workflowRuleTemplatesUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=0&isorganizationlevel=true&injectreferences=${InjectReferences}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
          return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        }
        return [];
      }, "workflowRuleTemplates - getOrganizationLevelWorkflowRuleTemplates");
    },
    async getUserLevelWorkflowRuleTemplates(context, { ProductRootId, OrganizationRootId, UserRootId, WorkflowRuleTemplateRootId, IncludePossible, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        WorkflowRuleTemplateRootId = Number(WorkflowRuleTemplateRootId ?? 0);
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? false;
        if (UserRootId > 0) {
          const formattedUrl = `${userWorkflowRuleTemplatesUrl}?userrootid=${UserRootId}&organizationrootid=${OrganizationRootId}&productrootid=${ProductRootId}&workflowruletemplaterootid=${WorkflowRuleTemplateRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
          return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        }
        return [];
      }, "organizations - getOrganizationUserWorkflowRuleTemplates");
    },
    async getWorkflowRuleTemplateTemplate(
      context,
      {
        ProductRootId,
        ProductDataSourceRootId,
        OrganizationDataSourceRootId,
        AdditionalProductDataSourceRootIds,
        AdditionalOrganizationDataSourceRootIds,
        IsProductLevel,
        IsOrganizationLevel,
        IsUserLevel,
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        ProductDataSourceRootId = Number(ProductDataSourceRootId ?? 0);
        OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0);
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedAdditionalProductDataSourceRootIds = AdditionalProductDataSourceRootIds?.length > 0 ? AdditionalProductDataSourceRootIds.join("-") : "";
        const formattedAdditionalOrganizationDataSourceRootIds = AdditionalOrganizationDataSourceRootIds?.length > 0 ? AdditionalOrganizationDataSourceRootIds.join("-") : "";
        const formattedUrl = `${workflowRuleTemplatesUrl}?productrootid=${ProductRootId}&productdatasourcerootid=${ProductDataSourceRootId}&organizationdatasourcerootid=${OrganizationDataSourceRootId}&additionalproductdatasourcerootids=${formattedAdditionalProductDataSourceRootIds}&additionalorganizationdatasourcerootids=${formattedAdditionalOrganizationDataSourceRootIds}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}&template=true&injectreferences=true`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "workflowRuleTemplates - getWorkflowRuleTemplateTemplate");
    },
    async addWorkflowRuleTemplate(context, { WorkflowRuleTemplate, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${workflowRuleTemplatesUrl}?isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: WorkflowRuleTemplate });
      }, "workflowRuleTemplates - addWorkflowRuleTemplate");
    },
    async updateWorkflowRuleTemplate(context, { WorkflowRuleTemplate, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${workflowRuleTemplatesUrl}/${Number(
          WorkflowRuleTemplate?.RootId ?? 0
        )}?isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: WorkflowRuleTemplate });
      }, "workflowRuleTemplates - updateWorkflowRuleTemplate");
    },
    async cloneWorkflowRuleTemplate(context, { WorkflowRuleTemplate, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${workflowRuleTemplatesUrl}/${Number(
          WorkflowRuleTemplate?.RootId ?? 0
        )}/clone?isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: WorkflowRuleTemplate });
      }, "workflowRuleTemplates - cloneWorkflowRuleTemplate");
    },
    async renameWorkflowRuleTemplate(context, workflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowRuleTemplatesUrl}/${Number(workflowRuleTemplate?.RootId ?? 0)}/rename`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowRuleTemplate });
      }, "workflowRuleTemplates - renameWorkflowRuleTemplate");
    },
    async enableProductWorkflowRuleTemplate(context, workflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowRuleTemplatesUrl}/${Number(workflowRuleTemplate?.RootId ?? 0)}/updateproductworkflowruletemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowRuleTemplate, Enabled: true } });
      }, "workflowRuleTemplates - enableProductWorkflowRuleTemplate");
    },
    async disableProductWorkflowRuleTemplate(context, workflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowRuleTemplatesUrl}/${Number(workflowRuleTemplate?.RootId ?? 0)}/updateproductworkflowruletemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowRuleTemplate, Enabled: false } });
      }, "workflowRuleTemplates - disableProductWorkflowRuleTemplate");
    },
    async enableOrganizationWorkflowRuleTemplate(context, workflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowRuleTemplatesUrl}/${Number(workflowRuleTemplate?.RootId ?? 0)}/updateorganizationworkflowruletemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowRuleTemplate, Enabled: true } });
      }, "workflowRuleTemplates - enableOrganizationWorkflowRuleTemplate");
    },
    async disableOrganizationWorkflowRuleTemplate(context, workflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowRuleTemplatesUrl}/${Number(workflowRuleTemplate?.RootId ?? 0)}/updateorganizationworkflowruletemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowRuleTemplate, Enabled: false } });
      }, "workflowRuleTemplates - disableOrganizationWorkflowRuleTemplate");
    },
    async updateOrganizationWorkflowRuleTemplateAlias(context, workflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowRuleTemplatesUrl}/${Number(workflowRuleTemplate?.RootId ?? 0)}/updateorganizationworkflowruletemplatealias`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowRuleTemplate });
      }, "workflowRuleTemplates - updateOrganizationWorkflowRuleTemplateAlias");
    },
    async updateOrganizationWorkflowRuleTemplateAccessSettings(context, organizationWorkflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationWorkflowRuleTemplatesUrl}/${Number(organizationWorkflowRuleTemplate?.RootId ?? 0)}/updateaccesssettings`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationWorkflowRuleTemplate });
      }, "organizations - updateOrganizationWorkflowRuleTemplateAccessSettings");
    },
    async updateWorkflowRuleTemplateAccessSettings(context, workflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowRuleTemplatesUrl}/${Number(workflowRuleTemplate?.RootId ?? 0)}/updateaccesssettings`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowRuleTemplate });
      }, "organizations - updateWorkflowRuleTemplateAccessSettings");
    },
    async removeWorkflowRuleTemplate(context, workflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowRuleTemplatesUrl}/${Number(workflowRuleTemplate?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "workflowRuleTemplates - removeWorkflowRuleTemplate");
    },
    async updateUserWorkflowRuleTemplateEnabledStatus(context, userWorkflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${userWorkflowRuleTemplatesUrl}/${Number(userWorkflowRuleTemplate?.RootId ?? 0)}/updateenabledstatus`;
        const updatedUserWorkflowRuleTemplate = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userWorkflowRuleTemplate });
        context.commit("_updateUserWorkflowRuleTemplate", {
          UserRootId: updatedUserWorkflowRuleTemplate.UserRootId,
          ProductRootId: updatedUserWorkflowRuleTemplate.ProductRootId,
          WorkflowRuleTemplateRootId: updatedUserWorkflowRuleTemplate.WorkflowRuleTemplateRootId,
          Enabled: updatedUserWorkflowRuleTemplate.Enabled ?? false,
        });
        return updatedUserWorkflowRuleTemplate;
      }, "organizations - updateUserWorkflowRuleTemplateEnabledStatus");
    },
    async updateUserWorkflowRuleTemplateSelectedStatus(context, userWorkflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${userWorkflowRuleTemplatesUrl}/${Number(userWorkflowRuleTemplate?.RootId ?? 0)}/updateselectedstatus`;
        const updatedUserWorkflowRuleTemplate = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userWorkflowRuleTemplate });
        context.commit("_updateUserWorkflowRuleTemplate", {
          UserRootId: updatedUserWorkflowRuleTemplate.UserRootId,
          ProductRootId: updatedUserWorkflowRuleTemplate.ProductRootId,
          WorkflowRuleTemplateRootId: updatedUserWorkflowRuleTemplate.WorkflowRuleTemplateRootId,
          Selected: updatedUserWorkflowRuleTemplate.Selected ?? false,
        });
        return updatedUserWorkflowRuleTemplate;
      }, "organizations - updateUserWorkflowRuleTemplateSelectedStatus");
    },
    async updateUserWorkflowRuleTemplateAccessSettings(context, userWorkflowRuleTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${userWorkflowRuleTemplatesUrl}/${Number(userWorkflowRuleTemplate?.RootId ?? 0)}/updateaccesssettings`;
        const updatedUserWorkflowRuleTemplate = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userWorkflowRuleTemplate });
        context.commit("_updateUserWorkflowRuleTemplate", {
          UserRootId: updatedUserWorkflowRuleTemplate.UserRootId,
          ProductRootId: updatedUserWorkflowRuleTemplate.ProductRootId,
          WorkflowRuleTemplateRootId: updatedUserWorkflowRuleTemplate.WorkflowRuleTemplateRootId,
          CanCopy: updatedUserWorkflowRuleTemplate.CanCopy ?? false,
          CanClose: updatedUserWorkflowRuleTemplate.CanClose ?? false,
          CanShare: updatedUserWorkflowRuleTemplate.CanShare ?? false,
        });
        return updatedUserWorkflowRuleTemplate;
      }, "organizations - updateUserWorkflowRuleTemplateAccessSettings");
    },
  },
};
