import _ from "lodash";
import { FAILURE_MESSAGE, VALIDATION_KEY, REQUIRE_FORM_NONEMPTY_KEY, CUSTOM_DATE_KEY, INNER_FORM_VALIDATION, ValidationTypes } from "@/utils/validateForm";

export const GENERAL_FORM_KEY = "OrderInternalWriteUpGeneralFields";

//schedule A
export const SCHEDULE_A_POLICIES_FORM_KEY = "OrderInternalWriteUpPolicies";
export const SCHEDULE_A_LEGAL_DESCRIPTIONS_FORM_KEY = "OrderInternalWriteUpLegalDescriptions";
export const SCHEDULE_A_PIDS_FORM_KEY = "OrderInternalWriteUpPIDs";
export const SCHEDULE_A_ADDRESSES_FORM_KEY = "OrderInternalWriteUpAddresses";
export const SCHEDULE_A_EFFECTIVE_DATES_FORM_KEY = "OrderInternalWriteUpEffectiveDates";
export const SCHEDULE_A_VESTINGS_FORM_KEY = "OrderInternalWriteUpVestings";
export const SCHEDULE_A_INSURED_INTERESTS_FORM_KEY = "OrderInternalWriteUpInsuredInterests";
//sub keys
// export const SCHEDULE_A_LEGAL_DESCRIPTION_PROPERTY_TYPE_OTHER_FORM_KEY = "PropertyTypeOther"; // TODO?
export const SCHEDULE_A_EFFECTIVE_DATE_ATTACHMENT_ID_FORM_KEY = "AttachmentRootId";
export const SCHEDULE_A_EFFECTIVE_DATE_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE = 0;
export const SCHEDULE_A_EFFECTIVE_DATE_DATE_TIME_FORM_KEY = "CommitmentEffectiveDate";
export const SCHEDULE_A_POLICIES_ATTACHMENT_ID_FORM_KEY = "AttachmentRootId";
export const SCHEDULE_A_POLICIES_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE = 0;
export const SCHEDULE_A_POLICIES_POLICY_TYPE_FORM_KEY = "PolicyType";
export const SCHEDULE_A_POLICIES_PROPOSED_INSURED_FORM_KEY = "ProposedInsured";
export const SCHEDULE_A_POLICIES_POLICY_AMOUNT_FORM_KEY = "ProposedPolicyAmount";
export const SCHEDULE_A_POLICIES_PREMIUM_FORM_KEY = "Premium";
export const SCHEDULE_A_LEGAL_DESCRIPTION_ATTACHMENT_ID_FORM_KEY = "AttachmentRootId";
export const SCHEDULE_A_LEGAL_DESCRIPTION_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE = 0;
export const SCHEDULE_A_LEGAL_DESCRIPTION_TEXT_FORM_KEY = "LegalDescription";
export const SCHEDULE_A_LEGAL_DESCRIPTION_PROPERTY_CATEGORY_FORM_KEY = "PropertyZoningType";
export const SCHEDULE_A_LEGAL_DESCRIPTION_PROPERTY_TYPE_FORM_KEY = "PropertyLegalType";
export const SCHEDULE_A_LEGAL_DESCRIPTION_HAS_DEEDED_GARAGE_FORM_KEY = "HasDeededGarageOrParkingSpace";
export const SCHEDULE_A_LEGAL_DESCRIPTION_HAS_LIMITED_COMMON_ELEMENT = "HasLimitedCommonElementParking";
export const SCHEDULE_A_PROPERTY_ATTACHMENT_ID_FORM_KEY = "AttachmentRootId";
export const SCHEDULE_A_PROPERTY_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE = 0;
export const SCHEDULE_A_PROPERTY_ADDRESS1_FORM_KEY = "PropertyAddress1";
export const SCHEDULE_A_PROPERTY_ADDRESS2_FORM_KEY = "PropertyAddress2";
export const SCHEDULE_A_PROPERTY_CITY_FORM_KEY = "PropertyCity";
export const SCHEDULE_A_PROPERTY_ZIP_FORM_KEY = "PropertyZip";
export const SCHEDULE_A_PROPERTY_COUNTY_FORM_KEY = "PropertyCounty";
export const SCHEDULE_A_PROPERTY_STATE_FORM_KEY = "PropertyState";
export const SCHEDULE_A_PIDS_ATTACHMENT_ID_FORM_KEY = "AttachmentRootId";
export const SCHEDULE_A_PIDS_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE = 0;
export const SCHEDULE_A_PIDS_TEXT_FORM_KEY = "PID";
export const SCHEDULE_A_INSURED_INTEREST_ATTACHMENT_ID_FORM_KEY = "AttachmentRootId";
export const SCHEDULE_A_INSURED_INTEREST_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE = 0;
export const SCHEDULE_A_INSURED_INTEREST_TYPE_FORM_KEY = "InsuredInterest";
export const SCHEDULE_A_INSURED_INTEREST_OTHER_TEXT_FORM_KEY = "InsuredInterestOther";
export const SCHEDULE_A_INSURED_INTEREST_IF_OTHER_THAN_FEE_SIMPLE_KEY = "InsuredInterestIfOtherThanFeeSimple";
export const SCHEDULE_A_VESTING_ATTACHMENT_ID_FORM_KEY = "AttachmentRootId";
export const SCHEDULE_A_VESTING_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE = 0;
export const SCHEDULE_A_VESTING_DATA_FIELD_SEARCH_TEXT = ["Grantee", "Decedent", "Lessee", "Assignee", "Defendant", "Permitee", "Trustee", "Beneficiary"];
export const SCHEDULE_A_VESTING_DESCRIPTION_FORM_KEY = "Vesting";
export const SCHEDULE_A_VESTING_TENANCY_FORM_KEY = "TitleHeldTenancyAtEffectiveDate";
export const SCHEDULE_A_VESTING_TENANCY_OTHER_TEXT_FORM_KEY = "TitleHeldTenancyAtEffectiveDateOther";
export const SCHEDULE_A_LEGAL_DESCRIPTION_DATA_FIELD_SEARCH_TEXT = "legal desc";
export const SCHEDULE_A_PID_DATA_FIELD_SEARCH_TEXT = ["PIN", "PID", "APN"];

const INTERNAL_WRITEUP_EFFECTIVE_DATE_VALIDATION_DEFINITION = {
  [REQUIRE_FORM_NONEMPTY_KEY]: {
    [VALIDATION_KEY]: true,
    [FAILURE_MESSAGE]: "Vesting at Effective Date is Missing",
  },

  // [SCHEDULE_A_EFFECTIVE_DATE_ATTACHMENT_ID_FORM_KEY]: {
  //   [VALIDATION_KEY]: ValidationTypes.PositiveNumber,
  //   [FAILURE_MESSAGE]: "Vesting At Effective Date Has No Valid Remote Attachment Entry",
  // }
  [SCHEDULE_A_EFFECTIVE_DATE_DATE_TIME_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.PastCustomDate,
    [CUSTOM_DATE_KEY]: new Date("1995-12-17T03:24:00"),
    [FAILURE_MESSAGE]: "Vesting at Effective Date is Missing",
  },
};

const INTERNAL_WRITEUP_LEGAL_DESCRIPTION_VALIDATION_DEFINITION = {
  [REQUIRE_FORM_NONEMPTY_KEY]: {
    [VALIDATION_KEY]: true,
    [FAILURE_MESSAGE]: "Legal Description Section is Missing",
  },

  [SCHEDULE_A_LEGAL_DESCRIPTION_TEXT_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
    [FAILURE_MESSAGE]: "Legal Description is Missing",
  },
  // [SCHEDULE_A_LEGAL_DESCRIPTION_PROPERTY_CATEGORY_FORM_KEY]: {
  //   [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
  //   [FAILURE_MESSAGE]: "Legal Description Property Category is Missing",
  // },
  // [SCHEDULE_A_LEGAL_DESCRIPTION_PROPERTY_TYPE_FORM_KEY]: {
  //   [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
  //   [FAILURE_MESSAGE]: "Legal Description Property Type is Missing",
  // },
  // TODO: deeded garage and limited common parking check if condo or pud?
};

const INTERNAL_WRITEUP_PROPERTY_VALIDATION_DEFINITION = {
  [REQUIRE_FORM_NONEMPTY_KEY]: {
    [VALIDATION_KEY]: true,
    [FAILURE_MESSAGE]: "Address Section is Missing",
  },

  [SCHEDULE_A_PROPERTY_ADDRESS1_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
    [FAILURE_MESSAGE]: "Street Address is Missing",
  },
  // [SCHEDULE_A_PROPERTY_ADDRESS2_FORM_KEY]: {
  //   [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
  //   [FAILURE_MESSAGE]: "Address Line 2 is Missing",
  // },
  [SCHEDULE_A_PROPERTY_CITY_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
    [FAILURE_MESSAGE]: "Address City is Missing",
  },
  [SCHEDULE_A_PROPERTY_ZIP_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
    [FAILURE_MESSAGE]: "Address Zip Code is Missing",
  },
  [SCHEDULE_A_PROPERTY_COUNTY_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
    [FAILURE_MESSAGE]: "Address County is Missing",
  },
  [SCHEDULE_A_PROPERTY_STATE_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
    [FAILURE_MESSAGE]: "Address State is Missing",
  },
};

const INTERNAL_WRITEUP_PIDS_VALIDATION_DEFINITION = {
  [REQUIRE_FORM_NONEMPTY_KEY]: {
    [VALIDATION_KEY]: true,
    [FAILURE_MESSAGE]: "Property Identification Number Section is Missing",
  },

  [SCHEDULE_A_PIDS_TEXT_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
    [FAILURE_MESSAGE]: "Property Identification Number is Missing",
  },
};

const INTERNAL_WRITEUP_INSURED_INTEREST_VALIDATION_DEFINITION = {
  [REQUIRE_FORM_NONEMPTY_KEY]: {
    [VALIDATION_KEY]: true,
    [FAILURE_MESSAGE]: "Insured Interest Section is Missing",
  },

  // [SCHEDULE_A_INSURED_INTEREST_ATTACHMENT_ID_FORM_KEY]: {
  //   [VALIDATION_KEY]: ValidationTypes.PositiveNumber,
  //   [FAILURE_MESSAGE]: "Insured Interest Has No Valid Remote Attachment Entry",
  // },
  [SCHEDULE_A_INSURED_INTEREST_TYPE_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
    [FAILURE_MESSAGE]: "Insured Interest is Missing",
  },
  // TODO: check for if it is other then what is other text?
};

const INTERNAL_WRITEUP_VESTING_VALIDATION_DEFINITION = {
  [REQUIRE_FORM_NONEMPTY_KEY]: {
    [VALIDATION_KEY]: true,
    [FAILURE_MESSAGE]: "Vesting Section is Missing",
  },

  [SCHEDULE_A_VESTING_DESCRIPTION_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
    [FAILURE_MESSAGE]: "Vesting is Missing",
  },
  // [SCHEDULE_A_VESTING_TENANCY_FORM_KEY]: {
  //   [VALIDATION_KEY]: ValidationTypes.NonEmptyString,
  //   [FAILURE_MESSAGE]: "Vesting Tenancy Effective Date is Missing",
  // },
};

export const INTERNAL_WRITEUP_VALIDATION_DEFINITION = {
  [SCHEDULE_A_EFFECTIVE_DATES_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.RepeatedEntriesValidation,
    [INNER_FORM_VALIDATION]: INTERNAL_WRITEUP_EFFECTIVE_DATE_VALIDATION_DEFINITION,
  },
  [SCHEDULE_A_LEGAL_DESCRIPTIONS_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.RepeatedEntriesValidation,
    [INNER_FORM_VALIDATION]: INTERNAL_WRITEUP_LEGAL_DESCRIPTION_VALIDATION_DEFINITION,
  },
  [SCHEDULE_A_ADDRESSES_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.RepeatedEntriesValidation,
    [INNER_FORM_VALIDATION]: INTERNAL_WRITEUP_PROPERTY_VALIDATION_DEFINITION,
  },
  [SCHEDULE_A_PIDS_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.RepeatedEntriesValidation,
    [INNER_FORM_VALIDATION]: INTERNAL_WRITEUP_PIDS_VALIDATION_DEFINITION,
  },
  [SCHEDULE_A_INSURED_INTERESTS_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.RepeatedEntriesValidation,
    [INNER_FORM_VALIDATION]: INTERNAL_WRITEUP_INSURED_INTEREST_VALIDATION_DEFINITION,
  },
  [SCHEDULE_A_VESTINGS_FORM_KEY]: {
    [VALIDATION_KEY]: ValidationTypes.RepeatedEntriesValidation,
    [INNER_FORM_VALIDATION]: INTERNAL_WRITEUP_VESTING_VALIDATION_DEFINITION,
  },
};

// enums for legal description
export const CONDO = "Condo";
export const PUD = "Pud";
export const SUBDIVISION = "Subdivision";
export const METES_AND_BOUNDS = "MetesAndBounds";
export const OTHER = "Other";

export const RESIDENTIAL = "Residential";
export const COMMERCIAL = "Commercial";

export function makeNewEffectiveDateSection({ RootId, Position }) {
  return _.cloneDeep({
    Id: 0,
    RootId,
    AttachmentId: 0,
    [SCHEDULE_A_EFFECTIVE_DATE_ATTACHMENT_ID_FORM_KEY]: SCHEDULE_A_EFFECTIVE_DATE_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE,
    Position,
    [SCHEDULE_A_EFFECTIVE_DATE_DATE_TIME_FORM_KEY]: "",
  });
}

export function makeNewAddressSection({ RootId, Position }) {
  return _.cloneDeep({
    Id: 0,
    RootId,
    Position,
    AttachmentId: 0,
    [SCHEDULE_A_PROPERTY_ATTACHMENT_ID_FORM_KEY]: SCHEDULE_A_PROPERTY_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE,
    [SCHEDULE_A_PROPERTY_ADDRESS1_FORM_KEY]: "",
    [SCHEDULE_A_PROPERTY_ADDRESS2_FORM_KEY]: "",
    [SCHEDULE_A_PROPERTY_CITY_FORM_KEY]: "",
    [SCHEDULE_A_PROPERTY_ZIP_FORM_KEY]: "",
    [SCHEDULE_A_PROPERTY_COUNTY_FORM_KEY]: "",
    [SCHEDULE_A_PROPERTY_STATE_FORM_KEY]: "",
  });
}

export function makeNewVestingSection({ RootId, Position }) {
  return _.cloneDeep({
    Id: 0,
    RootId,
    AttachmentId: 0,
    [SCHEDULE_A_VESTING_ATTACHMENT_ID_FORM_KEY]: SCHEDULE_A_VESTING_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE,
    Position,
    [SCHEDULE_A_VESTING_DESCRIPTION_FORM_KEY]: "",
    [SCHEDULE_A_VESTING_TENANCY_FORM_KEY]: "",
    [SCHEDULE_A_VESTING_TENANCY_OTHER_TEXT_FORM_KEY]: "",
  });
}

export function makeNewInsuredInterestSection({ RootId, Position }) {
  return _.cloneDeep({
    Id: 0,
    RootId,
    Position,
    AttachmentId: 0,
    [SCHEDULE_A_INSURED_INTEREST_ATTACHMENT_ID_FORM_KEY]: SCHEDULE_A_INSURED_INTEREST_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE,
    [SCHEDULE_A_INSURED_INTEREST_TYPE_FORM_KEY]: "",
    [SCHEDULE_A_INSURED_INTEREST_OTHER_TEXT_FORM_KEY]: "",
  });
}

export function makeNewPolicySection({ RootId, Position }) {
  return _.cloneDeep({
    Id: 0,
    RootId,
    AttachmentId: 0,
    [SCHEDULE_A_POLICIES_ATTACHMENT_ID_FORM_KEY]: SCHEDULE_A_POLICIES_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE,
    Comments: "",
    Position,
    [SCHEDULE_A_POLICIES_POLICY_TYPE_FORM_KEY]: "",
    [SCHEDULE_A_POLICIES_POLICY_AMOUNT_FORM_KEY]: 0,
    [SCHEDULE_A_POLICIES_PROPOSED_INSURED_FORM_KEY]: "",
    [SCHEDULE_A_POLICIES_PREMIUM_FORM_KEY]: 0,
  });
}

export function makeNewPidsSection({ RootId, Position }) {
  return _.cloneDeep({
    Id: 0,
    RootId,
    AttachmentId: 0,
    [SCHEDULE_A_PIDS_ATTACHMENT_ID_FORM_KEY]: SCHEDULE_A_PIDS_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE,
    Comments: "",
    Position,
    [SCHEDULE_A_PIDS_TEXT_FORM_KEY]: "",
  });
}

export function makeNewLegalDescriptionSection({ RootId, Position }) {
  return _.cloneDeep({
    Id: 0,
    RootId,
    AttachmentId: 0,
    [SCHEDULE_A_LEGAL_DESCRIPTION_ATTACHMENT_ID_FORM_KEY]: SCHEDULE_A_LEGAL_DESCRIPTION_ATTACHMENT_ID_FORM_KEY_DEFAULT_VALUE,
    Comments: "",
    Position,
    [SCHEDULE_A_LEGAL_DESCRIPTION_TEXT_FORM_KEY]: "",
    [SCHEDULE_A_LEGAL_DESCRIPTION_PROPERTY_TYPE_FORM_KEY]: "",
    [SCHEDULE_A_LEGAL_DESCRIPTION_PROPERTY_CATEGORY_FORM_KEY]: "",
    [SCHEDULE_A_LEGAL_DESCRIPTION_HAS_DEEDED_GARAGE_FORM_KEY]: "",
    [SCHEDULE_A_LEGAL_DESCRIPTION_HAS_LIMITED_COMMON_ELEMENT]: "",
  });
}

// schedule B
export const SCHEDULE_B1_FORM_KEY = "OrderInternalWriteUpB1Entries";
export const SCHEDULE_B2_FORM_KEY = "OrderInternalWriteUpB2Entries";
export const SCHEDULE_B_SUBSECTION_PARENT_ROOT_ID_KEY = "ParentOrderInternalWriteUpEntryRootId";
export const SCHEDULE_B_SECTION_POSITION_KEY = "Number";
export const SCHEDULE_B_SUBSECTION_POSITION_KEY = "Position";
export const SCHEDULE_B_PARENT_SECTION_ARRAY_UNIQUE_KEY = "Id";
export const SCHEDULE_B_PARENT_SECTION_ARRAY_KEY = "RootId";

// only for B1 and only once
export const SCHEDULE_B1_TAXES_MOST_RECENT_YEAR_FORM_KEY = "TaxesMostRecentAvailableTaxYear";
export const SCHEDULE_B1_TAXES_CURRENT_YEAR_FIRST_INSTALLMENT_YEAR_FORM_KEY = "TaxesCurrentTaxYearFirstInstallmentYear";
export const SCHEDULE_B1_TAXES_CURRENT_YEAR_FIRST_INSTALLMENT_AMOUNT_FORM_KEY = "TaxesCurrentTaxYearFirstInstallmentAmount";
export const SCHEDULE_B1_TAXES_CURRENT_YEAR_FIRST_INSTALLMENT_STATUS_FORM_KEY = "TaxesCurrentTaxYearFirstInstallmentStatus";
export const SCHEDULE_B1_TAXES_CURRENT_YEAR_SECOND_INSTALLMENT_YEAR_FORM_KEY = "TaxesCurrentTaxYearSecondInstallmentYear";
export const SCHEDULE_B1_TAXES_CURRENT_YEAR_SECOND_INSTALLMENT_AMOUNT_FORM_KEY = "TaxesCurrentTaxYearSecondInstallmentAmount";
export const SCHEDULE_B1_TAXES_CURRENT_YEAR_SECOND_INSTALLMENT_STATUS_FORM_KEY = "TaxesCurrentTaxYearSecondInstallmentStatus";

// because schedule b is a flat datastructure but the view of it is nested
export const TEMPORARY_SCHEDULE_B_SUB_SECTION_ARRAY_KEY = "SubParagraphGroup";

export function makeNewSubSection({ RootId, Number, ParentOrderInternalWriteUpEntryId, ParentOrderInternalWriteUpEntryRootId, Position, ScheduleType }) {
  if (!RootId) {
    // must be greater than 0 (temp root id when id is 0)
    console.error("invalid subsection");
    return null;
  }
  if (ParentOrderInternalWriteUpEntryId < 0) {
    // can be 0 if the parent Root id is set because this means it needs to be added as new in the database
    console.error("invalid parent entry for subsection");
    return null;
  }
  if (!ParentOrderInternalWriteUpEntryRootId) {
    // must be greater than 0 (parent has temp root id when id is 0)
    console.error("invalid parent for subsection");
    return null;
  }
  if (!Position && Position !== 0) {
    // allow 0 because it doesn't matter, parent is always at the top
    console.error("invalid location for subsection");
    return;
  }
  return _.cloneDeep({
    Id: 0,
    RootId,
    AttachmentRootId: 0,
    AttachmentId: 0,
    ProductCodeBookCodeRootId: 0,
    ParentOrderInternalWriteUpEntryId,
    ParentOrderInternalWriteUpEntryRootId,
    ScheduleType,
    Code: "",
    Reference: "",
    Position,
    Number,
  });
}

export function makeNewParentSection({ RootId, Number, Position, ScheduleType }) {
  if (!RootId) {
    console.error("invalid new parent section");
    return null;
  }
  if (!Number) {
    console.error("invalid section number for new parent section");
    return null;
  }
  return _.cloneDeep({
    Id: 0,
    RootId,
    AttachmentRootId: 0,
    AttachmentId: 0,
    ProductCodeBookCodeRootId: 0,
    ScheduleType,
    Code: "",
    Reference: "",
    Position,
    Number,
  });
}

export function getNextRootId(list) {
  let nextRootId = 1;
  while (list.find((item) => item.RootId === nextRootId)) {
    nextRootId++;
  }
  return nextRootId;
}

export function selectedItemFormat(row) {
  if (!row) {
    return;
  }
  if (row.Name || row.DocumentTypeName) {
    if (!row.Name) {
      return `${row?.DocumentTypeName}`;
    }
    return `${row?.Name} - ${row?.DocumentTypeName}`;
  }
  return;
}

export function selectedStateFormat(row) {
  if (!row) {
    return;
  }
  if (row.Name || row.Code) {
    if (!row.Code) {
      return `${row?.Name}`;
    }
    return `${row?.Code} - ${row?.Name}`;
  }
  return;
}
