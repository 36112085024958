import { createStore } from "vuex";

import AuthModule from "./auth";
import AuditLogsModule from "./auditLogs";
import OrganizationsModule from "./organizations";
import ProductsModule from "./products";
import GroupsModule from "./groups";
import UsersModule from "./users";
import OrdersModule from "./orders";
import UploadsModule from "./uploads";
import AttachmentsModule from "./attachments";
import SearchesModule from "./searches";
import SearchGroupsModule from "./searchGroups";
import FormLayoutsModule from "./formLayouts";
import FormDataModule from "./formData";
import FormLocationsModule from "./formLocations";
import SharedSearchesModule from "./sharedSearches";
import SharedFormLayoutsModule from "./sharedFormLayouts";
import SharedDataLogModule from "./sharedDataLog";
import SystemSettingsModule from "./systemSettings";
import DataSourceTypesModule from "./dataSourceTypes";
import SearchLocationsModule from "./searchLocations";
import FeedbackEntriesModule from "./feedbackEntries";
import SalesLeadsModule from "./salesLeads";
import ImageModule from "./image";
import LinksAttachmentsModule from "./linksAttachments";
import LinksQueuedAttachmentsModule from "./linksQueuedAttachments";
import DirectUploadsModule from "./directUploads";
import DocumentTypesModule from "./documentTypes";
import DocumentTypeFieldTypesModule from "./documentTypeFieldTypes";
import DocumentCategoriesModule from "./documentCategories";
import PotentialDocumentTypesModule from "./potentialDocumentTypes";
import StaticTemplatesModule from "./staticTemplates";
import LoadingModule from "./loading";
import FlueidModule from "./flueid";
import BookmarkedOrdersModule from "./bookmarkedOrders";
import ViewedOrdersModule from "./viewedOrders";
import CopiedLinksModule from "./copiedLinks";
import PartiesModule from "./parties";
import PartyRolesModule from "./partyRoles";
import MimeTypesModule from "./mimeTypes";
import NotificationsModule from "./notifications";
import DocumentPlaceOrdersModule from "./documentPlaceOrders";
import OrderDocumentsModule from "./orderDocuments";
import DocumentLogModule from "./documentLog";
import WorkflowLogModule from "./workflowLog";
import UserSearchSettingsLogModule from "./userSearchSettingsLog";
import UserAccessSettingsLogModule from "./userAccessSettingsLog";
import UserConfigSettingsLogModule from "./userConfigSettingsLog";
import InstantMessagesModule from "./instantMessages";
import WorkflowTasksModule from "./workflowTasks";
import WorkflowRulesModule from "./workflowRules";
import WorkflowNotificationsModule from "./workflowNotifications";
import SearchResultsModule from "./searchResults";
import NotesModule from "./notes";
import OrganizationNotificationTemplatesModule from "./organizationNotificationTemplates";
import AppliedTagsModule from "./appliedTags";
import UserTagsModule from "./userTags";
import OrganizationTagsModule from "./organizationTags";
import ProductTagsModule from "./productTags";
import QuickSearchModule from "./quickSearch";
import ProductSearchLocationsModule from "./productSearchLocations";
import ProductFormLocationsModule from "./productFormLocations";
import ProductCustomFieldsModule from "./productCustomFields";
import OrganizationCustomFieldsModule from "./organizationCustomFields";
import ProductCustomFieldEntriesModule from "./productCustomFieldEntries";
import OrganizationCustomFieldEntriesModule from "./organizationCustomFieldEntries";
import ProductCustomUserFieldsModule from "./productCustomUserFields";
import OrganizationCustomUserFieldsModule from "./organizationCustomUserFields";
import WorkflowTaskTemplatesModule from "./workflowTaskTemplates";
import WorkflowRuleTemplatesModule from "./workflowRuleTemplates";
import WorkflowNotificationTemplatesModule from "./workflowNotificationTemplates";
import ProductDataSourcesModule from "./productDataSources";
import ProductDataSourceFieldsModule from "./productDataSourceFields";
import OrganizationDataSourcesModule from "./organizationDataSources";
import OrganizationDataSourceFieldsModule from "./organizationDataSourceFields";
import OrganizationCustomersModule from "./organizationCustomers";
import OrganizationVendorsModule from "./organizationVendors";
import OrganizationCustomerDataSourceFieldAccessSettingsModule from "@/store/organizationCustomerDataSourceFieldAccessSettings";
import OrganizationVendorDataSourceFieldAccessSettingsModule from "@/store/organizationVendorDataSourceFieldAccessSettings";
import OrganizationBrandsModule from "./organizationBrands";
import SyncfusionDropDownListModule from "./syncfusionDropDownList";
import FormGroupsModule from "./formGroups";
import UserDataSourceFieldAccessSettingsModule from "@/store/userDataSourceFieldAccessSettings";
import GroupDataSourceFieldAccessSettingsModule from "@/store/groupDataSourceFieldAccessSettings";
import GroupOrganizationVendorsModule from "@/store/groupOrganizationVendors";
import ProductVendorCategoriesModule from "@/store/productVendorCategories";
import OrganizationVendorCategoriesModule from "@/store/organizationVendorCategories";
import OrganizationVendorCategoryMembersModule from "@/store/organizationVendorCategoryMembers";
import NotificationTypesModule from "@/store/notificationTypes";
import NotificationEventsModule from "@/store/notificationEvents";
import UserNotificationSettingsModule from "@/store/userNotificationSettings";
import WidgetTypesModule from "@/store/widgetTypes";
import WidgetContextFieldTypesModule from "@/store/widgetContextFieldTypes";
import UploaderModule from "@/store/uploader";
import FileDropModule from "@/store/fileDrop";
import OrganizationContactCompaniesModule from "@/store/organizationContactCompanies";
import OrganizationContactPersonsModule from "@/store/organizationContactPersons";
import OrderContactCompaniesModule from "@/store/orderContactCompanies";
import OrderContactPersonsModule from "@/store/orderContactPersons";
import UserProductsModule from "@/store/userProducts";
import GroupMembersModule from "@/store/groupMembers";
import OrganizationAccessSettingsModule from "@/store/organizationAccessSettings";
import OrganizationConfigSettingsModule from "@/store/organizationConfigSettings";
import UserAccessSettingsModule from "@/store/userAccessSettings";
import UserConfigSettingsModule from "@/store/userConfigSettings";
import UserProductCodeBooksModule from "@/store/userProductCodeBooks";
import UserBranchesModule from "@/store/userBranches";
import GroupAccessSettingsModule from "@/store/groupAccessSettings";
import PlatformAdministratorsModule from "@/store/platformAdministrators";
import Contextmenu from "@/store/contextmenu";
import Modals from "@/store/modals";

export const store = createStore({
  strict: true,
  modules: {
    auth: AuthModule,
    al: AuditLogsModule,
    cm: Contextmenu,
    upload: UploadsModule,
    orgs: OrganizationsModule,
    prods: ProductsModule,
    grps: GroupsModule,
    usrs: UsersModule,
    up: UploaderModule,
    ords: OrdersModule,
    atts: AttachmentsModule,
    srchs: SearchesModule,
    srchGrps: SearchGroupsModule,
    shaSrchs: SharedSearchesModule,
    sdl: SharedDataLogModule,
    sysSettings: SystemSettingsModule,
    dst: DataSourceTypesModule,
    psl: ProductSearchLocationsModule,
    pcf: ProductCustomFieldsModule,
    ocf: OrganizationCustomFieldsModule,
    pcfe: ProductCustomFieldEntriesModule,
    ocfe: OrganizationCustomFieldEntriesModule,
    pcuf: ProductCustomUserFieldsModule,
    ocuf: OrganizationCustomUserFieldsModule,
    pds: ProductDataSourcesModule,
    pdsf: ProductDataSourceFieldsModule,
    ods: OrganizationDataSourcesModule,
    odsf: OrganizationDataSourceFieldsModule,
    sl: SearchLocationsModule,
    feedbackEntries: FeedbackEntriesModule,
    lksAtts: LinksAttachmentsModule,
    lksQudAtts: LinksQueuedAttachmentsModule,
    drtUplds: DirectUploadsModule,
    slsLd: SalesLeadsModule,
    img: ImageModule,
    dt: DocumentTypesModule,
    dtft: DocumentTypeFieldTypesModule,
    dc: DocumentCategoriesModule,
    pdt: PotentialDocumentTypesModule,
    st: StaticTemplatesModule,
    load: LoadingModule,
    flu: FlueidModule,
    bkmrk: BookmarkedOrdersModule,
    vwdord: ViewedOrdersModule,
    cplks: CopiedLinksModule,
    parties: PartiesModule,
    partyRoles: PartyRolesModule,
    mt: MimeTypesModule,
    nt: NotificationsModule,
    dpo: DocumentPlaceOrdersModule,
    od: OrderDocumentsModule,
    dl: DocumentLogModule,
    wl: WorkflowLogModule,
    ussl: UserSearchSettingsLogModule,
    uasl: UserAccessSettingsLogModule,
    ucsl: UserConfigSettingsLogModule,
    ont: OrganizationNotificationTemplatesModule,
    im: InstantMessagesModule,
    wt: WorkflowTasksModule,
    wr: WorkflowRulesModule,
    wn: WorkflowNotificationsModule,
    sr: SearchResultsModule,
    note: NotesModule,
    atags: AppliedTagsModule,
    utags: UserTagsModule,
    otags: OrganizationTagsModule,
    ptags: ProductTagsModule,
    qs: QuickSearchModule,
    wftt: WorkflowTaskTemplatesModule,
    wfrt: WorkflowRuleTemplatesModule,
    wfnt: WorkflowNotificationTemplatesModule,
    sddl: SyncfusionDropDownListModule,
    fl: FormLayoutsModule,
    fd: FormDataModule,
    sfl: SharedFormLayoutsModule,
    frmlocs: FormLocationsModule,
    pfl: ProductFormLocationsModule,
    fg: FormGroupsModule,
    udsfas: UserDataSourceFieldAccessSettingsModule,
    gdsfas: GroupDataSourceFieldAccessSettingsModule,
    oc: OrganizationCustomersModule,
    ov: OrganizationVendorsModule,
    ocdsfas: OrganizationCustomerDataSourceFieldAccessSettingsModule,
    ovdsfas: OrganizationVendorDataSourceFieldAccessSettingsModule,
    obr: OrganizationBrandsModule,
    gov: GroupOrganizationVendorsModule,
    pvc: ProductVendorCategoriesModule,
    ovc: OrganizationVendorCategoriesModule,
    ovcm: OrganizationVendorCategoryMembersModule,
    ntype: NotificationTypesModule,
    nevnt: NotificationEventsModule,
    uns: UserNotificationSettingsModule,
    drop: FileDropModule,
    wtype: WidgetTypesModule,
    wcft: WidgetContextFieldTypesModule,
    occ: OrganizationContactCompaniesModule,
    ocp: OrganizationContactPersonsModule,
    ordcc: OrderContactCompaniesModule,
    ordcp: OrderContactPersonsModule,
    uprod: UserProductsModule,
    grpmem: GroupMembersModule,
    oas: OrganizationAccessSettingsModule,
    ocs: OrganizationConfigSettingsModule,
    uas: UserAccessSettingsModule,
    ucs: UserConfigSettingsModule,
    upcb: UserProductCodeBooksModule,
    ub: UserBranchesModule,
    gas: GroupAccessSettingsModule,
    pa: PlatformAdministratorsModule,
    modals: Modals,
  },
});
