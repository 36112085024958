import {DesqProductRootId, TrulyEsqProductRootId} from "@/lib/settings";

export default function (productRootId) {
  switch (productRootId ?? 0) {
    case TrulyEsqProductRootId:
      return { name: "truly-esq-orders-grid" };
    case DesqProductRootId:
      return { name: "d-esq-orders-grid" };
  }
  return {};
}
