import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, userProductsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    userProducts: [],
  },
  getters: {
  },
  mutations: {
    setUserProductData(state, userProductsData) {
      state.userProducts = userProductsData?.sort((up1, up2) => (up1.ProductDisplayName > up2.ProductDisplayName ? 1 : -1)) || [];
    },
    _addUserProduct(state, userProduct) {
      const userProductsData = _.cloneDeep(state.userProducts) || [];
      userProductsData.push(userProduct);
      state.userProducts = userProductsData.sort((up1, up2) => (up1.ProductDisplayName > up2.ProductDisplayName ? 1 : -1)) || [];
    },
    _updateUserProduct(state, updateUserProduct) {
      const rootId = Number(updateUserProduct?.RootId || 0);
      if (rootId > 0) {
        let userProductsData = _.cloneDeep(state.userProducts) || [];
        userProductsData = [...userProductsData.filter((userProduct) => Number(userProduct?.RootId || 0) !== rootId), _.cloneDeep(updateUserProduct)] || [];
        state.userProducts = userProductsData.sort((up1, up2) => (up1.ProductDisplayName > up2.ProductDisplayName ? 1 : -1)) || [];
      }
    },
    _removeUserProduct(state, deleteUserProduct) {
      const rootId = Number(deleteUserProduct?.RootId || 0);
      if (rootId > 0) {
        let userProductsData = _.cloneDeep(state.userProducts) || [];
        userProductsData = [...userProductsData.filter((userProduct) => Number(userProduct?.RootId || 0) !== rootId)] || [];
        state.userProducts = userProductsData.sort((up1, up2) => (up1.ProductDisplayName > up2.ProductDisplayName ? 1 : -1)) || [];
      }
    },
  },
  actions: {
    async getUserProducts(context, UserRootId) {
      return timeOperation(async () => {
        UserRootId = Number(UserRootId || 0);
        context.commit("setUserProductData", []);
        if (UserRootId > 0) {
          const formattedUrl = `${userProductsUrl}?userrootid=${UserRootId}&includemetadata=true`;
          const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
          context.commit("setUserProductData", data);
          return data;
        }
      }, "userProducts - getUserProducts");
    },
    async addUserProduct(context, userProduct) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userProductsUrl, Payload: userProduct });
        if (data?.RootId > 0) {
          userProduct.RootId = data.RootId;
          context.commit("_addUserProduct", data);
        }
        return data;
      }, "userProducts - addUserProduct");
    },
    async updateUserProduct(context, userProduct) {
      return timeOperation(async () => {
        const formattedUrl = `${userProductsUrl}/${Number(userProduct?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userProduct});
        context.commit("_updateUserProduct", data);
        return data;
      }, "userProducts - updateUserProduct");
    },
    async removeUserProduct(context, userProduct) {
      return timeOperation(async () => {
        const formattedUrl = `${userProductsUrl}/${Number(userProduct?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("_removeUserProduct", userProduct);
        return data
      }, "userProducts - removeUserProduct");
    },
  },
};
