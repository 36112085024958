import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationCustomersUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationCustomers: [],
  },
  getters: {
    getOrganizationCustomerByRootId: (state) => (rootId) => {
      return state.organizationCustomers?.find((organizationCustomer) => Number(organizationCustomer?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
  },
  mutations: {
    setOrganizationCustomers(state, organizationCustomersData) {
      state.organizationCustomers = organizationCustomersData ?? [];
    },
    clearOrganizationCustomers(state) {
      state.organizationCustomers = [];
    },
    _addOrganizationCustomer(state, organizationCustomer) {
      if (organizationCustomer?.RootId > 0) {
        state.organizationCustomers.push(organizationCustomer);
      }
    },
    _updateOrganizationCustomer(state, updateOrganizationCustomer) {
      const rootId = Number(updateOrganizationCustomer?.RootId ?? 0);
      if (rootId > 0) {
        let organizationCustomersData = _.cloneDeep(state.organizationCustomers) ?? [];
        organizationCustomersData = [...organizationCustomersData.filter((organizationCustomer) => Number(organizationCustomer?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationCustomer)] ??[];
        state.organizationCustomers = organizationCustomersData ?? [];
      }
    },
    _removeOrganizationCustomer(state, deleteOrganizationCustomer) {
      const rootId = Number(deleteOrganizationCustomer?.RootId ?? 0);
      if (rootId > 0) {
        let organizationCustomersData = _.cloneDeep(state.organizationCustomers) ?? [];
        organizationCustomersData = [...organizationCustomersData.filter((organizationCustomer) => Number(organizationCustomer?.RootId ?? 0) !== rootId)] ?? [];
        state.organizationCustomers = organizationCustomersData ?? [];
      }
    },
  },
  actions: {
    async getOrganizationCustomers(context, { OrganizationRootId, IncludePossible, NoCache }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        IncludePossible = IncludePossible ?? false;
        NoCache = NoCache ?? false;
        const formattedUrl = `${organizationCustomersUrl}?organizationrootid=${OrganizationRootId}&includemetadata=true&includepossible=${IncludePossible}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (!NoCache) {
          context.commit("setOrganizationCustomers", data);
        }
        return data;
      }, "organizationCustomers - getOrganizationCustomers");
    },
    async addOrganizationCustomer(context, organizationCustomer) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationCustomersUrl, Payload: organizationCustomer });
        if (data?.RootId > 0) {
          organizationCustomer.RootId = data.RootId;
          context.commit("_addOrganizationCustomer", data);
        }
        return data;
      }, "organizationCustomers - addOrganizationCustomer");
    },
    async updateOrganizationCustomer(context, organizationCustomer) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomersUrl}/${Number(organizationCustomer?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationCustomer });
        context.commit("_updateOrganizationCustomer", data);
      }, "organizationCustomers - updateOrganizationCustomer");
    },
    async removeOrganizationCustomer(context, organizationCustomer) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomersUrl}/${Number(organizationCustomer?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationCustomer", organizationCustomer);
      }, "organizationCustomers - removeOrganizationCustomer");
    },
  },
};
