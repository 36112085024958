import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationAccessSettingsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationAccessSettings: [],
  },
  getters: {
    getOrganizationAccessSettingByRootId: (state) => (rootId) => {
      return state.organizationAccessSettings.find((organizationAccessSetting) => Number(organizationAccessSetting?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
  },
  mutations: {
    setOrganizationAccessSettingData(state, organizationAccessSettings) {
      state.organizationAccessSettings = organizationAccessSettings ?? [];
    },
    _addOrganizationAccessSetting(state, organizationAccessSetting) {
      const organizationAccessSettingsData = _.cloneDeep(state.organizationAccessSettings) ?? [];
      organizationAccessSettingsData.push(organizationAccessSetting);
      state.organizationAccessSettings = organizationAccessSettingsData ?? [];
    },
    _updateOrganizationAccessSetting(state, updateOrganizationAccessSetting) {
      const rootId = Number(updateOrganizationAccessSetting?.RootId ?? 0);
      if (rootId > 0) {
        let organizationAccessSettingsData = _.cloneDeep(state.organizationAccessSettings) ?? [];
        organizationAccessSettingsData = [...organizationAccessSettingsData.filter(organizationAccessSetting => Number(organizationAccessSetting?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationAccessSetting)] ?? [];
        state.organizationAccessSettings = organizationAccessSettingsData;
      }
    },
    _removeOrganizationAccessSetting(state, deleteOrganizationAccessSetting) {
      const rootId = Number(deleteOrganizationAccessSetting?.RootId ?? 0);
      if (rootId > 0) {
        let organizationAccessSettingsData = _.cloneDeep(state.organizationAccessSettings) ?? [];
        organizationAccessSettingsData = [...organizationAccessSettingsData.filter(organizationAccessSetting => Number(organizationAccessSetting?.RootId ?? 0) !== rootId)] ?? [];
        state.organizationAccessSettings = organizationAccessSettingsData;
      }
    },
  },
  actions: {
    async getOrganizationAccessSettings(context, { OrganizationRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${organizationAccessSettingsUrl}?organizationrootid=${OrganizationRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setOrganizationAccessSettingData", data);
        return data;
      }, "organizationAccessSettings - getOrganizationAccessSettings");
    },
    async addOrganizationAccessSetting(context, organizationAccessSetting) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationAccessSettingsUrl, Payload: organizationAccessSetting });
        if (data?.RootId > 0) {
          organizationAccessSetting.RootId = data.RootId;
          context.commit("_addOrganizationAccessSetting", data);
        }
        return data;
      }, "organizationAccessSettings - addOrganizationAccessSetting");
    },
    async updateOrganizationAccessSetting(context, organizationAccessSetting) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationAccessSettingsUrl}/${Number(organizationAccessSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationAccessSetting});
        context.commit("_updateOrganizationAccessSetting", data);
        return data;
      }, "organizationAccessSettings - updateOrganizationAccessSetting");
    },
    async removeOrganizationAccessSetting(context, organizationAccessSetting) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationAccessSettingsUrl}/${Number(organizationAccessSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("_removeOrganizationAccessSetting", organizationAccessSetting);
        return data;
      }, "organizationAccessSettings - removeOrganizationAccessSetting");
    },
  },
};
