import {ApiRequest, GetVerb, PutVerb, groupDataSourceFieldAccessSettingsUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    groupDataSourceFieldAccessSettings: [],
  },
  getters: {
  },
  mutations: {
    setGroupDataSourceFieldAccessSettings(state, groupDataSourceFieldAccessSettings) {
      state.groupDataSourceFieldAccessSettings = groupDataSourceFieldAccessSettings ?? [];
    },
    _updateGroupDataSourceFieldAccessSetting(state, updateGroupDataSourceFieldAccessSetting) {
      let groupDataSourceFieldAccessSetting = state.groupDataSourceFieldAccessSettings?.find((groupDataSourceFieldAccessSetting) => Number(groupDataSourceFieldAccessSetting?.GroupDataSourceFieldAccessSettingRootId ?? 0) === Number(updateGroupDataSourceFieldAccessSetting?.RootId ?? 0)) ?? null;
      if (!(groupDataSourceFieldAccessSetting?.RootId > 0)) {
        groupDataSourceFieldAccessSetting = state.groupDataSourceFieldAccessSettings?.find((groupDataSourceFieldAccessSetting) =>
          (Number(groupDataSourceFieldAccessSetting?.OrganizationDataSourceRootId ?? 0) === Number(updateGroupDataSourceFieldAccessSetting?.OrganizationDataSourceRootId ?? 0)) &&
          (Number(groupDataSourceFieldAccessSetting?.RootId ?? 0) === Number(updateGroupDataSourceFieldAccessSetting?.OrganizationDataSourceFieldRootId ?? 0))) ?? null;
      }
      if (groupDataSourceFieldAccessSetting?.RootId > 0) {
        groupDataSourceFieldAccessSetting.Hide = updateGroupDataSourceFieldAccessSetting?.Hide ?? false;
        groupDataSourceFieldAccessSetting.Obfuscate = updateGroupDataSourceFieldAccessSetting?.Obfuscate ?? false;
      }
    },
  },
  actions: {
    async getGroupDataSourceFieldAccessSettings(context, { GroupRootId, OrganizationDataSourceRootId, IncludePossible, IncludeMetadata }) {
      GroupRootId = Number(GroupRootId ?? 0);
      OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0);
      IncludePossible = IncludePossible ?? false;
      IncludeMetadata = IncludeMetadata ?? true;
      await timeOperation(async () => {
        context.commit("setGroupDataSourceFieldAccessSettings", { OrganizationDataSourceRootId, Data: [] });
        const formattedUrl = `${groupDataSourceFieldAccessSettingsUrl}?grouprootid=${GroupRootId}&organizationdatasourcerootid=${OrganizationDataSourceRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setGroupDataSourceFieldAccessSettings", data);
      }, "groupDataSourceFieldAccessSettings - getGroupDataSourceFieldAccessSettings");
    },
    async updateGroupDataSourceFieldAccessSetting(context, groupDataSourceFieldAccessSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${groupDataSourceFieldAccessSettingsUrl}/${Number(groupDataSourceFieldAccessSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: groupDataSourceFieldAccessSetting });
        context.commit("_updateGroupDataSourceFieldAccessSetting", data);
      }, "groupDataSourceFieldAccessSettings - updateGroupDataSourceFieldAccessSetting");
    },
  },
};
