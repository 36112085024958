import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, userTagsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    userTags: [],
    userTagsCacheData: {},
  },
  getters: {},
  mutations: {
    setUserTagsCacheData(state, { data, OrganizationDataSourceRootId }) {
      state.userTagsCacheData[OrganizationDataSourceRootId] = _.cloneDeep(data);
    },
    setUserTagData(state, userTagsData) {
      state.userTags = userTagsData ?? [];
    },
    clearUserTags(state) {
      state.userTags = [];
    },
    _addUserTag(state, userTag) {
      const userTagsData = _.cloneDeep(state.userTags) ?? [];
      userTagsData.push(userTag);
      state.userTags = userTagsData;
    },
    _updateUserTag(state, updateUserTag) {
      const rootId = Number(updateUserTag?.RootId ?? 0);
      if (rootId > 0) {
        let userTagsData = _.cloneDeep(state.userTags) ?? [];
        userTagsData = [...userTagsData.filter((userTag) => Number(userTag?.RootId ?? 0) !== rootId), _.cloneDeep(updateUserTag)] ?? [];
        state.userTags = userTagsData;
      }
    },
    _updateUserTagEnabled(state, updateUserTag) {
      const rootId = Number(updateUserTag?.RootId ?? 0);
      if (rootId > 0) {
        let userTag = state.userTags?.filter((userTag) => Number(userTag?.RootId ?? 0) === rootId) ?? null;
        if (userTag?.RootId > 0) {
          userTag.Enabled = updateUserTag.Enabled;
        }
      }
    },
    _removeUserTag(state, deleteUserTag) {
      const rootId = Number(deleteUserTag?.RootId ?? 0);
      if (rootId > 0) {
        let userTagsData = _.cloneDeep(state.userTags) ?? [];
        userTagsData = [...userTagsData.filter((userTag) => Number(userTag?.RootId ?? 0) !== rootId)] ?? [];
        state.userTags = userTagsData;
      }
    },
  },
  actions: {
    async getUserTags(context, { ProductRootId, UserRootId, OrganizationDataSourceRootId, UseCache }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        UserRootId = Number(UserRootId || 0);
        OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId || 0);
        if (UseCache && context.state.userTagsCacheData[OrganizationDataSourceRootId]) {
          return _.cloneDeep(context.state.userTagsCacheData[OrganizationDataSourceRootId]);
        }
        context.commit("setUserTagData", []);
        const formattedUrl = `${userTagsUrl}?productrootid=${ProductRootId}&userrootid=${UserRootId}&organizationdatasourcerootid=${OrganizationDataSourceRootId}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setUserTagData", data);
        if (UseCache) {
          context.commit("setUserTagsCacheData", { OrganizationDataSourceRootId, data });
        }
      }, "userTags - getUserTags");
    },
    async addUserTag(context, userTag) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userTagsUrl, Payload: userTag });
        if (data?.RootId > 0) {
          userTag.RootId = data.RootId;
          context.commit("_addUserTag", data);
        }
        return data;
      }, "userTags - addUserTag");
    },
    async updateUserTag(context, userTag) {
      await timeOperation(async () => {
        const formattedUrl = `${userTagsUrl}/${Number(userTag?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userTag });
        context.commit("_updateUserTag", data);
      }, "userTags - updateUserTag");
    },
    async enableUserTag(context, userTag) {
      await timeOperation(async () => {
        const formattedUrl = `${userTagsUrl}/${Number(userTag?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...userTag, Enabled: true } });
        context.commit("_updateUserTagEnabled", data);
      }, "userTags - enableUserTag");
    },
    async disableUserTag(context, userTag) {
      await timeOperation(async () => {
        const formattedUrl = `${userTagsUrl}/${Number(userTag?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...userTag, Enabled: false } });
        context.commit("_updateUserTagEnabled", data);
      }, "userTags - disableUserTag");
    },
    async removeUserTag(context, userTag) {
      await timeOperation(async () => {
        const formattedUrl = `${userTagsUrl}/${Number(userTag?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeUserTag", userTag);
      }, "userTags - removeUserTag");
    },
  },
};
