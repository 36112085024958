import Swal from "sweetalert2";
const swalWithCustomOk = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary",
  },
  buttonsStyling: false,
});
export function okSweetAlert(name, title = `Unable to preview document '${name}'.`) {
  swalWithCustomOk.fire({
    html: `<hr />
    <div style="color: #3e4a55; text-align: center; font-size: 1.25rem; line-height: 1.75rem; font-weight: 500;">${title}</div>`,
    // icon: "error",
    // html: "You can use <b>bold text</b>, " + '<a href="//sweetalert2.github.io">links</a> ' + "and other HTML tags",
    // showCloseButton: true,
    // showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: "OK",
    confirmButtonColor: "#3e4a55",
    // confirmButtonAriaLabel: "Thumbs up, great!",
    // cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
    // cancelButtonAriaLabel: "Thumbs down",
  });
}
export async function simpleSweetAlert(text, operation = () => {}) {
  await swalWithCustomOk
    .fire({
      html: `<hr />
    <div style="color: #3e4a55; text-align: center; font-size: 1.25rem; line-height: 1.75rem; font-weight: 500;">${text}</div>`,
      // icon: "error",
      // html: "You can use <b>bold text</b>, " + '<a href="//sweetalert2.github.io">links</a> ' + "and other HTML tags",
      // showCloseButton: true,
      // showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#3e4a55",
      // confirmButtonAriaLabel: "Thumbs up, great!",
      // cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      // cancelButtonAriaLabel: "Thumbs down",
    })
    .then(async () => {
      await operation();
    });
}
export async function simpleSweetAlertWithHeader(title, text) {
  await swalWithCustomOk.fire({
    title,
    html: `<hr />
    <div style="text-align: center; font-size: 1.25rem; line-height: 1.75rem; font-weight: 500;">${text}</div>`,
    // icon: "error",
    // html: "You can use <b>bold text</b>, " + '<a href="//sweetalert2.github.io">links</a> ' + "and other HTML tags",
    // showCloseButton: true,
    // showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: "OK",
    confirmButtonColor: "#3e4a55",
    // confirmButtonAriaLabel: "Thumbs up, great!",
    // cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
    // cancelButtonAriaLabel: "Thumbs down",
  });
}
const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary mx-2",
    cancelButton: "btn btn-secondary mx-2",
  },
  buttonsStyling: false,
});
export async function confirmOperation(
  operation,
  title = "Are you sure you want to switch the priority of these items?",
  confirmButtonText = "Confirm Priority Switch",
  cancelButtonText = "Cancel",
  falseOperation = () => {}
) {
  return await swalWithCustomButtons
    .fire({
      html: `<hr />
      <div style="color: #3e4a55; text-align: center; font-size: 1.25rem; line-height: 1.75rem; font-weight: 500;">${title}</div>`,
      // icon: "warning",
      showCancelButton: true,
      focusCancel: true,
      confirmButtonText,
      cancelButtonText,
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        await operation();
      } else {
        await falseOperation();
      }
      return result.isConfirmed ? true : false;
    });
}
const swalWithGreenSuccessButton = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-secondary mx-2",
    cancelButton: "btn btn-primary mx-2",
  },
  buttonsStyling: false,
});
export async function confirmSaveOperation(
  operation,
  title = "You have unsaved changes, would you like to save them?",
  confirmButtonText = "Continue Without Saving",
  cancelButtonText = "Save Changes"
) {
  await swalWithGreenSuccessButton
    .fire({
      html: `<hr />
      <div style="color: #3e4a55; text-align: center; font-size: 1.25rem;  line-height: 1.75rem; font-weight: 500;">${title}</div>`,
      // icon: "warning",
      showCancelButton: true,
      focusCancel: true,
      focusConfirm: false,
      confirmButtonText,
      cancelButtonText,
    })
    .then(async (result) => {
      if (!result.isConfirmed) {
        // switched confirm and cancel
        await operation();
      }
      return result.isConfirmed ? true : false;
    });
}
