import {
  ApiRequest,
  GetVerb,
  PostVerb,
  PutVerb,
  groupOrganizationVendorsUrl,
} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    groupOrganizationVendors: [],
  },
  getters: {
    getGroupOrganizationVendorByName: (state) => (name) => {
      let result = null;
      const groupOrganizationVendor = state.groupOrganizationVendors.find((groupOrganizationVendor) => String(groupOrganizationVendor?.Name ?? "") === String(name ?? "")) ?? null;
      if (groupOrganizationVendor?.RootId > 0) {
        result = _.cloneDeep(groupOrganizationVendor);
      }
      return result;
    },
  },
  mutations: {
    setGroupOrganizationVendors(state, groupOrganizationVendors) {
      state.groupOrganizationVendors = groupOrganizationVendors;
    },
    _updateGroupOrganizationVendorData(state, params) {
      const groupRootId = Number(params?.GroupRootId ?? 0);
      const productRootId = Number(params?.ProductRootId ?? 0);
      const organizationRootId = Number(params?.OrganizationRootId ?? 0);
      const searchRootId = Number(params?.OrganizationVendorRootId ?? 0);
      if (groupRootId > 0 && productRootId > 0 && organizationRootId > 0 && searchRootId > 0) {
        let groupOrganizationVendorsData = _.cloneDeep(state.organizationGroupOrganizationVendors) ?? [];
        const updateGroupOrganizationVendor =
          groupOrganizationVendorsData.find(
            (groupOrganizationVendor) =>
              Number(groupOrganizationVendor?.GroupRootId ?? 0) === groupRootId &&
              Number(groupOrganizationVendor?.ProductRootId ?? 0) === productRootId &&
              Number(groupOrganizationVendor?.OrganizationRootId ?? 0) === organizationRootId &&
              Number(groupOrganizationVendor?.OrganizationVendorRootId ?? 0) === searchRootId
          ) ?? null;
        if (updateGroupOrganizationVendor?.RootId > 0) {
          let updateGroupOrganizationVendorCopy = _.cloneDeep(updateGroupOrganizationVendor);
          const id = Number(params?.Id ?? 0);
          const rootId = Number(params?.RootId ?? 0);
          if (id > 0) {
            updateGroupOrganizationVendorCopy.Id = id;
          }
          if (rootId > 0) {
            updateGroupOrganizationVendorCopy.RootId = rootId;
          }
          if (params?.hasOwnProperty("Enabled") ?? false) {
            updateGroupOrganizationVendorCopy.Enabled = params?.Enabled ?? false;
          }
          if (params?.hasOwnProperty("Selected") ?? false) {
            updateGroupOrganizationVendorCopy.Selected = params?.Selected ?? false;
          }
          groupOrganizationVendorsData =
            [...groupOrganizationVendorsData.filter((groupOrganizationVendor) => Number(groupOrganizationVendor?.RootId ?? 0) !== Number(updateGroupOrganizationVendor.RootId)), updateGroupOrganizationVendorCopy].sort((gov1, gov2) =>
              gov1.GroupName > gov2.GroupName ? 1 : -1
            ) ?? [];
          state.organizationGroupOrganizationVendors = _.cloneDeep(groupOrganizationVendorsData);
        }
      }
    },
  },
  actions: {
    async getGroupOrganizationVendors(context, { GroupRootId, ProductRootId, OrganizationRootId, OrganizationVendorRootId, IncludeMetadata }) {
      await timeOperation(async () => {
        GroupRootId = Number(GroupRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        OrganizationVendorRootId = Number(OrganizationVendorRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${groupOrganizationVendorsUrl}?grouprootid=${GroupRootId}&productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&organizationvendorrootid=${OrganizationVendorRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setGroupOrganizationVendors", data);
      }, "groupOrganizationVendors - getProductGroupOrganizationVendorsForProduct");
    },
    async AddGroupOrganizationVendor(context, { Name, Position, ParentGroupOrganizationVendorRootId, FormLayoutRootId }) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: groupOrganizationVendorsUrl, Payload: { Name, Position, ParentGroupOrganizationVendorRootId, FormLayoutRootId } });
        if (data?.RootId > 0) {
          context.commit("_addGroupOrganizationVendor", _.cloneDeep(data));
        }
      }, "groupOrganizationVendors - AddGroupOrganizationVendor");
    },
    async updateGroupOrganizationVendorEnabledStatus(context, groupOrganizationVendor) {
      await timeOperation(async () => {
        const formattedUrl = `${groupOrganizationVendorsUrl}/${Number(groupOrganizationVendor?.RootId ?? 0)}/updateenabledstatus`;
        const updatedGroupOrganizationVendor = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: groupOrganizationVendor });
        context.commit("_updateGroupOrganizationVendorData", {
          GroupRootId: updatedGroupOrganizationVendor.GroupRootId,
          ProductRootId: updatedGroupOrganizationVendor.ProductRootId,
          OrganizationRootId: updatedGroupOrganizationVendor.OrganizationRootId,
          OrganizationVendorRootId: updatedGroupOrganizationVendor.OrganizationVendorRootId,
          Enabled: updatedGroupOrganizationVendor.Enabled ?? false,
        });
      }, "groupOrganizationVendors - updateGroupOrganizationVendorEnabledStatus");
    },
    async updateGroupOrganizationVendorSelectedStatus(context, groupOrganizationVendor) {
      await timeOperation(async () => {
        const formattedUrl = `${groupOrganizationVendorsUrl}/${Number(groupOrganizationVendor?.RootId ?? 0)}/updateselectedstatus`;
        const updatedGroupOrganizationVendor = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: groupOrganizationVendor });
        context.commit("_updateGroupOrganizationVendorData", {
          GroupRootId: updatedGroupOrganizationVendor.GroupRootId,
          ProductRootId: updatedGroupOrganizationVendor.ProductRootId,
          OrganizationRootId: updatedGroupOrganizationVendor.OrganizationRootId,
          OrganizationVendorRootId: updatedGroupOrganizationVendor.OrganizationVendorRootId,
          Selected: updatedGroupOrganizationVendor.Selected ?? false,
        });
      }, "groupOrganizationVendors - updateGroupOrganizationVendorSelectedStatus");
    },
  },
};
