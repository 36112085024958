import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, userProductCodeBooksUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    userProductCodeBooks: [],
  },
  getters: {
    getUserProductCodeBookByRootId: (state) => (rootId) => {
      return state.userProductCodeBooks.find((userProductCodeBook) => Number(userProductCodeBook?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
  },
  mutations: {
    setUserProductCodeBookData(state, userProductCodeBooks) {
      state.userProductCodeBooks = userProductCodeBooks ?? [];
    },
    _addUserProductCodeBook(state, userProductCodeBook) {
      const userProductCodeBooksData = _.cloneDeep(state.userProductCodeBooks) ?? [];
      userProductCodeBooksData.push(userProductCodeBook);
      state.userProductCodeBooks = userProductCodeBooksData ?? [];
    },
    _updateUserProductCodeBook(state, updateUserProductCodeBook) {
      const rootId = Number(updateUserProductCodeBook?.RootId ?? 0);
      if (rootId > 0) {
        let userProductCodeBooksData = _.cloneDeep(state.userProductCodeBooks) ?? [];
        userProductCodeBooksData = [...userProductCodeBooksData.filter(userProductCodeBook => Number(userProductCodeBook?.RootId ?? 0) !== rootId), _.cloneDeep(updateUserProductCodeBook)] ?? [];
        state.userProductCodeBooks = userProductCodeBooksData;
      }
    },
    _removeUserProductCodeBook(state, deleteUserProductCodeBook) {
      const rootId = Number(deleteUserProductCodeBook?.RootId ?? 0);
      if (rootId > 0) {
        let userProductCodeBooksData = _.cloneDeep(state.userProductCodeBooks) ?? [];
        userProductCodeBooksData = [...userProductCodeBooksData.filter(userProductCodeBook => Number(userProductCodeBook?.RootId ?? 0) !== rootId)] ?? [];
        state.userProductCodeBooks = userProductCodeBooksData;
      }
    },
  },
  actions: {
    async getUserProductCodeBooks(context, { UserRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        UserRootId = Number(UserRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${userProductCodeBooksUrl}?userrootid=${UserRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setUserProductCodeBookData", data);
        return data;
      }, "userProductCodeBooks - getUserProductCodeBooks");
    },
    async addUserProductCodeBook(context, userProductCodeBook) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userProductCodeBooksUrl, Payload: userProductCodeBook });
        if (data?.RootId > 0) {
          userProductCodeBook.RootId = data.RootId;
          context.commit("_addUserProductCodeBook", data);
        }
        return data;
      }, "userProductCodeBooks - addUserProductCodeBook");
    },
    async updateUserProductCodeBook(context, userProductCodeBook) {
      return timeOperation(async () => {
        const formattedUrl = `${userProductCodeBooksUrl}/${Number(userProductCodeBook?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userProductCodeBook});
        context.commit("_updateUserProductCodeBook", data);
        return data;
      }, "userProductCodeBooks - updateUserProductCodeBook");
    },
    async removeUserProductCodeBook(context, userProductCodeBook) {
      return timeOperation(async () => {
        const formattedUrl = `${userProductCodeBooksUrl}/${Number(userProductCodeBook?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("_removeUserProductCodeBook", userProductCodeBook);
        return data;
      }, "userProductCodeBooks - removeUserProductCodeBook");
    },
  },
};
