import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationNotificationTemplatesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationNotificationTemplates: [],
    selectedAdminOrganizationNotificationTemplate: {},
  },
  getters: {
    getOrganizationNotificationTemplateByRootId: (state) => (rootId) => {
      return state.organizationNotificationTemplates.find((organizationNotificationTemplate) => Number(organizationNotificationTemplate?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getOrganizationNotificationTemplateByName: (state) => (name) => {
      return state.organizationNotificationTemplates.find((organizationNotificationTemplate) => String(organizationNotificationTemplate?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getOrganizationNotificationTemplatesForProduct: (state) => (productRootId) => {
      let result = [];
      state.organizationNotificationTemplates.forEach((organizationNotificationTemplate) => {
        if (Number(organizationNotificationTemplate.ProductRootId) === Number(productRootId)) {
          result.push(_.cloneDeep(organizationNotificationTemplate));
        }
      });
      return result;
    },
    getNextOrganizationNotificationTemplateRootId: (state) => {
      let result = 1;
      state.organizationNotificationTemplates.forEach((organizationNotificationTemplate) => {
        if (Number(organizationNotificationTemplate.RootId) >= result) {
          result = Number(organizationNotificationTemplate.RootId) + 1;
        }
      });
      return result;
    },
    isOrganizationNotificationTemplateNameTaken: (state) => (currentOrganizationNotificationTemplate) => {
      const { organizationNotificationTemplates } = state;
      if (organizationNotificationTemplates?.length < 1) {
        console.error("no organization notification templates");
        return false;
      }
      if (!currentOrganizationNotificationTemplate) {
        console.error("no organization notification template given");
        return true;
      }
      const { Name: currentName, RootId } = currentOrganizationNotificationTemplate;
      if (!currentName) {
        console.error("no name");
        return true;
      }
      if (!RootId) {
        console.error("invalid organization notification template");
        return true;
      }
      return !!organizationNotificationTemplates.find((organizationNotificationTemplate) => organizationNotificationTemplate?.Name === currentName && RootId !== organizationNotificationTemplate?.RootId);
    },
  },
  mutations: {
    setSelectedAdminOrganizationNotificationTemplate(state, newOrganizationNotificationTemplate) {
      state.selectedAdminOrganizationNotificationTemplate = _.cloneDeep(newOrganizationNotificationTemplate);
    },
    setOrganizationNotificationTemplates(state, organizationNotificationTemplatesData) {
      state.organizationNotificationTemplates = organizationNotificationTemplatesData?.sort((ont1, ont2) => (ont1.Name > ont2.Name ? 1 : -1)) ?? [];
    },
    _addOrganizationNotificationTemplate(state, organizationNotificationTemplate) {
      const organizationNotificationTemplatesData = _.cloneDeep(state.organizationNotificationTemplates) ?? [];
      organizationNotificationTemplatesData.push(organizationNotificationTemplate);
      state.organizationNotificationTemplates = organizationNotificationTemplatesData.sort((ont1, ont2) => (ont1.Name > ont2.Name ? 1 : -1)) ?? [];
    },
    _updateOrganizationNotificationTemplate(state, updateOrganizationNotificationTemplate) {
      const rootId = Number(updateOrganizationNotificationTemplate?.RootId ?? 0);
      if (rootId > 0) {
        let organizationNotificationTemplatesData = _.cloneDeep(state.organizationNotificationTemplates) ?? [];
        state.organizationNotificationTemplates = [...organizationNotificationTemplatesData.filter((organizationNotificationTemplate) => Number(organizationNotificationTemplate?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationNotificationTemplate)].sort((ont1, ont2) => (ont1.Name > ont2.Name ? 1 : -1)) ?? [];
      }
    },
    _removeOrganizationNotificationTemplate(state, deleteOrganizationNotificationTemplate) {
      const rootId = Number(deleteOrganizationNotificationTemplate?.RootId ?? 0);
      if (rootId > 0) {
        let organizationNotificationTemplatesData = _.cloneDeep(state.organizationNotificationTemplates) ?? [];
        state.organizationNotificationTemplates = [...organizationNotificationTemplatesData.filter((organizationNotificationTemplate) => Number(organizationNotificationTemplate?.RootId ?? 0) !== rootId)].sort((ont1, ont2) => (ont1.Name > ont2.Name ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getOrganizationNotificationTemplates(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationNotificationTemplatesUrl
          }?productrootid=${Number(params?.ProductRootId ?? 0)
          }&organizationrootid=${Number(params?.OrganizationRootId ?? 0)
          }&includemetadata=${params?.IncludeMetadata ?? false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationNotificationTemplates", data);
      }, "organizationNotificationTemplates - getOrganizationNotificationTemplates");
    },
    async addOrganizationNotificationTemplate(context, organizationNotificationTemplate) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationNotificationTemplatesUrl, Payload: organizationNotificationTemplate });
        context.commit("_addOrganizationNotificationTemplate", data);
      }, "organizationNotificationTemplates - addOrganizationNotificationTemplate");
    },
    async updateOrganizationNotificationTemplate(context, organizationNotificationTemplate) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationNotificationTemplatesUrl}/${Number(organizationNotificationTemplate?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationNotificationTemplate });
        context.commit("_updateOrganizationNotificationTemplate", data);
      }, "organizationNotificationTemplates - updateOrganizationNotificationTemplate");
    },
    async removeOrganizationNotificationTemplate(context, organizationNotificationTemplate) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationNotificationTemplatesUrl}/${Number(organizationNotificationTemplate?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationNotificationTemplate", organizationNotificationTemplate);
      }, "organizationNotificationTemplates - removeOrganizationNotificationTemplate");
    },
  },
};
