import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationVendorsUrl } from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationVendors: [],
  },
  mutations: {
    setOrganizationVendors(state, organizationVendors) {
      state.organizationVendors = organizationVendors;
    },
  },
  actions: {
    async getOrganizationVendors(context, { OrganizationRootId, IncludePossible, NoCache }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        IncludePossible = IncludePossible ?? false;
        NoCache = NoCache ?? false;
        const formattedUrl = `${organizationVendorsUrl}?organizationrootid=${OrganizationRootId}&includemetadata=true&includepossible=${IncludePossible}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (!NoCache) {
          context.commit("setOrganizationVendors", data);
        }
        return data;
      }, "organizationVendors - getOrganizationVendors");
    },
    async addOrganizationVendor(context, organizationVendor) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationVendorsUrl, Payload: organizationVendor });
        if (data?.RootId > 0) {
          organizationVendor.RootId = data.RootId;
        }
        return data;
      }, "organizationVendors - addOrganizationVendor");
    },
    async updateOrganizationVendor(context, organizationVendor) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationVendorsUrl}/${Number(organizationVendor?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationVendor });
      }, "organizationVendors - updateOrganizationVendor");
    },
    async removeOrganizationVendor(context, organizationVendor) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationVendorsUrl}/${Number(organizationVendor?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "organizationVendors - removeOrganizationVendor");
    },
  },
};
