export const DATE_TIME_INPUT = "Date Time";
export const DATE_INPUT = "Date";
export const TEXT_INPUT = "Text Input";
export const EMAIL_INPUT = "Email";
export const CUSTOMIZABLE_SEARCH_WIDGET = "Customizable Search Widget";
export const PHONE_INPUT = "Phone";
export const TEXT = "Text";
export const ATTACHMENT = "Attachment";
export const RADIO_INPUT = "Radio";
export const RADIO_CHECKBOX_INPUT = "Checkbox Group";
export const MULTI_INPUT = "Multi";
export const GRID_INPUT = "Grid";
export const TEXT_EDITOR = "Text Editor";
export const DEFAULT_DROP_GRID_FIELDS = "Default Drop Grid Fields";
export const DEFAULT_DROP_GRID_DATASOURCE = "Default Drop Grid Datasource";
export const CURRENCY_INPUT = "Currency";
export const SMALL_CURRENCY_INPUT = "Small Currency";
export const SECTION_HEADER = "Section Header";
export const LINE_BREAK = "Line Break";
export const TAB = "Tab";
export const HALF_TAB = "Half Tab";
export const SPACE = "Space";
export const FULL_WIDTH_TEXT_INPUT = "Full Width Text Input";
export const NUMBER_INPUT = "Number";
export const SMALL_NUMBER_INPUT = "Small Number";
export const DATE_WIDTH_TEXT_INPUT = "Date Width Text";
export const SIGNATURE_LINE = "Signature Line";
export const LARGE_TEXT_EDITOR = "Text Editor";
export const TEXT_GRID = "Text Grid";
export const GRID_INNER_FORMS = "Grid Inner Forms";
export const PERSONAL_SIGNATURE_INPUT = "Personal Signature Input";
export const HORIZONTAL_ROW = "Horizontal Line";
export const FLEXIBLE_DATE_INPUT = "Flexible Date";
export const COLOR_INPUT = "Color Input";
export const DROP_LIST_INPUT = "Drop List Input";
export const CHECKBOX_INPUT = "Checkbox Input";
export const TEXT_AREA_INPUT = "Text Area Input";
export const FORM = "Form";
export const FORM_SIZE = "Form Size";
export const FORM_LOCATION = "Form Location";
export const FORM_DROPDOWN = "Form Dropdown";
export const FORM_CUSTOM_AUTO_COMPLETE = "Form Custom Auto Complete";
export const IMAGE = "Image Input";
export const JUSTIFY_CONTENT = "Justify Content";
export const LABEL_INPUT = "Label Input";
export const CUSTOM_FIELD = "Custom Field";
export const CUSTOM_USER_FIELD = "Custom User Field";
export const TREE_PANEL = "NavigationPanel";
export const FORMS_INPUT = "Forms Input";
export const VIEWS_DATASOURCE = "Views Datasource";
export const VIEWS_WIDGET = "Views Widget";
// export const USER_DOCUMENTS_WIDGET = "User Documents Widget";
export const SERVICE_PROVIDER_WIDGET = "Service Provider Picker Widget";
export const ORDER_DOCUMENTS_WIDGET = "Order Documents Widget";
export const ORDER_PICKER_WIDGET = "Order Picker Widget";
export const NEEDED_DATE_TIME_WIDGET = "Needed by Date/Time Widget";
export const SERVICE_PROVIDER_OFFERINGS_WIDGET = "Service Provider Offerings Widget";
export const MESSAGE_WIDGET = "Message Widget";
export const SERVICE_ORDER_PICKER_WIDGET = "Select Service Order Widget";
export const ARRAY_CONTROL = "Array Control";
export const SPLIT_PANES_WIDGET = "Split Panes Widget";
export const SPLIT_PANES_ARRAY_CONTROL = "Split Panes Array Control";
export const DOCUMENT_VIEWER_WIDGET = "Document Viewer Widget";
export const SERVICE_ORDER_SELECTION_GRID_WIDGET = "Service Order Entries Selection Grid";
export const NAVIGATION_PANEL_FORM_PICKER = "Navigation Panel Form Picker";
export const CONNECTED_TO_DATASOURCE = "Connected To Datasource";
