import {ApiRequest, GetVerb, PutVerb, userDataSourceFieldAccessSettingsUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    userDataSourceFieldAccessSettings: [],
  },
  getters: {
  },
  mutations: {
    setUserDataSourceFieldAccessSettings(state, userDataSourceFieldAccessSettings) {
      state.userDataSourceFieldAccessSettings = userDataSourceFieldAccessSettings ?? [];
    },
    _updateUserDataSourceFieldAccessSetting(state, updateUserDataSourceFieldAccessSetting) {
      let userDataSourceFieldAccessSetting = state.userDataSourceFieldAccessSettings?.find((userDataSourceFieldAccessSetting) => Number(userDataSourceFieldAccessSetting?.UserDataSourceFieldAccessSettingRootId ?? 0) === Number(updateUserDataSourceFieldAccessSetting?.RootId ?? 0)) ?? null;
      if (!(userDataSourceFieldAccessSetting?.RootId > 0)) {
        userDataSourceFieldAccessSetting = state.userDataSourceFieldAccessSettings?.find((userDataSourceFieldAccessSetting) =>
          (Number(userDataSourceFieldAccessSetting?.OrganizationDataSourceRootId ?? 0) === Number(updateUserDataSourceFieldAccessSetting?.OrganizationDataSourceRootId ?? 0)) &&
          (Number(userDataSourceFieldAccessSetting?.RootId ?? 0) === Number(updateUserDataSourceFieldAccessSetting?.OrganizationDataSourceFieldRootId ?? 0))) ?? null;
      }
      if (userDataSourceFieldAccessSetting?.RootId > 0) {
        userDataSourceFieldAccessSetting.Hide = updateUserDataSourceFieldAccessSetting?.Hide ?? false;
        userDataSourceFieldAccessSetting.Obfuscate = updateUserDataSourceFieldAccessSetting?.Obfuscate ?? false;
      }
    },
  },
  actions: {
    async getUserDataSourceFieldAccessSettings(context, { UserRootId, OrganizationDataSourceRootId, IncludePossible, IncludeMetadata }) {
      UserRootId = Number(UserRootId ?? 0);
      OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0);
      IncludePossible = IncludePossible ?? false;
      IncludeMetadata = IncludeMetadata ?? true;
      await timeOperation(async () => {
        context.commit("setUserDataSourceFieldAccessSettings", { OrganizationDataSourceRootId, Data: [] });
        const formattedUrl = `${userDataSourceFieldAccessSettingsUrl}?userrootid=${UserRootId}&organizationdatasourcerootid=${OrganizationDataSourceRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setUserDataSourceFieldAccessSettings", data);
      }, "userDataSourceFieldAccessSettings - getUserDataSourceFieldAccessSettings");
    },
    async updateUserDataSourceFieldAccessSetting(context, userDataSourceFieldAccessSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${userDataSourceFieldAccessSettingsUrl}/${Number(userDataSourceFieldAccessSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userDataSourceFieldAccessSetting });
        context.commit("_updateUserDataSourceFieldAccessSetting", data);
      }, "userDataSourceFieldAccessSettings - updateUserDataSourceFieldAccessSetting");
    },
  },
};
