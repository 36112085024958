import {ApiRequest, GetVerb, PostVerb, PutVerb, sharedSearchesUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getSharedSearches(context, { SearchRootId, OwnerUserRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        SearchRootId = Number(SearchRootId ?? 0);
        OwnerUserRootId = Number(OwnerUserRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${sharedSearchesUrl
        }?searchrootid=${SearchRootId
        }&owneruserrootid=${OwnerUserRootId
        }&includemetadata=${IncludeMetadata}`;
        return await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
      }, "sharedSearches - getSharedSearches");
    },
    async updateSharedSearchAccessSettings(context, sharedSearch) {
      return timeOperation(async () => {
        const formattedUrl = `${sharedSearchesUrl}/${Number(sharedSearch?.RootId ?? 0)}/updateaccesssettings`;
        return await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: sharedSearch});
      }, "sharedSearches - updateSharedSearchAccessSettings");
    },
    async saveSharedSearches(context, { SearchRootId, SharedSearches }) {
      return timeOperation(async () => {
        SearchRootId = Number(SearchRootId ?? 0);
        const formattedUrl = `${sharedSearchesUrl}/${SearchRootId}/savesharedsearches`;
        return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: formattedUrl, Payload: SharedSearches ?? []});
      }, "sharedSearches - saveSharedSearches");
    },
  },
};
