import {ApiRequest, GetVerb, PostVerb, organizationContactPersonsUrl, PutVerb, DeleteVerb} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import {OrganizationContactPersonRecordType} from "@/lib/settings";
import {getGridData} from "@/store/searchResults";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getOrganizationContactPersonsAdvancedSearchData(context, {
      ProductRootId,
      OrganizationRootId,
      UserRootId,
      SearchRootId,
      PreviewParameters,
      IncludeMetadata,
      IncludeDebugData,
    }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        IncludeDebugData = IncludeDebugData ?? false;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${organizationContactPersonsUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&searchrootid=${SearchRootId
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata
          }&includedebugdata=${IncludeDebugData}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        context.commit("setSearchResultsData", { ViewType: OrganizationContactPersonRecordType, Data });
      }, "organizationContactPersons - getOrganizationContactPersonsAdvancedSearchData");
    },
    async getOrganizationContactPersons(context, {
      OrganizationRootId,
      UseCache
    }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UseCache = UseCache ?? false;
        if (OrganizationRootId > 0) {
          const formattedUrl = `${organizationContactPersonsUrl}?organizationrootid=${OrganizationRootId}&includemetadata=true`;
          return await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        } else {
          console.error("OrganizationRootId is not valid");
        }
        return [];
      }, "organizations - getOrganizationContactPersons");
    },
    async addOrganizationContactPerson(context, organizationContactPerson) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: organizationContactPersonsUrl, Payload: organizationContactPerson});
      }, "organizationContactPersons - addOrganizationContactPerson");
    },
    async updateOrganizationContactPerson(context, organizationContactPerson) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationContactPersonsUrl}/${Number(organizationContactPerson?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationContactPerson});
      }, "organizationContactPersons - updateOrganizationContactPerson");
    },
    async removeOrganizationContactPerson(context, organizationContactPerson) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationContactPersonsUrl}/${organizationContactPerson.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "organizationContactPersons - removeOrganizationContactPerson");
    },
  },
};
