// noinspection JSCheckFunctionSignatures,JSIgnoredPromiseFromCall

import Axios from "axios";
import { CognitoUserPool, AuthenticationDetails, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { loginUrl, userAccessSettingsUrl, userConfigSettingsUrl, ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb } from "@/lib/api";
import { TrulyEsqProductRootId, LinksProductRootId, DesqProductRootId } from "@/lib/settings";
import { okSweetAlert } from "@/utils/sweetAlerts";
import { simpleSweetAlertWithHeader } from "@/utils/sweetAlerts";
import timeOperation from "@/utils/timeOperation";
import _ from "lodash";

const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;

// noinspection JSUnusedGlobalSymbols
export default {
  state: {
    pool: new CognitoUserPool({
      UserPoolId: USERPOOL_ID,
      ClientId: CLIENT_ID,
    }),
    authenticating: false,
    currentHomeRoute: "",
    authenticatedUser: null,
    authenticatedUserAttributes: null,
    emailAddress: "",
    loginError: "",
    idToken: null,
    refreshToken: null,
    accessToken: null,
    accessTokenIssuedTime: null,
    accessTokenExpiresTime: null,
    loggedInUser: null,
    isUserLoaded: false,
    isPlatformAdmin: false,
    isAdmin: false,
    inPlatformAdminMode: false,
    inAdminMode: false,
    inHelpMode: false,
    viewedHomeTour: false,
    viewedOrderTour: false,
    isTempPassword: false,
    showFeedbackModal: false,
    showChangePassword: false,
    updateAccessToken: false,
    globalSelectedProductRootId: 0,
  },
  getters: {
    authenticatedAxios(state) {
      return Axios.create({ headers: { Authorization: state.accessToken } });
    },
    unAuthenticatedAxios() {
      return Axios.create();
    },
    isAccessTokenExpiring(state) {
      const thirtyMinutes = 1800;
      const nowEpochTime = Math.floor(Date.now() / 1000);
      const expiresEpochTime = (state.accessTokenExpiresTime ?? 0) - thirtyMinutes;
      return expiresEpochTime < nowEpochTime;
    },
    hasAccessTokenExpired: () => (data) => {
      return (data?.hasOwnProperty("ErrorMessage") ?? false) && String(data.ErrorMessage ?? "") === "Access Token Expired";
    },
    getAccessToken(state) {
      return state.accessToken ?? "";
    },
    isAuthenticated(state) {
      return !state.authenticating && state.authenticatedUser != null;
    },
    loggedInUserHasProducts: (state) => {
      return state.loggedInUser?.UserProducts?.length > 0;
    },
    canLoggedInUserAccessTrulyEsq: (state) => {
      return (state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === TrulyEsqProductRootId) ?? null)?.Enabled ?? false;
    },
    isTrulyEsqDefaultForLoggedInUser: (state) => {
      const trulyEsqUserProduct = state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === TrulyEsqProductRootId) ?? null;
      const linksUserProduct = state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === LinksProductRootId) ?? null;
      const desqUserProduct = state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === DesqProductRootId) ?? null;
      const trulyEsqIsDefault = trulyEsqUserProduct?.DefaultProduct ?? false;
      const linksIsDefault = linksUserProduct?.DefaultProduct ?? false;
      const desqIsDefault = desqUserProduct?.DefaultProduct ?? false;
      const trulyEsqIsEnabled = trulyEsqUserProduct?.Enabled ?? false;
      return trulyEsqIsDefault || (trulyEsqIsEnabled && !(linksIsDefault || desqIsDefault));
    },
    canLoggedInUserAccessLinks: (state) => {
      return (state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === LinksProductRootId) ?? null)?.Enabled ?? false;
    },
    isLinksDefaultForLoggedInUser: (state) => {
      const trulyEsqUserProduct = state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === TrulyEsqProductRootId) ?? null;
      const linksUserProduct = state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === LinksProductRootId) ?? null;
      const desqUserProduct = state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === DesqProductRootId) ?? null;
      const trulyEsqIsDefault = trulyEsqUserProduct?.DefaultProduct ?? false;
      const linksIsDefault = linksUserProduct?.DefaultProduct ?? false;
      const desqIsDefault = desqUserProduct?.DefaultProduct ?? false;
      const linksIsEnabled = linksUserProduct?.Enabled ?? false;
      return linksIsDefault || (linksIsEnabled && !(trulyEsqIsDefault || desqIsDefault));
    },
    canLoggedInUserAccessDesq: (state) => {
      return (state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === DesqProductRootId) ?? null)?.Enabled ?? false;
    },
    isDesqDefaultForLoggedInUser: (state) => {
      const trulyEsqUserProduct = state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === TrulyEsqProductRootId) ?? null;
      const linksUserProduct = state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === LinksProductRootId) ?? null;
      const desqUserProduct = state.loggedInUser?.UserProducts?.find((userProduct) => Number(userProduct.ProductRootId) === DesqProductRootId) ?? null;
      const trulyEsqIsDefault = trulyEsqUserProduct?.DefaultProduct ?? false;
      const linksIsDefault = linksUserProduct?.DefaultProduct ?? false;
      const desqIsDefault = desqUserProduct?.DefaultProduct ?? false;
      const desqIsEnabled = desqUserProduct?.Enabled ?? false;
      return desqIsDefault || (desqIsEnabled && !(trulyEsqIsDefault || linksIsDefault));
    },
    getUserAccessSetting:
      (state) =>
      ({ ProductRootId, SettingName, User }) => {
        const productRootId = Number(ProductRootId ?? 0);
        const settingName = String(SettingName ?? "");
        let settingValue =
          (
            (User?.RootId > 0 ? User : state.loggedInUser)?.UserAccessSettings?.find(
              (userAccessSetting) => Number(userAccessSetting?.ProductRootId ?? 0) === productRootId && String(userAccessSetting?.ProductAccessSettingName ?? "") === settingName
            ) ?? null
          )?.SettingValue ?? "false";
        settingValue += "";
        switch (settingValue.toLowerCase()) {
          case "true":
          case "yes":
          case "1":
            settingValue = "true";
            break;
        }
        return settingValue;
      },
    getUserConfigSetting:
      (state) =>
      ({ ProductRootId, SettingName, User }) => {
        const productRootId = Number(ProductRootId ?? 0);
        const settingName = String(SettingName ?? "");
        return (
          (
            (User?.RootId > 0 ? User : state.loggedInUser)?.UserConfigSettings?.find(
              (userConfigSetting) => Number(userConfigSetting?.ProductRootId ?? 0) === productRootId && String(userConfigSetting?.ProductConfigSettingName ?? "") === settingName
            ) ?? null
          )?.SettingValue ?? ""
        );
      },
    getMergedUserConfigSetting:
      (state) =>
      ({ ProductRootId, SettingName, SettingValue, User }) => {
        const productRootId = Number(ProductRootId ?? 0);
        const settingName = String(SettingName ?? "");
        let settingClone;
        if (User?.RootId > 0) {
          settingClone = _.cloneDeep(
            User?.UserConfigSettings?.find(
              (userConfigSetting) => Number(userConfigSetting?.ProductRootId ?? 0) === productRootId && String(userConfigSetting?.ProductConfigSettingName ?? "") === settingName
            ) ?? null
          );
        } else {
          settingClone = _.cloneDeep(
            state.loggedInUser?.UserConfigSettings?.find(
              (userConfigSetting) => Number(userConfigSetting?.ProductRootId ?? 0) === productRootId && String(userConfigSetting?.ProductConfigSettingName ?? "") === settingName
            ) ?? null
          );
        }
        if (settingClone != null) {
          settingClone.SettingValue = String(SettingValue ?? "");
        }
        return settingClone;
      },
    getOrganizationSetting: (state) => (params) => {
      const productRootId = Number(params?.ProductRootId ?? 0);
      const settingName = String(params?.SettingName ?? "");
      return (
        (
          state.loggedInUser?.OrganizationSettings?.find(
            (organizationSetting) =>
              Number(organizationSetting?.ProductRootId ?? 0) === productRootId && String(organizationSetting?.ProductConfigSettingName ?? "") === settingName
          ) ?? null
        )?.SettingValue ?? ""
      );
    },
    getGlobalSelectedProductRootId: (state) => {
      let productRootId = state.globalSelectedProductRootId;
      if (productRootId < 1) {
        productRootId = (state.authenticatedUser?.UserProducts?.find((userProduct) => userProduct?.DefaultProduct ?? false) ?? null)?.ProductRootId ?? 0;
      }
      if (productRootId < 1) {
        productRootId = (state.authenticatedUser?.UserProducts?.find((userProduct) => userProduct?.Enabled ?? false) ?? null)?.ProductRootId ?? 0;
      }
      return productRootId;
    },
  },
  mutations: {
    setCurrentHomeRoute(state, homeRoute) {
      state.currentHomeRoute = homeRoute ?? "";
    },
    setShowFeedbackModal(state, showFeedbackModal) {
      state.showFeedbackModal = showFeedbackModal ?? false;
    },
    setAuthenticating(state, authenticating) {
      state.authenticating = authenticating ?? false;
    },
    setAuthenticatedUser(state, authenticatedUser) {
      state.authenticatedUser = authenticatedUser;
    },
    setEmailAddress(state, emailAddress) {
      state.emailAddress = emailAddress ?? "";
    },
    setIdToken(state, idToken) {
      state.idToken = idToken;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setAccessTokenIssuedTime(state, accessTokenIssuedTime) {
      state.accessTokenIssuedTime = accessTokenIssuedTime;
    },
    setAccessTokenExpiresTime(state, accessTokenExpiresTime) {
      state.accessTokenExpiresTime = accessTokenExpiresTime;
    },
    setAttributes(state, attributes) {
      state.authenticatedUserAttributes = attributes;
    },
    loginUser(state, user) {
      state.loggedInUser = user;
      state.loginError = "";
      state.isUserLoaded = user != null;
      state.isPlatformAdmin = user?.IsPlatformAdmin ?? false;
      state.isAdmin = (user?.IsPlatformAdmin ?? false) || (user?.IsAdmin ?? false);
    },
    logoutUser(state, loginError) {
      state.loggedInUser = null;
      state.loginError = loginError ?? "";
      state.isUserLoaded = false;
      state.isPlatformAdmin = false;
      state.isAdmin = false;
    },
    resetLoginError(state) {
      state.loginError = "";
    },
    enterPlatformAdminMode(state) {
      state.inPlatformAdminMode = true;
    },
    exitPlatformAdminMode(state) {
      state.inPlatformAdminMode = false;
    },
    enterAdminMode(state) {
      state.inAdminMode = true;
    },
    exitAdminMode(state) {
      state.inAdminMode = false;
    },
    enterHelpMode(state) {
      state.inHelpMode = true;
    },
    exitHelpMode(state) {
      state.inHelpMode = false;
    },
    setShowChangePassword(state, showChangePassword) {
      state.showChangePassword = showChangePassword ?? false;
    },
    setIsTempPassword(state, isTempPassword) {
      state.isTempPassword = isTempPassword ?? false;
    },
    setViewedHomePageTour(state, viewedHomeTour) {
      state.viewedHomeTour = viewedHomeTour ?? false;
    },
    setViewedOrderPageTour(state, viewedOrderTour) {
      state.viewedOrderTour = viewedOrderTour ?? false;
    },
    setUpdateAccessToken(state, updateAccessToken) {
      state.updateAccessToken = updateAccessToken ?? false;
    },
    _updateUserAccessSetting(state, params) {
      if (state.userAccessSettings?.length > 0 && Number(params?.ProductRootId ?? 0) > 0) {
        let userAccessSetting = state.userAccessSettings?.find(
          (userAccessSetting) =>
            Number(userAccessSetting?.ProductRootId ?? 0) === Number(params?.ProductRootId ?? 0) &&
            String(userAccessSetting?.ProductAccessSettingName ?? "") === String(params?.SettingName ?? "")
        );
        if (userAccessSetting && (userAccessSetting.SettingValue ?? false) !== (params?.SettingValue ?? false)) {
          userAccessSetting.SettingValue = params?.SettingValue ?? false;
        }
      }
      if (state.loggedInUser?.UserAccessSettings?.length > 0 && Number(params?.ProductRootId ?? 0) > 0) {
        let userAccessSetting = state.loggedInUser.UserAccessSettings?.find(
          (userAccessSetting) =>
            Number(userAccessSetting?.ProductRootId ?? 0) === Number(params?.ProductRootId ?? 0) &&
            String(userAccessSetting?.ProductAccessSettingName ?? "") === String(params?.SettingName ?? "")
        );
        if (userAccessSetting && (userAccessSetting.SettingValue ?? false) !== (params?.SettingValue ?? false)) {
          userAccessSetting.SettingValue = params?.SettingValue ?? false;
        }
      }
    },
    _updateUserConfigSetting(state, params) {
      if (state.userConfigSettings?.length > 0 && Number(params?.ProductRootId ?? 0) > 0) {
        let userConfigSetting = state.userConfigSettings?.find(
          (userConfigSetting) =>
            Number(userConfigSetting?.ProductRootId ?? 0) === Number(params?.ProductRootId ?? 0) &&
            String(userConfigSetting?.ProductConfigSettingName ?? "") === String(params?.SettingName ?? "")
        );
        if (userConfigSetting && String(userConfigSetting.SettingValue ?? "") !== String(params?.SettingValue ?? "")) {
          userConfigSetting.SettingValue = params?.SettingValue ?? "";
        }
      }
      if (state.loggedInUser?.UserConfigSettings?.length > 0 && Number(params?.ProductRootId ?? 0) > 0) {
        let userConfigSetting = state.loggedInUser.UserConfigSettings?.find(
          (userConfigSetting) =>
            Number(userConfigSetting?.ProductRootId ?? 0) === Number(params?.ProductRootId ?? 0) &&
            String(userConfigSetting?.ProductConfigSettingName ?? "") === String(params?.SettingName ?? "")
        );
        if (userConfigSetting && String(userConfigSetting.SettingValue ?? "") !== String(params?.SettingValue ?? "")) {
          userConfigSetting.SettingValue = params?.SettingValue ?? "";
        }
      }
    },
    setGlobalSelectedProductRootId(state, productRootId) {
      productRootId = Number(productRootId ?? 0);
      if (productRootId < 1) {
        productRootId = (state.authenticatedUser?.UserProducts?.find((userProduct) => userProduct?.DefaultProduct ?? false) ?? null)?.ProductRootId ?? 0;
      }
      if (productRootId < 1) {
        productRootId = (state.authenticatedUser?.UserProducts?.find((userProduct) => userProduct?.Enabled ?? false) ?? null)?.ProductRootId ?? 0;
      }
      state.globalSelectedProductRootId = Number(productRootId ?? 0);
    },
  },
  actions: {
    authenticateUser({ commit, state }, payload) {
      return new Promise((resolve) => {
        commit("setAuthenticating", true);
        commit("setEmailAddress", payload.email);
        const email = payload.email;
        const password = payload.password;
        const newPassword = payload.newPassword ?? "";
        const user = new CognitoUser({
          Username: email,
          Pool: state.pool,
        });
        user.authenticateUser(
          new AuthenticationDetails({
            Username: email,
            Password: password,
          }),
          {
            onFailure: function (error) {
              commit("setAuthenticating", false);
              commit("setEmailAddress", "");
              resolve(error);
            },
            onSuccess: function (session) {
              commit("setAuthenticating", false);
              commit("setAuthenticatedUser", user);
              commit("setIdToken", session.idToken?.jwtToken);
              commit("setRefreshToken", session.refreshToken);
              commit("setAccessToken", session.accessToken?.jwtToken);
              commit("setAccessTokenIssuedTime", session.accessToken?.payload?.auth_time);
              commit("setAccessTokenExpiresTime", session.accessToken?.payload?.exp);
              commit("setIsTempPassword", false);
              resolve(session);
            },
            newPasswordRequired: function (userAttributes, requiredAttributes) {
              commit("setAuthenticating", false);
              if (newPassword.length > 0) {
                delete userAttributes.email_verified; // Immutable field
                user.completeNewPasswordChallenge(newPassword, requiredAttributes, this);
              } else {
                resolve({ message: "New password is required." });
              }
            },
          }
        );
      });
    },
    async getNewAccessToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        try {
          const user = state.pool.getCurrentUser();
          if (user != null) {
            user.getSession((error, session) => {
              const fifteenSeconds = 15;
              const thirtyMinutes = 1800;
              const nowEpochTime = Math.floor(Date.now() / 1000);
              const expiresEpochTime = (state.accessTokenExpiresTime ?? 0) - thirtyMinutes;
              const accessTokenIssuedTimeEpochTime = (state.accessTokenIssuedTime ?? 0) + fifteenSeconds;
              if ((state.updateAccessToken && accessTokenIssuedTimeEpochTime < nowEpochTime) || expiresEpochTime < nowEpochTime || !session.isValid()) {
                user.refreshSession(state.refreshToken, (err, session) => {
                  if (error) {
                    commit("setIdToken", null);
                    commit("setRefreshToken", null);
                    commit("setAccessToken", null);
                    commit("setAccessTokenIssuedTime", null);
                    commit("setAccessTokenExpiresTime", null);
                    reject("Session error");
                    console.log("Failed to get new access token");
                  } else {
                    commit("setIdToken", session.idToken?.jwtToken);
                    commit("setRefreshToken", session.refreshToken);
                    commit("setAccessToken", session.accessToken?.jwtToken);
                    commit("setAccessTokenIssuedTime", session.accessToken?.payload?.auth_time);
                    commit("setAccessTokenExpiresTime", session.accessToken?.payload?.exp);
                    console.log(`New Access Token Issued: NEW TOKEN`); // ${session.accessToken?.jwtToken ?? ""}`);
                    resolve(session);
                  }
                });
                commit("setUpdateAccessToken", false);
              }
            });
          } else {
            resolve();
          }
        } catch (e) {
          // setTimeout is used to make this function async
          setTimeout(async () => {
            await okSweetAlert("", "Your connection to the server was interrupted. Please log back in.");
            const user = state?.pool?.getCurrentUser?.();
            if (user != null) {
              user.signOut();
              commit("setAuthenticatedUser", null);
              commit("setIdToken", null);
              commit("setRefreshToken", null);
              commit("setAccessToken", null);
              commit("setAccessTokenIssuedTime", null);
              commit("setAccessTokenExpiresTime", null);
              commit("setEmailAddress", "");
              commit("logoutUser", "");
            }
            document.location.href = "/login";
          }, 1);
        }
      });
    },
    getUserAttributes({ commit, state }) {
      return new Promise((resolve, reject) => {
        const user = state.pool.getCurrentUser();
        if (user == null) {
          resolve();
        } else {
          user.getSession((error) => {
            if (error) {
              reject(error);
            } else {
              user.getUserAttributes((error, result) => {
                if (error) {
                  reject(error);
                } else {
                  const attributes = {};
                  result.forEach((attribute) => {
                    attributes[attribute.getName()] = attribute.getValue();
                  });
                  commit("setAttributes", attributes);
                  resolve("Attributes fetched");
                }
              });
            }
          });
        }
      });
    },
    changePassword({ state }, payload) {
      return new Promise((resolve) => {
        const currentPassword = payload.currentPassword;
        const newPassword = payload.newPassword;
        const user = state.pool.getCurrentUser();
        if (user == null) {
          resolve("Unauthenticated");
        } else {
          user.getSession((error) => {
            if (error) {
              resolve(error);
            } else {
              user.changePassword(currentPassword, newPassword, (error, result) => {
                if (error) {
                  resolve(error);
                } else {
                  resolve(result);
                }
              });
            }
          });
        }
      });
    },
    forgotPassword({ state }, payload) {
      const email = payload.email;
      const user = new CognitoUser({
        Username: email,
        Pool: state.pool,
      });
      user.forgotPassword({
        onSuccess: (result) => {
          return result;
        },
        onFailure: function (error) {
          simpleSweetAlertWithHeader("Failed to send the verification code.", error.message);
        },
      });
    },
    async confirmPassword({ state }, payload) {
      return await new Promise((resolve) => {
        const email = payload.email;
        const verificationCode = payload.code;
        const newPassword = payload.password;
        const user = new CognitoUser({
          Username: email,
          Pool: state.pool,
        });
        return user.confirmPassword(verificationCode, newPassword, {
          onSuccess: (result) => {
            resolve(result);
            return result;
          },
          onFailure: function (error) {
            simpleSweetAlertWithHeader("Failed to change password.", error.message);
            resolve(error);
            return error;
          },
        });
      });
    },
    signUp({ state }, payload) {
      const email = payload.email;
      const password = payload.password;
      return new Promise((resolve, reject) => {
        const attributes = [];
        if (payload.attributesList) {
          Object.entries(payload.attributesList).forEach((entry) => {
            attributes.push(
              new CognitoUserAttribute({
                Name: String(entry[0] ?? ""),
                Value: Object(entry[1]),
              })
            );
          });
        }
        attributes.push(
          new CognitoUserAttribute({
            Name: "email",
            Value: email,
          })
        );
        state.pool.signUp(email, password, attributes, null, (error, result) => {
          if (error) {
            reject(error);
          } else {
            const user = result.user;
            resolve(user);
          }
        });
      });
    },
    confirmRegistration({ state }, payload) {
      return new Promise((resolve, reject) => {
        const email = payload.email;
        const verificationCode = payload.verificationCode;
        const user = new CognitoUser({
          Username: email,
          Pool: state.pool,
        });
        user.confirmRegistration(verificationCode, true, (error, result) => {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        });
      });
    },
    resendConfirmationCode({ state }, payload) {
      return new Promise((resolve, reject) => {
        const email = payload.email;
        const user = new CognitoUser({
          Username: email,
          Pool: state.pool,
        });
        user.resendConfirmationCode((error, result) => {
          if (error) {
            simpleSweetAlertWithHeader("Failed to resend confirmation code.", "Something went wrong. Please try again.");
            reject(error);
          } else {
            resolve(result);
          }
        });
      });
    },
    signOut({ commit, state }) {
      const user = state.pool.getCurrentUser();
      if (user != null) {
        user.signOut();
        commit("setAuthenticatedUser", null);
        commit("setIdToken", null);
        commit("setRefreshToken", null);
        commit("setAccessToken", null);
        commit("setAccessTokenIssuedTime", null);
        commit("setAccessTokenExpiresTime", null);
        commit("setEmailAddress", "");
        commit("logoutUser", "");
      }
    },
    async getLoggedInUser(context) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: loginUrl, Payload: null });
        if ((data?.Result ?? false) === true) {
          context.commit("loginUser", data);
        } else {
          context.commit("logoutUser", data.ErrorMessage ?? "");
        }
      }, "auth - getLoggedInUser");
    },
    async updateUserAccessSetting(context, userAccessSetting) {
      await timeOperation(async () => {
        const rootId = Number(userAccessSetting?.RootId ?? 0);
        if (rootId > 0) {
          const formattedUrl = `${userAccessSettingsUrl}/${rootId}`;
          const updatedUserAccessSetting = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userAccessSetting });
          context.commit("_updateUserAccessSetting", {
            ProductRootId: updatedUserAccessSetting.ProductRootId ?? 0,
            SettingName: updatedUserAccessSetting.ProductAccessSettingName ?? "",
            SettingValue: updatedUserAccessSetting.SettingValue ?? false,
          });
        }
      }, "auth - updateUserAccessSetting");
    },
    async updateUserConfigSetting(context, userConfigSetting) {
      await timeOperation(async () => {
        const loggedInUser = context?.state?.loggedInUser;
        const NoCache = loggedInUser?.UserRootId !== userConfigSetting?.RootId;
        const rootId = Number(userConfigSetting?.RootId ?? 0);
        if (rootId > 0) {
          const formattedUrl = `${userConfigSettingsUrl}/${rootId}`;
          const updatedUserConfigSetting = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userConfigSetting });
          if (!NoCache) {
            context.commit("_updateUserConfigSetting", {
              ProductRootId: updatedUserConfigSetting.ProductRootId ?? 0,
              SettingName: updatedUserConfigSetting.ProductConfigSettingName ?? "",
              SettingValue: updatedUserConfigSetting.SettingValue ?? "",
            });
          }
        }
      }, "auth - updateUserConfigSetting");
    },
    async apiRequest(context, { Verb, FormattedUrl, Payload }) {
      let data = null;
      try {
        if (FormattedUrl?.length > 0) {
          if (context.getters.isAccessTokenExpiring) {
            context.commit("setUpdateAccessToken", true);
            await context.dispatch("getNewAccessToken");
          }
          switch (String(Verb ?? "").toLowerCase()) {
            case GetVerb:
              data = (await context.getters.authenticatedAxios.get(FormattedUrl)).data;
              break;
            case PostVerb:
              data = (await context.getters.authenticatedAxios.post(FormattedUrl, Payload)).data;
              break;
            case PutVerb:
              data = (await context.getters.authenticatedAxios.put(FormattedUrl, Payload)).data;
              break;
            case DeleteVerb:
              data = (await context.getters.authenticatedAxios.delete(FormattedUrl, Payload)).data;
              break;
          }
          if (context.getters.hasAccessTokenExpired(data)) {
            context.commit("setUpdateAccessToken", true);
            await context.dispatch("getNewAccessToken");
            switch (String(Verb ?? "").toLowerCase()) {
              case GetVerb:
                data = (await context.getters.authenticatedAxios.get(FormattedUrl)).data;
                break;
              case PostVerb:
                data = (await context.getters.authenticatedAxios.post(FormattedUrl, Payload)).data;
                break;
              case PutVerb:
                data = (await context.getters.authenticatedAxios.put(FormattedUrl, Payload)).data;
                break;
              case DeleteVerb:
                data = (await context.getters.authenticatedAxios.delete(FormattedUrl, Payload)).data;
                break;
            }
          }
        }
        if (data?.ErrorMessage) {
          console.warn(`Error with request: ${data?.ErrorMessage || ""}`);
        }
        return data;
      } catch (e) {
        console.warn(`Error with request: ${e}`);
        return data;
      }
    },
  },
};
