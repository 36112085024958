export default {
  state: {
    contextMenuOpenFunction: (x, y) => {
      console.warn("No open function set for context menu");
      console.log(x, y);
    },
    beforeContextMenuOpenFunction: () => {},
    contextMenuItemSelectedFunction: (item) => {
      console.warn("No item selected function set for context menu");
      console.log(item);
    },
    closeContextMenuFunction: () => {
      console.warn("No close function set for context menu");
    },
    contextMenuTargetElementTop: -1,
    contextMenuTargetElementLeft: -1,
  },
  mutations: {
    setContextMenuOpenFunction(state, openFunction) {
      state.contextMenuOpenFunction = openFunction;
    },
    setCloseContextMenuFunction(state, closeFunction) {
      state.closeContextMenuFunction = closeFunction;
    },
    setBeforeContextMenuOpenFunction(state, beforeOpenFunction) {
      state.beforeContextMenuOpenFunction = beforeOpenFunction;
    },
    setContextMenuItemSelectedFunction(state, itemSelectedFunction) {
      state.contextMenuItemSelectedFunction = itemSelectedFunction;
    },
    setTargetElementTop(state, top) {
      state.contextMenuTargetElementTop = top;
    },
    setTargetElementLeft(state, left) {
      state.contextMenuTargetElementLeft = left;
    },
  },
};
