import {ApiRequest, GetVerb, PutVerb, organizationCustomerDataSourceFieldAccessSettingsUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationCustomerDataSourceFieldAccessSettings: [],
  },
  getters: {
  },
  mutations: {
    setOrganizationCustomerDataSourceFieldAccessSettings(state, organizationCustomerDataSourceFieldAccessSettings) {
      state.organizationCustomerDataSourceFieldAccessSettings = organizationCustomerDataSourceFieldAccessSettings ?? [];
    },
    _updateOrganizationCustomerDataSourceFieldAccessSetting(state, updateOrganizationCustomerDataSourceFieldAccessSetting) {
      let organizationCustomerDataSourceFieldAccessSetting = state.organizationCustomerDataSourceFieldAccessSettings?.find((organizationCustomerDataSourceFieldAccessSetting) => Number(organizationCustomerDataSourceFieldAccessSetting?.OrganizationCustomerDataSourceFieldAccessSettingRootId ?? 0) === Number(updateOrganizationCustomerDataSourceFieldAccessSetting?.RootId ?? 0)) ?? null;
      if (!(organizationCustomerDataSourceFieldAccessSetting?.RootId > 0)) {
        organizationCustomerDataSourceFieldAccessSetting = state.organizationCustomerDataSourceFieldAccessSettings?.find((organizationCustomerDataSourceFieldAccessSetting) =>
          (Number(organizationCustomerDataSourceFieldAccessSetting?.OrganizationDataSourceRootId ?? 0) === Number(updateOrganizationCustomerDataSourceFieldAccessSetting?.OrganizationDataSourceRootId ?? 0)) &&
          (Number(organizationCustomerDataSourceFieldAccessSetting?.RootId ?? 0) === Number(updateOrganizationCustomerDataSourceFieldAccessSetting?.OrganizationDataSourceFieldRootId ?? 0))) ?? null;
      }
      if (organizationCustomerDataSourceFieldAccessSetting?.RootId > 0) {
        organizationCustomerDataSourceFieldAccessSetting.Hide = updateOrganizationCustomerDataSourceFieldAccessSetting?.Hide ?? false;
        organizationCustomerDataSourceFieldAccessSetting.Obfuscate = updateOrganizationCustomerDataSourceFieldAccessSetting?.Obfuscate ?? false;
      }
    },
  },
  actions: {
    async getOrganizationCustomerDataSourceFieldAccessSettings(context, { OrganizationRootId, OrganizationDataSourceRootId, IncludePossible, IncludeMetadata }) {
      OrganizationRootId = Number(OrganizationRootId ?? 0);
      OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0);
      IncludePossible = IncludePossible ?? false;
      IncludeMetadata = IncludeMetadata ?? true;
      await timeOperation(async () => {
        context.commit("setOrganizationCustomerDataSourceFieldAccessSettings", { OrganizationDataSourceRootId, Data: [] });
        const formattedUrl = `${organizationCustomerDataSourceFieldAccessSettingsUrl}?organizationrootid=${OrganizationRootId}&organizationdatasourcerootid=${OrganizationDataSourceRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationCustomerDataSourceFieldAccessSettings", data);
      }, "organizationCustomerDataSourceFieldAccessSettings - getOrganizationCustomerDataSourceFieldAccessSettings");
    },
    async updateOrganizationCustomerDataSourceFieldAccessSetting(context, organizationCustomerDataSourceFieldAccessSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationCustomerDataSourceFieldAccessSettingsUrl}/${Number(organizationCustomerDataSourceFieldAccessSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationCustomerDataSourceFieldAccessSetting });
        context.commit("_updateOrganizationCustomerDataSourceFieldAccessSetting", data);
      }, "organizationCustomerDataSourceFieldAccessSettings - updateOrganizationCustomerDataSourceFieldAccessSetting");
    },
  },
};
