import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productCustomUserFieldsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productDataSourceCustomUserFields: {},
  },
  getters: {
    getProductCustomUserFieldByRootId: (state) => (rootId) => {
      const productCustomUserFields = _.reduce(
        state.productDataSourceCustomUserFields,
        (result, listOfProductCustomUserFields) => {
          if (listOfProductCustomUserFields?.length > 0) {
            listOfProductCustomUserFields.forEach((productCustomUserField) => {
              result.push(productCustomUserField);
            });
          }
          return result;
        },
        []
      );
      const productCustomUserField =
        productCustomUserFields.find((productCustomUserField) => Number(productCustomUserField?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
      return productCustomUserField?.RootId > 0 ? _.cloneDeep(productCustomUserField) : null;
    },
  },
  mutations: {
    setProductDataSourceCustomUserFields(state, { ProductDataSourceRootId, Data }) {
      if (ProductDataSourceRootId > 0) {
        state.productDataSourceCustomUserFields[ProductDataSourceRootId] = Data?.sort((pcuf1, pcuf2) => (pcuf1.CustomUserFieldIndex > pcuf2.CustomUserFieldIndex ? 1 : -1)) ?? [];
      } else {
        let productDataSourceCustomUserFields = {};
        Data?.forEach((productCustomUserField) => {
          const productDataSourceRootId = Number(productCustomUserField?.ProductDataSourceRootId ?? 0);
          if (productDataSourceRootId > 0) {
            if (!productDataSourceCustomUserFields.hasOwnProperty(productDataSourceRootId)) {
              productDataSourceCustomUserFields[productDataSourceRootId] = [];
            }
            productDataSourceCustomUserFields[productDataSourceRootId].push(_.cloneDeep(productCustomUserField));
          }
        });
        state.productDataSourceCustomUserFields = productDataSourceCustomUserFields;
      }
    },
    clearProductDataSourceCustomUserFields(state) {
      state.productDataSourceCustomUserFields = {};
    },
    _addProductCustomUserField(state, productCustomUserField) {
      if (productCustomUserField?.RootId > 0) {
        const productDataSourceRootId = Number(productCustomUserField?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomUserFields.hasOwnProperty(productDataSourceRootId)) {
          state.productDataSourceCustomUserFields[productDataSourceRootId].push(productCustomUserField);
          state.productDataSourceCustomUserFields[productDataSourceRootId] =
            state.productDataSourceCustomUserFields[productDataSourceRootId].sort((pcuf1, pcuf2) => (pcuf1.CustomUserFieldIndex > pcuf2.CustomUserFieldIndex ? 1 : -1)) ?? [];
        } else {
          state.productDataSourceCustomUserFields[productDataSourceRootId] = [productCustomUserField];
        }
      }
    },
    _updateProductCustomUserField(state, updateProductCustomUserField) {
      const rootId = Number(updateProductCustomUserField?.RootId ?? 0);
      if (rootId > 0) {
        const productDataSourceRootId = Number(updateProductCustomUserField?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomUserFields.hasOwnProperty(productDataSourceRootId)) {
          let productCustomUserFieldsData = _.cloneDeep(state.productDataSourceCustomUserFields[productDataSourceRootId]) ?? [];
          productCustomUserFieldsData = [...productCustomUserFieldsData.filter((productCustomUserField) => Number(productCustomUserField?.RootId ?? 0) !== rootId), _.cloneDeep(updateProductCustomUserField)] ?? [];
          state.productDataSourceCustomUserFields[productDataSourceRootId] = (_.map(productCustomUserFieldsData, (productCustomUserField) => { return { ...productCustomUserField, CanEdit: Number(productCustomUserField.ProductCustomUserFieldRootId ?? 0) < 1 }}) ?? []).sort((pcuf1, pcuf2) => (pcuf1.CustomUserFieldIndex > pcuf2.CustomUserFieldIndex ? 1 : -1)) ?? [];
        } else {
          state.productDataSourceCustomUserFields[productDataSourceRootId] = [updateProductCustomUserField];
        }
      }
    },
    _updateProductCustomUserFieldEnabled(state, updateProductCustomUserField) {
      const rootId = Number(updateProductCustomUserField?.RootId ?? 0);
      if (rootId > 0) {
        const productDataSourceRootId = Number(updateProductCustomUserField?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomUserFields.hasOwnProperty(productDataSourceRootId)) {
          let productCustomUserField = state.productDataSourceCustomUserFields[productDataSourceRootId]?.find((productCustomUserField) => Number(productCustomUserField?.RootId ?? 0) === rootId) ?? null;
          if (productCustomUserField?.RootId > 0) {
            productCustomUserField.Enabled = updateProductCustomUserField.Enabled;
          }
        }
      }
    },
    _removeProductCustomUserField(state, deleteProductCustomUserField) {
      const rootId = Number(deleteProductCustomUserField?.RootId ?? 0);
      if (rootId > 0) {
        const productDataSourceRootId = Number(deleteProductCustomUserField?.ProductDataSourceRootId ?? 0);
        if (state.productDataSourceCustomUserFields.hasOwnProperty(productDataSourceRootId)) {
          let productCustomUserFieldsData = _.cloneDeep(state.productDataSourceCustomUserFields[productDataSourceRootId]) ?? [];
          productCustomUserFieldsData =
            [...productCustomUserFieldsData.filter((productCustomUserField) => Number(productCustomUserField?.RootId ?? 0) !== rootId)] ?? [];
          state.productDataSourceCustomUserFields[productDataSourceRootId] =
            (
              _.map(productCustomUserFieldsData, (productCustomUserField) => {
                return { ...productCustomUserField, CanEdit: Number(productCustomUserField.ProductCustomUserFieldRootId ?? 0) < 1 };
              }) ?? []
            ).sort((pcuf1, pcuf2) => (pcuf1.CustomUserFieldIndex > pcuf2.CustomUserFieldIndex ? 1 : -1)) ?? [];
        }
      }
    },
  },
  actions: {
    async getProductCustomUserFields(context, { ProductRootId, ProductDataSourceRootId, UseCache, CanUseServerCache }) {
      CanUseServerCache = CanUseServerCache || true;
      const isCached = UseCache && context.state.productDataSourceCustomUserFields?.hasOwnProperty(ProductDataSourceRootId);
      const hasProductsCustomUserFields =
        isCached &&
        context.state.productDataSourceCustomUserFields[ProductDataSourceRootId]?.find(
          (productCustomUserField) => Number(productCustomUserField?.ProductRootId ?? 0) === Number(ProductRootId ?? 0)
        );
      if (!hasProductsCustomUserFields) {
        await timeOperation(async () => {
          context.commit("setProductDataSourceCustomUserFields", { ProductDataSourceRootId, Data: [] });
          const formattedUrl = `${productCustomUserFieldsUrl}?productrootid=${ProductRootId}&productdatasourcerootid=${ProductDataSourceRootId}&canusecache=${CanUseServerCache}&includemetadata=true`;
          const Data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setProductDataSourceCustomUserFields", { ProductDataSourceRootId, Data });
        }, "productCustomUserFields - getProductCustomUserFields");
      }
    },
    async addProductCustomUserField(context, productCustomUserField) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productCustomUserFieldsUrl, Payload: productCustomUserField });
        if (data?.RootId > 0) {
          productCustomUserField.RootId = data.RootId;
          context.commit("_addProductCustomUserField", data);
        }
        return data;
      }, "productCustomUserFields - addProductCustomUserField");
    },
    async updateProductCustomUserField(context, productCustomUserField) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomUserFieldsUrl}/${Number(productCustomUserField?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productCustomUserField });
        context.commit("_updateProductCustomUserField", data);
      }, "productCustomUserFields - updateProductCustomUserField");
    },
    async enableProductCustomUserField(context, productCustomUserField) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomUserFieldsUrl}/${Number(productCustomUserField?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...productCustomUserField, Enabled: true } });
        context.commit("_updateProductCustomUserFieldEnabled", data);
      }, "productCustomUserFields - enableProductCustomUserField");
    },
    async disableProductCustomUserField(context, productCustomUserField) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomUserFieldsUrl}/${Number(productCustomUserField?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...productCustomUserField, Enabled: false } });
        context.commit("_updateProductCustomUserFieldEnabled", data);
      }, "productCustomUserFields - disableProductCustomUserField");
    },
    async removeProductCustomUserField(context, productCustomUserField) {
      await timeOperation(async () => {
        const formattedUrl = `${productCustomUserFieldsUrl}/${Number(productCustomUserField?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductCustomUserField", productCustomUserField);
      }, "productCustomUserFields - removeProductCustomUserField");
    },
    async applyProductCustomUserField(context, appliedCustomUserField) {
      let res = null;
      await timeOperation(async () => {
        const formattedUrl = `${productCustomUserFieldsUrl}/setcustomuserfield`;
        res = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: appliedCustomUserField });
      }, "productCustomUserFields - applyProductCustomUserField");
      return res?.Result ?? false;
    },
  },
};
