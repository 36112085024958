<!--suppress JSDeprecatedSymbols -->
<template>
  <div class="">
    <div class="nav-header-home">
      <NavLogo
        :imageClasses="headerImageStyle"
        :imageSrc="getHeaderImage"
        :menuItems="logoDropdownContextMenuItems"
        :showRecentHistory="true"
        @menuItemSelected="handleMenuItemSelected"
        @tabSelected="(tab) => emit('tabSelected', tab)"
      />
      <FormViewerModal
        v-model:show="showCreateNewOrderModal"
        :modal-type="CreateNewOrderModalFormLocationName"
        v-model:formSaveData="localFormSaveData"
        :order="orderBeingCreated"
        @save="handleSaveNewOrder"
      />
      <div v-if="!isOnSettingsPage">
        <div>
          <QuickSearch
            v-model:options="autoCompleteOptions"
            :format-function="quickSearchResult"
            @search="handleQuickSearch"
            @update:quickSearchOrderNumber="(value) => emit('update:quickSearchOrderNumber', value)"
            @update:quickSearchOrderRootId="(value) => emit('update:quickSearchOrderRootId', value)"
            @update:customSearchText="(value) => emit('update:customSearchText', value)"
            @update:loadQuickSearch="(value) => emit('update:loadQuickSearch', value)"
          />
        </div>
      </div>
      <div v-if="canCreateViews && canViewAdvancedSearch && !isOnSettingsPage" class="w-80">
        <a @click="emit('advancedSearchClicked')" class="cursor-pointer">
          <svg
            v-tooltip="`Advanced`"
            class="w-5 h-5 py-0.5 home-screen-icon"
            data-slot="icon"
            fill="none"
            stroke-width="1.5"
            stroke="white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6"></path>
          </svg>
        </a>
      </div>
      <a v-if="isOnSettingsPage" @click="onHomeButtonClick" class="cursor-pointer select-none mr-2">
        <div class="flex mr-2" style="color: #34406b">
          <svg
            class="w-5 h-5 ml-1 mt-0.5 pt-0.5 home-screen-icon"
            data-slot="icon"
            fill="none"
            stroke-width="1.5"
            stroke="white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"></path>
          </svg>
          <div class="btn btn-secondary py-0 text-nowrap ml-2">
            <div class="my-0.5">Back Home</div>
          </div>
        </div>
      </a>
      <div class="w-80 flex justify-content-end align-content-end"></div>
      <div class="w-full flex justify-content-end align-content-end relative top-1.5">
        <div v-if="showDeliverProduct">
          <a @click="handleDeliverProductClicked" class="cursor-pointer select-none mr-2 relative top-1.5">
            <div class="flex mr-2" style="color: #34406b">
              <div class="btn btn-secondary py-0 text-nowrap ml-2">
                <div class="my-0.5">Deliver Product</div>
              </div>
              <svg
                class="w-5 h-5 ml-1 mt-0.5 pt-0.5 home-screen-icon"
                data-slot="icon"
                fill="none"
                stroke-width="1.5"
                stroke="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12"
                ></path>
              </svg>
            </div>
          </a>
        </div>
        <div v-if="showPlaceNewOrder" class="relative top-1.5">
          <a @click="handlePlaceNewOrder" class="cursor-pointer select-none mr-2">
            <div class="flex" style="color: #34406b">
              <div class="btn btn-secondary py-0 text-nowrap ml-2">
                <div class="my-0.5">New Service Order</div>
              </div>
              <svg
                class="w-5 h-5 ml-1 mt-0.5 pt-0.5 home-screen-icon"
                style="margin-top: 1px"
                data-slot="icon"
                fill="none"
                stroke-width="1.5"
                stroke="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                ></path>
              </svg>
            </div>
          </a>
        </div>
        <div class="flex" :class="!showDeliverProduct && !showPlaceNewOrder ? 'relative bottom-1.5' : ''">
          <div style="color: #ffffff !important" class="mt-1.5 ml-4 text-nowrap">{{ loggedInUserFirstName }}</div>
          <div>
            <UserLoginStatus />
          </div>
        </div>
      </div>
    </div>
    <LogoModal
      v-model:show="showFormDataModal"
      :show-desq-logo="isInDesq"
      :show-truly-esq-logo="isInTrulyEsq"
      :buttons="confirmModalButtons"
      @buttonClicked="handleFormDataModalButtonClicked"
    >
      <div style="width: 25rem" class="select-text">
        <div v-for="(item, index) in confirmItems" :key="index" :class="item.classes">
          {{ item.text }}
          <div v-if="index < confirmItems.length - 1">
            <br />
          </div>
        </div>
      </div>
    </LogoModal>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import _ from "lodash";

import FormViewerModal from "@/components/admin/modals/FormViewerModal.vue";
import QuickSearch from "@/components/uIElements/QuickSearch.vue";
import UserLoginStatus from "@/components/UserLoginStatus";
import NavLogo from "@/components/navbars/NavLogo.vue";

import {
  TrulyEsqProductRootId,
  DesqProductRootId,
  LinksProductRootId,
  OrdersTypeCanViewDebugInfoAccessKey,
  DocumentsTypeCanViewDebugInfoAccessKey,
  OrdersTypeCanPlaceDocumentOrdersAccessKey,
  CanCreateViewsAccessKey,
  OrdersTypeCanPlaceTitleOrdersAccessKey,
  CanViewAdvancedSearchAccessKey,
  IncludeExternalOrderNumbersInQuickSearchConfigKey,
  CreateNewOrderModalFormLocationName,
  OpenNewlyCreatedOrdersConfigKey,
} from "@/lib/settings";
import getOrdersGridLink from "@/utils/navigation/getOrdersGridLink.js";
import LogoModal from "@/components/uIElements/LogoModal.vue";

const CREATE_NEW_ORDER = "Create a New Order";
export default {
  name: "OrdersNavbar",
  components: {
    LogoModal,
    QuickSearch,
    UserLoginStatus,
    NavLogo,
    FormViewerModal,
  },
  emits: ["update:quickSearchOrderNumber", "update:quickSearchOrderRootId", "update:customSearchText", "update:loadQuickSearch", "advancedSearchClicked", "tabSelected"],
  setup(_props, { emit }) {
    const router = useRouter();
    const route = useRoute();

    // Vuex
    const store = useStore();
    // State
    const selectedOrderEditorRootId = computed(() => store.state.srchs.selectedOrderEditorRootId);
    const orderNumbers = computed(() => store.state.ords.orderNumbers);
    const loggedInUser = computed(() => store.state.auth.loggedInUser);
    // Getters
    const getUserConfigSetting = computed(() => store.getters.getUserConfigSetting);
    function getBooleanConfigSetting(settingName) {
      return Boolean(String(getUserConfigSetting.value({ ProductRootId: getGlobalSelectedProductRootId.value, SettingName: settingName })) === "true");
    }
    const getUserAccessSetting = computed(() => store.getters.getUserAccessSetting);
    const getOrganizationSetting = computed(() => store.getters.getOrganizationSetting);
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    function getBooleanAccessSetting(productRootId, settingName) {
      return Boolean(String(getUserAccessSetting.value({ ProductRootId: productRootId, SettingName: settingName })) === "true");
    }
    const canViewDebugInfo = computed(() => {
      const productRootId = getGlobalSelectedProductRootId.value;
      switch (productRootId) {
        case TrulyEsqProductRootId:
        case DesqProductRootId:
          return getBooleanAccessSetting(productRootId, OrdersTypeCanViewDebugInfoAccessKey);
        case LinksProductRootId:
          return getBooleanAccessSetting(productRootId, DocumentsTypeCanViewDebugInfoAccessKey);
      }
      return false;
    });
    const canCreateViews = computed(() => getBooleanAccessSetting(getGlobalSelectedProductRootId.value, CanCreateViewsAccessKey));
    const canViewAdvancedSearch = computed(() => getBooleanAccessSetting(getGlobalSelectedProductRootId.value, CanViewAdvancedSearchAccessKey));

    // Mutations
    const resetProductDocuments = () => store.commit("setProductDocuments", []);
    const setIsSearchDocumentSelectedFalse = () => store.commit("setIsSearchDocumentSelected", false);
    // Actions
    const loadOperation = async (Operation, Message, ShowAsError = false, HiddenLoading = false) =>
      await store.dispatch("loadOperation", { Operation, Message, Caller: "OrdersNavbar", ShowAsError, HiddenLoading });
    const getConfirmCreateFormDataOrderDetails = async () =>
      await store.dispatch("getConfirmCreateFormDataOrderDetails", {
        FormLayoutRootId: Number(localFormSaveData.value?.FormLayoutRootId || 0),
        FormData: localFormSaveData.value?.FormData,
      });
    const cancelFormDataOrder = async () =>
      await store.dispatch("cancelFormDataOrder", { FormLayoutRootId: Number(localFormSaveData.value?.FormLayoutRootId || 0), FormData: localFormSaveData.value?.FormData });
    const createFormDataOrder = async () =>
      await store.dispatch("createFormDataOrder", { FormLayoutRootId: Number(localFormSaveData.value?.FormLayoutRootId || 0), FormData: localFormSaveData.value?.FormData });
    const getOrderNumbersNameSearchData = async (params) => await store.dispatch("getOrderNumbersNameSearchData", params);
    const getTemporaryOrder = async () => await store.dispatch("getTemporaryOrder", { FormLayoutRootId: Number(localFormSaveData.value?.FormLayoutRootId || 0) });

    function getBooleanOrganizationConfigSetting(productRootId, settingName) {
      return Boolean(String(getOrganizationSetting.value({ ProductRootId: productRootId, SettingName: settingName })) === "true");
    }

    const includeExternalOrderNumbers = computed(() =>
      getBooleanOrganizationConfigSetting(getGlobalSelectedProductRootId.value, IncludeExternalOrderNumbersInQuickSearchConfigKey)
    );

    const isInDesq = computed(() => getGlobalSelectedProductRootId.value === DesqProductRootId);
    const isInTrulyEsq = computed(() => getGlobalSelectedProductRootId.value === TrulyEsqProductRootId);

    const orderBeingCreated = ref(null);
    const localFormSaveData = ref({});
    const autoCompleteOptions = ref([]);
    const inputText = ref("");
    const showCreateNewOrderModal = ref(false);

    const logoDropdownContextMenuItems = computed(() => {
      return [
        {
          text: CREATE_NEW_ORDER,
          iconCss: "e-folder-open e-icons relative top-1",
          enabled: true,
        },
      ];
    });
    const isOnSettingsPage = computed(() => route.fullPath.includes("user-settings"));
    const canPlaceOrders = computed(() => {
      const productRootId = getGlobalSelectedProductRootId.value;
      switch (productRootId) {
        case TrulyEsqProductRootId:
        case DesqProductRootId:
          return (
            getBooleanAccessSetting(productRootId, OrdersTypeCanPlaceDocumentOrdersAccessKey) || getBooleanAccessSetting(productRootId, OrdersTypeCanPlaceTitleOrdersAccessKey)
          );
        case LinksProductRootId:
          break;
      }
      return false;
    });
    const isOnTrulyEsq = computed(() => selectedProduct.value === "trulyesq");
    const isOnDesq = computed(() => selectedProduct.value === "desq");
    const selectedProduct = computed(() => {
      const hostName = window.location.host || "";
      const fullPath = route.fullPath.toLowerCase();
      if (hostName.includes("links") || fullPath.includes("links")) {
        return "links";
      }
      if (hostName.includes("truly-esq") || hostName.includes("trulyesq") || fullPath.includes("truly-esq") || fullPath.includes("trulyesq")) {
        return "trulyesq";
      }
      return "desq";
    });
    const canDeliverProduct = computed(() => loggedInUser.value?.CanDeliverProducts || false);
    const loggedInUserFirstName = computed(() => {
      let firstName = loggedInUser.value?.DisplayName?.split(" ")[0] || "";
      if (firstName.length > 0) {
        return `Welcome, ${firstName}`;
      }
      return "Welcome!";
    });
    const showPlaceNewOrder = computed(() => (isOnDesq.value || isOnTrulyEsq.value) && canPlaceOrders.value && !isOnSettingsPage.value);
    const showDeliverProduct = computed(() => canDeliverProduct.value && !isOnSettingsPage.value);
    const confirmModalButtons = computed(() => [
      {
        Id: 1,
        text: "Cancel",
        cssClass: "btn btn-secondary m-1",
        type: "cancel",
      },
      {
        Id: 2,
        text: "Create New Order",
        cssClass: "btn btn-primary m-1",
        type: "save",
      },
    ]);

    async function quickSearchInputChanged(input) {
      inputText.value = input || "";
      await getOrderNumbersNameSearchData({
        ProductRootId: getGlobalSelectedProductRootId.value,
        OrganizationRootId: Number(loggedInUser.value?.OrganizationRootId || 0),
        SearchText: String(inputText.value || ""),
        IncludeExternalOrderNumbers: includeExternalOrderNumbers.value,
        IncludeLinksOrderNumbers: false,
        IncludeDebugData: canViewDebugInfo.value,
      });
      return _.uniq(orderNumbers.value || []);
    }
    async function handleQuickSearch(searchText) {
      autoCompleteOptions.value = await quickSearchInputChanged(searchText);
    }
    function quickSearchResult(args) {
      return args?.OrderNumber || "";
    }
    async function onHomeButtonClick() {
      setIsSearchDocumentSelectedFalse();
      const productRootId = getGlobalSelectedProductRootId.value;
      switch (productRootId) {
        case TrulyEsqProductRootId:
        case DesqProductRootId:
          await router.push(getOrdersGridLink(productRootId)).catch(() => {
            console.error("Unable to open orders grid");
          });
          break;
        default:
          await router.push({ path: "/links" }).catch(() => {
            console.error("Unable to open links");
          });
          break;
      }
    }
    function handleDeliverProductClicked() {
      if (selectedOrderEditorRootId.value < 1) {
        resetProductDocuments();
      }
      router.push("/deliver-documents");
    }

    function handlePlaceNewOrder() {
      if (isOnTrulyEsq.value) {
        router.push({ name: "truly-esq-place-order" });
      } else if (isOnDesq.value) {
        router.push({ name: "desq-place-order" });
      }
    }

    const getHeaderImage = computed(() => {
      if (isOnTrulyEsq.value) {
        return require("../../assets/images/truly-esq-logo-white.png");
      } else {
        return require("../../assets/images/d-esq-logo-white.png");
      }
    });

    const headerImageStyle = computed(() => {
      if (isOnTrulyEsq.value) {
        return "home-screen-header-image-esq";
      } else {
        return "home-screen-header-image-desq";
      }
    });

    async function handleMenuItemSelected(item) {
      switch (item.text) {
        case CREATE_NEW_ORDER:
          showCreateNewOrderModal.value = true;
          orderBeingCreated.value = await getTemporaryOrder();
          break;
      }
    }
    async function handleSaveNewOrder() {
      await loadOperation(async () => {
        showCreateNewOrderModal.value = false;
        orderDetails.value = await getConfirmCreateFormDataOrderDetails();
        showFormDataModal.value = true;
      }, "Validating");
    }
    async function handleCreateNewOrder() {
      await loadOperation(async () => {
        const order = await createFormDataOrder();
        const orderData = order?.Data;
        if (order?.Result && orderData) {
          if (getBooleanConfigSetting(OpenNewlyCreatedOrdersConfigKey)) emit("tabSelected", orderData);
        } else {
          console.error(order?.ErrorMessage);
        }
      }, "Creating New Order");
    }
    async function handleCancelNewOrder() {
      await loadOperation(async () => {
        await cancelFormDataOrder();
      }, "Canceling New Order");
    }
    const showFormDataModal = ref(false);
    const orderDetails = ref(null);
    const confirmItems = computed(() => {
      let result = [];
      result.push({
        text: `Placed By:  ${orderDetails.value.CreatedByUserDisplayName ?? ""}`,
        classes: "font-semibold",
      });
      result.push({
        text: `Order Number:  ${orderDetails.value.OrderNumber ?? ""}`,
        classes: "font-semibold",
      });
      result.push({
        text: `Buyer:  ${orderDetails.value.Buyer ?? ""}`,
        classes: "font-semibold",
      });
      result.push({
        text: `Seller:  ${orderDetails.value.Seller ?? ""}`,
        classes: "font-semibold",
      });
      result.push({
        text: `Address:  ${orderDetails.value.FormattedPropertyAddress ?? ""}`,
        classes: "font-semibold",
      });
      result.push({
        text: `Uploaded Documentation:`,
        classes: "font-semibold",
      });
      result.push({
        text: orderDetails.value.AttachmentNames?.length > 0 ? orderDetails.value.AttachmentNames : "None",
        classes: "font-semibold ml-4",
      });
      return result;
    });
    async function handleFormDataModalButtonClicked(button) {
      showFormDataModal.value = false;
      switch ((button?.type ?? "").toLowerCase()) {
        case "save":
          await handleCreateNewOrder();
          break;
        default:
        case "cancel":
          await handleCancelNewOrder();
          break;
      }
    }

    return {
      CreateNewOrderModalFormLocationName,

      emit,

      autoCompleteOptions,
      localFormSaveData,

      canDeliverProduct,
      getHeaderImage,
      isOnTrulyEsq,
      headerImageStyle,
      loggedInUserFirstName,
      isOnDesq,
      canCreateViews,
      canViewAdvancedSearch,
      canPlaceOrders,
      isOnSettingsPage,
      showPlaceNewOrder,
      showDeliverProduct,
      logoDropdownContextMenuItems,
      showCreateNewOrderModal,
      confirmItems,
      isInDesq,
      isInTrulyEsq,
      orderBeingCreated,

      handleFormDataModalButtonClicked,
      confirmModalButtons,
      showFormDataModal,
      handlePlaceNewOrder,
      handleQuickSearch,
      handleDeliverProductClicked,
      onHomeButtonClick,
      quickSearchResult,
      handleMenuItemSelected,
      handleSaveNewOrder,
    };
  },
};
</script>
