import ZipToTimezone from "zipcode-to-timezone";
import dayjs from "dayjs";
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);

export default function (order) {
  if (!order) {
    return dayjs.tz.guess();
  }
  const timezone = (order?.PropertyZip?.length > 0 ? ZipToTimezone.lookup(order.PropertyZip) : dayjs.tz.guess()) ?? "";
  const safeTimezone = timezone?.length > 0 ? timezone : dayjs.tz.guess();
  return safeTimezone;
}
