export default {
  state: {
    customSearchText: "",
    loadQuickSearch: false,
  },
  mutations: {
    setCustomSearchText(state, newCustomSearchText) {
      state.customSearchText = newCustomSearchText;
    },
    setLoadQuickSearch(state, newloadQuickSearch) {
      state.loadQuickSearch = newloadQuickSearch;
    },
  },
};
