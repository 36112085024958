import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, userNotificationSettingsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    userNotificationSettings: [],
  },
  getters: {
    getUserNotificationSettingByRootId: (state) => (rootId) => {
      return state.userNotificationSettings.find((userNotificationSetting) => Number(userNotificationSetting?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
  },
  mutations: {
    setUserNotificationSettingData(state, userNotificationSettings) {
      state.userNotificationSettings = userNotificationSettings ?? [];
    },
    clearUserNotificationSettings(state) {
      state.userNotificationSettings = [];
    },
    _addUserNotificationSetting(state, userNotificationSetting) {
      const userNotificationSettingsData = _.cloneDeep(state.userNotificationSettings) ?? [];
      userNotificationSettingsData.push(userNotificationSetting);
      state.userNotificationSettings = userNotificationSettingsData ?? [];
    },
    _updateUserNotificationSetting(state, updateUserNotificationSetting) {
      const rootId = Number(updateUserNotificationSetting?.RootId ?? 0);
      if (rootId > 0) {
        let userNotificationSettingsData = _.cloneDeep(state.userNotificationSettings) ?? [];
        userNotificationSettingsData = [...userNotificationSettingsData.filter(userNotificationSetting => Number(userNotificationSetting?.RootId ?? 0) !== rootId), _.cloneDeep(updateUserNotificationSetting)] ?? [];
        state.userNotificationSettings = userNotificationSettingsData;
      }
    },
    _removeUserNotificationSetting(state, deleteUserNotificationSetting) {
      const rootId = Number(deleteUserNotificationSetting?.RootId ?? 0);
      if (rootId > 0) {
        let userNotificationSettingsData = _.cloneDeep(state.userNotificationSettings) ?? [];
        userNotificationSettingsData = [...userNotificationSettingsData.filter(userNotificationSetting => Number(userNotificationSetting?.RootId ?? 0) !== rootId)] ?? [];
        state.userNotificationSettings = userNotificationSettingsData;
      }
    },
  },
  actions: {
    async getUserNotificationSettings(context, { UserRootId, IncludeMetadata }) {
      await timeOperation(async () => {
        UserRootId = Number(UserRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${userNotificationSettingsUrl}?userrootid=${UserRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setUserNotificationSettingData", data);
      }, "userNotificationSettings - getUserNotificationSettings");
    },
    async addUserNotificationSetting(context, userNotificationSetting) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userNotificationSettingsUrl, Payload: userNotificationSetting });
        if (data?.RootId > 0) {
          userNotificationSetting.RootId = data.RootId;
          context.commit("_addUserNotificationSetting", data);
        }
        return data;
      }, "userNotificationSettings - addUserNotificationSetting");
    },
    async updateUserNotificationSetting(context, userNotificationSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${userNotificationSettingsUrl}/${Number(userNotificationSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userNotificationSetting });
        context.commit("_updateUserNotificationSetting", data);
      }, "userNotificationSettings - updateUserNotificationSetting");
    },
    async removeUserNotificationSetting(context, userNotificationSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${userNotificationSettingsUrl}/${Number(userNotificationSetting?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeUserNotificationSetting", userNotificationSetting);
      }, "userNotificationSettings - removeUserNotificationSetting");
    },
  },
};
