import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, documentCategoriesUrl, documentCategoryPartiesUrl, documentCategoryPartyRolesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    documentCategories: [],
    selectedAdminDocumentCategory: null,
  },
  getters: {
    getDocumentCategoryByName: (state) => (name) => {
      return state.documentCategories.find((documentCategory) => String(documentCategory?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getNextDocumentCategoryRootId: (state) => {
      let result = 1;
      state.documentCategories.forEach((documentCategory) => {
        if (Number(documentCategory.RootId) >= result) {
          result = Number(documentCategory.RootId) + 1;
        }
      });
      return result;
    },
    isDocumentCategoryNameTaken: (state) => (currentDocumentCategory) => {
      const { documentCategories } = state;
      if (documentCategories?.length < 1) {
        console.error("no document categories");
        return false;
      }
      if (!currentDocumentCategory) {
        console.error("no document category given");
        return true;
      }
      const { Name: currentCategoryName, RootId } = currentDocumentCategory;
      if (!currentCategoryName) {
        console.error("no name");
        return true;
      }
      if (!RootId) {
        console.error("invalid document category");
        return true;
      }
      return !!documentCategories.find((docCat) => docCat?.Name === currentCategoryName && RootId !== docCat?.RootId);
    },
  },
  mutations: {
    setSelectedAdminDocumentCategory(state, newDocumentCategory) {
      state.selectedAdminDocumentCategory = _.cloneDeep(newDocumentCategory);
    },
    setDocumentCategories(state, documentCategoriesData) {
      state.documentCategories = documentCategoriesData?.sort((dc1, dc2) => (dc1.Name > dc2.Name ? 1 : -1)) ?? [];
    },
    _addDocumentCategory(state, documentCategory) {
      const documentCategoriesData = _.cloneDeep(state.documentCategories) ?? [];
      documentCategoriesData.push(documentCategory);
      state.documentCategories = documentCategoriesData.sort((dc1, dc2) => (dc1.Name > dc2.Name ? 1 : -1)) ?? [];
    },
    _updateDocumentCategory(state, updateDocumentCategory) {
      const rootId = Number(updateDocumentCategory?.RootId ?? 0);
      if (rootId > 0) {
        let documentCategoriesData = _.cloneDeep(state.documentCategories) ?? [];
        state.documentCategories =
          [...documentCategoriesData.filter((documentCategory) => Number(documentCategory?.RootId ?? 0) !== rootId), _.cloneDeep(updateDocumentCategory)].sort((dc1, dc2) =>
            dc1.Name > dc2.Name ? 1 : -1
          ) ?? [];
      }
    },
    _removeDocumentCategory(state, deleteDocumentCategory) {
      const rootId = Number(deleteDocumentCategory?.RootId ?? 0);
      if (rootId > 0) {
        let documentCategoriesData = _.cloneDeep(state.documentCategories) ?? [];
        state.documentCategories =
          [...documentCategoriesData.filter((documentCategory) => Number(documentCategory?.RootId ?? 0) !== rootId)].sort((dc1, dc2) => (dc1.Name > dc2.Name ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getDocumentCategories(context, params) {
      await timeOperation(async () => {
        const useCache = params?.UseCache ?? false;
        if (!useCache || !(context.state.documentCategories?.length > 0)) {
          const formattedUrl = `${documentCategoriesUrl}?includemetadata=${params?.IncludeMetadata ?? false}&includedocumenttypes=${
            params?.IncludeDocumentTypeTitles ?? false
          }&includepartiesandroles=${params?.IncludePartiesAndRoles ?? false}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setDocumentCategories", data);
        }
      }, "documentCategories - getDocumentCategories");
    },
    async addDocumentCategory(context, documentCategory) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentCategoriesUrl, Payload: documentCategory });
        context.commit("_addDocumentCategory", data);
      }, "documentCategories - addDocumentCategory");
    },
    async updateDocumentCategory(context, documentCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${documentCategoriesUrl}/${Number(documentCategory?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentCategory });
        context.commit("_updateDocumentCategory", data);
      }, "documentCategories - updateDocumentCategory");
    },
    async removeDocumentCategory(context, documentCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${documentCategoriesUrl}/${Number(documentCategory?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeDocumentCategory", documentCategory);
      }, "documentCategories - removeDocumentCategory");
    },
    async getSelectedDocumentCategoryPartiesAndPartyRoles(context) {
      await timeOperation(async () => {
        const { RootId } = context.state.selectedAdminDocumentCategory;
        if (!RootId) {
          console.error("cannot find selected admin document category");
          return;
        }
        await context.dispatch("getSelectedAdminDocumentCategoryPartyRoles", RootId);
        await context.dispatch("getSelectedAdminDocumentCategoryParties", RootId);
      }, "documentCategories - getSelectedDocumentCategoryPartiesAndPartyRoles");
    },
    async getSelectedAdminDocumentCategoryParties(context, RootId) {
      await timeOperation(async () => {
        if (!RootId) {
          console.error("no document category");
          return;
        }
        const formattedUrl = `${documentCategoryPartiesUrl}?DocumentCategoryRootId=${encodeURIComponent(Number(RootId))}`;
        const queryResult = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (!queryResult?.data) {
          console.error("invalid query " + queryResult?.ErrorMessage);
          return;
        }
        const parties = queryResult.data;
        context.commit("setSelectedAdminDocumentCategory", { ...context.state.selectedAdminDocumentCategory, UserParties: _.cloneDeep(parties) });
      }, "documentCategories - getSelectedAdminDocumentCategoryParties");
    },
    async addDocumentCategoryParty(context, documentCategoryParty) {
      await timeOperation(async () => {
        if (!documentCategoryParty?.DocumentCategoryRootId) {
          console.error("no document category");
          return;
        }
        if (!documentCategoryParty?.PartyRootId) {
          console.error("no party given");
          return;
        }
        if (documentCategoryParty?.Id) {
          console.error("cannot add existing record");
          return;
        }
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentCategoryPartiesUrl, Payload: documentCategoryParty });
      }, "documentCategories - addDocumentCategoryParty");
    },
    async removeDocumentCategoryParty(context, documentCategoryParty) {
      await timeOperation(async () => {
        if (!documentCategoryParty?.RootId) {
          console.error("no record to remove");
        }
        const formattedUrl = `${documentCategoryPartiesUrl}/${Number(documentCategoryParty?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "documentCategories - removeDocumentCategoryParty");
    },
    async getSelectedAdminDocumentCategoryPartyRoles(context, RootId) {
      await timeOperation(async () => {
        if (!RootId) {
          console.error("no document category");
          return;
        }
        const formattedUrl = `${documentCategoryPartyRolesUrl}?DocumentCategoryRootId=${encodeURIComponent(Number(RootId))}`;
        const queryResult = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (!queryResult?.data) {
          console.error("invalid query " + queryResult?.ErrorMessage);
          return;
        }
        const partyRoles = queryResult.data;
        context.commit("setSelectedAdminDocumentCategory", { ...context.state.selectedAdminDocumentCategory, DocumentCategoryPartyRoles: _.cloneDeep(partyRoles) });
      }, "documentCategories - getSelectedAdminDocumentCategoryPartyRoles");
    },
    async addDocumentCategoryPartyRole(context, documentCategoryPartyRole) {
      await timeOperation(async () => {
        if (!documentCategoryPartyRole?.DocumentCategoryRootId) {
          console.error("no document category");
          return;
        }
        if (!documentCategoryPartyRole?.PartyRoleRootId) {
          console.error("no party role");
          return;
        }
        if (documentCategoryPartyRole?.Id) {
          console.error("cannot add existing record");
          return;
        }
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentCategoryPartyRolesUrl, Payload: documentCategoryPartyRole });
      }, "documentCategories - addDocumentCategoryPartyRole");
    },
    async removeDocumentCategoryPartyRole(context, documentCategoryPartyRole) {
      await timeOperation(async () => {
        if (!documentCategoryPartyRole?.RootId) {
          console.error("no record to remove");
          return;
        }
        const formattedUrl = `${documentCategoryPartyRolesUrl}/${Number(documentCategoryPartyRole?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "documentCategories - removeDocumentCategoryPartyRole");
    },
  },
};
