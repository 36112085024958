import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, staticTemplatesUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import _ from "lodash";

export default {
  state: {
    staticTemplates: [],
    staticTemplateData: null,
  },
  getters: {
    getStaticTemplateByName: (state) => (name) => {
      return state.staticTemplates.find((staticTemplate) => String(staticTemplate?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getNextStaticTemplateRootId: (state) => {
      let result = 1;
      state.staticTemplates.forEach((staticTemplate) => {
        if (Number(staticTemplate.RootId) >= result) {
          result = Number(staticTemplate.RootId) + 1;
        }
      });
      return result;
    },
  },
  mutations: {
    setStaticTemplates(state, staticTemplatesData) {
      state.staticTemplates = staticTemplatesData?.sort((st1, st2) => (st1.Name > st2.Name ? 1 : -1)) ?? [];
    },
    setStaticTemplateData(state, staticTemplateData) {
      state.staticTemplateData = staticTemplateData;
    },
    clearStaticTemplateData(state) {
      state.staticTemplateData = null;
    },
    _addStaticTemplate(state, staticTemplate) {
      const staticTemplatesData = _.cloneDeep(state.staticTemplates) ?? [];
      staticTemplatesData.push(staticTemplate);
      state.staticTemplates = staticTemplatesData.sort((dt1, dt2) => (dt1.Name > dt2.Name ? 1 : -1)) ?? [];
    },
    _updateStaticTemplate(state, updateStaticTemplate) {
      const rootId = Number(updateStaticTemplate?.RootId ?? 0);
      if (rootId > 0) {
        let staticTemplatesData = _.cloneDeep(state.staticTemplates) ?? [];
        staticTemplatesData = [...staticTemplatesData.filter(staticTemplate => Number(staticTemplate?.RootId ?? 0) !== rootId), _.cloneDeep(updateStaticTemplate)].sort((st1, st2) => (st1.Name > st2.Name ? 1 : -1)) ?? [];
        state.staticTemplates = staticTemplatesData;
      }
    },
    _removeStaticTemplate(state, rootId) {
      rootId = Number(rootId ?? 0);
      if (rootId > 0) {
        let staticTemplatesData = _.cloneDeep(state.staticTemplates) ?? [];
        staticTemplatesData = [...staticTemplatesData.filter(staticTemplate => Number(staticTemplate?.RootId ?? 0) !== rootId)].sort((st1, st2) => (st1.Name > st2.Name ? 1 : -1)) ?? [];
        state.staticTemplates = staticTemplatesData;
      }
    },
  },
  actions: {
    async getStaticTemplates(context) {
      await timeOperation(async () => {
        const formattedUrl = `${staticTemplatesUrl}?includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setStaticTemplates", data);
      }, "staticTemplates - getStaticTemplates");
    },
    async getStaticTemplateData(context, name) {
      await timeOperation(async () => {
        if ((name ?? "").length > 0) {
          const formattedUrl = `${staticTemplatesUrl}?name=${String(name ?? "")}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setStaticTemplateData", data);
        } else {
          context.commit("clearStaticTemplateData");
        }
      }, "staticTemplates - getStaticTemplateData");
    },
    async addStaticTemplate(context, staticTemplate) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: staticTemplatesUrl, Payload: staticTemplate });
        context.commit("_addStaticTemplate", data);
      }, "staticTemplates - addStaticTemplate");
    },
    async updateStaticTemplate(context, staticTemplate) {
      await timeOperation(async () => {
        const formattedUrl = `${staticTemplatesUrl}/${Number(staticTemplate?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: staticTemplate });
        context.commit("_updateStaticTemplate", data);
      }, "staticTemplates - updateStaticTemplate");
    },
    async removeStaticTemplate(context, rootId) {
      await timeOperation(async () => {
        const formattedUrl = `${staticTemplatesUrl}/${Number(rootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeStaticTemplate", rootId);
      }, "staticTemplates - removeStaticTemplate");
    },
  },
};
