import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, widgetTypesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    widgetTypes: [],
  },
  getters: {
    getWidgetTypeByRootId: (state) => (rootId) => {
      return state.widgetTypes.find((widgetType) => Number(widgetType?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getWidgetTypeByName: (state) => (name) => {
      return state.widgetTypes.find((widgetType) => String(widgetType?.Name ?? "") === String(name ?? "") || String(widgetType?.DisplayName ?? "") === String(name ?? "")) ?? null;
    },
  },
  mutations: {
    setWidgetTypeData(state, widgetTypes) {
      state.widgetTypes = widgetTypes?.sort((wtype1, wtype2) => (wtype1.DisplayName > wtype2.DisplayName ? 1 : -1)) ?? [];
    },
    clearWidgetTypes(state) {
      state.widgetTypes = [];
    },
    _addWidgetType(state, widgetType) {
      const widgetTypesData = _.cloneDeep(state.widgetTypes) ?? [];
      widgetTypesData.push(widgetType);
      state.widgetTypes = widgetTypesData.sort((wtype1, wtype2) => (wtype1.DisplayName > wtype2.DisplayName ? 1 : -1));
    },
    _updateWidgetType(state, updateWidgetType) {
      const rootId = Number(updateWidgetType?.RootId ?? 0);
      if (rootId > 0) {
        let widgetTypesData = _.cloneDeep(state.widgetTypes) ?? [];
        widgetTypesData =
          [...widgetTypesData.filter((widgetType) => Number(widgetType?.RootId ?? 0) !== rootId), _.cloneDeep(updateWidgetType)].sort((wtype1, wtype2) =>
            wtype1.DisplayName > wtype2.DisplayName ? 1 : -1
          ) ?? [];
        state.widgetTypes = widgetTypesData;
      }
    },
    _removeWidgetType(state, deleteWidgetType) {
      const rootId = Number(deleteWidgetType?.RootId ?? 0);
      if (rootId > 0) {
        let widgetTypesData = _.cloneDeep(state.widgetTypes) ?? [];
        widgetTypesData =
          [...widgetTypesData.filter((widgetType) => Number(widgetType?.RootId ?? 0) !== rootId)].sort((wtype1, wtype2) => (wtype1.DisplayName > wtype2.DisplayName ? 1 : -1)) ??
          [];
        state.widgetTypes = widgetTypesData;
      }
    },
  },
  actions: {
    async getWidgetTypes(context) {
      if (!(context.state.widgetTypes?.length > 0)) {
        await timeOperation(async () => {
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: widgetTypesUrl, Payload: null });
          context.commit("setWidgetTypeData", data);
        }, "widgetTypes - getWidgetTypes");
      }
    },
    async getWidgetTypeContextFields(context, names) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: widgetTypesUrl, Payload: names });
      }, "widgetTypes - getWidgetTypeContextFields");
    },
    async addWidgetType(context, widgetType) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: widgetTypesUrl, Payload: widgetType });
        if (data?.RootId > 0) {
          widgetType.RootId = data.RootId;
          context.commit("_addWidgetType", data);
        }
        return data;
      }, "widgetTypes - addWidgetType");
    },
    async updateWidgetType(context, widgetType) {
      await timeOperation(async () => {
        const formattedUrl = `${widgetTypesUrl}/${Number(widgetType?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: widgetType });
        context.commit("_updateWidgetType", data);
      }, "widgetTypes - updateWidgetType");
    },
    async removeWidgetType(context, widgetType) {
      await timeOperation(async () => {
        const formattedUrl = `${widgetTypesUrl}/${Number(widgetType?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeWidgetType", widgetType);
      }, "widgetTypes - removeWidgetType");
    },
  },
};
