<template>
  <div class="fixed top-4 z-top">
    <div class="w-screen flex justify-content-center">
      <div class="flex space-x-2 bg-gray rounded-sm shadow-md text-white py-2 px-3">
        <div class="my-auto">A New Version Is Available</div>
        <div>|</div>
        <div class="text-light-blue cursor-pointer" @click="refresh">Refresh</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewVersionMessage",
  emits: ["refresh"],
  setup(_props, { emit }) {
    function refresh() {
      location.reload();
      emit("refresh");
    }

    return {
      refresh,
    };
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: rgb(50, 50, 50);
}

.text-light-blue {
  color: rgb(130, 130, 253);
}

.z-top {
  z-index: 1000000;
}
</style>
