import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationVendorCategoryMembersUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationVendorCategoryMembers: [],
  },
  getters: {
    getOrganizationVendorCategoryMemberByRootId: (state) => (rootId) => {
      return state.organizationVendorCategoryMembers.find((organizationVendorCategoryMember) => Number(organizationVendorCategoryMember?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
    getOrganizationVendorCategoryMemberByName: (state) => (name) => {
      return state.organizationVendorCategoryMembers.find((organizationVendorCategoryMember) => String(organizationVendorCategoryMember?.VendorOrganizationName ?? "") === String(name ?? "")) ?? null;
    },
    getOrganizationVendorCategoryMemberRootIdByName: (state) => (name) => {
      return (state.organizationVendorCategoryMembers.find((organizationVendorCategoryMember) => String(organizationVendorCategoryMember?.VendorOrganizationName ?? "") === String(name ?? "")) ?? null)?.RootId ?? 0;
    },
  },
  mutations: {
    setOrganizationVendorCategoryMemberData(state, organizationVendorCategoryMembers) {
      state.organizationVendorCategoryMembers = organizationVendorCategoryMembers?.sort((ovcm1, ovcm2) => (ovcm1.VendorOrganizationName > ovcm2.VendorOrganizationName ? 1 : -1)) ?? [];
    },
    clearOrganizationVendorCategoryMembers(state) {
      state.organizationVendorCategoryMembers = [];
    },
    _addOrganizationVendorCategoryMember(state, organizationVendorCategoryMember) {
      const organizationVendorCategoryMembersData = _.cloneDeep(state.organizationVendorCategoryMembers) ?? [];
      organizationVendorCategoryMembersData.push(organizationVendorCategoryMember);
      state.organizationVendorCategoryMembers = organizationVendorCategoryMembersData.sort((ovcm1, ovcm2) => (ovcm1.VendorOrganizationName > ovcm2.VendorOrganizationName ? 1 : -1));
    },
    _updateOrganizationVendorCategoryMember(state, updateOrganizationVendorCategoryMember) {
      const rootId = Number(updateOrganizationVendorCategoryMember?.RootId ?? 0);
      if (rootId > 0) {
        let organizationVendorCategoryMembersData = _.cloneDeep(state.organizationVendorCategoryMembers) ?? [];
        organizationVendorCategoryMembersData = [...organizationVendorCategoryMembersData.filter(organizationVendorCategoryMember => Number(organizationVendorCategoryMember?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationVendorCategoryMember)].sort((ovcm1, ovcm2) => (ovcm1.VendorOrganizationName > ovcm2.VendorOrganizationName ? 1 : -1)) ?? [];
        state.organizationVendorCategoryMembers = organizationVendorCategoryMembersData;
      }
    },
    _removeOrganizationVendorCategoryMember(state, deleteOrganizationVendorCategoryMember) {
      const rootId = Number(deleteOrganizationVendorCategoryMember?.RootId ?? 0);
      if (rootId > 0) {
        let organizationVendorCategoryMembersData = _.cloneDeep(state.organizationVendorCategoryMembers) ?? [];
        organizationVendorCategoryMembersData = [...organizationVendorCategoryMembersData.filter(organizationVendorCategoryMember => Number(organizationVendorCategoryMember?.RootId ?? 0) !== rootId)].sort((ovcm1, ovcm2) => (ovcm1.VendorOrganizationName > ovcm2.VendorOrganizationName ? 1 : -1)) ?? [];
        state.organizationVendorCategoryMembers = organizationVendorCategoryMembersData;
      }
    },
  },
  actions: {
    async getOrganizationVendorCategoryMembers(context, { OrganizationRootId, ProductRootId, OrganizationVendorRootId, OrganizationVendorCategoryRootId, IncludeMetadata }) {
      await timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationVendorRootId = Number(OrganizationVendorRootId ?? 0);
        OrganizationVendorCategoryRootId = Number(OrganizationVendorCategoryRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${organizationVendorCategoryMembersUrl}?organizationrootid=${OrganizationRootId}&productrootid=${ProductRootId}&organizationvendorrootid=${OrganizationVendorRootId}&organizationvendorcategoryrootid=${OrganizationVendorCategoryRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationVendorCategoryMemberData", data);
      }, "organizationVendorCategoryMembers - getOrganizationVendorCategoryMembers");
    },
    async addOrganizationVendorCategoryMember(context, organizationVendorCategoryMember) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationVendorCategoryMembersUrl, Payload: organizationVendorCategoryMember });
        if (data?.RootId > 0) {
          organizationVendorCategoryMember.RootId = data.RootId;
          context.commit("_addOrganizationVendorCategoryMember", data);
        }
        return data;
      }, "organizationVendorCategoryMembers - addOrganizationVendorCategoryMember");
    },
    async updateOrganizationVendorCategoryMember(context, organizationVendorCategoryMember) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationVendorCategoryMembersUrl}/${Number(organizationVendorCategoryMember?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationVendorCategoryMember });
        context.commit("_updateOrganizationVendorCategoryMember", data);
      }, "organizationVendorCategoryMembers - updateOrganizationVendorCategoryMember");
    },
    async removeOrganizationVendorCategoryMember(context, organizationVendorCategoryMember) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationVendorCategoryMembersUrl}/${Number(organizationVendorCategoryMember?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationVendorCategoryMember", organizationVendorCategoryMember);
      }, "organizationVendorCategoryMembers - removeOrganizationVendorCategoryMember");
    },
  },
};
