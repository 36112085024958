import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, dataSourceTypesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    dataSourceTypes: [],
  },
  getters: {
    getDataSourceTypeByName: (state) => (name) => {
      return state.dataSourceTypes.find((dataSourceType) => String(dataSourceType?.Name ?? "") === String(name ?? "")) ?? null;
    },
  },
  mutations: {
    setDataSourceTypeData(state, dataSourceTypesData) {
      state.dataSourceTypes = dataSourceTypesData?.sort((dst1, dst2) => (dst1.Name > dst2.Name ? 1 : -1)) ?? [];
    },
    clearDataSourceTypes(state) {
      state.dataSourceTypes = [];
    },
    _addDataSourceType(state, dataSourceType) {
      const dataSourceTypesData = _.cloneDeep(state.dataSourceTypes) ?? [];
      dataSourceTypesData.push(dataSourceType);
      state.dataSourceTypes = dataSourceTypesData.sort((dst1, dst2) => (dst1.Name > dst2.Name ? 1 : -1)) ?? [];
    },
    _updateDataSourceType(state, updateDataSourceType) {
      const rootId = Number(updateDataSourceType?.RootId ?? 0);
      if (rootId > 0) {
        let dataSourceTypesData = _.cloneDeep(state.dataSourceTypes) ?? [];
        state.dataSourceTypes =
          [...dataSourceTypesData.filter((dataSourceType) => Number(dataSourceType?.RootId ?? 0) !== rootId), _.cloneDeep(updateDataSourceType)].sort((dst1, dst2) =>
            dst1.Name > dst2.Name ? 1 : -1
          ) ?? [];
      }
    },
    _removeDataSourceType(state, deleteDataSourceType) {
      const rootId = Number(deleteDataSourceType?.RootId ?? 0);
      if (rootId > 0) {
        let dataSourceTypesData = _.cloneDeep(state.dataSourceTypes) ?? [];
        state.dataSourceTypes =
          [...dataSourceTypesData.filter((dataSourceType) => Number(dataSourceType?.RootId ?? 0) !== rootId)].sort((dst1, dst2) => (dst1.Name > dst2.Name ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getDataSourceTypes(context) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: dataSourceTypesUrl, Payload: null });
        context.commit("setDataSourceTypeData", data);
      }, "dataSourceTypes - getDataSourceTypes");
    },
    async addDataSourceType(context, dataSourceType) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: dataSourceTypesUrl, Payload: dataSourceType});
        if (data?.RootId > 0) {
          dataSourceType.RootId = data.RootId;
          context.commit("_addDataSourceType", data);
        }
        return data;
      }, "dataSourceTypes - addDataSourceType");
    },
    async updateDataSourceType(context, dataSourceType) {
      await timeOperation(async () => {
        const formattedUrl = `${dataSourceTypesUrl}/${Number(dataSourceType?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: dataSourceType });
        context.commit("_updateDataSourceType", data);
      }, "dataSourceTypes - updateDataSourceType");
    },
    async removeDataSourceType(context, dataSourceType) {
      await timeOperation(async () => {
        const formattedUrl = `${dataSourceTypesUrl}/${Number(dataSourceType?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeDataSourceType", dataSourceType);
      }, "dataSourceTypes - removeDataSourceType");
    },
  },
};
