import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, userBranchesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    userBranches: [],
  },
  getters: {
    getUserBranchByRootId: (state) => (rootId) => {
      return state.userBranches.find((userBranch) => Number(userBranch?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
  },
  mutations: {
    setUserBranchData(state, userBranches) {
      state.userBranches = userBranches ?? [];
    },
    _addUserBranch(state, userBranch) {
      const userBranchesData = _.cloneDeep(state.userBranches) ?? [];
      userBranchesData.push(userBranch);
      state.userBranches = userBranchesData ?? [];
    },
    _updateUserBranch(state, updateUserBranch) {
      const rootId = Number(updateUserBranch?.RootId ?? 0);
      if (rootId > 0) {
        let userBranchesData = _.cloneDeep(state.userBranches) ?? [];
        userBranchesData = [...userBranchesData.filter(userBranch => Number(userBranch?.RootId ?? 0) !== rootId), _.cloneDeep(updateUserBranch)] ?? [];
        state.userBranches = userBranchesData;
      }
    },
    _removeUserBranch(state, deleteUserBranch) {
      const rootId = Number(deleteUserBranch?.RootId ?? 0);
      if (rootId > 0) {
        let userBranchesData = _.cloneDeep(state.userBranches) ?? [];
        userBranchesData = [...userBranchesData.filter(userBranch => Number(userBranch?.RootId ?? 0) !== rootId)] ?? [];
        state.userBranches = userBranchesData;
      }
    },
  },
  actions: {
    async getUserBranches(context, { UserRootId, IncludeMetadata }) {
      return timeOperation(async () => {
        UserRootId = Number(UserRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        const formattedUrl = `${userBranchesUrl}?userrootid=${UserRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setUserBranchData", data);
        return data;
      }, "userBranches - getUserBranches");
    },
    async addUserBranch(context, userBranch) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: userBranchesUrl, Payload: userBranch });
        if (data?.RootId > 0) {
          userBranch.RootId = data.RootId;
          context.commit("_addUserBranch", data);
        }
        return data;
      }, "userBranches - addUserBranch");
    },
    async updateUserBranch(context, userBranch) {
      return timeOperation(async () => {
        const formattedUrl = `${userBranchesUrl}/${Number(userBranch?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: userBranch});
        context.commit("_updateUserBranch", data);
        return data;
      }, "userBranches - updateUserBranch");
    },
    async removeUserBranch(context, userBranch) {
      return timeOperation(async () => {
        const formattedUrl = `${userBranchesUrl}/${Number(userBranch?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("_removeUserBranch", userBranch);
        return data;
      }, "userBranches - removeUserBranch");
    },
  },
};
