import {
  ApiRequest,
  GetVerb,
  PostVerb,
  PutVerb,
  DeleteVerb,
  workflowNotificationTemplatesUrl,
  organizationWorkflowNotificationTemplatesUrl,
  userWorkflowNotificationTemplatesUrl,
} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  actions: {
    async getProductLevelWorkflowNotificationTemplates(context, { ProductRootId, InjectReferences, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        InjectReferences = InjectReferences ?? true;
        IncludeMetadata = IncludeMetadata ?? true;
        if (ProductRootId > 0) {
          const formattedUrl = `${workflowNotificationTemplatesUrl}?productrootid=${ProductRootId}&organizationrootid=0&userrootid=0&isproductlevel=true&injectreferences=${InjectReferences}&includemetadata=${IncludeMetadata}&includepossible=false`;
          return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        }
        return [];
      }, "workflowNotificationTemplates - getProductLevelWorkflowNotificationTemplates");
    },
    async getOrganizationLevelWorkflowNotificationTemplates(context, { ProductRootId, OrganizationRootId, InjectReferences, IncludePossible, IncludeMetadata }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        InjectReferences = InjectReferences ?? true;
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? true;
        if (OrganizationRootId > 0) {
          const formattedUrl = `${workflowNotificationTemplatesUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=0&isorganizationlevel=true&injectreferences=${InjectReferences}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
          return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        }
        return [];
      }, "workflowNotificationTemplates - getOrganizationLevelWorkflowNotificationTemplates");
    },
    async getUserLevelWorkflowNotificationTemplates(
      context,
      { ProductRootId, OrganizationRootId, UserRootId, WorkflowNotificationTemplateRootId, IncludePossible, IncludeMetadata }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        WorkflowNotificationTemplateRootId = Number(WorkflowNotificationTemplateRootId ?? 0);
        IncludePossible = IncludePossible ?? false;
        IncludeMetadata = IncludeMetadata ?? false;
        if (UserRootId > 0) {
          const formattedUrl = `${userWorkflowNotificationTemplatesUrl}?userrootid=${UserRootId}&organizationrootid=${OrganizationRootId}&productrootid=${ProductRootId}&workflownotificationtemplaterootid=${WorkflowNotificationTemplateRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
          return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        }
        return [];
      }, "organizations - getOrganizationUserWorkflowNotificationTemplates");
    },
    async getWorkflowNotificationTemplateTemplate(
      context,
      {
        ProductRootId,
        ProductDataSourceRootId,
        OrganizationDataSourceRootId,
        AdditionalProductDataSourceRootIds,
        AdditionalOrganizationDataSourceRootIds,
        IsProductLevel,
        IsOrganizationLevel,
        IsUserLevel,
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        ProductDataSourceRootId = Number(ProductDataSourceRootId ?? 0);
        OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0);
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedAdditionalProductDataSourceRootIds = AdditionalProductDataSourceRootIds?.length > 0 ? AdditionalProductDataSourceRootIds.join("-") : "";
        const formattedAdditionalOrganizationDataSourceRootIds = AdditionalOrganizationDataSourceRootIds?.length > 0 ? AdditionalOrganizationDataSourceRootIds.join("-") : "";
        const formattedUrl = `${workflowNotificationTemplatesUrl}?productrootid=${ProductRootId}&productdatasourcerootid=${ProductDataSourceRootId}&organizationdatasourcerootid=${OrganizationDataSourceRootId}&additionalproductdatasourcerootids=${formattedAdditionalProductDataSourceRootIds}&additionalorganizationdatasourcerootids=${formattedAdditionalOrganizationDataSourceRootIds}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}&template=true&injectreferences=true`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "workflowNotificationTemplates - getWorkflowNotificationTemplateTemplate");
    },
    async addWorkflowNotificationTemplate(context, { WorkflowNotificationTemplate, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${workflowNotificationTemplatesUrl}?isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: WorkflowNotificationTemplate });
      }, "workflowNotificationTemplates - addWorkflowNotificationTemplate");
    },
    async updateWorkflowNotificationTemplate(context, { WorkflowNotificationTemplate, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${workflowNotificationTemplatesUrl}/${Number(
          WorkflowNotificationTemplate?.RootId ?? 0
        )}?isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: WorkflowNotificationTemplate });
      }, "workflowNotificationTemplates - updateWorkflowNotificationTemplate");
    },
    async cloneWorkflowNotificationTemplate(context, { WorkflowNotificationTemplate, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        IsProductLevel = IsProductLevel ?? false;
        IsOrganizationLevel = IsOrganizationLevel ?? false;
        IsUserLevel = IsUserLevel ?? false;
        const formattedUrl = `${workflowNotificationTemplatesUrl}/${Number(
          WorkflowNotificationTemplate?.RootId ?? 0
        )}/clone?isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: WorkflowNotificationTemplate });
      }, "workflowNotificationTemplates - cloneWorkflowNotificationTemplate");
    },
    async renameWorkflowNotificationTemplate(context, workflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowNotificationTemplatesUrl}/${Number(workflowNotificationTemplate?.RootId ?? 0)}/rename`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowNotificationTemplate });
      }, "workflowNotificationTemplates - renameWorkflowNotificationTemplate");
    },
    async enableProductWorkflowNotificationTemplate(context, workflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowNotificationTemplatesUrl}/${Number(workflowNotificationTemplate?.RootId ?? 0)}/updateproductworkflownotificationtemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowNotificationTemplate, Enabled: true } });
      }, "workflowNotificationTemplates - enableProductWorkflowNotificationTemplate");
    },
    async disableProductWorkflowNotificationTemplate(context, workflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowNotificationTemplatesUrl}/${Number(workflowNotificationTemplate?.RootId ?? 0)}/updateproductworkflownotificationtemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowNotificationTemplate, Enabled: false } });
      }, "workflowNotificationTemplates - disableProductWorkflowNotificationTemplate");
    },
    async enableOrganizationWorkflowNotificationTemplate(context, workflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowNotificationTemplatesUrl}/${Number(workflowNotificationTemplate?.RootId ?? 0)}/updateorganizationworkflownotificationtemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowNotificationTemplate, Enabled: true } });
      }, "workflowNotificationTemplates - enableOrganizationWorkflowNotificationTemplate");
    },
    async disableOrganizationWorkflowNotificationTemplate(context, workflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowNotificationTemplatesUrl}/${Number(workflowNotificationTemplate?.RootId ?? 0)}/updateorganizationworkflownotificationtemplateenabledstatus`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...workflowNotificationTemplate, Enabled: false } });
      }, "workflowNotificationTemplates - disableOrganizationWorkflowNotificationTemplate");
    },
    async updateOrganizationWorkflowNotificationTemplateAlias(context, workflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowNotificationTemplatesUrl}/${Number(workflowNotificationTemplate?.RootId ?? 0)}/updateorganizationworkflownotificationtemplatealias`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowNotificationTemplate });
      }, "workflowNotificationTemplates - updateOrganizationWorkflowNotificationTemplateAlias");
    },
    async updateOrganizationWorkflowNotificationTemplateAccessSettings(context, organizationWorkflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${organizationWorkflowNotificationTemplatesUrl}/${Number(organizationWorkflowNotificationTemplate?.RootId ?? 0)}/updateaccesssettings`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationWorkflowNotificationTemplate });
      }, "organizations - updateOrganizationWorkflowNotificationTemplateAccessSettings");
    },
    async updateWorkflowNotificationTemplateAccessSettings(context, workflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowNotificationTemplatesUrl}/${Number(workflowNotificationTemplate?.RootId ?? 0)}/updateaccesssettings`;
        return await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: workflowNotificationTemplate });
      }, "organizations - updateWorkflowNotificationTemplateAccessSettings");
    },
    async removeWorkflowNotificationTemplate(context, workflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${workflowNotificationTemplatesUrl}/${Number(workflowNotificationTemplate?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "workflowNotificationTemplates - removeWorkflowNotificationTemplate");
    },
    async updateUserWorkflowNotificationTemplateEnabledStatus(context, userWorkflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${userWorkflowNotificationTemplatesUrl}/${Number(userWorkflowNotificationTemplate?.RootId ?? 0)}/updateenabledstatus`;
        const updatedUserWorkflowNotificationTemplate = await context.dispatch(ApiRequest, {
          Verb: PutVerb,
          FormattedUrl: formattedUrl,
          Payload: userWorkflowNotificationTemplate,
        });
        context.commit("_updateUserWorkflowNotificationTemplate", {
          UserRootId: updatedUserWorkflowNotificationTemplate.UserRootId,
          ProductRootId: updatedUserWorkflowNotificationTemplate.ProductRootId,
          WorkflowNotificationTemplateRootId: updatedUserWorkflowNotificationTemplate.WorkflowNotificationTemplateRootId,
          Enabled: updatedUserWorkflowNotificationTemplate.Enabled ?? false,
        });
        return updatedUserWorkflowNotificationTemplate;
      }, "organizations - updateUserWorkflowNotificationTemplateEnabledStatus");
    },
    async updateUserWorkflowNotificationTemplateSelectedStatus(context, userWorkflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${userWorkflowNotificationTemplatesUrl}/${Number(userWorkflowNotificationTemplate?.RootId ?? 0)}/updateselectedstatus`;
        const updatedUserWorkflowNotificationTemplate = await context.dispatch(ApiRequest, {
          Verb: PutVerb,
          FormattedUrl: formattedUrl,
          Payload: userWorkflowNotificationTemplate,
        });
        context.commit("_updateUserWorkflowNotificationTemplate", {
          UserRootId: updatedUserWorkflowNotificationTemplate.UserRootId,
          ProductRootId: updatedUserWorkflowNotificationTemplate.ProductRootId,
          WorkflowNotificationTemplateRootId: updatedUserWorkflowNotificationTemplate.WorkflowNotificationTemplateRootId,
          Selected: updatedUserWorkflowNotificationTemplate.Selected ?? false,
        });
        return updatedUserWorkflowNotificationTemplate;
      }, "organizations - updateUserWorkflowNotificationTemplateSelectedStatus");
    },
    async updateUserWorkflowNotificationTemplateAccessSettings(context, userWorkflowNotificationTemplate) {
      return timeOperation(async () => {
        const formattedUrl = `${userWorkflowNotificationTemplatesUrl}/${Number(userWorkflowNotificationTemplate?.RootId ?? 0)}/updateaccesssettings`;
        const updatedUserWorkflowNotificationTemplate = await context.dispatch(ApiRequest, {
          Verb: PutVerb,
          FormattedUrl: formattedUrl,
          Payload: userWorkflowNotificationTemplate,
        });
        context.commit("_updateUserWorkflowNotificationTemplate", {
          UserRootId: updatedUserWorkflowNotificationTemplate.UserRootId,
          ProductRootId: updatedUserWorkflowNotificationTemplate.ProductRootId,
          WorkflowNotificationTemplateRootId: updatedUserWorkflowNotificationTemplate.WorkflowNotificationTemplateRootId,
          CanCopy: updatedUserWorkflowNotificationTemplate.CanCopy ?? false,
          CanClose: updatedUserWorkflowNotificationTemplate.CanClose ?? false,
          CanShare: updatedUserWorkflowNotificationTemplate.CanShare ?? false,
        });
        return updatedUserWorkflowNotificationTemplate;
      }, "organizations - updateUserWorkflowNotificationTemplateAccessSettings");
    },
  },
};
