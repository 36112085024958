import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, systemSettingsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    systemSettings: [],
  },
  getters: {
    getSystemSettingByName: (state) => (name) => {
      return state.systemSettings.find((systemSetting) => String(systemSetting?.Name ?? "") === String(name ?? "")) ?? null;
    },
  },
  mutations: {
    setSystemSettingData(state, systemSettings) {
      state.systemSettings = systemSettings?.sort((ss1, ss2) => (ss1.Name > ss2.Name ? 1 : -1)) ?? [];
    },
    clearSystemSettings(state) {
      state.systemSettings = [];
    },
    _addSystemSetting(state, systemSetting) {
      const systemSettingsData = _.cloneDeep(state.systemSettings) ?? [];
      systemSettingsData.push(systemSetting);
      state.systemSettings = systemSettingsData.sort((ss1, ss2) => (ss1.Name > ss2.Name ? 1 : -1));
    },
    _updateSystemSetting(state, updateSystemSetting) {
      const id = Number(updateSystemSetting?.Id ?? 0);
      if (id > 0) {
        let systemSettingsData = _.cloneDeep(state.systemSettings) ?? [];
        systemSettingsData = [...systemSettingsData.filter(systemSetting => Number(systemSetting?.Id ?? 0) !== id), _.cloneDeep(updateSystemSetting)].sort((ss1, ss2) => (ss1.Name > ss2.Name ? 1 : -1)) ?? [];
        state.systemSettings = systemSettingsData;
      }
    },
    _removeSystemSetting(state, deleteSystemSetting) {
      const id = Number(deleteSystemSetting?.Id ?? 0);
      if (id > 0) {
        let systemSettingsData = _.cloneDeep(state.systemSettings) ?? [];
        systemSettingsData = [...systemSettingsData.filter(systemSetting => Number(systemSetting?.Id ?? 0) !== id)].sort((ss1, ss2) => (ss1.Name > ss2.Name ? 1 : -1)) ?? [];
        state.systemSettings = systemSettingsData;
      }
    },
  },
  actions: {
    async getSystemSettings(context) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: systemSettingsUrl, Payload: null });
        context.commit("setSystemSettingData", data);
      }, "systemSettings - getSystemSettings");
    },
    async addSystemSetting(context, systemSetting) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: systemSettingsUrl, Payload: systemSetting });
        if (data?.Id > 0) {
          systemSetting.Id = data.Id;
          context.commit("_addSystemSetting", data);
        }
        return data;
      }, "systemSettings - addSystemSetting");
    },
    async updateSystemSetting(context, systemSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${systemSettingsUrl}/${Number(systemSetting?.Id ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: systemSetting });
        context.commit("_updateSystemSetting", data);
      }, "systemSettings - updateSystemSetting");
    },
    async removeSystemSetting(context, systemSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${systemSettingsUrl}/${Number(systemSetting?.Id ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeSystemSetting", systemSetting);
      }, "systemSettings - removeSystemSetting");
    },
  },
};
