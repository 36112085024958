import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    showLoadingMessage: false,
    loadedSearchLocations: false,
    loadedDataSources: false,
    fadeBackground: true,
    loadingMessage: "",
    loadingMessageMinimumTimeShownSeconds: 1.5,
    showSpinner: true,
    loadingQueue: [],
    loadingHistory: {},
    // TODO: enable or disable spinner for simple messages
  },
  mutations: {
    setLoadedSearchLocations(state, value) {
      state.loadedSearchLocations = value;
    },
    setLoadedDataSources(state, value) {
      state.loadedDataSources = value;
    },
    setLoadingMessage(state, message) {
      state.loadingMessage = message;
    },
    setShowLoadingMessage(state, value) {
      state.showLoadingMessage = value;
    },
    setLoadingMessageMinimumTimeShownSeconds(state, value) {
      state.loadingMessageMinimumTimeShownSeconds = value;
    },
    setShowSpinner(state, value) {
      state.showSpinner = value;
    },
    pushLoadingQueueItem(state, message) {
      state.loadingQueue.push(message);
      state.loadingMessage = message;
      state.showSpinner = true;
      state.showLoadingMessage = true;
    },
    removeLoadingQueueItem(state, message) {
      const index = state.loadingQueue.findIndex((m) => m === message);
      if (index < 0) {
        console.error("cannot stop loading that is not in queue");
        return;
      }
      state.loadingQueue.splice(index, 1);
      if (state.loadingQueue.length > 0) {
        state.loadingMessage = state.loadingQueue[0];
        state.showSpinner = true;
      } else {
        state.loadingMessage = "";
        state.showLoadingMessage = false;
      }
    },
    setFadeBackground(state, value) {
      state.fadeBackground = value;
    },
    addToLoadHistory(state, value) {
      if (value?.Message?.length > 0 && value?.Caller?.length > 0) {
        const key = `${value.Caller} - ${value.Message}`;
        let count = 0;
        if (state.loadingHistory.hasOwnProperty(key)) {
          count = Number(state.loadingHistory[key]);
        }
        state.loadingHistory[key] = count + 1;
        console.log(`${key} - ${state.loadingHistory[key]}`);
      }
    },
  },
  actions: {
    // technically a mutation but its async, so it goes in actions for clarity
    async loadOperation(context, value) {
      // value is {Message: '', Operation: async () => await function(args)}
      if (!value.Message) {
        console.warn("no loading message");
        return;
      }
      const { Message, Caller, HiddenLoading, ShowAsError } = value;
      let result = null;
      if (!HiddenLoading) {
        context.commit("setFadeBackground", true);
        context.commit("pushLoadingQueueItem", Message);
      }
      try {
        context.commit("addToLoadHistory", value);
        result = await timeOperation(async () => await value.Operation(), `${Caller} - ${Message}`, ShowAsError);
      } catch (e) {
        console.error("loaded operation failed ", Caller, Message, e);
      } finally {
        if (!HiddenLoading) {
          context.commit("removeLoadingQueueItem", Message);
        }
      }
      return result;
    },
    async showMessage(context, { TimeShownInSeconds, Message }) {
      if (!Message) {
        console.error("no message");
        return;
      }
      if (!TimeShownInSeconds) {
        console.error("no time shown");
        return;
      }
      context.commit("setFadeBackground", false);
      context.commit("setLoadingMessage", Message);
      context.commit("setShowLoadingMessage", true);
      context.commit("setShowSpinner", false);
      setTimeout(() => {
        context.commit("setShowLoadingMessage", false);
      }, TimeShownInSeconds * 1000);
    },
  },
};
