import _ from "lodash";

const IS_SEARCH_GROUP = "IsSearchGroup";
const SEARCH_GROUP_ROOT_ID = "SearchGroupRootId";
const PARENT_SEARCH_GROUP_ROOT_ID = "ParentSearchGroupRootId";
const SELECTED = "Selected";

export function getSearchTabsAndSearchGroupsTree(searchTabs, searchGroups) {
  const newGroupTabs = searchGroups.map((group) => ({ ...group, [IS_SEARCH_GROUP]: true, [SEARCH_GROUP_ROOT_ID]: group[PARENT_SEARCH_GROUP_ROOT_ID], [SELECTED]: true }));
  const result = groupSearchTabs(newGroupTabs, [], true);
  const groupedTabs = groupSearchTabs(searchTabs, result, false);
  let finalResult = removeEmptySearchGroups(groupedTabs);
  return sortChildren(finalResult);
}

function groupSearchTabs(searchTabs, result = [], isGroupSearches) {
  // Step 1: get the searches with no parent and add them
  const parentSearches = searchTabs?.filter(
    (search) => search[SEARCH_GROUP_ROOT_ID] === 0 || (search[SEARCH_GROUP_ROOT_ID] === search?.RootId && search?.[IS_SEARCH_GROUP] === true)
  );
  parentSearches?.forEach((search) => {
    result.push(search);
  });
  // Step 2: get the next search and add it to the parent. If the parent is not added, find the parent, add it and then add this recursively
  const childSearches = searchTabs?.filter(
    (search) => search[SEARCH_GROUP_ROOT_ID] !== 0 && !(search[SEARCH_GROUP_ROOT_ID] === search?.RootId && search?.[IS_SEARCH_GROUP] === true)
  );
  for (let i = 0; i < childSearches?.length; i++) {
    const search = childSearches[i];
    search.IsInSearchGroup = true;
    addChildSearch(search.RootId, searchTabs, result, isGroupSearches);
  }
  return result;
}

function addChildSearch(childSearchRootId, searchTabs, result, childIsGroup) {
  let childSearch = searchTabs.find((search) => search.RootId === childSearchRootId && (search?.[IS_SEARCH_GROUP] ?? false) === childIsGroup);
  if (!childSearch) {
    const flatResult = getFlatTree(result);
    childSearch = flatResult.find((search) => search.RootId === childSearchRootId && (search?.[IS_SEARCH_GROUP] ?? false) === childIsGroup);
  }
  if (!childSearch) {
    console.error(`Could not find search with root id ${childSearchRootId}`);
    return null;
  }
  if (!childSearch?.[SEARCH_GROUP_ROOT_ID]) {
    return childSearch;
  }
  if (childSearch?.[SEARCH_GROUP_ROOT_ID] === childSearch?.RootId && childSearch?.[IS_SEARCH_GROUP]) {
    return childSearch;
  }
  const flatResult = getFlatTree(result);
  let parent = flatResult.find((search) => search.RootId === childSearch[SEARCH_GROUP_ROOT_ID] && (search?.[IS_SEARCH_GROUP] ?? false) === true);
  if (parent?.RootId > 0) {
    if (!(parent?.children?.length > 0)) {
      parent.children = [];
      parent.IsSearchGroup = true;
    }
    const childAlreadyAdded = parent.children.find((child) => child.RootId === childSearchRootId && (child?.[IS_SEARCH_GROUP] ?? false) === childIsGroup);
    if (childAlreadyAdded?.RootId !== childSearchRootId) {
      parent.children.push(childSearch);
      return childSearch;
    }
    return childAlreadyAdded;
  } else {
    const parent = addChildSearch(childSearch[SEARCH_GROUP_ROOT_ID], searchTabs, result, true);
    if (parent) {
      if (!(parent?.children?.length > 0)) {
        parent.children = [];
        parent.IsSearchGroup = true;
      }
      const childAlreadyAdded = parent.children.find((child) => child.RootId === childSearchRootId && (child?.[IS_SEARCH_GROUP] ?? false) === childIsGroup);
      if (childAlreadyAdded?.RootId !== childSearchRootId) {
        parent.children.push(childSearch);
        return childSearch;
      }
      return childAlreadyAdded;
    } else {
      console.warn(`Could not find parent for child search ${JSON.stringify(childSearch, null, 2)}`);
      return null;
    }
  }
}

export function getFlatTree(tree) {
  const result = tree?.reduce((acc, search) => {
    acc.push(search);
    if (search?.children?.length > 0) {
      acc = acc.concat(getFlatTree(search.children));
    }
    return acc;
  }, []);
  return result;
}

function sortChildren(data) {
  let result = _.cloneDeep(data);
  result.forEach((search) => {
    if (search.IsSearchGroup) {
      if (search?.children?.length > 0) {
        search.children = _.sortBy(sortChildren(search.children), "Position");
      } else {
        console.warn(`Search group ${search.Name} has no children removing it from the list`);
      }
    } else {
      result = _.sortBy(result, "Position");
    }
  });
  return result;
}

function removeEmptySearchGroups(searches) {
  let localSearches = [];
  searches?.forEach?.((search) => {
    if (search?.IsSearchGroup) {
      if (search?.children?.length > 0 && doesSearchGroupHaveSelectedChildren(search)) {
        const children = removeEmptySearchGroups(search?.children);
        if (children?.length > 0) {
          search[SELECTED] = true;
          localSearches.push({ ...search, children });
        }
      } else {
        const children = removeEmptySearchGroups(search?.children);
        if (children?.length > 0) {
          search[SELECTED] = false;
          localSearches.push({ ...search, children });
        }
      }
    } else {
      localSearches.push(search);
    }
  });
  return localSearches;
}

export function doesSearchGroupHaveSelectedChildren(searchGroup) {
  if (searchGroup?.children?.length > 0) {
    let hasChildren = searchGroup.children.some((child) => {
      if (child?.IsSearchGroup) {
        return doesSearchGroupHaveSelectedChildren(child);
      } else {
        return child?.Selected ?? false;
      }
    });
    return hasChildren;
  }
  return false;
}

export function formatSearchNames(searches, productRootId, userRootId, sortByPosition) {
  let formattedSearches = [];
  let localSearches = _.cloneDeep(searches);
  if (productRootId > 0 && userRootId > 0) {
    localSearches?.forEach?.((search) => {
      if (Number(search?.ProductRootId ?? 0) === productRootId || search.IsOrderTab || search.IsSearchGroup || search.IsViewEditorTab) {
        let searchCopy = _.cloneDeep(search);
        searchCopy.FormattedSearchName = searchCopy.Alias?.length > 0 ? searchCopy.Alias : searchCopy.Name;
        if (
          searchCopy.UserRootId !== userRootId &&
          (searchCopy.CanClose ?? false) &&
          searchCopy.FormattedSearchName?.length > 0 &&
          !searchCopy.IsOrderTab &&
          !searchCopy.IsViewEditorTab
        ) {
          searchCopy.FormattedSearchName = `*${searchCopy.FormattedSearchName}`;
        }
        if (searchCopy.IsSearchGroup) {
          searchCopy.children = formatSearchNames(searchCopy.children, productRootId, userRootId, sortByPosition);
        }
        if (searchCopy.LastViewedDateTime) {
          const lastViewedDateTime = new Date(searchCopy.LastViewedDateTime);
          if (lastViewedDateTime.getFullYear() <= 1900) {
            searchCopy.LastViewedDateTime = null;
          }
        }
        formattedSearches.push(searchCopy);
      }
    });
  }
  return sortByPosition
    ? formattedSearches.sort((search1, search2) => (search1.Position > search2.Position ? 1 : -1)) ?? []
    : formattedSearches.sort((search1, search2) => (search1.FormattedSearchName > search2.FormattedSearchName ? 1 : -1)) ?? [];
}

export function formatSearchName(search, productRootId, userRootId) {
  if (productRootId > 0 && userRootId > 0) {
    if (Number(search?.ProductRootId ?? 0) === productRootId || search.IsOrderTab || search.IsSearchGroup || search.IsViewEditorTab) {
      let searchCopy = _.cloneDeep(search);
      searchCopy.FormattedSearchName = searchCopy.Alias?.length > 0 ? searchCopy.Alias : searchCopy.Name;
      if (
        searchCopy.UserRootId !== userRootId &&
        (searchCopy.CanClose ?? false) &&
        searchCopy.FormattedSearchName?.length > 0 &&
        !searchCopy.IsOrderTab &&
        !searchCopy.IsViewEditorTab
      ) {
        searchCopy.FormattedSearchName = `*${searchCopy.FormattedSearchName}`;
      }
      if (searchCopy.LastViewedDateTime) {
        const lastViewedDateTime = new Date(searchCopy.LastViewedDateTime);
        if (lastViewedDateTime.getFullYear() <= 1900) {
          searchCopy.LastViewedDateTime = null;
        }
      }
      return searchCopy;
    }
  }
}

export function reformatSearchNames(searches, userRootId) {
  let localSearches = _.cloneDeep(searches);
  localSearches?.forEach?.((search) => {
    if (_.isEmpty(search.FormattedSearchName)) {
      search.FormattedSearchName = search.Alias?.length > 0 ? search.Alias : search.Name;
      if (search.UserRootId !== userRootId && (search.CanClose ?? false) && search.FormattedSearchName?.length > 0 && !search.IsOrderTab && !search.IsViewEditorTab) {
        search.FormattedSearchName = `*${search.FormattedSearchName}`;
      }
      if (search.IsSearchGroup) {
        search.children = reformatSearchNames(search.children);
      }
    }
  });
  return localSearches;
}

export function getChildSearchByRootId(tree, rootId) {
  const flatTree = getFlatTree(tree);
  return flatTree?.find((search) => search.RootId === rootId);
}

export function getParentSearchGroupBySearchGroupRootId(tree, rootId, includeDeslected = false) {
  const flatTree = getFlatTree(tree);
  if (includeDeslected) {
    return flatTree?.find((search) => search.RootId === rootId && (search?.[IS_SEARCH_GROUP] ?? false) === true);
  } else {
    return flatTree?.find((search) => search.RootId === rootId && (search?.[IS_SEARCH_GROUP] ?? false) === true && search?.Selected);
  }
}

export function getCompleteSearchTreeByRootId(tree, rootId) {
  let result = [];
  tree?.forEach?.((item) => {
    if (item?.RootId === rootId || item?.SearchGroupRootId || parentContainsChildWithRootId(item, rootId)) {
      result.push(item); // Add the item to the result if its ID matches the target ID
    }
    if (item.children && item.children.length > 0) {
      // If the current item has children, recursively search in its children
      const childMatches = getCompleteSearchTreeByRootId(item.children, rootId);
      if (childMatches.length > 0) {
        result.push(...childMatches); // Add child matches to the result
      }
    }
  });
  return result;
}

function parentContainsChildWithRootId(parent, rootId) {
  if (parent?.IsSearchGroup) {
    if (parent?.children.length > 0) {
      for (const item of parent?.children) {
        if (item?.RootId === rootId || item?.SearchGroupRootId === rootId) {
          return true;
        } else {
          if (item?.children?.length > 0) {
            return parentContainsChildWithRootId(item, rootId);
          }
        }
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function updateParentTabInTree(tree, parentTab) {
  let localTree = _.cloneDeep(tree);
  for (let i = 0; i < localTree?.length; i++) {
    const search = localTree[i];
    if (search.RootId === parentTab.RootId && search?.[IS_SEARCH_GROUP]) {
      localTree[i] = parentTab;
      break;
    } else {
      if (search?.children?.length > 0) {
        localTree[i].children = updateParentTabInTree(search.children, parentTab);
      }
    }
  }
  return localTree;
}

export function getTempTabs(tree) {
  let flatTree = getFlatTree(tree);
  let tempTabs = [];
  flatTree?.forEach((search) => {
    if (search.IsOrderTab || search.IsViewEditorTab) {
      tempTabs.push(search);
    }
  });
  return tempTabs;
}

export function removeSearchFromTree(tree, searchToRemove) {
  let localTree = _.cloneDeep(tree);
  for (let i = 0; i < localTree?.length; i++) {
    const search = localTree[i];
    if (search.RootId === searchToRemove.RootId) {
      localTree.splice(i, 1);
      break;
    } else {
      if (search?.children?.length > 0) {
        localTree[i].children = removeSearchFromTree(search.children, searchToRemove);
      }
    }
  }
  localTree = removeEmptySearchGroups(localTree);
  return localTree;
}

export function deselectSearchGroupParents(searchGroup, tree) {
  let localTree = _.cloneDeep(tree);
  let parentSearchGroup = getParentSearchGroupBySearchGroupRootId(localTree, searchGroup?.[SEARCH_GROUP_ROOT_ID]);
  if (parentSearchGroup) {
    if (!doesSearchGroupHaveSelectedChildren(parentSearchGroup)) {
      parentSearchGroup.Selected = false;
    }
    localTree = updateParentTabInTree(localTree, parentSearchGroup);
    localTree = deselectSearchGroupParents(parentSearchGroup, localTree);
  }
  return localTree;
}

export function getChildGroupFromTreeByRootId(tree, rootId) {
  let childGroup = null;
  for (let i = 0; i < tree?.length; i++) {
    const search = tree[i];
    if (search.RootId === rootId && search?.[IS_SEARCH_GROUP]) {
      childGroup = search;
      break;
    } else {
      if (search?.children?.length > 0) {
        childGroup = getChildGroupFromTreeByRootId(search.children, rootId);
        if (childGroup) {
          break;
        }
      }
    }
  }
  return childGroup;
}

export function findSearchInTreeByName(tree, searchName) {
  let search = null;
  for (let i = 0; i < tree?.length; i++) {
    let currentSearch = tree[i];
    if (currentSearch.Name === searchName) {
      search = currentSearch;
      break;
    } else {
      if (currentSearch?.children?.length > 0) {
        search = findSearchInTreeByName(currentSearch.children, searchName);
        if (search) {
          break;
        }
      }
    }
  }
  return search;
}
