<template v-if="this.route.fullPath === '/'">
  <div>
    <div class="nav-header-simple">
      <img :class="headerImageStyle" :src="getHeaderImage" alt="" />
      <a @click="onHomeButtonClick" class="cursor-pointer select-none mr-2">
        <div class="flex mr-2" style="color: #34406b">
          <svg
            class="w-5 h-5 ml-1 mt-0.5 pt-0.5 home-screen-icon"
            data-slot="icon"
            fill="none"
            stroke-width="1.5"
            stroke="white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"></path>
          </svg>
          <div class="btn btn-secondary py-0 text-nowrap ml-2">
            <div class="my-0.5">Back Home</div>
          </div>
        </div>
      </a>
      <div class="w-full flex justify-content-end align-content-end">
        <div style="color: #ffffff !important" class="text-center mt-1.5">{{ loggedInUserFirstName }}</div>
        <div>
          <UserLoginStatus />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { DesqProductRootId, TrulyEsqProductRootId } from "@/lib/settings";
import getOrdersGridLink from "@/utils/navigation/getOrdersGridLink.js";
import UserLoginStatus from "@/components/UserLoginStatus";

export default {
  name: "SimpleNavbar",
  components: {
    UserLoginStatus,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    // Vuex
    const store = useStore();
    // State
    const loggedInUser = computed(() => store.state.auth.loggedInUser);
    // Getters
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    // Mutations
    const setIsSearchDocumentSelectedFalse = () => store.commit("setIsSearchDocumentSelected", false);

    // Functions
    async function onHomeButtonClick() {
      setIsSearchDocumentSelectedFalse();
      const productRootId = getGlobalSelectedProductRootId.value;
      switch (productRootId) {
        case TrulyEsqProductRootId:
        case DesqProductRootId:
          await router.push(getOrdersGridLink(productRootId)).catch(() => {
            console.error("Unable to open orders grid");
          });
          break;
        default:
          await router.push({ path: "/links" }).catch(() => {
            console.error("Unable to open links");
          });
          break;
      }
    }

    const getHeaderImage = computed(() => {
      const productRootId = getGlobalSelectedProductRootId.value;
      switch (productRootId) {
        case TrulyEsqProductRootId:
          return require("@/assets/images/truly-esq-logo-white.png");
        case DesqProductRootId:
          return require("@/assets/images/d-esq-logo-white.png");
        default:
          return require("@/assets/images/links-white-136w.png");
      }
    });

    const headerImageStyle = computed(() => {
      const productRootId = getGlobalSelectedProductRootId.value;
      switch (productRootId) {
        case TrulyEsqProductRootId:
          return "home-screen-header-image-esq";
        case DesqProductRootId:
          return "home-screen-header-image-desq";
        default:
          return "home-screen-header-image-links";
      }
    });

    const loggedInUserFirstName = computed(() => {
      let firstName = loggedInUser.value?.DisplayName?.split?.(" ")?.[0] ?? "";
      if (firstName.length > 0) {
        return `Welcome, ${firstName}`;
      }
      return "Welcome!";
    });

    return {
      router,
      route,

      getHeaderImage,
      loggedInUserFirstName,
      headerImageStyle,

      onHomeButtonClick,
    };
  },
};
</script>
