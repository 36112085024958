import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationBrandsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationBrands: [],
  },
  getters: {
    getOrganizationBrandByRootId: (state) => (rootId) => {
      return state.organizationBrands?.find((organizationBrand) => Number(organizationBrand?.RootId ?? 0) === Number(rootId ?? 0)) ?? null;
    },
  },
  mutations: {
    setOrganizationBrands(state, organizationBrandsData) {
      state.organizationBrands = organizationBrandsData ?? [];
    },
    clearOrganizationBrands(state) {
      state.organizationBrands = [];
    },
    _addOrganizationBrand(state, organizationBrand) {
      if (organizationBrand?.RootId > 0) {
        state.organizationBrands.push(organizationBrand);
      }
    },
    _updateOrganizationBrand(state, updateOrganizationBrand) {
      const rootId = Number(updateOrganizationBrand?.RootId ?? 0);
      if (rootId > 0) {
        let organizationBrandsData = _.cloneDeep(state.organizationBrands) ?? [];
        organizationBrandsData =
          [...organizationBrandsData.filter((organizationBrand) => Number(organizationBrand?.RootId ?? 0) !== rootId), _.cloneDeep(updateOrganizationBrand)] ?? [];
        state.organizationBrands = organizationBrandsData ?? [];
      }
    },
    _removeOrganizationBrand(state, deleteOrganizationBrand) {
      const rootId = Number(deleteOrganizationBrand?.RootId ?? 0);
      if (rootId > 0) {
        let organizationBrandsData = _.cloneDeep(state.organizationBrands) ?? [];
        organizationBrandsData = [...organizationBrandsData.filter((organizationBrand) => Number(organizationBrand?.RootId ?? 0) !== rootId)] ?? [];
        state.organizationBrands = organizationBrandsData ?? [];
      }
    },
  },
  actions: {
    async getOrganizationBrands(context, { OrganizationRootId, NoCache }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        NoCache = NoCache ?? false;
        const formattedUrl = `${organizationBrandsUrl}?organizationrootid=${OrganizationRootId}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        if (!NoCache) {
          context.commit("setOrganizationBrands", data);
        }
        return data;
      }, "organizationBrands - getOrganizationBrands");
    },
    async addOrganizationBrand(context, organizationBrand) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationBrandsUrl, Payload: organizationBrand });
        if (data?.RootId > 0) {
          organizationBrand.RootId = data.RootId;
          context.commit("_addOrganizationBrand", data);
        }
        return data;
      }, "organizationBrands - addOrganizationBrand");
    },
    async updateOrganizationBrand(context, organizationBrand) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationBrandsUrl}/${Number(organizationBrand?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationBrand });
        context.commit("_updateOrganizationBrand", data);
      }, "organizationBrands - updateOrganizationBrand");
    },
    async removeOrganizationBrand(context, organizationBrand) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationBrandsUrl}/${Number(organizationBrand?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationBrand", organizationBrand);
      }, "organizationBrands - removeOrganizationBrand");
    },
  },
};
