import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productTagsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productTags: [],
  },
  getters: {
  },
  mutations: {
    setProductTagData(state, productTagsData) {
      state.productTags = productTagsData?.sort((pt1, pt2) => (pt1.Name > pt2.Name ? 1 : -1)) ?? [];
    },
    clearProductTags(state) {
      state.productTags = [];
    },
    _addProductTag(state, productTag) {
      const productTagsData = _.cloneDeep(state.productTags) ?? [];
      productTagsData.push(productTag);
      state.productTags = productTagsData?.sort((pt1, pt2) => (pt1.Name > pt2.Name ? 1 : -1)) ?? [];
    },
    _updateProductTag(state, updateProductTag) {
      const rootId = Number(updateProductTag?.RootId ?? 0);
      if (rootId > 0) {
        let productTagsData = _.cloneDeep(state.productTags) ?? [];
        productTagsData = [...productTagsData.filter((productTag) => Number(productTag?.RootId ?? 0) !== rootId), _.cloneDeep(updateProductTag)] ?? [];
        state.productTags = productTagsData?.sort((pt1, pt2) => (pt1.Name > pt2.Name ? 1 : -1)) ?? [];
      }
    },
    _updateProductTagEnabled(state, updateProductTag) {
      const rootId = Number(updateProductTag?.RootId ?? 0);
      if (rootId > 0) {
        let productTag = state.productTags?.filter((productTag) => Number(productTag?.RootId ?? 0) === rootId) ?? null;
        if (productTag?.RootId > 0) {
          productTag.Enabled = updateProductTag.Enabled;
        }
      }
    },
    _removeProductTag(state, deleteProductTag) {
      const rootId = Number(deleteProductTag?.RootId ?? 0);
      if (rootId > 0) {
        let productTagsData = _.cloneDeep(state.productTags) ?? [];
        productTagsData = [...productTagsData.filter((productTag) => Number(productTag?.RootId ?? 0) !== rootId)] ?? [];
        state.productTags = productTagsData?.sort((pt1, pt2) => (pt1.Name > pt2.Name ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getProductTags(context, params) {
      await timeOperation(async () => {
        const CanUseServerCache = params?.CanUseServerCache || true;
        const formattedUrl = `${productTagsUrl}?productrootid=${Number(params?.ProductRootId ?? 0)}&productdatasourcerootid=${Number(params?.ProductDataSourceRootId ?? 0)}&canusecache=${CanUseServerCache}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductTagData", data);
      }, "productTags - getProductTags");
    },
    async addProductTag(context, productTag) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productTagsUrl, Payload: productTag });
        if (data?.RootId > 0) {
          productTag.RootId = data.RootId;
          context.commit("_addProductTag", data);
        }
        return data;
      }, "productTags - addProductTag");
    },
    async updateProductTag(context, productTag) {
      await timeOperation(async () => {
        const formattedUrl = `${productTagsUrl}/${Number(productTag?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productTag });
        context.commit("_updateProductTag", data);
      }, "productTags - updateProductTag");
    },
    async enableProductTag(context, productTag) {
      await timeOperation(async () => {
        const formattedUrl = `${productTagsUrl}/${Number(productTag?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: {...productTag, Enabled: true} });
        context.commit("_updateProductTagEnabled", data);
      }, "productTags - enableProductTag");
    },
    async disableProductTag(context, productTag) {
      await timeOperation(async () => {
        const formattedUrl = `${productTagsUrl}/${Number(productTag?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: {...productTag, Enabled: false} });
        context.commit("_updateProductTagEnabled", data);
      }, "productTags - disableProductTag");
    },
    async removeProductTag(context, productTag) {
      await timeOperation(async () => {
        const formattedUrl = `${productTagsUrl}/${Number(productTag?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductTag", productTag);
      }, "productTags - removeProductTag");
    },
  },
};
