import {ApiRequest, GetVerb, PutVerb, organizationVendorDataSourceFieldAccessSettingsUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationVendorDataSourceFieldAccessSettings: [],
  },
  getters: {
  },
  mutations: {
    setOrganizationVendorDataSourceFieldAccessSettings(state, organizationVendorDataSourceFieldAccessSettings) {
      state.organizationVendorDataSourceFieldAccessSettings = organizationVendorDataSourceFieldAccessSettings ?? [];
    },
    _updateOrganizationVendorDataSourceFieldAccessSetting(state, updateOrganizationVendorDataSourceFieldAccessSetting) {
      let organizationVendorDataSourceFieldAccessSetting = state.organizationVendorDataSourceFieldAccessSettings?.find((organizationVendorDataSourceFieldAccessSetting) => Number(organizationVendorDataSourceFieldAccessSetting?.OrganizationVendorDataSourceFieldAccessSettingRootId ?? 0) === Number(updateOrganizationVendorDataSourceFieldAccessSetting?.RootId ?? 0)) ?? null;
      if (!(organizationVendorDataSourceFieldAccessSetting?.RootId > 0)) {
        organizationVendorDataSourceFieldAccessSetting = state.organizationVendorDataSourceFieldAccessSettings?.find((organizationVendorDataSourceFieldAccessSetting) =>
          (Number(organizationVendorDataSourceFieldAccessSetting?.OrganizationDataSourceRootId ?? 0) === Number(updateOrganizationVendorDataSourceFieldAccessSetting?.OrganizationDataSourceRootId ?? 0)) &&
          (Number(organizationVendorDataSourceFieldAccessSetting?.RootId ?? 0) === Number(updateOrganizationVendorDataSourceFieldAccessSetting?.OrganizationDataSourceFieldRootId ?? 0))) ?? null;
      }
      if (organizationVendorDataSourceFieldAccessSetting?.RootId > 0) {
        organizationVendorDataSourceFieldAccessSetting.Hide = updateOrganizationVendorDataSourceFieldAccessSetting?.Hide ?? false;
        organizationVendorDataSourceFieldAccessSetting.Obfuscate = updateOrganizationVendorDataSourceFieldAccessSetting?.Obfuscate ?? false;
      }
    },
  },
  actions: {
    async getOrganizationVendorDataSourceFieldAccessSettings(context, { OrganizationRootId, OrganizationDataSourceRootId, IncludePossible, IncludeMetadata }) {
      OrganizationRootId = Number(OrganizationRootId ?? 0);
      OrganizationDataSourceRootId = Number(OrganizationDataSourceRootId ?? 0);
      IncludePossible = IncludePossible ?? false;
      IncludeMetadata = IncludeMetadata ?? true;
      await timeOperation(async () => {
        context.commit("setOrganizationVendorDataSourceFieldAccessSettings", { OrganizationDataSourceRootId, Data: [] });
        const formattedUrl = `${organizationVendorDataSourceFieldAccessSettingsUrl}?organizationrootid=${OrganizationRootId}&organizationdatasourcerootid=${OrganizationDataSourceRootId}&includepossible=${IncludePossible}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationVendorDataSourceFieldAccessSettings", data);
      }, "organizationVendorDataSourceFieldAccessSettings - getOrganizationVendorDataSourceFieldAccessSettings");
    },
    async updateOrganizationVendorDataSourceFieldAccessSetting(context, organizationVendorDataSourceFieldAccessSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationVendorDataSourceFieldAccessSettingsUrl}/${Number(organizationVendorDataSourceFieldAccessSetting?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationVendorDataSourceFieldAccessSetting });
        context.commit("_updateOrganizationVendorDataSourceFieldAccessSetting", data);
      }, "organizationVendorDataSourceFieldAccessSettings - updateOrganizationVendorDataSourceFieldAccessSetting");
    },
  },
};
