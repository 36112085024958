export default {
  state: {
    directUploadFiles: [],
  },
  getters: {},
  mutations: {
    addDirectUploadFile(state, directUploadFile) {
      state.directUploadFiles?.push?.(directUploadFile);
    },
    clearDirectUploadFiles(state) {
      state.directUploadFiles.value?.splice?.(0, state.directUploadFiles.value?.length ?? 0);
    },
    emptyDirectUploadFiles(state) {
      state.directUploadFiles = [];
    },
    updateDirectUploadFilesIndex(state, { Index, DirectUploadFile }) {
      state.directUploadFiles[Index] = DirectUploadFile;
    },
  },
};
