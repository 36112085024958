<template>
  <div>
    <ejs-richtexteditor
      v-if="width > 0"
      v-model:value="localModelValue"
      tabindex="0"
      ref="textEditor"
      :height="loaded ? height : localHeight"
      :width="loaded ? width : localWidth"
      :toolbarSettings="formBuilderToolbar ? formBuilderToolbarOptions : extendedToolbar ? extendedToolbarOptions : toolbarSettings"
      :placeholder="placeholder"
      :enabled="!disabled"
    />
    <ejs-richtexteditor
      v-else
      v-model:value="localModelValue"
      tabindex="0"
      ref="textEditor"
      :height="loaded ? height : localHeight"
      :toolbarSettings="formBuilderToolbar ? formBuilderToolbarOptions : extendedToolbar ? extendedToolbarOptions : toolbarSettings"
      :placeholder="placeholder"
      :enabled="!disabled"
    />
  </div>
</template>

<script>
import { ref, computed, watch, nextTick, onMounted } from "vue";

import { HtmlEditor, RichTextEditorComponent, Toolbar as RichToolBar } from "@syncfusion/ej2-vue-richtexteditor";

export default {
  name: "RichTextEditorWrapper",
  components: {
    "ejs-richtexteditor": RichTextEditorComponent,
  },
  provide: {
    richtexteditor: [RichToolBar, HtmlEditor],
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    toolbarSettings: {
      type: Object,
      default: null,
    },
    extendedToolbar: {
      type: Boolean,
      default: false,
    },
    formBuilderToolbar: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const localModelValue = ref(props.modelValue);
    watch(localModelValue, (is) => {
      if (is !== props.modelValue) {
        emit("update:modelValue", is);
      }
    });
    const modelValue = computed(() => props.modelValue);
    watch(modelValue, (is) => {
      localModelValue.value = is;
    });
    onMounted(() => {
      nextTick(() => {
        localModelValue.value = props.modelValue;
      });
    });

    const textEditor = ref(null);
    const localHeight = ref(null);
    const localWidth = ref(null);
    const loaded = ref(false);

    onMounted(() => {
      nextTick(() => {
        if (props.height > 0) {
          localHeight.value = props.height + 1;
          localWidth.value = props.width + 1;
          nextTick(() => {
            localHeight.value = props.height;
            localWidth.value = props.width;
            nextTick(() => {
              loaded.value = true;
            });
          });
        }
        if (textEditor.value) {
          textEditor.value.refreshUI(); // hack because syncfusion doesn't always show the text editor otherwise
        }
      });
    });

    const extendedToolbarOptions = computed(() => ({
      type: "MultiRow",
      enableFloating: false,
      items: [
        "Bold",
        "Italic",
        "Underline",
        "StrikeThrough",
        "FontName",
        "FontSize",
        "FontColor",
        // "BackgroundColor",
        "LowerCase",
        "UpperCase",
        "|",
        // "Formats",
        "Alignments",
        // "OrderedList",
        // "UnorderedList",
        "Outdent",
        "Indent",
        // "|",
        // "CreateLink",
        // "Image",
        // "|",
        "ClearFormat",
        // "Print",
        // "SourceCode",
        "FullScreen",
        "|",
        "Undo",
        "Redo",
      ],
    }));
    const formBuilderToolbarOptions = computed(() => ({
      type: "MultiRow",
      enableFloating: false,
      items: [
        "Bold",
        "Italic",
        "Underline",
        "StrikeThrough",
        "FontName",
        "FontSize",
        // "FontColor",
        // "BackgroundColor",
        // "LowerCase",
        // "UpperCase",
        "|",
        // "Formats",
        "Alignments",
        // "OrderedList",
        // "UnorderedList",
        "Outdent",
        "Indent",
        // "|",
        // "CreateLink",
        // "Image",
        // "|",
        "ClearFormat",
        // "Print",
        // "SourceCode",
        "FullScreen",
        "|",
        "Undo",
        "Redo",
      ],
    }));

    return {
      localModelValue,
      localHeight,
      localWidth,
      loaded,
      textEditor,

      extendedToolbarOptions,
      formBuilderToolbarOptions,
    };
  },
};
</script>

<style>
.e-richtexteditor .e-rte-toolbar.e-rte-tb-float,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-float {
  top: 0 !important;
  position: relative !important;
  width: 100% !important;
  overflow: hidden !important;
  height: 1rem !important;
}
</style>
