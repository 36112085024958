import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, partyRolesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    partyRoles: [],
  },
  getters: {},
  mutations: {
    setPartyRoleData(state, partyRolesData) {
      state.partyRoles = partyRolesData?.sort((partyRole1, partyRole2) => (partyRole1.Name > partyRole2.Name ? 1 : -1)) ?? [];
    },
    clearPartyRoles(state) {
      state.partyRoles = [];
    },
    _addPartyRole(state, partyRole) {
      const partyRolesData = _.cloneDeep(state.partyRoles) ?? [];
      partyRolesData.push(partyRole);
      state.partyRoles = partyRolesData.sort((partyRole1, partyRole2) => (partyRole1.Name > partyRole2.Name ? 1 : -1)) ?? [];
    },
    _updatePartyRole(state, updatePartyRole) {
      const rootId = Number(updatePartyRole?.RootId ?? 0);
      if (rootId > 0) {
        let partyRolesData = _.cloneDeep(state.partyRoles) ?? [];
        partyRolesData =
          [...partyRolesData.filter((partyRole) => Number(partyRole?.RootId ?? 0) !== rootId), _.cloneDeep(updatePartyRole)].sort((partyRole1, partyRole2) =>
            partyRole1.Name > partyRole2.Name ? 1 : -1
          ) ?? [];
        state.partyRoles = partyRolesData;
      }
    },
    _removePartyRole(state, deletePartyRole) {
      const rootId = Number(deletePartyRole?.RootId ?? 0);
      if (rootId > 0) {
        let partyRolesData = _.cloneDeep(state.partyRoles) ?? [];
        partyRolesData =
          [...partyRolesData.filter((partyRole) => Number(partyRole?.RootId ?? 0) !== rootId)].sort((partyRole1, partyRole2) => (partyRole1.Name > partyRole2.Name ? 1 : -1)) ?? [];
        state.partyRoles = partyRolesData;
      }
    },
  },
  actions: {
    async getPartyRolesData(context, { IncludeMetadata, PartyRootId }) {
      await timeOperation(async () => {
        const formattedUrl = `${partyRolesUrl
          }?includemetadata=${IncludeMetadata ?? false
          }&partyRootId=${Number(PartyRootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setPartyRoleData", data);
      }, "partyRoles - getPartyRolesData");
    },
    async addPartyRole(context, partyRole) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: partyRolesUrl, Payload: partyRole });
        context.commit("_addPartyRole", data);
        return data;
      }, "partyRoles - addPartyRole");
    },
    async updatePartyRole(context, partyRole) {
      await timeOperation(async () => {
        const formattedUrl = `${partyRolesUrl}/${Number(partyRole?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: partyRole });
        context.commit("_updatePartyRole", data);
      }, "partyRoles - updatePartyRole");
    },
    async removePartyRole(context, partyRole) {
      await timeOperation(async () => {
        const formattedUrl = `${partyRolesUrl}/${Number(partyRole?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removePartyRole", partyRole);
      }, "partyRoles - removePartyRole");
    },
  },
};
