import { store } from "@/store";
import { computed } from "vue";

export function openContextMenuWithBeforeFunctionAtXY(x, y, menuItems, beforeFunction, itemSelectedFunction) {
  // Mutations
  const setBeforeContextMenuOpenFunction = (item) => store.commit("setBeforeContextMenuOpenFunction", item);
  const setContextMenuItemSelectedFunction = (item) => store.commit("setContextMenuItemSelectedFunction", item);
  // State
  const contextMenuOpenFunction = computed(() => store.state.cm.contextMenuOpenFunction);

  setBeforeContextMenuOpenFunction(beforeFunction);
  setContextMenuItemSelectedFunction(itemSelectedFunction);

  if (!contextMenuOpenFunction.value) {
    console.warn("No open function set for context menu");
    return;
  }

  contextMenuOpenFunction.value(x, y, menuItems);
}

export function openContextMenuAtXY(x, y, menuItems, itemSelectedFunction) {
  // Mutations
  const setContextMenuItemSelectedFunction = (item) => store.commit("setContextMenuItemSelectedFunction", item);
  // State
  const contextMenuOpenFunction = computed(() => store.state.cm.contextMenuOpenFunction);

  setContextMenuItemSelectedFunction(itemSelectedFunction);

  if (!contextMenuOpenFunction.value) {
    console.warn("No open function set for context menu");
    return;
  }

  contextMenuOpenFunction.value(x, y, menuItems);
}

export function openContextMenuAtTargetElement(targetElementLeft, targetElementTop, menuItems, itemSelectedFunction) {
  // Mutations
  const setContextMenuItemSelectedFunction = (item) => store.commit("setContextMenuItemSelectedFunction", item);
  const setTargetElementTop = (item) => store.commit("setTargetElementTop", item);
  const setTargetElementLeft = (item) => store.commit("setTargetElementLeft", item);
  // State
  const contextMenuOpenFunction = computed(() => store.state.cm.contextMenuOpenFunction);

  setContextMenuItemSelectedFunction(itemSelectedFunction);
  setTargetElementTop(targetElementTop);
  setTargetElementLeft(targetElementLeft);

  if (!contextMenuOpenFunction.value) {
    console.warn("No open function set for context menu");
    return;
  }

  contextMenuOpenFunction.value(targetElementLeft, targetElementTop, menuItems);
}

export function openContextMenuWithBeforeAtTargetElement(targetElementLeft, targetElementTop, menuItems, beforeFunction, itemSelectedFunction) {
  // Mutations
  const setContextMenuItemSelectedFunction = (item) => store.commit("setContextMenuItemSelectedFunction", item);
  const setTargetElementTop = (item) => store.commit("setTargetElementTop", item);
  const setTargetElementLeft = (item) => store.commit("setTargetElementLeft", item);
  const setBeforeContextMenuOpenFunction = (item) => store.commit("setBeforeContextMenuOpenFunction", item);
  // State
  const contextMenuOpenFunction = computed(() => store.state.cm.contextMenuOpenFunction);

  setBeforeContextMenuOpenFunction(beforeFunction);
  setContextMenuItemSelectedFunction(itemSelectedFunction);
  setTargetElementTop(targetElementTop);
  setTargetElementLeft(targetElementLeft);

  if (!contextMenuOpenFunction.value) {
    console.warn("No open function set for context menu");
    return;
  }

  contextMenuOpenFunction.value(targetElementLeft, targetElementTop, menuItems);
}

export function closeContextMenu() {
  const closeContextMenuFunction = computed(() => store.state.cm.closeContextMenuFunction);
  if (!closeContextMenuFunction.value) {
    console.warn("No close function set for context menu");
    return;
  }
  closeContextMenuFunction.value();
}
